import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';



export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className={'map-container'}>
                <Map google={this.props.google} zoom={14}
                     initialCenter={{
                         lat: this.props.latitude,
                         lng: this.props.longitude
                     }}
                     className={'map'}>
                    <Marker
                        title={this.props.locationTitle}
                        position={{
                            lat: this.props.latitude,
                            lng: this.props.longitude
                        }}/>
                </Map>

            </div>
        );
    }
}

export default GoogleApiWrapper(
    (props) => ({
            apiKey: ('AIzaSyBLuS7tU_VaElJ2TmEj59edJKfXGRGvhM8')
        }
    ))(MapContainer)