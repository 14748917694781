import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as Util from "../Framework/Util";
const moment = require('moment-timezone');

function DuplicateTimeLogModal(props) {
    return (
        <Modal id="duplicate-timelog-modal" toggle={props.cancel} isOpen={props.isOpen}>
        <ModalHeader>Duplicate Shift</ModalHeader>
        <ModalBody>
            {props.duplicateTimeLog &&
                <p>User has already logged shift time between {moment(Date.parse(props.duplicateTimeLog.clockIn.replace('[UTC]', ''))).format('dddd, MMM DD (h:mm A)')} and {props.duplicateTimeLog.clockOut ? moment(Date.parse(props.duplicateTimeLog.clockOut.replace('[UTC]', ''))).format('dddd, MMM DD (h:mm A)') : "now"}.</p>
            }
        <br />
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={() => props.onDuplicateShiftClick(props.duplicateTimeLog.id)}>Open duplicate shift</Button>{' '}
            <Button color="secondary" onClick={() => props.cancel()}>Cancel</Button>
        </ModalFooter>
    </Modal>
)
}

export default DuplicateTimeLogModal;