import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import _ from "lodash";

function EmployeesTable (props) {
    const [lastOrderSelected, setLastOrderSelected] = useState('');
    const [userData, setUserData] = useState([]);

    useEffect(() =>{
        loadData();
    }, [props.data])

    function loadData(columnHeader){
        if (columnHeader) {
            switch (columnHeader) {
                case 'First Name':
                    columnHeader = 'nameFirst';
                    break;
                case 'Last Name':
                    columnHeader = 'nameLast';
                    break;
                case 'Last Worked':
                    columnHeader = 'lastTimeWorked';
                    break;
                default:
                    columnHeader = columnHeader.toLowerCase();
            }

            if (props.data && columnHeader !== lastOrderSelected) {
                switch (columnHeader) {
                    case 'active':
                        setUserData(_.sortBy(props.data, columnHeader).reverse());  //Sort Yes at the top on first click
                        break;
                    case 'nameFirst':
                        setUserData(_.sortBy(props.data, (d) => d.nameFirst.toLowerCase()));
                        break;
                    case 'nameLast':
                        setUserData(_.sortBy(props.data, (d) => d.nameLast.toLowerCase()));
                        break;
                    case 'title':
                        setUserData(_.sortBy(props.data, (d) => d.title ? d.title.toLowerCase() : 'zzz')); // Put data without title at bottom
                        break;
                    case 'position':
                        setUserData(_.sortBy(props.data, (d) => d.position ? d.position.toLowerCase() : 'zzz')); // Put data without position at bottom
                        break;
                    case 'department':
                        setUserData(_.sortBy(props.data, (d) => d.department ? d.department.name.toLowerCase() : 'zzz')); // Put data without department at bottom
                        break;
                    default:
                        setUserData(_.sortBy(props.data, columnHeader));
                }
                setLastOrderSelected(columnHeader);
            } else {
                switch (columnHeader) {
                    case 'active':
                        setUserData(_.sortBy(props.data, columnHeader));
                        break;
                    case 'nameFirst':
                        setUserData(_.sortBy(props.data, (d) => d.nameFirst.toLowerCase()).reverse());
                        break;
                    case 'nameLast':
                        setUserData(_.sortBy(props.data, (d) => d.nameLast.toLowerCase()).reverse());
                        break;
                    case 'title':
                        setUserData(_.sortBy(props.data, (d) => d.title ? d.title.toLowerCase() : 'zzz').reverse());
                        break;
                    case 'position':
                        setUserData(_.sortBy(props.data, (d) => d.position ? d.position.toLowerCase() : 'zzz').reverse());
                        break;
                    case 'department':
                        setUserData(_.sortBy(props.data, (d) => d.department ? d.department.name.toLowerCase() : 'zzz').reverse());
                        break;
                    default:
                        setUserData(_.sortBy(props.data, columnHeader).reverse());
                }
                setLastOrderSelected('')
            }
        } else {
            setUserData(_.sortBy(props.data, (d) => d.nameFirst.toLowerCase()));
            setLastOrderSelected('nameFirst');
        }
    }
        return (
            <div>
                <Row className="row-header col-header col-lg-12">
                    {
                        props.tableColumns.map((column, index) => {
                            return column.hideColumn ? '' :
                                <Col key={index} onClick={() => loadData(column.displayName)}>{column.displayName}</Col>
                        })
                    }
                </Row>
                {
                    userData.length < 1 ?
                        <NoData/> :
                    userData.map((row, index) => {
                        return <div
                            className={props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                            key={index}>
                            <Row className='row-data row-header col-lg-12'
                                 onClick={(event) => props.toggleEditModal(event, row)}>
                                <Col><span>{row.nameFirst}</span></Col>
                                <Col><span>{row.nameLast}</span></Col>
                                <Col><span>{row.lastTimeWorked ? row.lastTimeWorked : '-'}</span></Col>
                                <Col><span>{row.title ? row.title : "N/A"}</span></Col>
                                <Col><span>{row.position ? row.position : 'N/A'}</span></Col>
                                <Col><span>{row.department ? row.department.name : 'N/A'}</span></Col>
                                <Col><span>{row.active ? "Yes": 'No'}</span></Col>
                                <Col className="cursor-text">
                                    <span title="Send Reset Password Email" onClick={e =>{ e.stopPropagation(); props.resetPassword(row); }} className="action-icon material-icons">send</span>
                                </Col>
                            </Row>
                        </div>
                    })
                }
            </div>
        )
}

export default EmployeesTable;
