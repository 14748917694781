import React, {Component} from 'react';
import {Row, Col, Input, Label} from 'reactstrap';
import NoData from "./NoData";
import moment from "moment";

class HoursOfOperationTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.tableSettings = props.tableSettings;
        this.state = {};
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveData = this.saveData.bind(this);
        this.timeIsValid = this.timeIsValid.bind(this);
        this.endTimeIsValid = this.endTimeIsValid.bind(this);
        this.timeConvertsToMomentTime = this.timeConvertsToMomentTime.bind(this);
        this.days = props.data;
        this.saveDataParent = props.saveDataParent;
        this.timeInputStyle = {
            "maxWidth": "75px",
            "paddingLeft": ".25rem",
            "paddingRight": ".20rem"
        };
        this.hooTimeMiddleStyle = {
            padding: "8px 5px 0 5px"
        };
        this.inputValidationShift = {
            "marginLeft": "-15px"
        };
        this.hooTimeWrapperStyle = {
            overflow: "visible"
        };
        this.state = {
            days: props.data
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data && this.props.data.length > 0 && this.props.data !== this.state.days) {
            this.setState({days: this.props.data});
        }
    }

    handleCheckBoxChange(e, saveData) {
        if (this.tableSettings.canEdit) {
            for (let i = 0; i < this.state.days.length; i++) {
                let days = this.state.days;
                if (days[i].dayOfWeek === e.target.name) {
                    days[i].changed = true;
                    days[i].active = e.target.checked;
                    if (e.target.checked && days[i].startTime === "") {
                        days[i].startTime = "8:00 am";
                        days[i].endTime = "5:00 pm";
                    }
                    this.setState({days: days});
                    if (saveData) this.saveData(i);
                }
            }
        }
    }

    handleChange(e, dayOfWeek) {
        if (this.tableSettings.canEdit) {
            let days = this.state.days;
            for (let i = 0; i < days.length; i++) {
                if (days[i].dayOfWeek === dayOfWeek) {
                    if (days[i].active) {
                        days[i].changed = true;
                        days[i][e.target.name] = e.target.value;
                        this.saveData(i);
                    }
                }
            }
            this.setState({days: days});
        }
    };

    saveData(index) {
        if (this.tableSettings.canEdit) {
            let days = this.state.days;
            if (!days[index].active) {
                days[index].startTime = "";
                days[index].endTime = "";
                this.setState({days: days});
            }

            if (!this.state.days[index].active ||
                (this.state.days[index].active &&
                    this.endTimeIsValid(this.state.days[index].startTime, this.state.days[index].endTime))
            ) {
                this.saveDataParent(this.state.days[index], "POST");
            }
        }
    }

    timeConvertsToMomentTime(timeString) {
        let timeConverted = moment(timeString, "hh:mm a", true);
        if (!timeConverted.isValid()) {
            timeConverted = moment(timeString, "h:mm a", true);
        }
        return {
            isValid: timeConverted && timeConverted.isValid(),
            time: timeConverted
        };
    }

    timeIsValid(time) {
        if (time === null) return false;
        let hourPattern = /^[0-1]?[0-9]:[0-6][0-9]\s?[AaPp][Mm]$/g
        if (!time.match(hourPattern)) return false;
        return this.timeConvertsToMomentTime(time).isValid;
    }

    endTimeIsValid(startTime, endTime) {
        if (startTime === null || endTime === null) return null;
        let hourPattern = /^[0-1]?[0-9]:[0-6][0-9]\s?[AaPp][Mm]$/g
        if (!startTime.match(hourPattern) || !endTime.match(hourPattern)) return false;
        let startTimeConverted = this.timeConvertsToMomentTime(startTime);
        let endTimeConverted = this.timeConvertsToMomentTime(endTime);
        if (startTimeConverted.isValid && endTimeConverted.isValid && endTimeConverted.time > startTimeConverted.time) {
            return true;
        }
        return false;
    }

    createTable() {
        const self = this;
        const canEdit = self.props.tableSettings.canEdit;
        return <div>
            <Row className="row-header col-header col-lg-12">
                <Col className='col-lg-3' key="0">Active</Col>
                <Col className='col-lg-3' key="1">Day</Col>
                <Col key="2">Time</Col>
            </Row>
            {
                this.state.days.length < 1 ?
                    <NoData/> :
                    this.state.days.map((row, index) => {
                        return <div className={canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                                    key={index}>
                            <Row className='row-data row-header col-lg-12 no-gutters'>
                                <Col className='col-lg-3'>
                                    <Label className="switch switch-icon switch-pill switch-info"
                                           title="The day of the week.">
                                        <Input type="checkbox" name={row.dayOfWeek} ref={row.dayOfWeek}
                                               className="switch-input"
                                               checked={row.active}
                                               onChange={(e) => {
                                                   this.handleCheckBoxChange(e, true)
                                               }}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </Col>
                                <Col className='col-lg-3'>
                                    <span>{row.dayOfWeek}</span>
                                </Col>
                                {
                                    self.props.tableSettings.canEdit ?
                                        <Col style={this.hooTimeWrapperStyle}>
                                            <input type="text" placeholder="8:00 am" name="startTime" ref="startTime"
                                                   value={row.startTime ? row.startTime.replace(/^0+/, '') : ''}
                                                   className="form-control d-inline"
                                                   style={this.timeInputStyle}
                                                   onChange={(e) => {
                                                       this.handleChange(e, row.dayOfWeek)
                                                   }} required
                                            />
                                            {row.active && !this.timeIsValid(row.startTime) ?
                                                <span className="material-icons md-14 error"
                                                      style={this.inputValidationShift}>error</span> : null}
                                            <div className="d-inline" style={this.hooTimeMiddleStyle}>to</div>
                                            <input type="text" placeholder="5:00 pm" name="endTime" ref="endTime"
                                                   value={row.endTime ? row.endTime.replace(/^0+/, '') : ''}
                                                   className="form-control d-inline"
                                                   style={this.timeInputStyle}
                                                   onChange={(e) => {
                                                       this.handleChange(e, row.dayOfWeek)
                                                   }} required
                                            />
                                            {row.active && !this.endTimeIsValid(row.startTime, row.endTime) ?
                                                <span className="material-icons md-14 error"
                                                      style={this.inputValidationShift}>error</span> : null}
                                        </Col>
                                        :
                                        row.active === true ?
                                            <Col>{row.startTime} to {row.endTime}</Col> :
                                            ""
                                }
                            </Row>
                        </div>
                    })
            }
        </div>
    }


    render() {
        return this.createTable();
    }
}

export default HoursOfOperationTable;

