import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import ModalHeader from "react-bootstrap/es/ModalHeader";
import ModalBody from "react-bootstrap/es/ModalBody";
import {Button, Modal, ModalFooter} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';


class ImageCrop extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            crop: {
                aspect: 1,
                width: 50,
                height: 50,
                x: 0,
                y: 0,
            },
        }
    }

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
        this.makeClientCrop(this.state.crop, pixelCrop);
    };

    onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let file = e.target.files[0];
            if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
                toast.error('Image must be JPEG, PNG, or GIF', {position: 'bottom-right'});
                return;

            }
            if (file.size > 5000000) {
                toast.error('Image size too big. It must be 5MB or smaller', {position: 'bottom-right'});
                return;

            }
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(file);
            const el = document.getElementById('imageForm');
            if(el.reset){
                el.reset();
            }
            this.props.toggleModal();
        }
    };

    makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg',
            ).then((base64) => {
                this.setState({ base64 });
            });
        }
    }

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
            let dataUrl = canvas.toDataURL('image/jpeg');
            resolve(dataUrl);
        });
    }


    render() {
        const {src, crop, base64} = this.state;
        return (
            <div>
                <Button color='primary' onClick={() => document.getElementById(this.props.imageId).click()} style={{marginTop: '10px'}}>Image Upload</Button>
                <div id={'imageForm'}>
                    <input id={this.props.imageId} type={'file'} onChange={this.onSelectFile} hidden/>
                </div>
                <Modal isOpen={src && this.props.showModal} toggle={this.props.toggleModal}>
                    <ModalHeader>Crop Image</ModalHeader>
                    <ModalBody>
                        <div style={{height: '500px'}}>
                            <div style={{textAlign: 'center'}}>
                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {
                            this.setState({src: null});
                            this.props.toggleModal();}}>Cancel</Button>
                        <Button color="success" onClick={() => this.props.saveImage(base64)}>Save</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ImageCrop.propTypes = {
    saveImage: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default ImageCrop;