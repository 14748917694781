import React, {Component} from "react";
import PropTypes from 'prop-types';
import * as Util from "../Framework/Util";
import AsyncSelect from 'react-select/lib/Async';
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import _ from "lodash";

export default class SelectPayPeriodRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payPeriodRuleId: '',
            payPeriodRule: this.props.payPeriodRule
        }
    }

    handleChange = newPayPeriodRule => {
        // If the user backspaces all the way, newOvertimeRule becomes an empty array which errors out in the props.onChange method
        if (newPayPeriodRule && newPayPeriodRule.constructor === Array) {
            newPayPeriodRule = null;
        }

        if (newPayPeriodRule) {
            this.setState({
                payPeriodRuleId: newPayPeriodRule.id,
                payPeriodRule: newPayPeriodRule
            });
        } else {
            this.setState({
                payPeriodRuleId: '',
                payPeriodRule: null
            });
        }

        this.props.onChange(newPayPeriodRule);
    };

    loadPayPeriodRules = input => {
        const apiUrl = Util.apiSearchUrl(`pay_period_rule`, {data: input});
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let selectedRule = {
                    "id": "",
                    "name": "All",
                    "deletedOn": null
                };
                json.resultList.push(selectedRule);
                return _.filter(json.resultList, function(o) { return (o.deletedOn === null)})
            })
            .catch(error => ServerResponseHandler.handleError(error));
    };

    render() {
        return (
            <div className="fb-select">
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable={this.props.isClearable}
                    value={this.state.payPeriodRule}
                    noOptionsMessage={() => 'No Pay Period Rules found'}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    loadOptions={this.loadPayPeriodRules}
                    placeholder={'Select a Pay Period Rule...'}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

SelectPayPeriodRule.propTypes = {
    onChange: PropTypes.func.isRequired,
    payPeriodRule: PropTypes.object
};

SelectPayPeriodRule.defaultProps = {
    isClearable: true
};