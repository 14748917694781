import React from 'react';
import {Row, Col} from 'reactstrap';

const PtoTableRowData = (props) => {
    return <div>
        {
            props.pdfData.map((column, index) => {
                return <Row className={'row-header'} key={index}>
                    <Col>{index+1}</Col>
                    <Col>{column.firstName}</Col>
                    <Col>{column.lastName}</Col>
                    <Col>{column.sickHours}</Col>
                    <Col>{column.vacationHours}</Col>
                    <Col>{column.holidayHours}</Col>
                    <Col>{column.regularHours}</Col>
                    <Col>{column.overtime}</Col>
                    <Col>{column.total}</Col>
                    <Col>{column.pay}</Col>
                </Row>
            })
        }
    </div>
};
export default PtoTableRowData;
