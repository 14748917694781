import React from 'react';
import {Row, Col, Tooltip} from 'reactstrap';
import useTooltip from "../../../../Hooks";

function ProjectDetailsPdfData(props) {
    const [tooltip, setTooltip] = useTooltip({});
    const index = props.parentIndex;

    return (
        <div>
            {
                props.pdfData.map((column, index) => {
                    let tooltipKey = props.parentIndex + '-' + index;
                    return <Row key={index} className={'mb-2 text-center row-header'}>
                        <Col id={'date' + tooltipKey} className={'ellipsis'}>{column.date}</Col>
                        <Col id={'employee' + tooltipKey} className="ellipsis">{column.employee}</Col>
                        <Col id={'position' + tooltipKey} className="ellipsis">{column.position}</Col>
                        <Col id={'hours' + tooltipKey} className="ellipsis">{column.hours}</Col>
                        {props.showColumn ? <Col id={'cost' + tooltipKey} className="ellipsis">{column.cost}</Col> : ''}
                        <Tooltip target={'date' + tooltipKey} isOpen={tooltip['date' + tooltipKey]} toggle={() => setTooltip('date' + tooltipKey)}>{column.date}</Tooltip>
                        <Tooltip target={'employee' + tooltipKey} isOpen={tooltip['employee' + tooltipKey]} toggle={() => setTooltip('employee' + tooltipKey)}>{column.employee}</Tooltip>
                        <Tooltip target={'position' + tooltipKey} isOpen={tooltip['position' + tooltipKey]} toggle={() => setTooltip('position' + tooltipKey)}>{column.position}</Tooltip>
                        <Tooltip target={'hours' + tooltipKey} isOpen={tooltip['hours' + tooltipKey]} toggle={() => setTooltip('hours' + tooltipKey)}>{column.hours}</Tooltip>
                        {props.showColumn ? <Tooltip target={'cost' + tooltipKey} isOpen={tooltip['cost' + tooltipKey]} toggle={() => setTooltip('cost' + tooltipKey)}>{column.cost}</Tooltip> : ''}
                    </Row>
                })
            }
        </div>
    )
}

export default ProjectDetailsPdfData;
