import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import * as Util from '../../../../components/Framework/Util';

const companySettings = Util.getCompanySettings();

function ProjectDetailsTotalSummary(props) {
    return (
        <Container fluid className={'justify-content-end mt-5'}>
            <div>
                <Row className={'col-3 offset-9'}><Col><h5
                    className='text-center'>{companySettings.projectSingleName + ' Totals'}</h5></Col></Row>
                <Row className={'col-3 offset-9 mt-2'}>
                    <Col>
                        <div>
                            <Row><Col><strong>{'Hours:'}</strong></Col></Row>
                            {props.showColumn ? <Row><Col><strong>{'Cost:'}</strong></Col></Row> : ''}
                        </div>
                    </Col>
                    <div>
                        <Col>
                            <Row><Col><strong>{Util.formatTimeFromSeconds(props.totals.duration)}</strong></Col></Row>
                            {props.showColumn ? <Row><Col><strong>{Util.formatCurrency(props.totals.cost.toFixed(2))}</strong></Col></Row> : ''}
                        </Col>
                    </div>
                </Row>
            </div>
        </Container>

        // <div className={'mt-3 p-3'}>
        //         <div className={'mb-2'}><h4 className={'text-center'}>Employee Totals</h4></div>
        //         <Row className={'row-header col-header col-lg-12'}>
        //             {
        //                 props.tableHeaders.map((column, index) => {
        //                     return <Col key={index} className={column === 'Employee Name' ? props.class : ''}>{column}</Col>
        //                 })
        //             }
        //         </Row>
        //
        //         <Row className={'row-header col-lg-12'}>
        //             <Col className={"props.class"}>{props.totals.name}</Col>
        //             <Col>{Util.formatTimeFromSeconds(props.totals.duration)}</Col>
        //             <Col>{Util.formatCurrency(props.totals.cost)}</Col>
        //         </Row>
        //     </div>
    )
}

export default ProjectDetailsTotalSummary;
