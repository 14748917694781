import React, {Component} from 'react';

class Print extends Component {
    render() {
        return (
            <div key="printData">
                {this.props.data}
                {window.print()}
            </div>
        )
    }
}

export default Print;