import React, {useState} from "react";
import PropTypes from 'prop-types';
import * as Util from '../Framework/Util';
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import AsyncSelect from 'react-select/lib/Async';

export default function SelectEmployee(props) {
    const [company, setCompany] = useState(null);
    const [companyId, setCompanyId] = useState('');


    function handleChange(company) {
        // If the user backspaces all the way, company becomes an empty array which errors out in the props.onChange method
        if (company && company.constructor === Array) {
            company = null;
        }

        if (company) {
            setCompany(company);
            setCompanyId(company.id);
        } else {
            setCompany(null);
            setCompanyId('');
        }

        props.onChange(company);
    }

    function loadCompanies(input) {
        const search = {};
        search.data = input;

        const apiUrl = Util.apiSearchUrl(`company_global/search_list`, search);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                // for (const emp of json.resultList) {
                //     emp.details = `${Util.nameFormatter(emp.nameFirst, emp.nameLast)}`;
                // }
                return json.resultList;
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    return (
        <div className="fb-select">
            <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable
                value={company}
                noOptionsMessage={() => 'No Companies found'}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                loadOptions={loadCompanies}
                placeholder={'Select a Company...'}
                onChange={handleChange}
            />
        </div>
    )
}

// SelectEmployee.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     employee: PropTypes.object
// };