import React, {Component} from 'react';
import {Button, Container, InputGroup, Label} from 'reactstrap';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import ImageCrop from '../../../components/Framework/ImageCrop';
import {ToastContainer, toast} from 'react-toastify';
import TimeClockPinModal from "../../../components/Modal/TimeClockPinModal";

const to = require('to-case');

class UserSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            updatedFrom: 'settings',
            nameFirst: '',
            nameLast: '',
            email: '',
            username: '',
            phone: '',
            imageUrl: '',
            image: '',
            currentPassword: '',
            password: '',
            confirm: '',
            newPin: '',
            showModal: false,
            showPinModal: false,
            pinLength: '',
        };
    }

    componentDidMount() {
        this.loadData(Util.getUserTenant().id);
    }

    handleChange = (e) => {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            if (e.target.name.includes('password')) {
                this.showPasswordInputError(e.target.name);
            } else {
                Util.showInputError(this, e.target.name);
            }
        }
    };

    changePassword = () => {
        if (!this.showPasswordFormErrors()) {
            return;
        }

        this.setState({loading: true});
        let payload = {
            username: Util.getUserTenant().username,
            currentPassword: this.state.currentPassword,
            newPassword: this.state.password,
            confirmationPassword: this.state.confirm,
        };

        const apiUrl = Util.apiUrl(`users/change_password`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Password successfully changed', {position: 'bottom-right'});
                // this.loadData(json.UserTenant.id);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, currentPassword: '', password: '', confirm: ''}));
        this.removeActiveClass();
    };

    changePin = () => {
        let pin = Util.generateRandomPin(this.state.pinLength);
        this.setState({newPin: pin});
        let payload = {
            pin: pin.toString(),
        };

        this.saveNewPin(payload, Util.getUserTenant().id
        ).then((successful) => {
            if (!successful) {
                this.changePin();
                return;
            }
            this.togglePinModal();
        });
    };

    showPasswordInputError = (refName) => {
        const validity = this.refs[refName].validity;
        const name = this.refs[refName].name;
        const error = document.getElementById(refName + 'Error');
        const isPasswordConfirm = refName === 'passwordConfirm';

        if (validity === undefined || validity === null) {
            return true;
        }

        if (isPasswordConfirm) {
            if (this.refs.password.value !== this.refs.passwordConfirm.value) {
                this.refs.passwordConfirm.setCustomValidity('Passwords do not match');
            } else {
                this.refs.passwordConfirm.setCustomValidity('');
            }
        }

        if (!validity.valid) {
            if (validity.valueMissing) {
                error.textContent = to.sentence(name) + ' is a required field';
            } else if (validity.typeMismatch) {
                error.textContent = to.sentence(name) + ' should be a valid email address';
            } else if (validity.patternMismatch) {
                error.textContent = this.refs[refName].title;
            } else if (validity.customError) {
                error.textContent = 'Passwords do not match';
            }
            return false;
        }

        error.textContent = '';
        return true;
    };

    toggleModal = () => {
        this.setState({showModal: !this.state.showModal})
    };

    togglePinModal = () => {
        this.setState({showPinModal: !this.state.showPinModal})
    };

    saveImage = (base64) => {
        this.setState({image: base64, showModal: !this.state.showModal});
        let payload = {
            image: base64
        };
        this.saveData(payload, Util.getUserTenant().id, false);
    };

    render() {
        const user = this.state;

        let image = <img className="default-image-icon md-card-image image-circle" id="userImage"
                         src="/img/image_placeholder.png" width={200} height={200}/>;
        if (user && (user.image || user.imageUrl)) {
            image = <img className="default-image-icon md-card-image img-thumbnail image-circle" id="userImage"
                         src={user.image || user.imageUrl} width={200} height={200}/>;
        }
        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <br/>
                    <div>
                        <div className="row ">
                            <div className="col-lg-5 col-md-12 mt-lg-5 mt-md-0">
                                <div className={'text-center mt-lg-5 mt-md-0'}>
                                    <div className={'mt-lg-5 mt-md-0'}>{image}
                                        <div className={'mb-2'}>
                                            <ImageCrop
                                                showModal={this.state.showModal}
                                                saveImage={this.saveImage}
                                                toggleModal={this.toggleModal}
                                                imageId={'userSettingsImage'}
                                            />
                                            <TimeClockPinModal
                                                showModal={this.state.showPinModal}
                                                toggleModal={this.togglePinModal}
                                                pin={this.state.newPin}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-lg-7 mt-3 col-md-12 pr-lg-5 pr-md-0 "}>
                                <div className={"mb-2"}>
                                    <form id="user-form" noValidate>
                                        <Label>First Name<span className="error">*</span></Label>
                                        <InputGroup className="mb-3">
                                            <input type="text" placeholder="First Name" name="nameFirst" ref="nameFirst"
                                                   value={user.nameFirst}
                                                   className="form-control tl-required" onChange={this.handleChange}
                                                   required/>
                                        </InputGroup>

                                        <Label>Last Name<span className="error">*</span></Label>
                                        <InputGroup className="mb-3">
                                            <input type="text" placeholder="Last Name" name="nameLast" ref="nameLast"
                                                   value={user.nameLast}
                                                   className="form-control tl-required" onChange={this.handleChange}
                                                   required/>
                                        </InputGroup>

                                        <Label>Email<span className="error">*</span></Label>
                                        <InputGroup className="mb-3">
                                            <input type="email" placeholder="Name" name="email" ref="email"
                                                   value={user.email}
                                                   className="form-control tl-required" onChange={this.handleChange}
                                                   required/>
                                        </InputGroup>

                                        <Label>Phone</Label>
                                        <InputGroup className="mb-3">
                                            <input type="text" placeholder="Phone Number" name="phone" ref="phone"
                                                   value={user.phone}
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>
                                        <div className={'text-right'}>
                                            <Button color="primary" className="btn"
                                                    disabled={this.state.loading}
                                                    onClick={() => this.handleSubmit()}>Save Settings</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={'mb-2'}>
                                    <form id="password-form" noValidate>
                                        <Label>Current Password</Label>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">lock</span>
                                            </span>
                                            </div>
                                            <input value={this.state.currentPassword} type="password"
                                                   placeholder="Current Password" name="currentPassword"
                                                   ref="currentPassword"
                                                   className="form-control tl-required" onChange={this.handleChange}
                                                   required/>
                                        </InputGroup>

                                        <Label>New Password</Label>
                                        <div className="errorDiv" id="passwordError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">lock</span>
                                            </span>
                                            </div>
                                            <input value={this.state.password} type="password"
                                                   placeholder="New Password"
                                                   name="password" ref="password"
                                                   className="form-control password-validate"
                                                   onChange={this.handleChange}
                                                   required
                                                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,}"
                                                   title="Must contain at least one number and one uppercase and lowercase letter, and at least 9 or more characters"/>
                                        </InputGroup>

                                        <Label>Confirm New Password</Label>
                                        <div className="errorDiv" id="confirmError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">lock_open</span>
                                            </span>
                                            </div>
                                            <input value={this.state.confirm} type="password"
                                                   placeholder="Confirm New Password"
                                                   name="confirm" ref="confirm"
                                                   className="form-control password-validate"
                                                   onChange={this.handleChange}
                                                   autoComplete="new-password" required/>
                                        </InputGroup>
                                        <div className={'text-right'}>
                                            <Button color="primary" id="change-password" className="btn"
                                                    disabled={this.state.loading} onClick={this.changePassword}>Change
                                                Password</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={"mb-5"}>
                                    <form id="pin-form" noValidate>
                                        <Label>New Time Clock PIN</Label>
                                        <div className={'text-right'}>
                                            <Button color="primary" id="change-pin" className="btn"
                                                    disabled={this.state.loading} onClick={this.changePin}>Change
                                                Pin</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }

    showPasswordFormErrors = () => {
        let isFormValid = true;

        const currentPwd = document.getElementById('password-form').querySelector('.tl-required');
        currentPwd.classList.add('active');
        let isInputValid = Util.showInputError(this, currentPwd.name);
        if (!isInputValid) {
            isFormValid = false;
        }

        document.getElementById('password-form').querySelectorAll('.password-validate').forEach(input => {
            input.classList.add('active');

            isInputValid = this.showPasswordInputError(input.name);

            if (!isInputValid) {
                isFormValid = false;
            }
        });

        return isFormValid;
    };

    showFormErrors = () => {
        let isFormValid = true;

        document.getElementById('user-form').querySelectorAll('.tl-required').forEach(input => {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        });

        return isFormValid;
    };

    handleSubmit = () => {
        if (!this.showFormErrors()) {
            return;
        }

        let payload = {
            updatedFrom: this.state.updatedFrom,
            nameFirst: this.state.nameFirst,
            nameLast: this.state.nameLast,
            email: this.state.email,
            username: this.state.username,
            phone: this.state.phone,
            imageUrl: this.state.imageUrl,
            image: this.state.image,
        };

        this.saveData(payload, Util.getUserTenant().id);
    };

    saveData = (user, userId, reloadData) => {
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`users/${userId}`);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(user),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('User was saved', {position: 'bottom-right'});
                if (reloadData) {
                    this.loadData(userId);
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, newPin: ''}));
    };

    saveNewPin = async (user, userId) => {
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`users/${userId}`);
        let successful = false;
        await fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(user),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (!json.errorMessage) {
                    successful = true;
                    toast.success('Pin was updated', {position: 'bottom-right'});
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
        return successful;
    };

    loadData = (userId) => {
        this.setState({loading: true});

        const apiUrl = Util.apiUrl(`users/${userId}`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const user = json;

                if (!user.imageUrl) {
                    user.imageUrl = '';
                }

                this.setState({
                    nameFirst: user.nameFirst,
                    nameLast: user.nameLast,
                    email: user.email,
                    username: user.username,
                    phone: user.phone,
                    imageUrl: user.imageUrl,
                    currentPassword: '',
                    password: '',
                    confirm: '',
                    newPin: '',
                });

                localStorage.setItem("userTenant", JSON.stringify(json));
                this.removeActiveClass();

                this.loadCompanySettings();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    };

    loadCompanySettings = () => {
        const apiUrl = Util.apiUrl(`company/settings`);

        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const company = json;

                this.setState({
                    pinLength: company.pinLength
                });
            })
    }

    removeActiveClass = () => {
        const elems = document.querySelectorAll("input");

        elems.forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
    }
}

export default UserSettings;