import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";


class AccrualRulesTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                self.props.data && self.props.data.length > 0 ?
                    self.props.data.map((row, index) => {
                        return <div className={self.props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'} key={index}>
                            <Row className='row-data row-header col-lg-12' onClick={(event) => self.props.toggleEditModal(event, row)}>
                                <Col><span>{row.hours}</span></Col>
                                <Col><span>{row.frequency}</span></Col>
                                <Col><span>{row.startNum === 0 ? "Immediately" : row.startNum + ' ' + row.startUnit}</span></Col>
                                <Col><span>{row.endNum === 100 && row.endUnit === "Years" ? "Indefinitely" : row.endNum + ' ' + row.endUnit}</span></Col>
                                {
                                    !self.props.tableSettings.canDelete
                                        ?
                                        ''
                                        :
                                        <Col className="cursor-text">
                                                     <span title="Delete"
                                                           onClick={() => self.props.toggleConfirmDeleteModal(row)}
                                                           className="delete action-icon material-icons">clear</span>
                                        </Col>
                                }
                            </Row>
                        </div>
                    }) :
                    <NoData/>
            }
        </div>
    }


    render() {
        return this.createTable();
    }
}

export default AccrualRulesTable;
