import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as Util from "../Framework/Util";
import {RadioGroup, ReversedRadioButton} from "../Framework/RadioButtons";
import moment from "moment";
import SelectEmployee from "../Selects/SelectEmployee";
import SelectPtoStatus from "../Selects/SelectPtoStatus";
import SelectPtoVacationType from "../Selects/SelectPtoVacationType";
import FBDatePicker from "../DateTimePickers/FBDatePicker";

class TimeOffRequestModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            employee: null,
            id: null,
            employeeCode: null,
            employeeName: '',
            type: 'Vacation',
            status: 'Pending',
            description: '',
            startDate: null,
            endDate: null,
            note: '',
            hours: 8,
            includeWeekends: true,
            hideEndDay: false,
            disableEndDay: true,
            dateRadio: 'single',
            isEditModal: false,
            ptoRequest: props.ptoRequest,
            useInternationalDates: Util.getCompanySettings().useInternationalDates,
        };

        this.previousState = this.state;
    }

    componentDidUpdate() {
        if (this.props.ptoRequest) {
            this.toggleEditModal(this.props.ptoRequest);
        }
    }

    componentDidMount() {
        this.setState(this.previousState);
    }

    showFormErrors = () => {
        let inputs = document.getElementById('modal-form').querySelectorAll('input');
        let isFormValid = true;
        inputs.forEach(input => {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        });

        if (this.state.startDate == null) {
            isFormValid = false;
        }

        return isFormValid;
    };

    handleDateFocusChange = (focusField, dateField, focused) => {
        this.setState({[focusField]: focused.focused});
        if (focused.focused) {
            return;
        }
        let element = document.getElementById(dateField);
        if (element && !Util.isEmpty(element.value)) {
            let dateValue = moment(element.value);
            if (!dateValue.isValid()) {
                element.value = '';
            }
        }
    };

    handleChange = (e) => {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    };

    handlePtoStatusChange = (ptoStatus) => {
        this.setState({status: ptoStatus});
    };

    handlePtoTypeChange = (ptoType) => {
        this.setState({type: ptoType});
    };

    handleDateChange = (field, date) => {
        this.setState({[field]: date});
    };

    handleDaysRadioChange = (val) => {
        this.setState({dateRadio: val, disableEndDay: val === 'single', includeWeekends: false});

        if (val === 'single') {
            this.setState({endDate: this.state.startDate});
        }
    };

    handleCheckBoxChange = (e) => {
        this.setState({[e.target.name]: e.target.checked});
    };

    handleEmployeeChange = (employee) => {
        if (!employee) {
            this.setState({employeeCode: '', employee: null});
        } else {
            this.setState({employeeCode: employee.id, employee: employee});
        }
    };

    toggleModal = () => {
        this.props.toggleModal();
    };

    render() {
        let employeeList = null;
        if (this.props.showEmployeeList) {
            employeeList = (
                this.state.employeeName
                    ?
                    <div className="clearfix" style={{marginBottom: '10px'}}>
                        <Label>Time off for:
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}>{'  ' + this.state.employeeName}</span>
                        </Label>
                    </div>
                    :
                    <div>
                        <Label>Employee</Label>
                        <InputGroup className="mb-3">
                            <SelectEmployee employee={this.state.employee} onChange={this.handleEmployeeChange}/>
                        </InputGroup>
                    </div>
            );
        }
        return (
            <div>
                <Modal isOpen={this.props.newDialog}>
                    <ModalHeader toggle={() => this.toggleModal()}>Time Off Request</ModalHeader>
                    <ModalBody id="modal-form">
                        {employeeList}
                        <div hidden={this.state.hideEndDay || this.state.isEditModal}>
                            <RadioGroup onChange={this.handleDaysRadioChange} value={this.state.dateRadio} horizontal>
                                <ReversedRadioButton value="single">Single Day</ReversedRadioButton>
                                <ReversedRadioButton value="multiple">Multiple Days</ReversedRadioButton>
                            </RadioGroup>
                            <br/>
                            <br/>
                        </div>

                        <div className="clearfix">
                            <div style={{width: "50%", float: "left", marginBottom: '15px'}}>
                                <Label>Start Date</Label>
                                <FBDatePicker
                                    id="startDate"
                                    value={this.state.startDate}
                                    onChange={date => this.handleDateChange('startDate', date)}
                                    placeholder="Start Date"
                                />
                            </div>

                            <div style={{width: "50%", float: "left"}} hidden={this.state.hideEndDay}>
                                <Label>End Date</Label>
                                <FBDatePicker
                                    id="endDate"
                                    value={this.state.endDate}
                                    disabled={this.state.disableEndDay}
                                    onChange={date => this.handleDateChange('endDate', date)}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div hidden={this.state.isEditModal || this.state.disableEndDay} className="clearfix"
                             style={{marginBottom: 5 + "px"}}>
                            <Label>Include Weekends</Label>
                            <Label className="switch switch-icon switch-pill switch-info pull-right">
                                <Input type="checkbox" name="includeWeekends" innerRef="includeWeekends"
                                       className="switch-input"
                                       checked={this.state.includeWeekends}
                                       disabled={this.state.disableEndDay}
                                       onChange={this.handleCheckBoxChange}/>
                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                <span className="switch-handle"/>
                            </Label>
                        </div>

                        <div className="clearfix" style={{marginBottom: 5 + "px"}}>
                            <Label>Hours Per Day</Label>
                            <input type="number" step="0.1" name="hours" ref="hours" value={this.state.hours} min={0}
                                   className="pull-right" onChange={this.handleChange} style={{width: 60 + "px"}}
                                   required/>
                        </div>

                        <Label>Type</Label>
                        <div className="mb-3">
                            <SelectPtoVacationType ptoType={this.state.type} onChange={this.handlePtoTypeChange}/>
                        </div>

                        <Label>Time Off Note</Label>
                        <InputGroup className="mb-3">
                            <input type="text" placeholder="Time off notes" name="note" ref="note"
                                   value={this.state.note} style={{borderRadius: '4px', border: '1px solid #CCCCCC'}}
                                   className="form-control" onChange={this.handleChange}/>
                        </InputGroup>
                        {
                            !Util.isManager()
                                ? ''
                                : (
                                    <div>
                                        <Label>Status</Label>
                                        <div className="mb-3">
                                            <SelectPtoStatus includeAll={false} ptoStatus={this.state.status}
                                                             onChange={this.handlePtoStatusChange}/>
                                        </div>
                                    </div>
                                )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.handleSubmit()}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => this.toggleModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    handleSubmit = () => {
        if (!this.showFormErrors()) {
            return;
        }

        let endDate = this.state.endDate;
        if (!endDate) {
            endDate = this.state.startDate;
        }

        let payload = {
            employeeCode: this.state.employeeCode,
            type: this.state.type,
            status: this.state.status,
            description: this.state.description,
            startDate: this.state.startDate,
            endDate: endDate,
            note: this.state.note,
            hours: this.state.hours,
            includeWeekends: this.state.includeWeekends || this.state.disableEndDay,
        };
        this.setState({
            employeeCode: null,
            type: 'Vacation',
            status: 'Pending',
            description: '',
            startDate: null,
            endDate: null,
            note: '',
            hours: 8,
            includeWeekends: true,
        });
        this.toggleModal();
        this.props.addPtoRequest('POST', payload);
    };
}

TimeOffRequestModal.propTypes = {
    addPtoRequest: PropTypes.func.isRequired,
    ptoRequest: PropTypes.object,
    newDialog: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func,
    showEmployeeList: PropTypes.bool.isRequired,
};

TimeOffRequestModal.defaultProps = {
    ptoRequest: null,
};

export default TimeOffRequestModal;