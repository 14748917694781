import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as Util from "../Framework/Util";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import LoadingModal from "../Modal/LoadingModal";

class AuditComparisonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: localStorage.getItem("isAdmin"),
            uuidMap: new Map(),
            currentAuditData: null,
            loading: false,
        };
    }

    componentDidUpdate(prevProps) {
        if(prevProps.modal === false && this.props.modal === true){
            this.setState({currentAuditData: null, loading: true}, this.getIndividualAuditEntryData);
        }
    }

    getIndividualAuditEntryData() {
        let url = Util.apiUrl('audit/getByAuditId/' + this.props.rowId);

        fetch(url, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(async json => await this.generateAuditData(json))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    async generateAuditData(json) {
        let oldData = {};
        if(json["old_row_data"] !== '') oldData = JSON.parse(json["old_row_data"]);
        let newData = {};
        if(json["new_row_data"] !== '') newData = JSON.parse(json["new_row_data"]);
        let values = {oldData: oldData, newData: newData};
        this.setState({currentAuditData: values, loading: false});

        await this.iterateRowDataForKeyUuidValues(oldData);
        await this.iterateRowDataForKeyUuidValues(newData);
    }

    async iterateRowDataForKeyUuidValues(dataset) {
        let userKeyList = ["createdBy", "lastEditedBy", "managerTenantId"];
        let uuidMap = this.state.uuidMap;

        for (let [key, value] of Object.entries(dataset)) {
            if (userKeyList.includes(key))
                uuidMap = await this.getUpdatedUuidMap(value, uuidMap, 'users/');

            if (key === "departmentId" && value !== null)
                uuidMap = await this.getUpdatedUuidMap(value, uuidMap, 'departments/');

            if (key === "payPeriodRuleId" && value !== null)
                uuidMap = await this.getUpdatedUuidMap(value, uuidMap, 'pay_period_rule/');

            if (key === "ptoRuleId" && value !== null)
                uuidMap = await this.getUpdatedUuidMap(value, uuidMap, 'pto_rule/');
        }

        this.setState({uuidMap: uuidMap});
    }

    async getUpdatedUuidMap(value, uuidMap, apiEndpoint) {
        if (value == null) return uuidMap;

        let returnedValue = await this.getGenericTypeByUuid(apiEndpoint, value);
        return this.insertIntoUuidMap(value, returnedValue, uuidMap);
    }

    async getGenericTypeByUuid(apiEndpoint, uuid) {
        let url = Util.apiUrl(apiEndpoint + uuid);
        let response = await fetch(url, {credentials: 'include'});
        return ServerResponseHandler.getResponse(response);
    }

    async insertIntoUuidMap(uuid, value, uuidMap) {
        if (uuidMap === undefined || uuidMap == null) return uuidMap;
        if (uuidMap.has(value)) return uuidMap;

        uuidMap.set(uuid, value);
        return uuidMap;
    }

    checkWeirdBooleanValue(value) {
        if (value === "base64:type16:AQ==") value = "True";
        if (value === "base64:type16:AA==") value = "False";

        return value;
    }

    getUserValue(uuid) {
        let updatedValue = uuid;
        if (this.state.uuidMap !== null && uuid !== null) {
            let user = this.state.uuidMap.get(uuid);
            if (user != null)
                updatedValue = (user["nameFirst"] !== undefined ? user["nameFirst"] : "") + " " + (user["nameLast"] !== undefined ? user["nameLast"] : "");
        }
        return updatedValue;
    }

    getNameFromGeneric(uuid) {
        let updatedValue = uuid;
        if (this.state.uuidMap !== null && uuid !== null) {
            let generic = this.state.uuidMap.get(uuid);
            if (generic !== undefined)
                updatedValue = (generic["name"] !== undefined ? generic["name"] : "");
        }
        return updatedValue;
    }

    checkForUuidValueToResolve(key, value) {
        let userKeyList = ["createdBy", "lastEditedBy", "managerTenantId"];

        let updatedValue;
        updatedValue = this.checkWeirdBooleanValue(value);
        if (userKeyList.includes(key)) updatedValue = this.getUserValue(value);
        if (key === "departmentId") updatedValue = this.getNameFromGeneric(value);
        if (key === "payPeriodRuleId") updatedValue = this.getNameFromGeneric(value);
        if (key === "ptoRuleId") updatedValue = this.getNameFromGeneric(value);

        return updatedValue;
    }

    getNewRowHtml(changed, key, value) {
        let newKey = this.cleanupKeyName(key);
        if (changed) {
            return (
                <tr key={key + ":" + value}>
                    <td scope="row" className="text-right pr-2"><b>{newKey}: </b></td>
                    <td className="bg-success">{value}</td>
                </tr>
            );
        } else {
            return (
                <tr key={key + ":" + value}>
                    <td scope="row" className="text-right pr-2"><b>{newKey}: </b></td>
                    <td>{value}</td>
                </tr>
            );
        }
    }

    cleanupKeyName(key) {
        const result = key.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    render() {
        let oldRows = [];
        let newRows = [];
        if (this.state.currentAuditData != null) {
            for (let [key, value] of Object.entries(this.state.currentAuditData["oldData"])) {
                let updatedValue = this.checkForUuidValueToResolve(key, value);
                oldRows.push(this.getNewRowHtml(false, key, updatedValue));
            }

            for (let [key, value] of Object.entries(this.state.currentAuditData["newData"])) {
                let updatedValue = this.checkForUuidValueToResolve(key, value);
                if (this.state.currentAuditData["oldData"][key] !== value) {
                    newRows.push(this.getNewRowHtml(true, key, updatedValue));
                } else {
                    newRows.push(this.getNewRowHtml(false, key, updatedValue));
                }
            }
        }


        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} size="xl">
                <ModalHeader>{this.props.auditType} Change Record #{this.props.rowId}</ModalHeader>
                <ModalBody>
                    <LoadingModal loading={this.state.loading} />
                    <div className="pull-left col-6">
                        <table>
                            <thead className="mb-4">
                            <th scope="col" key="oldSpace">&nbsp;</th>
                            <th scope="col" key="old">Original Version</th>
                            </thead>
                            <tbody>
                            {oldRows}
                            </tbody>
                        </table>
                    </div>
                    <div className="pull-right col-6">
                        <table>
                            <thead className="mb-4">
                            <th scope="col" key="newSpace">&nbsp;</th>
                            <th scope="col" key="new">Updated Version</th>
                            </thead>
                            <tbody>
                            {newRows}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default AuditComparisonModal;