import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from "../../../components/Framework/PrivateRoute";

import MyTimeClock from "./MyTimeClock";
import MyTimeOff from "../Pto/MyTimeOff";
import Terminals from "../Company/Terminals";
import { ToastContainer, toast } from 'react-toastify';

class TimeClock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            isAdmin: localStorage.getItem("isAdmin"),
            isManager: localStorage.getItem("isManager"),
        };
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Switch>
                    <PrivateRoute exact path="/timeclock/pto" name="My Time Off" component={MyTimeOff}/>
                    {
                        this.state.isAdmin ?
                            (<PrivateRoute exact path="/timeclock/terminals" name="Time Clock Terminals" component={Terminals}/>)
                            : ''
                    }
                    <PrivateRoute path="/timeclock" name="My Time Clock" component={MyTimeClock}/>
                </Switch>
            </div>
        )
    }
}

export default TimeClock;