import React, {useState, useEffect, createRef} from 'react';
import {
    Button,
    Container,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card,
    CardBody,
    Tooltip,
    Input
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import {geolocated} from 'react-geolocated';
import Select from "react-select";
import * as Util from "../../../components/Framework/Util";
import {ToastContainer, toast} from 'react-toastify';
import useTooltip from '../../../Hooks'
import "react-table/react-table.css";
import {SearchBar} from "../../../components/Framework/SearchBar";
import SelectProject from "../../../components/Selects/SelectProject";
import TimeTable from "../../../components/DataTables/TimeTable";
import MapContainer from "../../../components/Map/MapContainer";
import TimeLogModal from "../../../components/Modal/TimeLogModal";
import ConfirmDeleteModal from "../../../components/Modal/ConfirmDeleteModal";
import BreakModal from "../../../components/Modal/BreakModal";
import * as _ from "lodash";
import DuplicateTimeLogModal from "../../../components/Modal/DuplicateTimeLogModal";
import CSVExportFilters from "../../../components/Widget/CSVExportFilters";

const moment = require('moment-timezone');
let timeOfDay;
let countUpTimer;
let searchBarApi = {};
let latitude;
let longitude;

function MyTimeClock(props) {
    const loggedInUser = Util.getUserTenant();
    const companySettings = Util.getCompanySettings();
    const isAdminOrManager = Util.isAdmin() || Util.isManager();
    const canEdit = isAdminOrManager || companySettings.allowEditTime;
    const canDelete = isAdminOrManager;
    const projectsEnabled = companySettings.enableProjects;

    let deleteShift = {};
    let deleteShiftBreak = {};
    const [tooltip, setTooltip] = useTooltip({});
    const [data, setData] = useState([]);
    const [selectedShiftLog, setSelectedShiftLog] = useState({});
    const [loadBreakData, setLoadBreakData] = useState('');
    const [deleteDialog, setDeleteDialog] = useState({isOpen: false, message: []});
    const [showPositionColumn, setShowPositionColumn] = useState(false);
    const [showProjectColumn, setShowProjectColumn] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [positions, setPositions] = useState([]);
    const [project, setProject] = useState(null);
    const [position, setPosition] = useState(null);
    const [id, setId] = useState(null);
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [projectId, setProjectId] = useState('');
    const [positionId, setPositionId] = useState('');
    const [note, setNote] = useState('');
    const [endProject, setEndProject] = useState(false);
    const [finishProject, setFinishProject] = useState(false);
    const [locationTitle, setLocationTitle] = useState('');
    const [noteDialog, setNoteDialog] = useState(false);
    const [breakDialog, setBreakDialog] = useState(false);
    const [projectDialog, setProjectDialog] = useState(false);
    const [newDialog, setNewDialog] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [geoLocationDialog, setGeoLocationDialog] = useState(false);
    const [showPostedColumn, setShowPostedColumn] = useState(false);
    const [toggleStartTime, setToggleStartTime] = useState(false);
    const [toggleStartTimer, setToggleStartTimer] = useState(false);
    const paidBreaks = JSON.parse(localStorage.companySettings).paidBreaks;
    const [lock, setLock] = useState(false);
    const [lockBreak, setLockBreak] = useState(false);
    const [duplicateTimeLogModalOpen, setDuplicateTimeLogModalOpen] = useState(false);
    const [duplicateTimeLog, setDuplicateTimeLog] = useState(null);
    const [showCustomizeFieldModal, setShowCustomizeFieldsModal] = useState(false);
    const [csvHeader, setCsvHeader] = useState([
        {label: 'Date', key: 'date', active: true},
        {label: 'Clock In', key: 'clockInTime', active: true},
        {label: 'Clock Out', key: 'csvClockOutTime', active: true},
        {label: 'Hours', key: 'totalTime', active: true},
        {label: 'Break', key: 'totalBreakTime', active: true},
        {label: 'Position', key: 'positionName', active: true},
        {label: companySettings.projectSingleName, key: 'projectName', active: true}
    ]);

    const noteRef = createRef();

    useEffect(() => {
        loadData();
        parseUserPositions();
    }, []);

    useEffect(startTimer, [toggleStartTimer]);
    useEffect(startTime, [toggleStartTime]);

    function getTableColumns() {
        return [
            {displayName: 'Clock In', hideColumn: false},
            {displayName: 'Clock Out', hideColumn: false},
            {displayName: 'Time', hideColumn: false},
            {displayName: 'Break', hideColumn: false},
            {displayName: 'Position', hideColumn: !showPositionColumn},
            {displayName: companySettings.projectSingleName, hideColumn: !showProjectColumn},
            {displayName: 'Delete', hideColumn: !canDelete},
            {displayName: 'Posted', hideColumn: !showPostedColumn}
        ];
    }

    function getNumberOfHeaderColumns() {
        const tableColumns = getTableColumns();
        let columnCount = 0;
        tableColumns.map((column) => {
            if (!column.hideColumn) {
                columnCount += 1;
            }
        });
        return columnCount;
    }

    function createTableColumns() {
        let columns = [];
        let numberOfColumns = getNumberOfHeaderColumns();
        for (let i = 0; i < numberOfColumns; i++) {
            columns.push(<div></div>)
        }
        return columns;
    }

    function setSearchBarApi(api) {
        searchBarApi = api;
    }

    function showOpen() {
        let search = {
            showOpen: true
        }
        loadData(search);
    }

    function parseUserPositions() {
        const userDetails = Util.getUserTenant();
        if (userDetails.userTenantPositionList) {
            let positions = [];
            userDetails.userTenantPositionList.filter(x => !x.position.deletedOn).forEach(pos => positions.push({
                id: pos.positionId,
                name: pos.position.name
            }));
            setPositions(_.sortBy(positions, [position => position.name.toLowerCase()]));
            if (positions.length === 1) {
                setPosition(positions[0]);
                setPositionId(positions[0].id);
            }
        }
    }

    function handlePositionChange(position) {
        if (!position) {
            setPositionId('');
            setPosition(null);
        } else {
            setPositionId(position.id);
            setPosition(position);
        }
    }

    function startTime() {
        clearInterval(countUpTimer);
        clearInterval(timeOfDay);
        let updateTime = function () {
            const today = new Date();
            let currentTimeSpan = document.getElementById('time');
            if (currentTimeSpan !== null) {
                currentTimeSpan.innerHTML = today.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
            }
        };
        updateTime();
        timeOfDay = setInterval(function () {
            updateTime();
        }, 10000);
        return () => {
            clearInterval(timeOfDay)
        };
    }

    function startTimer() {
        clearInterval(timeOfDay);
        clearInterval(countUpTimer);
        if (localStorage.currentTimeLog && JSON.parse(localStorage.currentTimeLog)) {
            const timeLog = JSON.parse(localStorage.currentTimeLog);
            let breakOffset = timeLog.lunchBreakRoundedTime;
            breakOffset = !paidBreaks ? breakOffset += timeLog.breakRoundedTime : breakOffset;
            breakOffset = timeLog.currentBreak && timeLog.currentBreak.breakIn ? 0 : breakOffset;
            const startTime = timeLog.currentBreak && timeLog.currentBreak.breakIn ? timeLog.currentBreak.breakIn : timeLog.clockIn;
            countUpTimer = setInterval(function () {
                let now = moment(new Date().toString());
                let time = Math.round(now.diff(moment(startTime)) / 1000) - breakOffset;
                document.getElementById('time').innerHTML = Util.formatTimer(time, true);
            }, 1000);
        } else {
            setToggleStartTime(!toggleStartTime);
        }
        return () => {
            clearInterval(countUpTimer)
        };
    }

    function handleNoteChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            setNote(e.target.value);
        }
    }

    const loadShiftLog = async (shiftLogId) => {
        const apiUrl = Util.apiUrl('timelog/' + shiftLogId);
        let result = await fetch(apiUrl, {credentials: 'include'});
        return ServerResponseHandler.getResponse(result);
    }

    const openDuplicateModal = async (timeLogId) => {
        if (!canEdit) {
            return;
        }

        let result = await loadShiftLog(timeLogId);
        result.employeeName = result.employeeNameFirst + " " + result.employeeNameLast;
        let shiftLog = result;


        shiftLog.clockIn = Util.formatDateFromUtc(shiftLog.clockIn);
        shiftLog.clockOut = Util.formatDateFromUtc(shiftLog.clockOut);
        shiftLog.roundedClockIn = Util.formatDateFromUtc(shiftLog.roundedClockIn);
        shiftLog.roundedClockOut = Util.formatDateFromUtc(shiftLog.roundedClockOut);
        shiftLog.edit = true;
        shiftLog.myShift = true;

        setDuplicateTimeLogModalOpen(false);
        setSelectedShiftLog(shiftLog);

        setNewDialog(false);
        setNewDialog(true);
    }

    function handleProjectChange(project) {
        if (!project) {
            setProjectId('');
            setProject(null);
        } else {
            setProjectId(project.id);
            setProject(project);
        }
    }

    function toggleBreakModal() {
        setBreakDialog(!breakDialog);
    }

    function toggleNoteModal(shiftLogId) {
        setNoteDialog(!noteDialog);
        setId(shiftLogId);
    }

    function toggleGeoLocationModal(location, locationTitle) {
        if (location) {
            let coords = JSON.parse(location);
            latitude = coords.latitude;
            longitude = coords.longitude;
        }
        setGeoLocationDialog(!geoLocationDialog);
        setLocationTitle(locationTitle);
    }

    function toggleProjectModal(currentTimeLog, endProject) {
        let timeLogId = null;
        let position = null;
        let positionId = '';
        let project = null;
        let projectId = '';
        if (!endProject) {
            endProject = false;
        }

        if (currentTimeLog) {
            timeLogId = currentTimeLog.id;
            if (currentTimeLog.positionId) {
                position = {id: currentTimeLog.positionId, name: currentTimeLog.positionName};
            }

            positionId = currentTimeLog.positionId;

            if (currentTimeLog.projectId) {
                project = {
                    id: currentTimeLog.projectId,
                    number: currentTimeLog.projectNumber,
                    name: currentTimeLog.projectName
                }
                setCurrentProjectId(currentTimeLog.projectId);
            }

            projectId = currentTimeLog.projectId;
        }

        setProjectDialog(!projectDialog);
        setId(timeLogId);
        setPosition(position);
        setPositionId(positionId);
        setProject(project);
        setProjectId(projectId);
        setEndProject(endProject);
    }

    function getNewShiftLog() {
        return {
            id: null,
            mdlPosition: '',
            mdlPositionId: '',
            project: '',
            projectId: '',
            clockIn: Util.formatDateFromUtc(Date.now()),
            clockOut: Util.formatDateFromUtc(Date.now()),
            roundedClockIn: Util.formatDateFromUtc(Util.roundDateTo(Date.now(), moment.duration(companySettings.roundToNearest, "minutes"))),
            roundedClockOut: Util.formatDateFromUtc(Util.roundDateTo(Date.now(), moment.duration(companySettings.roundToNearest, "minutes"))),
            overrideAutoBreak: false,
            breakTimeHours: 0,
            breakTimeMinutes: 0,
            breakType: {name: 'Break'},
            note: '',
            shiftBreaks: [],
            myShift: true,
            edit: false
        }
    }

    function toggleNewModal() {
        setSelectedShiftLog(getNewShiftLog());
        setNewDialog(!newDialog);
    }

    function toggleEditModal(event, shiftLog) {
        if (event.target && (event.target.classList.contains('delete')) || event.target.classList.contains('geoLocation')) {
            return false;
        }
        if (!canEdit || !shiftLog.clockOut) {
            return;
        }
        if (shiftLog.projectId) {
            shiftLog.project = {id: shiftLog.projectId, number: shiftLog.projectNumber, name: shiftLog.projectName}
        }
        shiftLog.clockIn = Util.formatDateFromUtc(shiftLog.clockIn);
        shiftLog.clockOut = Util.formatDateFromUtc(shiftLog.clockOut);
        shiftLog.roundedClockIn = Util.formatDateFromUtc(shiftLog.roundedClockIn);
        shiftLog.roundedClockOut = Util.formatDateFromUtc(shiftLog.roundedClockOut);
        shiftLog.edit = true;
        shiftLog.myShift = true;

        setSelectedShiftLog(shiftLog);
        setNewDialog(!newDialog);
    }

    function toggleConfirmDeleteModal(shift, type) {
        if (shift) {
            shift.displayClockOutTime = shift.clockOut ? shift.clockOutTime : 'Present';
        }
        switch (type) {
            case 'deleteShiftBreak':
                setDeleteDialog({
                    header: 'Delete Break',
                    message: ['Are you sure you want to delete this break?'],
                    confirm: deleteBreak,
                    cancel: toggleConfirmDeleteModal,
                });
                deleteShiftBreak = shift;
                setConfirmDeleteDialog(!confirmDeleteDialog);
                break;
            case 'deleteShift':
                setDeleteDialog({
                    header: 'Delete Log',
                    message: ['Are you sure you want to delete this shift?', shift ? (shift.date ? shift.date : moment(shift.roundedClockIn).format('dddd, MMM DD')) + ' (' + (shift.clockInTime ? shift.clockInTime : moment(shift.roundedClockIn).format('h:mm A')) + ' - ' + (shift.displayClockOutTime ? shift.displayClockOutTime : moment(shift.roundedClockOut).format('h:mm A')) + ')' : ''],
                    warning: shift.datePosted ? 'Warning: This entry has been posted to QuickBooks and will need to be manually removed.' : false,
                    confirm: deleteLog,
                    cancel: toggleConfirmDeleteModal
                });
                deleteShift = shift;
                setConfirmDeleteDialog(!confirmDeleteDialog);
                break;
            default:
                setDeleteDialog({message: []});
                setConfirmDeleteDialog(false);
                break;
        }

    }

    function handleBreakSubmit(event, shiftEntry) {
        setBreakDialog(false);
        setLoading(true);
        shiftEntry = Util.formatBreakTimesForSerialization(shiftEntry);

        if (props.coords) {
            const geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
            shiftEntry = {...shiftEntry, breakInGeoLocation: geoLocation, breakOutGeoLocation: geoLocation};
        }

        const formUrl = Util.apiUrl(`timelog/${selectedShiftLog.id}/add-break`);
        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Break was added', {position: 'bottom-right'});
                loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                setLoading(false);
                setLoadBreakData(!loadBreakData)
            });
    }

    function handleNoteSubmit() {
        const payload = {note: note};
        saveData('PATCH', payload, [{set: setNoteDialog, value: false}], id, true);
    }

    function handleSubmit(shiftLog, method, breakList = [], callback) {

        const stateArr = [
            {set: setNewDialog, value: false},
            {set: setPositionId, value: ''},
            {set: setProject, value: null},
            {set: setProjectId, value: ''}
        ];

        if (method === 'POST') {
            let payload = [];
            breakList.forEach((shiftBreak) => {
                payload.push({
                    breakIn: Util.formatDateToUtc(shiftBreak.breakIn),
                    breakOut: Util.formatDateToUtc(shiftBreak.breakOut),
                    breakType: shiftBreak.breakType,
                    breakInOffset: moment.parseZone(moment()).utcOffset() * 60,
                    breakOutOffset: moment.parseZone(moment()).utcOffset() * 60
                });
            });
            shiftLog.clockInOffset = moment.parseZone(moment()).utcOffset() * 60;
            shiftLog.clockOutOffset = moment.parseZone(moment()).utcOffset() * 60;
            payload.unshift(shiftLog);
            saveData(method, payload, stateArr, null, false, callback);
        } else {
            saveData(method, shiftLog, stateArr, selectedShiftLog.id, false, callback);
        }
    }

    function saveData(method, shiftLog, stateArr, shiftId = '', updateShiftNote = false, callback) {
        setLoading(true);
        const formUrl = Util.apiUrl(updateShiftNote ? `timelog/${id}/add-shift-note` : method === 'PATCH' ? `timelog/${shiftId}` : 'timelog/v2');
        fetch(formUrl, {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftLog),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    if (json.errorCode === 'TIMELOG_ALREADY_ENTERED') {
                        const duplicateTimeLog = {
                            id: json.params[2],
                            clockIn: json.params[3],
                            clockOut: json.params[4]
                        };

                        setDuplicateTimeLog(duplicateTimeLog);
                        setDuplicateTimeLogModalOpen(true);
                    } else {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                    }

                    if (callback) callback();
                    return;
                }

                toast.success('Shift log was saved', {position: 'bottom-right'});
                loadData(searchBarApi.getParams());
                stateArr.forEach((obj) => {
                    obj.set(obj.value);
                });
            })
            .catch((error) => {
                ServerResponseHandler.handleError(error)
            })
            .finally(() => {
                setLoading(false);
                if (callback) callback();
            });
    }

    function deleteLog() {
        setLoading(true);
        const apiUrl = Util.apiUrl(`timelog/${deleteShift.id}`);
        deleteShift = Util.formatShiftTimesForSerialization(deleteShift);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(deleteShift),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Shift log was deleted', {position: 'bottom-right'});
                loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                setLoading(false);
                setConfirmDeleteDialog(false);
            });
    }

    function deleteBreak() {
        setLoading(true);
        const apiUrl = Util.apiUrl(`timelog/break/${deleteShiftBreak.id}`);
        deleteShiftBreak = Util.formatBreakTimesForSerialization(deleteShiftBreak);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(deleteShiftBreak),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Break was deleted', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                setLoading(false);
                setConfirmDeleteDialog(false);
                setLoadBreakData(!loadBreakData);
            });
    }

    function logBreak(type) {
        if (lockBreak) return;
        setLockBreak(true);
        const isBreakIn = type === 'Break' || type === 'Lunch';

        let shiftEntry = {};
        if (props.coords) {
            const geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
            shiftEntry = isBreakIn ? {breakInGeoLocation: geoLocation} : {breakOutGeoLocation: geoLocation};
        }
        if (type === 'Break' || type === 'Lunch') {
            shiftEntry = {
                ...shiftEntry,
                breakType: type
            }
        }

        if (isBreakIn) {
            shiftEntry.breakInOffset = moment.parseZone(moment()).utcOffset() * 60;
        } else {
            shiftEntry.breakOutOffset = moment.parseZone(moment()).utcOffset() * 60;
        }

        setLoading(true);
        const apiUrl = Util.apiUrl('timelog/break');
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                localStorage.setItem("currentTimeLog", JSON.stringify(json));

                loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                    setLockBreak(false);
                    setLoading(false);
                }
            );
    }

    function logShift(_endProject = false, _clockOut = false) {
        setProjectDialog(false);
        let geoLocation = null;
        let closeProject = _endProject || endProject;
        if (props.coords) {
            geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
        }

        if (companySettings.requireProjectClockIn && !projectId && !_clockOut) {
            toast.error('You must select a ' + companySettings.projectSingleName + ' to clock in.', {position: toast.POSITION.BOTTOM_RIGHT});
            return;
        }

        if (companySettings.requirePositionClockIn && !positionId && !_clockOut) {
            toast.error('You must select a position to clock in. If you are unable to, contact your manager to assign a position.', {position: 'bottom-right'});
            return;
        }

        if (lock) return;
        setLock(true);

        let shiftEntry = {
            note: note,
            //geoLocation: geoLocation,
            projectId: projectId,
            positionId: positionId,
            closeProject: closeProject
        };

        if (_clockOut) {
            shiftEntry.clockOutGeoLocation = geoLocation;
            shiftEntry.clockOutOffset = moment.parseZone(moment()).utcOffset() * 60;
        } else {
            shiftEntry.clockInGeoLocation = geoLocation;
            shiftEntry.clockInOffset = moment.parseZone(moment()).utcOffset() * 60;
        }

        setLoading(true);
        const apiUrl = Util.apiUrl('timelog/punch');
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    if (json.errorCodeValue === 705) {
                        const duplicateTimeLog = {
                            id: json.params[2],
                            clockIn: json.params[3],
                            clockOut: json.params[4]
                        };

                        setDuplicateTimeLog(duplicateTimeLog);
                        setDuplicateTimeLogModalOpen(true);
                    } else {
                        setEndProject(false);
                        setProjectDialog(false)
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                    }
                    return;
                }
                localStorage.setItem("currentTimeLog", JSON.stringify(json));
                loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                if (_clockOut) {
                    localStorage.setItem("currentTimeLog", null);
                }

                setLoading(false);
                setLock(false);
            });

        if (_clockOut) {
            setNote('');
            setProject(null);
            setProjectId('');
            if (positions.length > 1) {
                setPosition(null);
                setPositionId('');
            }
        }
    }

    function changeProject(endProject) {
        if (currentProjectId && projectId && currentProjectId === projectId) {
            toast.error('You must select a different ' + companySettings.projectSingleName + ' to switch to.', {position: 'bottom-right'});
            return;
        }

        setProjectDialog(false);
        let geoLocation = null;
        let closeProject = false;

        if (props.coords) {
            geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
        }

        if (endProject || finishProject) {
            closeProject = true;
        }

        if (companySettings.requireProjectClockIn && !projectId) {
            toast.error('You must select a ' + companySettings.projectSingleName + ' to clock in.', {position: 'bottom-right'});
            return;
        }

        if (companySettings.requirePositionClockIn && !positionId) {
            toast.error('You must select a position to clock in. If you are unable to select a position please contact your manager.', {position: 'bottom-right'});
            return;
        }

        const shiftEntry = {
            geoLocation: geoLocation,
            clockInGeoLocation: geoLocation,
            clockOutGeoLocation: geoLocation,
            positionId: positionId,
            projectId: projectId,
            closeProject: closeProject
        };

        setLoading(true);
        const apiUrl = Util.apiUrl('timelog/change-project');
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    setEndProject(false);
                    setProjectDialog(false);
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                alertSuccesful(endProject);
                localStorage.setItem("currentTimeLog", JSON.stringify(json));
                loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function alertSuccesful(endProject) {
        if (endProject) {
            toast.success('Closed ' + companySettings.projectPluralName, {position: 'bottom-right'});
        } else if (currentProjectId && projectId && currentProjectId != projectId) {
            toast.success('Switched ' + companySettings.projectPluralName, {position: 'bottom-right'});
        } else {
            toast.success('Completed', {position: 'bottom-right'});
        }
    }

    function loadData(search = null) {
        setLoading(true);
        Util.getPayPeriods(search).then(search => {
            const apiUrl = Util.apiSearchUrl('timelog/my', search);
            fetch(apiUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    let data = [];
                    if (json.resultList.length > 0) {
                        let dates = {};
                        let showProjectColumn = false;
                        let showPositionColumn = false;
                        let showPostedColumn = false;
                        json.resultList.map((line) => {
                            let totalBreakTime = line.breakRoundedTime + line.lunchBreakRoundedTime;
                            line.totalBreakTime = Util.formatTimeFromSeconds(totalBreakTime);
                            let clockInDate = Util.basicDateFormatter(moment(line.clockIn));
                            let clockOutDate = line.clockOut ? Util.basicDateFormatter(moment(line.clockOut)) : undefined;
                            let date = Util.multiDateFormat(line, clockInDate, clockOutDate);
                            if (dates[date]) {
                                dates[date].push(line);
                            } else {
                                dates[date] = [line];
                            }
                        });
                        for (let date in dates) {
                            const timeEvents = dates[date];
                            let totalTime = 0;
                            timeEvents.forEach((event) => {
                                if (event.clockOut) {
                                    totalTime += event.durationForPayroll;
                                }
                                if (event.positionName) {
                                    showPositionColumn = true;
                                }
                                if (event.projectName) {
                                    showProjectColumn = true;
                                }
                                if (event.datePosted) {
                                    showPostedColumn = true;
                                }
                            });
                            let line = {
                                name: date,
                                timeEvents: timeEvents,
                                totalTime: totalTime
                            };
                            data.push(line);
                        }
                        setLoadBreakData(!loadBreakData);
                        setShowPositionColumn(showPositionColumn);
                        setShowProjectColumn(showProjectColumn);
                        setShowPostedColumn(showPostedColumn);
                        setCsvData(json.resultList);
                        if (json.maxResults === 0) {
                            localStorage.setItem("currentTimeLog", null);
                        }
                    } else {
                        setData([]);
                    }
                    getCurrentTimeLog(data);
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => {
                    setLoading(false);
                });
        })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    function parseShift(shiftLogList) {
        const zone = moment.tz.guess();
        const currentTimeLog = Util.getCurrentTimeLog();

        shiftLogList.forEach(obj => {
            obj.timeEvents.forEach(obj => {
                if (obj.roundedClockIn) {
                    const value = obj.roundedClockIn;
                    obj.clockInTime = moment.tz(value, zone).format('h:mm A');
                    obj.date = Util.basicDateFormatter(moment.tz(value, zone));
                }

                if (obj.roundedClockOut) {
                    obj.clockOutTime = moment.tz(obj.roundedClockOut, zone).format('h:mm A');
                    obj.csvClockOutTime = moment.tz(obj.roundedClockOut, zone).format('h:mm A');
                } else if (obj.currentBreak) {
                    if (obj.currentBreak.breakType === 'Lunch') {
                        obj.clockOutTime =
                            <h5 className="mb-1"><span className="badge badge-pill badge-lunch">On Lunch</span></h5>;
                    } else {
                        obj.clockOutTime =
                            <h5 className="mb-1"><span className="badge badge-pill badge-break">On Break</span></h5>;
                    }
                    obj.csvClockOutTime = 'On Break';
                } else {
                    obj.clockOutTime =
                        <h5 className="mb-1"><span className="badge badge-pill badge-success">Working</span></h5>;
                    obj.csvClockOutTime = 'Working';
                }

                if (obj.roundedDuration) {
                    obj.totalTime = Util.formatTimeFromSeconds(obj.roundedDuration);
                }
            })
        });
    }

    const handleCSVHeaderChange = (event, name) => {
        const tempCsvHeader = [...csvHeader];
        const field = tempCsvHeader.find(x => x.key === name);

        if(field.required)
            return;

        field.active = event.target.checked;
        setCsvHeader(tempCsvHeader);
    }

    function getCurrentTimeLog(loadedResultList) {
        setLoading(true);
        const apiUrl = Util.apiUrl('timelog/current');
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (!json) {
                    localStorage.setItem("currentTimeLog", null);
                    setToggleStartTime(!toggleStartTime);
                } else {
                    localStorage.setItem("currentTimeLog", JSON.stringify(json));
                    if ((json.clockIn && !json.clockOut) || (json.currentBreak && json.currentBreak.breakIn)) {
                        setToggleStartTimer(!toggleStartTimer);
                    } else {
                        setToggleStartTime(!toggleStartTime);
                    }
                    setNote(json.shiftNote);
                }
                if (loadedResultList) {
                    parseShift(loadedResultList);
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                setLoading(false);
                setData(loadedResultList);
            });
    }

    const currentTimeLog = Util.getCurrentTimeLog();
    const positionsEnabled = Util.getCompanySettings().enablePositions;
    const positionsExist = positions.length > 0;
    const csvProperties = {
        csvHeader: csvHeader.filter(x => x.active),
        data: csvData,
        csvFilename: 'TimeCards.csv',
        showCustomize: true,
        customizeCallback: () => { setShowCustomizeFieldsModal(true); }
    };

    const buttons = {
        add: (isAdminOrManager || Util.getCompanySettings().allowAddTime) ? toggleNewModal : false,
        download: csvProperties,
        savePdf: false,
        loading: loading
    };

    let optionClockInPosition = '';
    let optionClockInProject = '';
    let optionBreak = '';
    let optionLunch = '';
    let optionProject = '';
    let optionNote = '';

    const btnEndProject = companySettings.requireProjectClockIn
        ? <button className="btn btn-outline-secondary clock-button w-75"
                  onClick={() => toggleProjectModal(currentTimeLog, true)}><i
            className="fas fa-wrench mr-1"></i>{'Close ' + companySettings.projectSingleName}</button>
        : <button className="btn btn-outline-secondary clock-button w-75"
                  onClick={() => changeProject(true)}><i
            className="fas fa-wrench mr-1"></i>{'Close ' + companySettings.projectSingleName}</button>;

    if (currentTimeLog) {
        if (!currentTimeLog.currentBreak) {
            optionNote = <div>
                <button className="btn btn-outline-secondary clock-button w-75"
                        onClick={() => toggleNoteModal(currentTimeLog.id)}>
                    <i className="fas fa-sticky-note mr-1"></i> Add Shift Note
                </button>
            </div>;
            optionBreak = <div>
                <button className="btn btn-outline-secondary clock-button w-75"
                        disabled={lock}
                        onClick={() => logBreak('Break')}><i className="fa fa-clock-o fa-lg mr-1"></i>Start Break
                </button>
            </div>;
            optionLunch = <div>
                <button className="btn btn-outline-secondary clock-button w-75"
                        disabled={lock}
                        onClick={() => logBreak('Lunch')}><i className="fas fa-utensils fa-lg mr-1"></i>Start Lunch
                </button>
            </div>;
            if (projectsEnabled) {
                if (!currentTimeLog.projectId) {
                    optionProject = <div>
                        <button className="btn btn-outline-secondary clock-button w-75"
                                onClick={() => toggleProjectModal(currentTimeLog, false)}>
                            <i className="fas fa-cog mr-1"></i>{'Start ' + companySettings.projectSingleName}</button>
                    </div>;
                } else {
                    optionProject = <div>
                        <div>
                            <button className="btn btn-outline-secondary clock-button w-75"
                                    onClick={() => toggleProjectModal(currentTimeLog, false)}>
                                <i className="fas fa-cog mr-1"></i>{'Switch ' + companySettings.projectSingleName}
                            </button>
                        </div>
                        <div>
                            {btnEndProject}
                        </div>
                    </div>;
                }
            }
        }
    } else {
        if (projectsEnabled) {
            optionClockInProject = (<div>
                <Label id={'projectLabel'}
                       className="font-weight-bold ellipsis">{companySettings.projectPluralName}</Label>
                <Tooltip target={'projectLabel'} isOpen={tooltip['projectLabel']}
                         toggle={() => setTooltip('projectLabel')}>{companySettings.projectPluralName}</Tooltip>
                <div id="projectsDropdown" className="mb-3">
                    <SelectProject employee={{employeeCode: loggedInUser.id}} project={project} onChange={handleProjectChange}/>
                </div>
            </div>);
        }
    }

    if (positionsExist && positionsEnabled) {
        optionClockInPosition = (<div>
            <Label className="font-weight-bold">Positions</Label>
            <div className="mb-3 fb-select">
                <Select
                    isClearable
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    value={position} options={positions}
                    onChange={handlePositionChange}
                    placeholder={'Select a Position...'}
                />
            </div>
        </div>);
    }

    return (
        <div className="animated fadeIn">
            <Container fluid>
                {
                    !newDialog ? '' :
                        <TimeLogModal
                            loadBreakData={loadBreakData}
                            shiftLog={selectedShiftLog}
                            isOpen={newDialog}
                            toggleGeoLocationModal={toggleGeoLocationModal}
                            toggleModal={toggleNewModal}
                            toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                            handleSubmit={handleSubmit}
                            toggleBreakModal={toggleBreakModal}
                            coords={props.coords}
                            myTimeClock={true}
                        />
                }
                {!breakDialog ? '' :
                    <BreakModal
                        toggleBreakModal={toggleBreakModal}
                        handleBreakSubmit={handleBreakSubmit}
                        isOpen={breakDialog}
                        shiftLog={selectedShiftLog}
                    />
                }
                <Modal isOpen={noteDialog}>
                    <ModalHeader>Add Shift Note</ModalHeader>
                    <ModalBody>
                        <Label>Shift Note</Label>
                        <InputGroup className="mb-3">
                            <input type="text" placeholder="Shift Note" name="note" ref={noteRef}
                                   value={note}
                                   className="form-control" onChange={handleNoteChange} required/>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => handleNoteSubmit()}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => toggleNoteModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <DuplicateTimeLogModal
                    isOpen={duplicateTimeLogModalOpen}
                    duplicateTimeLog={duplicateTimeLog}
                    cancel={() => {
                        setDuplicateTimeLogModalOpen(false)
                    }}
                    onDuplicateShiftClick={(id) => {
                        openDuplicateModal(id);
                    }}
                />
                <Modal isOpen={geoLocationDialog} toggle={toggleGeoLocationModal} size={'lg'}>
                    <ModalHeader>{locationTitle}</ModalHeader>
                    <ModalBody style={{height: '500px'}}>
                        {!props.isGeolocationAvailable
                            ? <div>Your browser does not support Geolocation</div>
                            : !props.isGeolocationEnabled
                                ? <div>Geolocation is not enabled</div>
                                : props.coords ?
                                    <MapContainer
                                        latitude={latitude}
                                        longitude={longitude}
                                        locationTitle={locationTitle}/> : ''
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => toggleGeoLocationModal(null)}>Close</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={projectDialog}>
                    <ModalHeader
                        className="ellipsis">{(endProject ? 'Close and ' : '') + 'Switch ' + companySettings.projectSingleName}</ModalHeader>
                    <ModalBody>
                        {optionClockInPosition}
                        <Label className="ellipsis font-weight-bold">{companySettings.projectSingleName}</Label>
                        <div className="mb-3">
                            <SelectProject project={null} onChange={handleProjectChange}/>
                        </div>
                        <InputGroup className="mb-3 pt-3">
                            <label>Close {companySettings.projectSingleName}</label>
                            <Label className="switch switch-icon switch-pill switch-info pull-left"
                                   style={{position: 'absolute', right: '0'}}
                                   title="Close">
                                <Input type="checkbox" name="positionChecked"
                                       className="switch-input"
                                       onChange={(event) => {
                                           setEndProject(event.target.checked);
                                       }}
                                       checked={endProject}/>
                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                <span className="switch-handle"/>
                            </Label>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        {!endProject ? '' : <Button color="danger" disabled={loading} onClick={() => {
                            logShift(true, true);
                        }}>CLOCK OUT</Button>}
                        <Button color="success" onClick={() => changeProject(endProject)}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => toggleProjectModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={showCustomizeFieldModal}>
                    <ModalHeader className="ellipsis">Customize Fields</ModalHeader>
                    <ModalBody>
                        <CSVExportFilters
                            fieldsConfiguration={csvHeader}
                            handleFieldChange={handleCSVHeaderChange}
                            />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() =>setShowCustomizeFieldsModal(false)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {
                    !confirmDeleteDialog ? '' :
                        <ConfirmDeleteModal
                            isOpen={confirmDeleteDialog}
                            deleteDialog={deleteDialog}
                        />
                }
                <div className="row mt-3">
                    <div className="col-lg-3 col-md-12 mb-5">
                        <Card className="card-accent-primary">
                            <CardBody>
                                <div className="text-center">
                                    <div className="clock-action-top">
                                        <div className="timeholder">
                                            <span id="time">00:00:00</span>
                                        </div>
                                    </div>
                                    {currentTimeLog ? '' : optionClockInPosition}
                                    {optionClockInProject}
                                    {companySettings.allowBreaks ? optionBreak : ''}
                                    {companySettings.allowLunchBreaks ? optionLunch : ''}
                                    {optionProject}
                                    {optionNote}
                                    <div className="clock-action-bottom">
                                        {
                                            currentTimeLog === null
                                                ? (<Button className="btn-success clockIn"
                                                           disabled={loading || lock}
                                                           onClick={() => {
                                                               logShift();
                                                           }}
                                                           active={true}>CLOCK IN</Button>)
                                                : currentTimeLog.currentBreak
                                                    ? (<Button className="btn-info clockIn" onClick={logBreak}
                                                               disabled={lock}
                                                               active={true}>RESUME SHIFT</Button>)
                                                    : (<Button className="btn-danger clockIn"
                                                               disabled={loading}
                                                               onClick={() => {
                                                                   logShift(false, true);
                                                               }}
                                                               active={true}>CLOCK OUT</Button>)
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                    <Card className="col-lg-9 col-md-12 card-accent-primary">
                        <CardBody>
                            <SearchBar
                                buttons={buttons}
                                loading={loading}
                                onSearch={loadData}
                                includePayPeriods={true}
                                includeShowOpenButton={true}
                                showOpen={showOpen}
                                includeDateRange
                                includeProject
                                includePosition
                                includeSearchLabel={true}
                                getSearchBarApi={setSearchBarApi}
                            />
                            <TimeTable
                                data={data}
                                tableColumns={getTableColumns()}
                                toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                                toggleEditModal={toggleEditModal}
                                toggleGeoLocationModal={toggleGeoLocationModal}
                                tableSettings={{
                                    createTableColumns: createTableColumns(),
                                    canDelete: canDelete,
                                    canEdit: canEdit,
                                    showProjectColumn: showProjectColumn,
                                    showPositionColumn: showPositionColumn,
                                    showPostedColumn: showPostedColumn,
                                    showShiftNoteColumn: false
                                }}
                            />
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </div>
    )
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    },
    suppressLocationOnMount: !Util.getCompanySettings().allowGeoLocation
})(MyTimeClock);