import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import * as Util from "../Framework/Util";

class CompanyHolidayTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
        this.overflowVisible = {
            overflow: "visible"
        };
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                <Col key="0" className="col-lg-4 text-center">Holiday</Col>
                <Col key="1" className='col-lg-2 text-center'>Start Date</Col>
                <Col key="2" className='col-lg-2 text-center'>End Date</Col>
                <Col key="3" className='col-lg-1 text-center'>Active</Col>
                <Col key="4" className='col-lg-1 text-center'>PTO</Col>
                {
                    !self.props.tableSettings.canDelete
                        ?
                        ''
                        :
                        <Col key="5" className="col-lg-1" style={this.overflowVisible}>Delete</Col>
                }
                
            </Row>
            {
                (!self.props.data || self.props.data.length < 1) ?
                    <NoData/> :
                self.props.data.map((row, index) => {
                    return <div className='data-row-edit margin-top' key={index}>
                        <Row style={{ cursor: 'pointer '}} onClick={() => {self.props.openEditModal(row)}} className='row-data row-header col-lg-12'>
                            <Col className='col-lg-4'><span>{row.name}</span></Col>
                            <Col className='col-lg-2'>
                                <span>{Util.basicDateFormatter(row.startDate, false, false)}</span>
                            </Col>
                            <Col className='col-lg-2'>
                                <span>{Util.basicDateFormatter(row.endDate, false, false)}</span>
                            </Col>
                            <Col className='col-lg-1'>
                                {row.active ?
                                <span title="Active"
                                    className="material-icons">check</span>  : '' }
                            </Col>
                            <Col className='col-lg-1'>
                                {row.pto ?
                                <span title="PTO"
                            className="material-icons">check</span> : '' } </Col>
                            {
                                !self.props.tableSettings.canDelete
                                    ?
                                    ''
                                    :
                                    <Col className="cursor-text col-lg-1">
                                                     <span title="Delete"
                                                           onClick={(e) => { e.stopPropagation(); self.props.toggleConfirmDeleteModal(row)}}
                                                           className="delete action-icon material-icons">clear</span>
                                    </Col>
                            }
                        </Row>
                    </div>
                })
            }
        </div>
    }


    render() {
        return this.createTable();
    }
}

export default CompanyHolidayTable;
