import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import * as Util from "../Framework/Util";

const companySettings = Util.getCompanySettings();

export default class SelectPickStatus extends Component {
    constructor(props) {
        super(props);

        let pickStatusOptions = [
            {value: 'Entered', label: 'Entered'},
            {value: 'Entered New', label: 'Entered New'},
            {value: 'Started', label: 'Started'},
            {value: 'Committed', label: 'Committed'},
            {value: 'Hold', label: 'Hold'},
            {value: 'Short', label: 'Short'},
            {value: 'Finished', label: 'Finished'},
        ];

        if(props.includeAll) {
            pickStatusOptions.unshift({value: 'All', label: 'All'});
        }

        this.pickStatusOptions = pickStatusOptions;
        this.state = {
            pickStatus: {value: this.props.pickStatus, label: this.props.pickStatus}
        }
    }

    handleChange = newPickStatus => {
        if (newPickStatus && newPickStatus.constructor === Array) {
            newPickStatus = 'Entered';
        }

        this.setState({pickStatus: newPickStatus});
        this.props.onChange(newPickStatus.value);
    };

    render() {
        return (
            <div className="fb-select">
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    value={this.state.pickStatus} options={this.pickStatusOptions}
                    onChange={this.handleChange}
                    placeholder={'Select a ' + companySettings.projectSingleName + ' Pick Status...'}
                />
            </div>
        )
    }
}

SelectPickStatus.propTypes = {
  onChange: PropTypes.func.isRequired,
  pickStatus: PropTypes.string
};

SelectPickStatus.defaultProps = {
  pickStatus: 'Entered'
};