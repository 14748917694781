import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalFooter} from 'reactstrap';
import Select from 'react-select';
import * as ServerResponseHandler from '../../../components/Framework/ServerResponseHandler';
import {toPdfTimeCards} from '../../../components/Framework/HtmlToPdf';
import * as Util from '../../../components/Framework/Util';
import SelectDepartment from "../../../components/Selects/SelectDepartment";
import SelectEmployee from "../../../components/Selects/SelectEmployee";
import EmployeeTimeCardsReport
    from "../../../components/ReportTemplates/EmployeeTimeCardsReport/EmployeeTimeCardsReport";
import {CSVLink} from "react-csv";
import {DateRange} from "react-date-range";
import CSVExportFilters from "../../../components/Widget/CSVExportFilters";

const companySettings = Util.getCompanySettings();
const moment = require('moment-timezone');
const today = new Date();

class EmployeeTimeCards extends Component {
    constructor(props) {
        super(props);
        this.userTenant = Util.getUserTenant();
        this.state = {
            timeCardList: [],
            csvData: [],
            maxResults: 0,
            loading: false,
            payPeriodDataList: [],
            selectedPayPeriodRule: null,
            payPeriodRuleDataList: [],
            currentPayPeriod: {},
            selectedPayPeriod: {startDate: today, endDate: today},
            searchParams: {},
            employeeList: [],
            employeeSelection: {},
            showProjects: true,
            showNotes: true,
            departmentList: [],
            pdfData: [],
            customDateRangeFilterIncluded: false,
            isOpen: false,
            customStartDate:  moment(),
            customEndDate:  moment(),
            showCustomizeFieldsContainer: false,
            csvHeader: [
                {label: 'First Name', key: 'firstName', active: true},
                {label: 'Last Name', key: 'lastName', active: true},
                {label: 'Date', key: 'date', active: true},
                {label: 'In', key: 'clockIn', active: true},
                {label: 'Out', key: 'clockOut', active: true},
                {label: 'Break', key: 'break', active: true},
                {label: 'Total (hrs)', key: 'total', active: true},
                {label: 'Position', key: 'position', active: true},
                {label: 'Project', key: 'project', active: true},
                {label: 'Notes', key: 'note', active: false}
            ],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handlePayPeriodSelectChange = this.handlePayPeriodSelectChange.bind(this);
        this.handlePayPeriodRuleSelectChange = this.handlePayPeriodRuleSelectChange.bind(this);
        this.handleDepartmentSelectChange = this.handleDepartmentSelectChange.bind(this);
        this.handleEmployeeSelectChange = this.handleEmployeeSelectChange.bind(this);
        this.getPdfData = this.getPdfData.bind(this);
        this.toggleReport = this.toggleReport.bind(this);
        this.getSelectedPayPeriodDays = this.getSelectedPayPeriodDays.bind(this);
        this.loadPayrollDates = this.loadPayrollDates.bind(this);
        this.loadTimeCards = this.loadTimeCards.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this.isPayPeriodApproved = this.isPayPeriodApproved.bind(this);
        this.print = this.print.bind(this);
    }

    componentDidMount() {
        this.loadPayperiodRules();
    }

    pdfColumnHeaders() {
        let pdfColumns = [
            {title: 'Date', dataKey: 'date', showColumn: true},
            {title: 'In', dataKey: 'clockIn', showColumn: true},
            {title: 'Out', dataKey: 'clockOut', showColumn: true},
            {title: 'Break', dataKey: 'break', showColumn: true},
            {title: 'Total', dataKey: 'total', showColumn: true},
            {title: 'Position', dataKey: 'position', showColumn: true},
            {title: companySettings.projectSingleName, dataKey: 'project', showColumn: this.getHeaderActive('project')},
            {title: 'Notes', dataKey: 'note', showColumn: this.getHeaderActive('note')}
        ];
        return pdfColumns;
    }

    getHeaderActive = (key) => {
        const field = this.state.csvHeader.find(x => x.key === key);
        if(!field)
            return false;

        return field.active;
    }

    getStartDate = () => {
        return this.state.customDateRangeFilterIncluded ? this.state.customStartDate : moment(this.state.payPeriod.startDate);
    }

    getEndDate = () => {
        return this.state.customDateRangeFilterIncluded ? this.state.customEndDate : moment(this.state.payPeriod.endDate);
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    handleCheckBoxChange(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handlePayPeriodRuleSelectChange(payPeriodRule) {
        const searchParams = {...this.state.searchParams,
            payPeriodRuleId: payPeriodRule.id
        };

        this.setState({
            selectedPayPeriodRule: payPeriodRule,
            searchParams: searchParams
        });

        this.loadPayrollDates(payPeriodRule);
    }

    handlePayPeriodSelectChange(payPeriod) {
        const searchParams = {...this.state.searchParams,
            startDate: Util.formatDateToUtc(moment(payPeriod.startDate).startOf('day')),
            endDate: Util.formatDateToUtc(moment(payPeriod.endDate).endOf('day')),
            workWeekStartDate: Util.calculateDayOfWorkWeekSearch(payPeriod.startDate, this.state.weekStartDay)
        };

        this.setState({
            selectedPayPeriod: payPeriod,
            searchParams: searchParams
        });
        this.preloadTimeCards(searchParams);
    }

    handleDepartmentSelectChange(department) {
        let searchParams = this.state.searchParams;
        this.setState({selectedDepartment: department});

        if (department) {
            searchParams.departmentId = department.id;
        } else {
            searchParams.departmentId = null;
        }
        this.setState({searchParams: searchParams});
    }

    handleEmployeeSelectChange(employee) {
        let searchParams = this.state.searchParams;
        this.setState({selectedEmployee: employee});

        if (employee) {
            searchParams.employeeCode = employee.id;
        } else {
            searchParams.employeeCode = null;
        }
        this.setState({searchParams: searchParams});
    }

    getPdfData() {
        this.fetchPdfAndCsvData();
        this.toggleReport();
    }

    fetchPdfAndCsvData() {
        let timeCard = [];
        let timeLogList;
        for (const entry of this.state.timeCardList) {
            timeLogList = [];
            for (const timelog of entry.timeLogList) {
                let clockIn = moment(Util.formatDateFromUtc(timelog.roundedClockInDate, true));
                let clockOut = moment(Util.formatDateFromUtc(timelog.roundedClockOutDate, true));
                timeLogList.push({
                    date: Util.basicDateFormatter(clockIn),
                    clockIn: clockIn.format('hh:mmA'),
                    clockOut: clockOut.format('hh:mmA'),
                    break: Util.formatTimeFromSecondsMorePrecision(timelog.breakDuration + timelog.lunchBreakDuration),
                    total: Util.formatTimeFromSeconds(timelog.durationForPayroll),
                    totalInSeconds: timelog.durationForPayroll,
                    position: timelog.position,
                    project: timelog.project,
                    note: timelog.note,
                });
            }
            let ptoHoursByType;
            if (entry.timeOffList.length > 0) {
                ptoHoursByType = Util.organizePtoHours(entry.timeOffList);
            } else {
                ptoHoursByType = Util.organizePtoHours();
            }

            timeCard.push({
                firstName: entry.firstName,
                lastName: entry.lastName,
                totalRegular: Util.formatTimeFromSeconds(entry.calculatedRegularDuration),
                totalOvertime: Util.formatTimeFromSeconds(entry.calculatedOvertimeDuration),
                totalHours: Util.formatTimeFromSeconds(+entry.calculatedRegularDuration + +entry.calculatedOvertimeDuration),
                totalPtoHours: Util.formatTimeFromSeconds(ptoHoursByType.totalPtoHours),
                sickHours: Util.formatTimeFromSeconds(ptoHoursByType.sickHours),
                vacationHours: Util.formatTimeFromSeconds(ptoHoursByType.vacationHours),
                holidayHours: Util.formatTimeFromSeconds(ptoHoursByType.holidayHours),
                timeCardData: timeLogList,
            })
        }

        let timeCardEntry = {
            title: 'Employee Timecard Report',
            subTitle: Util.basicDateFormatter(this.state.customDateRangeFilterIncluded ? this.state.customStartDate : this.state.selectedPayPeriod.startDate) +
                ' - ' + Util.basicDateFormatter(this.state.customDateRangeFilterIncluded ? this.state.customEndDate : this.state.selectedPayPeriod.endDate),
            department: this.state.selectedDepartment
                ? "Department: " + this.state.selectedDepartment.name
                : 'All Departments',
            showProjects: this.state.csvHeader.find(x => x.key === 'project') && this.state.csvHeader.find(x => x.key === 'project').active,
            showNotes: this.state.csvHeader.find(x => x.key === 'note') && this.state.csvHeader.find(x => x.key === 'note').active,
            timeCardList: timeCard,
        };

        let json = [];
        Array.prototype.forEach.call(timeCardEntry.timeCardList, x => {
            let metaRow = {};
            metaRow.firstName = x.firstName;
            metaRow.lastName = x.lastName;
            Array.prototype.forEach.call(x.timeCardData, y => {
                let row = {}
                row.firstName = metaRow.firstName;
                row.lastName = metaRow.lastName;
                row.break = y.break;
                row.clockIn = y.clockIn;
                row.clockOut = y.clockOut;
                row.date = y.date;
                row.position = y.position;
                row.project = y.project;
                row.total = y.total.replace(" hrs", "");
                row.note = y.note;
                json.push(row);
            });
        });
        this.setState({csvData: json, pdfData: timeCardEntry});
    }

    toggleReport() {
        this.setState({isOpen: !this.state.isOpen});
    }

    getSelectedPayPeriodDays() {
        let payPeriodDays = new Set();
        let currentDay = this.state.customDateRangeFilterIncluded ? moment(this.state.customStartDate) : moment(this.state.selectedPayPeriod.startDate);
        let endDate = this.state.customDateRangeFilterIncluded ? moment(this.state.customEndDate) : moment(this.state.selectedPayPeriod.endDate);

        if(!this.state.customDateRangeFilterIncluded) {
            endDate = endDate.add(1, 'day');
            while (!currentDay.isSame(endDate)) {
                payPeriodDays.add(Util.basicDateFormatter(currentDay));
                currentDay.add(1, 'day');
            }
        }

        return payPeriodDays;
    }

    loadPayperiodRules() {
        fetch(Util.apiUrl(`pay_period_rule`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let selectedRule = null;
                if (json.resultList && json.resultList.length > 0) {
                    json.resultList.forEach(rule => {
                        if (rule.default === true) {
                            selectedRule = rule;
                        }
                    });
                };

                this.setState({
                    payPeriodRuleDataList: json.resultList,
                    selectedPayPeriodRule: selectedRule,
                    weekStartDay: selectedRule.weekStartDay,
                    searchParams: {
                        ...this.state.searchParams,
                        payPeriodRuleId: selectedRule.id
                    }
                });
                this.loadPayrollDates(selectedRule);
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    loadPayrollDates(selectedPayPeriodRule) {
        fetch(Util.apiUrl(`reports/pay-periods/${selectedPayPeriodRule.id}`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let index = 0;
                for (const entry of json.periodList) {
                    entry.display = Util.basicDateFormatter(entry.startDate) + ' - ' + Util.basicDateFormatter(entry.endDate);
                    entry.id = index++;
                }

                json.periodList[0].display += ' (Current)';
                this.setState({
                    currentPayPeriod: json.current,
                    payPeriodDataList: json.periodList,
                    selectedPayPeriod: json.periodList[0],
                    searchParams: {
                            ...this.state.searchParams,
                            startDate: Util.formatDateToUtc(moment(json.periodList[0].startDate).startOf('day')),
                            endDate: Util.formatDateToUtc(moment(json.periodList[0].endDate).endOf('day')),
                            workWeekStartDate: Util.calculateDayOfWorkWeekSearch(json.periodList[0].startDate, this.state.weekStartDay)
                    }
                });
                this.preloadTimeCards(this.state.searchParams);
                if (!Util.isAdmin()) {
                    search.departmentId = this.userTenant.departmentId;
                }
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    loadTimeCards(search) {
        if(this.state.customDateRangeFilterIncluded) {
            search.startDate = this.state.customStartDate;
            search.endDate = this.state.customEndDate;
        }

        let url = Util.apiSearchUrl(`reports/time-cards`, search);
        url += "&timezone=" + moment.tz.guess();
        fetch(url, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({timeCardList: json, loading: false});
            })
            .then(() => this.getPdfData())
            .catch(error => ServerResponseHandler.handleError(error));
    }

    preloadTimeCards(search) {
        let url = Util.apiSearchUrl(`reports/time-cards`, search);
        url += "&timezone=" + moment.tz.guess();
        fetch(url, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({timeCardList: json, loading: false});
                this.fetchPdfAndCsvData()
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    isPayPeriodEnded = (payPeriod) => {
        return moment(payPeriod.endDate).isBefore(moment());
    }

    isPayPeriodApproved(payPeriodRule, payPeriod) {
        return payPeriodRule.lastApproved && moment(payPeriodRule.lastApproved).isAfter(moment(payPeriod.endDate));
    }

    downloadReport() {
        toPdfTimeCards(this.state.pdfData, this.getSelectedPayPeriodDays(), 'Time Card Report', 'timeCardReport', 'p')
    }

    print() {
        this.setState({isOpen: false});
        setTimeout(() =>{ window.print()});
    }

    handleDateChange = (obj) => {
        this.setState({ customStartDate: obj.startDate, customEndDate: obj.endDate });
    }

    toggleCustomDateRangeFilterIncluded = () => {
        const search = {...this.state.searchParams}
        this.setState({ customDateRangeFilterIncluded: !this.state.customDateRangeFilterIncluded, })
    }

    handleCSVHeaderChange = (event, name) => {
        const csvHeader = [...this.state.csvHeader];
        const field = csvHeader.find(x => x.key === name);

        if(field.required)
            return;

        field.active = event.target.checked;
        this.setState({ csvHeader: csvHeader });
    }

    getCsvHeaders = () => {
        return this.state.csvHeader.filter(x => x.active);
    }

    render() {
        const isEnded = this.state.selectedPayPeriodRule ? this.isPayPeriodEnded(this.state.selectedPayPeriod) : null;
        const isApproved = this.state.selectedPayPeriodRule ? this.isPayPeriodApproved(this.state.selectedPayPeriodRule, this.state.selectedPayPeriod) : null;
        const canApprove = isEnded && !isApproved;

        return (
            <Container fluid>
                <div className='animated fadeIn'>
                    <div id='payRoll' className='col-md-auto dont-print'>
                        <Col lg='12' className={'dont-print'}>
                            <Modal isOpen={this.state.isOpen} toggle={this.toggleReport}
                                   className="modal-lg dont-print">
                                {!this.state.pdfData ? '' :
                                    <EmployeeTimeCardsReport
                                        payPeriodDates={this.getSelectedPayPeriodDays()}
                                        pdfColumnHeaders={this.pdfColumnHeaders()}
                                        pdfData={this.state.pdfData}
                                        showNotes={this.state.csvHeader.find(x => x.key === 'note') && this.state.csvHeader.find(x => x.key === 'note').active}
                                        // totals={this.state.pdfTotalData}
                                    />
                                }
                                <ModalFooter>
                                    <Button color="success" onClick={() => this.print()}>Print
                                        Report</Button>{' '}
                                    <Button color="secondary" onClick={() => this.toggleReport()}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                            <Card className='mt-3 card-accent-primary dont-print'>
                                <CardHeader>
                                    Filters
                                    <div className='pull-right'>
                                        <CSVLink data={this.state.csvData}
                                                       headers={this.getCsvHeaders()}
                                                       filename={'EmployeeTimeCards.csv'} target="_blank">
                                            <button className="header-button btn btn-outline-primary"
                                                    disabled={this.state.loading}
                                                    title="Download CSV">
                                                <i className="material-icons">file_download</i>
                                            </button>
                                        </CSVLink>
                                        {this.state.selectedPayPeriodRule &&
                                        <React.Fragment>
                                            <Button color='success' className='header-button button-padding'
                                                dataToggle={!isApproved || !isEnded ? "tooltip" : null}
                                                dataPlacement="left"
                                                title={!isApproved || !isEnded ? !isEnded ? 'Pay period is not finished yet.' : 'Pay period is not approved yet.' : ''}
                                                onClick={() => this.loadTimeCards(this.state.searchParams)}>Generate
                                                Report
                                            </Button>
                                            {canApprove &&
                                            <Button color='success' className='header-button button-padding'
                                                onClick={() => this.props.history.push("/manage/time-card-approval")}>Approve
                                                    Payroll
                                            </Button> }
                                        </React.Fragment>}
                                    </div>
                                    {
                                        !isApproved &&
                                            <span style={{ fontSize: '10px', width: '300px', display: 'block', float: 'right', marginRight: '12px' }}><span style={{ fontWeight: 'bold' }}>Note: </span>This Pay Period has not yet been approved and may contain conflicting information. Additionally, its PTO accrual has not been calculated.</span>
                                    }
                                </CardHeader>
                                <CardBody>
                                    {this.state.payPeriodRuleDataList.length > 1 ?
                                    <div className='mb-3'>
                                        <Label>Pay Period Rule</Label>
                                        <div className="w-25 pull-right">
                                            <Select
                                                inputId="PayPeriodRule"
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                value={this.state.selectedPayPeriodRule}
                                                options={this.state.payPeriodRuleDataList}
                                                onChange={this.handlePayPeriodRuleSelectChange}
                                                placeholder={'Payperiod rules...'}
                                            />
                                        </div>
                                    </div> : null }
                                    {!this.state.customDateRangeFilterIncluded &&
                                    <div className='mb-3'>
                                        <Label>Pay Period</Label>
                                        <div className="w-25 pull-right">
                                            <Select
                                                inputId="PayPeriod"
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.display}
                                                value={this.state.selectedPayPeriod}
                                                options={this.state.payPeriodDataList}
                                                onChange={this.handlePayPeriodSelectChange}
                                                placeholder={'Payperiods...'}
                                            />
                                        </div>
                                    </div>}
                                    <div className='mb-3'>
                                        <Label>Custom Period {this.state.customDateRangeFilterIncluded ? <a onClick={() => this.setState({ customDateRangeFilterIncluded: false, customStartDate: moment(), customEndDate: moment() })}
                                        role="button" style={{ color: "#007bff", fontStyle: "italic" }}>&nbsp;remove filter</a> :
                                            <a onClick={() => this.setState({ customDateRangeFilterIncluded: true })} role="button" style={{ color: "#007bff", fontStyle: "italic" }}>&nbsp;add filter</a>}</Label>
                                            {this.state.customDateRangeFilterIncluded &&
                                            <DateRange
                                                style={{ textAlign: 'right' }}
                                                linkedCalendars={true}
                                                ranges={Util.defaultDateRanges}
                                                onInit={this.handleDateChange}
                                                onChange={this.handleDateChange}
                                                startDate={this.state.customStartDate}
                                                endDate={this.state.customEndDate}
                                                theme={{
                                                Calendar: {width: "250px"},
                                                PredefinedRanges: {marginLeft: 10, marginTop: 10}
                                            }}
                                                className="search-date-range"
                                                    />}
                                    </div>
                                    {this.userTenant.admin ?
                                    <div className='mb-3'>
                                        <Label>Department</Label>
                                        <div className="w-25 pull-right">
                                            <SelectDepartment department={this.state.selectedDepartment}
                                                              onChange={this.handleDepartmentSelectChange}/>
                                        </div>
                                    </div> : null }
                                    <div className='mb-3'>
                                        <Label>Employee</Label>
                                        <div className="w-25 pull-right">
                                            <SelectEmployee employee={this.state.selectedEmployee}
                                                            onChange={this.handleEmployeeSelectChange}/>
                                        </div>
                                    </div>
                                        {this.state.showCustomizeFieldsContainer ?
                                        <>
                                            <a onClick={() => this.setState({ showCustomizeFieldsContainer: false })} role="button" style={{ color: "#007bff", fontStyle: "italic" }}>Hide customize fields</a>
                                            <div className="mt-4">
                                                <CSVExportFilters
                                                    fieldsConfiguration={this.state.csvHeader}
                                                    handleFieldChange={this.handleCSVHeaderChange}
                                                />
                                            </div>
                                        </>
                                        :
                                            <span><a onClick={() => this.setState({ showCustomizeFieldsContainer: true })} role="button" style={{ color: "#007bff", fontStyle: "italic" }}>Customize fields</a> (show/hide)</span>
                                        }
                                </CardBody>
                            </Card>
                        </Col>
                    </div>
                    <div className={'print-block'}>
                        {!this.state.pdfData ? '' :
                            <EmployeeTimeCardsReport
                                payPeriodDates={this.getSelectedPayPeriodDays()}
                                pdfColumnHeaders={this.pdfColumnHeaders()}
                                pdfData={this.state.pdfData}
                                // totals={this.state.pdfTotalData}
                            />
                        }
                    </div>
                </div>
            </Container>
        )
    }
}

export default EmployeeTimeCards;