import React, {useState, useEffect} from 'react';
import {
    Button,
    Card,
    CardBody,
    Container,
    InputGroup,
    Modal,
    ModalHeader, ModalBody, Input, ModalFooter, Navbar, NavbarBrand, NavItem, Nav, CardHeader
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import ClockInModal from "../../../components/Modal/ClockInModal";
import NoteModal from "../../../components/Modal/NoteModal";
import TimeClockOptions from "../TimeClock/TimeClockOptions";
let timeOfDay;
let refresh;
let punchClockAfterSave = false;

function Terminal(props) {
    const [terminalSettings, setTerminalSettings] = useState({});
    const [companySettings, setCompanySettings] = useState({});
    const [currentShift, setCurrentShift] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [userTenant, setUserTenant] = useState(null);
    const [validUser, setValidUser] = useState(false);
    const [authenticateModal, setAuthenticateModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [pin, setPin] = useState('');
    const [loading, setLoading] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [clockInModal, setClockInModal] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [note, setNote] = useState('');
    const [projects, setProjects] = useState([]);
    const [ipRangeError, setIpRangeError] = useState(false);

    useEffect(loadData, []);
    useEffect(startTime, []);

    function refreshPage() {
        clearTimeout(refresh);
        refresh = setTimeout(() =>{ location.reload() }, 60000);
    }

    function toggleSettingsModal() {
        window.open('company/terminals','_newtab');
    }

    function toggleNoteModal() {
        setNoteModal(!noteModal);
    }

    function handleNoteChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            setNote(e.target.value);
        }
    }

    function handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            if (e.target.name.includes('password')) {
                setPassword(e.target.value);
            } else {
               setUsername(e.target.value);
            }
        }
    }

    function handlePinChange(e) {
        if (e.target) {
            setPin(e.target.value);
        }
    }

    function handleUsernameChange(e) {
        if (e.target) {
            setUsername(e.target.value);
        }
    }

    function handlePasswordChange(e) {
        if (e.target) {
            setPassword(e.target.value);
        }
    }

    function startTime() {
        clearInterval(timeOfDay);
        let updateTime = function () {
            const today = new Date();
            let currentTimeSpan = document.getElementById('time');
            if (currentTimeSpan !== null) {
                currentTimeSpan.innerHTML = today.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
            }
        };
        updateTime();
        timeOfDay = setInterval(function () {
            updateTime();
        }, 10000);
        return () => {clearInterval(timeOfDay)};
    }

    function logBreak(type) {
        let breakEntry = {employeeCode: selectedUser.userId};
        if (props.coords) {
            breakEntry.geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
        }
        if(type) {
            breakEntry.breakType = type;
        }
        setLoading(true);
        const apiUrl = Util.apiUrl(`terminals/break/${props.match.params.companyId}`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(breakEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Break Log Successful', {position: 'bottom-right'});
                setTimeout(() =>{ location.reload() }, 1500);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function saveNote() {
        setLoading(true);
        if(terminalSettings.requireShiftNote && !note) {
            toast.error('Shift note required for clock out.', {position: 'bottom-right'});
            return;
        }
        let shiftEntry = {...currentShift};
        shiftEntry.note = note;
        const apiUrl = Util.apiUrl(`terminals/save-note/${props.match.params.companyId}`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (!json || json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                setNoteModal(false);
                toast.success('Shift note was saved', {position: 'bottom-right'});
                if(punchClockAfterSave) {
                    punchClock(null, null);
                    punchClockAfterSave = false;
                    refreshPage();
                } else {
                    setTimeout(() =>{ location.reload() }, 1500);
                }
            })
            .catch((error) => {
                ServerResponseHandler.handleError(error)
            })
            .finally();
    }

    function validateUser() {
        setLoading(true);
        let formUrl;
        let payload;
        const companyId = props.match.params.companyId;
        switch (terminalSettings.loginType) {
            case 'Drop Down with Pin':
                formUrl = Util.apiUrl(`terminals/validate-pin/${companyId}`);
                payload = {
                    employeeCode: selectedUser.userId,
                    employeePin: pin
                };
                break;
            case 'Full Login':
                formUrl = Util.apiUrl(`terminals/validate-full-login/${companyId}`);
                payload = {
                    email: username,
                    password: password,
                    employeeCode: selectedUser.userId
                };
                break;
            case 'Drop Down':
                formUrl = Util.apiUrl(`terminals/get-user/${companyId}`);
                payload = selectedUser.userId;
                break;
        }

        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(payload)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    if (json.errorCode ==='UNKNOWN'){
                        json.errorMessage = 'Invalid username or password';
                    }
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    setValidUser(false);
                    return;
                }
                setUserTenant(json.userTenant);
                setCurrentShift(json.shiftLog);
                setNote(json.shiftLog ? json.shiftLog.shiftNote : '');
                if(!selectedUser) {
                    setSelectedUser({userId: json.userTenant.id});
                }
                setValidUser(true);

                if(json.userTenant && json.userTenant.userTenantProjectList) {
                    const tmpProjects = json.userTenant.userTenantProjectList.map(x => x.project);
                    console.log(tmpProjects);
                    setProjects(tmpProjects)
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function punchClock(event, requiredFields = null, closeProject) {
        if(requiredFields && companySettings.requirePositionClockIn && !requiredFields.position) {
            toast.error("You must select a position to clock in. If you are unable to, contact your manager to assign a position.", {position: 'bottom-right'});
            return;
        }

        if(requiredFields && companySettings.requireProjectClockIn && !requiredFields.project) {
            toast.error("Project is required.", {position: 'bottom-right'});
            return;
        }

        const payload = {
            companyId: props.match.params.companyId,
            terminalId: props.match.params.terminalId,
            shiftEntry: {
                employeeCode: selectedUser.userId,
                employeePin: pin,
                closeProject: !!closeProject,
                note: note
            }
        };
        if(requiredFields !== null) {
           payload.shiftEntry.projectId = requiredFields.project ? requiredFields.project.id : '';
           payload.shiftEntry.positionId = requiredFields.position ? requiredFields.position.id : '';
        }
        if(terminalSettings.requireShiftNote && !note && currentShift) {
            refreshPage();
            punchClockAfterSave = true;
            toggleNoteModal();
            return;
        }
        const formUrl = Util.apiUrl(`terminals/clock`);
        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(payload)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    if(json.errorMessage === 'Pin is invalid.') {
                        setClockInModal(false)
                    }
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                if(currentShift) {
                    toast.success('Clock Out Successful', {position: 'bottom-right'});
                } else {
                    toast.success('Clock In Successful', {position: 'bottom-right'});
                    setClockInModal(false);
                }
                setTimeout(() =>{ location.reload() }, 1500);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadData() {
        const formUrl = Util.apiUrl(`terminals/details`);
        const payload = {terminalId: props.match.params.terminalId, companyId: props.match.params.companyId};
        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(payload)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    if(json.errorCodeValue === 411) {
                        setAuthenticateModal(true);
                    }
                    if(json.errorCodeValue === 412) {
                        setIpRangeError(true);
                    }
                    return;
                }
                setCompanySettings(json.companySettings);
                setTerminalSettings(json.terminal);
                setProjects(json.projects.resultList);
                setUsers(json.users ? json.users : []);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function authenticateDevice() {
        const payload = {
            username: username,
            password: password
        };
        let querystring = require('querystring');
        const apiUrl = Util.apiUrl(`resources/login`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: querystring.stringify(payload),
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json) {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                const formUrl = Util.apiUrl(`terminals/authorize`);
                fetch(formUrl, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json;charset=UTF-8'},
                    body: JSON.stringify({terminalId: props.match.params.terminalId})
                })
                    .then(response => ServerResponseHandler.getResponse(response))
                    .then(json => {
                        if (json && json.errorMessage) {
                            toast.error(json.errorMessage, {position: 'bottom-right'});
                            return;
                        }
                        setAuthenticateModal(false);
                        loadData();
                    })
                    .catch(error => ServerResponseHandler.handleError(error))
                    .finally(() => setLoading(false));
            }
        }).catch(error => ServerResponseHandler.handleError(error.message)
        ).finally(() => {setLoading(false)});

    }
   return (
       <div className="animated fadeIn">
           <ToastContainer />
           <Container fluid>
               <Navbar>
                   <NavbarBrand>{terminalSettings.name}</NavbarBrand>
                       <Nav className="ml-auto" navbar>
                           <NavItem>
                               <Button color={'secondary'} onClick={toggleSettingsModal}>Update Terminal Settings</Button>
                           </NavItem>
                       </Nav>
               </Navbar>
               <div>
                   <Card className="p-4 terminal-element">
                       <CardHeader className="pt-0 text-center">
                           <span style={{fontSize: 'xx-large'}} id="time">00:00</span><br/>
                           {!validUser ? '' :
                               <span>{'Welcome ' + userTenant.nameFirst}</span>
                           }
                       </CardHeader>
                       <CardBody className="pt-0" style={{width: '100%'}}>
                           {
                               validUser || terminalSettings.loginType === 'Full Login' ? '' :
                               ipRangeError ? <div className="text-center">
                                   <div className="error">Device IP Address is out of range.</div>
                               </div> :
                               <div className="fb-select">
                                   <Select
                                       getOptionValue={(option) => option.userId}
                                       getOptionLabel={(option) => option.name}
                                       value={selectedUser} options={users}
                                       onChange={(user) => setSelectedUser(user)}
                                       placeholder={'Select a User...'}
                                   />
                               </div>
                           }
                           {
                               validUser ? '' :
                               terminalSettings.loginType === 'Drop Down with Pin' ?
                                   <div>
                                       <InputGroup className="mb-3 mt-3">
                                           <input type="password" autoComplete="new-password" placeholder="Enter Pin..." name="pin" value={pin}
                                           className="form-control" min="0" maxLength={6} onChange={handlePinChange}/>
                                       </InputGroup>
                                   </div>
                                   :
                               terminalSettings.loginType === 'Full Login' ?
                                   <div>
                                       <InputGroup className="mb-3 mt-3">
                                           <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <span className="material-icons">person</span>
                                                        </span>
                                           </div>
                                           <Input id="username" type="text" placeholder="Username" required
                                                  onChange={handleUsernameChange}
                                                  autoComplete="username"
                                                  onKeyPress={(e) => {if(e.key === 'Enter') {validateUser()}}}/>
                                       </InputGroup>
                                       <InputGroup className="mb-4">
                                           <div className="input-group-prepend">
                                               <span className="input-group-text">
                                               <span className="material-icons">lock</span>
                                               </span>
                                           </div>
                                           <Input id="password" type="password" placeholder="Password" required
                                           onChange={handlePasswordChange}
                                           autoComplete="current-password"
                                           onKeyPress={(e) => {if(e.key === 'Enter') {validateUser()}}}/>
                                       </InputGroup>
                                   </div>
                                   :
                                   ''
                           }
                           {
                               validUser ? '' :
                                   <div className="clock-action-bottom">
                                       <Button  disabled={loading || (!selectedUser && terminalSettings.loginType !== 'Full Login')} className="clockIn" onClick={validateUser}
                                                active={true}>SUBMIT</Button>
                                   </div>
                           }
                           {
                               !validUser ? '' :
                                   <TimeClockOptions
                                       buttonName={!companySettings.enableProjects && !companySettings.enablePositions ? 'Clock In' : 'Submit'}
                                       isOpen={validUser}
                                       companyId={props.match.params.companyId}
                                       companySettings={companySettings}
                                       employee={selectedUser}
                                       userTenant={userTenant}
                                       currentShift={currentShift}
                                       projects={projects}
                                       logBreak={logBreak}
                                       submit={punchClock}
                                       toggleNoteModal={toggleNoteModal}
                                       refresh={refreshPage}
                                   />
                           }
                       </CardBody>
                   </Card>
               </div>
               <Modal isOpen={settingsModal}>
                   <ModalHeader>Update Terminal Settings</ModalHeader>
                   <ModalBody>
                       <form onSubmit={() =>{}} id="login-form" style={{textAlign: 'center'}}>
                           <InputGroup className="mb-3">
                               <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="material-icons">person</span>
                                </span>
                               </div>
                               <Input id="username" type="text" placeholder="Username" required
                                      onChange={handleChange}
                                      autoComplete="username"/>
                           </InputGroup>
                           <InputGroup className="mb-4">
                               <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="material-icons">lock</span>
                                </span>
                               </div>
                               <Input id="password" type="password" name="password" placeholder="Password" required
                                      onChange={handleChange}
                                      autoComplete="current-password"/>
                           </InputGroup>
                       </form>
                   </ModalBody>
                   <ModalFooter>
                       <Button color="primary" onClick={() =>{}}>Authenticate</Button>{' '}
                       <Button color="secondary"
                               onClick={() => setSettingsModal(!settingsModal)}>Cancel</Button>
                   </ModalFooter>
               </Modal>
               <NoteModal
                   isOpen={noteModal}
                   required={terminalSettings.requireShiftNote}
                   note={note}
                   handleNoteChange={handleNoteChange}
                   saveNote={saveNote}
                   toggleModal={toggleNoteModal}
               />
                <ClockInModal
                    toggle={() => {setClockInModal(false)}}
                    isOpen={clockInModal}
                    enableProjects={companySettings.enableProjects}
                    enablePositions={companySettings.enablePositions}
                    employee={selectedUser}
                    currentShift={currentShift}
                    submit={punchClock}
                />
               <Modal isOpen={authenticateModal}>
                   <ModalHeader>Device Authentication Required</ModalHeader>
                   <ModalBody>
                       <form onSubmit={authenticateDevice} id="login-form" style={{textAlign: 'center'}}>
                           <img style={{width: '100%'}} src="https://s3.amazonaws.com/fishbowllabor/StaticContent/fbtimelogo.png"/>
                           <p>&nbsp;</p>
                           <InputGroup className="mb-3">
                               <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="material-icons">person</span>
                                </span>
                               </div>
                               <Input id="username" type="text" placeholder="Username" required
                                      onChange={handleChange}
                                      autoComplete="username"
                                      onKeyPress={(e) => {if(e.key === 'Enter'){authenticateDevice()};}}/>
                           </InputGroup>
                           <InputGroup className="mb-4">
                               <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="material-icons">lock</span>
                                </span>
                               </div>
                               <Input id="password" type="password" name="password" placeholder="Password" required
                                      onChange={handleChange}
                                      autoComplete="current-password"
                                      onKeyPress={(e) => {if(e.key === 'Enter'){authenticateDevice()};}}/>
                           </InputGroup>
                       </form>
                   </ModalBody>
                   <ModalFooter>
                       <Button color="primary" onClick={authenticateDevice}>Authenticate</Button>{' '}
                       <Button color="secondary"
                               onClick={() => setAuthenticateModal(!authenticateModal)}>Cancel</Button>
                   </ModalFooter>
               </Modal>
           </Container>
       </div>
    )
}

export default Terminal;