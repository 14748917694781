import React, {Component} from "react";
import {
    Button, Card,
    CardBody,
    CardHeader,
    Col, Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as ServerResponseHandler from "../../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../../components/Framework/Util";
import moment from "moment";
import OvertimeRulesTable from "../../../../components/DataTables/OvertimeRulesTable";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const companySettings = Util.getCompanySettings();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
const canDelete = isAdminOrManager;

class OvertimeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overtimeRuleList: [],
            overtimeRuleId: '',
            ruleName: '',
            ruleWeeklyOvertime: 0,
            ruleDailyOvertime: 0,
            ruleDailyDoubleOvertime: 0,
            ruleIsDefault: false,
            newDialogOvertime: false,
        }

        this.getTableColumnsOvertime = this.getTableColumnsOvertime.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleOvertimeSubmit = this.handleOvertimeSubmit.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleNewModalOvertime = this.toggleNewModalOvertime.bind(this);
        this.toggleConfirmDeleteOvertimeModal = this.toggleConfirmDeleteOvertimeModal.bind(this);
        this.saveOvertimeRule = this.saveOvertimeRule.bind(this);
        this.deleteLog = this.deleteLog.bind(this);
        this.loadOvertimeData = this.loadOvertimeData.bind(this);

        this.loadOvertimeData(true);
    }

    getTableColumnsOvertime() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Default', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}

        ];
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    handleCheckBoxChange(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    toggleNewModalOvertime() {
        this.setState({
            newDialogOvertime: !this.state.newDialogOvertime,
            overtimeRuleId: '',
            ruleName: '',
            ruleWeeklyOvertime: 0,
            ruleDailyOvertime: 0,
            ruleDailyDoubleOvertime: 0,
            ruleIsDefault: false,
        });
    }

    toggleEditModal(event, row) {
        if (event.target.classList.contains('delete')) {
            return false;
        }
        this.setState({
            newDialogOvertime: !this.state.newDialogOvertime,
            overtimeRuleId: row.id,
            ruleName: row.name,
            ruleWeeklyOvertime: row.weeklyOvertime,
            ruleDailyOvertime: row.dailyOvertime,
            ruleDailyDoubleOvertime: row.dailyDoubleOvertime,
            ruleIsDefault: row.default,
        });
    }

    toggleConfirmDeleteOvertimeModal(row) {
        this.setState({
            row: row,
            confirmDeleteOvertimeDialog: !this.state.confirmDeleteOvertimeDialog,
        });
    }

    handleOvertimeSubmit(event) {
        event.preventDefault();

        if (!this.state.ruleName) {
            toast.error('Overtime rule name is empty', {position: 'bottom-right'});
            return;
        }

        if (Number(this.state.ruleDailyDoubleOvertime) > 0 && Number(this.state.ruleDailyDoubleOvertime) <  Number(this.state.ruleDailyOvertime)) {
            toast.error('Daily overtime is greater than daily double overtime', {position: 'bottom-right'});
            return;
        }

        if (Number(this.state.ruleDailyDoubleOvertime) < 0 || Number(this.state.ruleDailyOvertime) < 0 || Number(this.state.ruleWeeklyOvertime) < 0) {
            toast.error('Overtime values cannot be negative', {position: 'bottom-right'});
            return;
        }

        if (Number(this.state.ruleDailyDoubleOvertime) > 0 && Number(this.state.ruleDailyOvertime) === 0) {
            toast.error('Daily overtime must be greater than zero if using daily double overtime', {position: 'bottom-right'});
            return;
        }

        const rule = {
            name: this.state.ruleName,
            weeklyOvertime: this.state.ruleWeeklyOvertime,
            dailyOvertime: this.state.ruleDailyOvertime,
            dailyDoubleOvertime: this.state.ruleDailyDoubleOvertime,
            isDefault: this.state.ruleIsDefault,
        };

        this.setState({newDialogOvertime: !this.state.newDialogOvertime});
        if (!this.state.overtimeRuleId) {
            this.saveOvertimeRule('POST', rule);
        } else {
            this.saveOvertimeRule('PATCH', rule, this.state.overtimeRuleId);
        }
    }

    saveOvertimeRule(method, obj, objId = '') {
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/settings/overtimeRules/${objId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(obj),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Overtime rule was saved', {position: 'bottom-right'});
                this.loadOvertimeData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    deleteLog() {
        this.setState({loading: true});
        const overTimeRuleToDelete =  this.state.row;
        overTimeRuleToDelete.deletedOn = moment();
        const apiUrl = Util.apiUrl(`company/settings/overtimeRules/${overTimeRuleToDelete.id}`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify(overTimeRuleToDelete),
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Overtime rule was deleted', {position: 'bottom-right'});
                this.loadOvertimeData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, confirmDeleteOvertimeDialog: false}));
    }

    loadOvertimeData(initial = false) {
        if(initial) this.state.loading = true;
        else this.setState({loading: true});

        const apiUrl = Util.apiUrl(`company/settings/overtimeRules`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({overtimeRuleList: json.resultList});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false})
            });
    }

    render() {
        const settings = this.state;
        return (
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between align-items-center no-gutters">
                        <Col><div>Overtime Settings</div></Col>
                        <Col>
                            <button className="header-button btn btn-outline-success pull-right"
                                    onClick={() => this.toggleNewModalOvertime()}><i
                                className="material-icons">add</i></button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <OvertimeRulesTable
                        data={settings.overtimeRuleList}
                        tableColumns={this.getTableColumnsOvertime()}
                        toggleConfirmDeleteModal={this.toggleConfirmDeleteOvertimeModal}
                        toggleEditModal={this.toggleEditModal}
                        tableSettings={{
                            canDelete: canDelete,
                            canEdit: canEdit
                        }}
                    />
                </CardBody>
                <Modal isOpen={settings.newDialogOvertime}>{/*================ New Overtime Rule Modal ================*/}
                    <form onSubmit={this.handleOvertimeSubmit} id="modal-form" noValidate>
                        <ModalHeader>Overtime Rule</ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Label>Name<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <input type="text" placeholder="Name" name="ruleName" ref="ruleName"
                                           value={settings.ruleName}
                                           className="form-control" onChange={this.handleChange} required/>
                                </InputGroup>
                            </div>

                            <div className="mb-3">
                                <Label>Weekly Overtime</Label>
                                <InputGroup className="mb-3">
                                    <input type="number" step="any" value={settings.ruleWeeklyOvertime}
                                           name="ruleWeeklyOvertime" ref="ruleWeeklyOvertime"
                                           className="form-control" onChange={this.handleChange} required/>
                                </InputGroup>
                            </div>

                            <div className="mb-3">
                                <Label>Daily Overtime</Label>
                                <InputGroup className="mb-3">
                                    <input type="number" step="any" value={settings.ruleDailyOvertime}
                                           name="ruleDailyOvertime" ref="ruleDailyOvertime"
                                           className="form-control" onChange={this.handleChange} required/>
                                </InputGroup>
                            </div>

                            <div className="mb-3">
                                <Label>Daily Double Overtime</Label>
                                <InputGroup className="mb-3">
                                    <input type="number" step="any" value={settings.ruleDailyDoubleOvertime}
                                           name="ruleDailyDoubleOvertime" ref="ruleDailyDoubleOvertime"
                                           className="form-control" onChange={this.handleChange} required/>
                                </InputGroup>
                            </div>

                            <div className="mb-3">
                                <Label>Default</Label>
                                <Label className="switch switch-icon switch-pill switch-info pull-right"
                                       title={'The default overtime rule will auto populate when new employees are created.'}>
                                    <Input type="checkbox" name="ruleIsDefault" ref="ruleIsDefault"
                                           className="switch-input"
                                           checked={settings.ruleIsDefault} onChange={this.handleCheckBoxChange}/>
                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                    <span className="switch-handle"/>
                                </Label>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success">Save</Button>{' '}
                            <Button color="secondary" onClick={() => this.toggleNewModalOvertime()}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={settings.confirmDeleteOvertimeDialog}>{/*================ Delete Overtime Modal ================*/}
                    <ModalHeader>Delete Overtime Rule</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete overtime rule?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.deleteLog()}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => this.toggleConfirmDeleteOvertimeModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Card>
        )
    }
}

export default OvertimeSettings;