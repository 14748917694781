import React, {Component} from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class Paging extends Component {
    constructor(props) {
        super(props);

        this.getPageNumber = this.getPageNumber.bind(this);
        this.getPagingItems = this.getPagingItems.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    getPageNumber() {
        return this.props.total % this.props.size == 0 ? Math.floor(this.props.total / this.props.size) : Math.floor(this.props.total / this.props.size) + 1;
    }

    getPagingItems() {
        const pageNumber = this.getPageNumber();
        let items = [];

        let numbers = [];
        if(pageNumber < 8) {
            numbers = Array.from({length: pageNumber}, (_, i) => i + 1);
        } else {
            if(this.props.page < 4) {
                numbers = Array.from({length: 4}, (_, i) => i + 1);
                numbers.push(0);
                numbers.push(pageNumber);
            } else if(this.props.page > pageNumber - 3) {
                numbers = Array.from({length: 4}, (_, i) => i + pageNumber - 3);
                numbers.unshift(0);
                numbers.unshift(1);
            } else {
                numbers = Array.from({length: 3}, (_, i) => i + this.props.page - 1);
                numbers.unshift(0);
                numbers.unshift(1);
                numbers.push(0);
                numbers.push(pageNumber);
            }
        }

        numbers.forEach(item => {
            items.push(<PaginationItem disabled={this.props.disabled} style={{ cursor: 'pointer' }} active={item == this.props.page}>
                            <PaginationLink onClick={() => { this.setPage(item) }}>
                                {item == 0 ? "..." : item}
                            </PaginationLink>
                        </PaginationItem>);
        });

        return items;
    }

    setPage(page) {
        const pageNumber = this.getPageNumber();

        if(page > pageNumber || page < 1)
            return;

        this.props.setPage(page);
    }

    render() {
        return(
            <React.Fragment>
                <Pagination style={{justifyContent: 'center'}} aria-label="Page navigation example">
                    <PaginationItem disabled={this.props.disabled} style={{ cursor: 'pointer' }}>
                        <PaginationLink onClick={() => { this.setPage(this.props.page - 1) }} previous />
                    </PaginationItem>
                    {this.getPagingItems()}
                    <PaginationItem disabled={this.props.disabled} style={{ cursor: 'pointer' }}>
                        <PaginationLink onClick={() => { this.setPage(this.props.page + 1) }} next />
                    </PaginationItem>
                </Pagination>
            </React.Fragment>
        );
    }
}

export default Paging;