import React, {useEffect, useState} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import * as Util from "../Framework/Util";
import _ from "lodash";

function GlobalCompaniesTable(props) {
    const [lastOrderSelected, setLastOrderSelected] = useState('');
    const [globalUserData, setGlobalUserData] = useState([]);

    useEffect(() =>{
        loadData();
    }, [props.data])

    function loadData(columnHeader){
        if (columnHeader) {
            switch (columnHeader) {
                case 'Company':
                    columnHeader = 'name';
                    break;
                case 'Email':
                    columnHeader = 'defaultContact.email';
                    break;
                case 'Max Users':
                    columnHeader = 'maxUsers';
                    break;
                case 'FB Integrated':
                    columnHeader = 'fishbowlIntegrated';
                    break;
                case 'Expire on':
                    columnHeader = 'expirationDate';
                    break;
                case 'Last Login':
                    columnHeader = 'lastLogin';
                    break;
                case 'Stripe Customer Id':
                    columnHeader = 'stripeCustomerId';
                    break;
                case 'Stripe Subscription Id':
                    columnHeader = 'stripeSubscriptionId';
                    break;
                default:
                    columnHeader = columnHeader.toLowerCase();
            }

            if (props.data && columnHeader !== lastOrderSelected) {
                switch (columnHeader) {
                    case 'fishbowlIntegrated':
                    case 'lastLogin':
                        setGlobalUserData(_.sortBy(props.data, (item) => new Date(item.lastLogin)).reverse());
                        break;
                    case 'name':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.name.toLowerCase()));
                        break;
                    case 'defaultContact.email':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.defaultContact.email.toLowerCase()));
                        break;
                    case 'stripeCustomerId':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.stripeCustomerId.toLowerCase()));
                        break;
                    case 'stripeSubscriptionId':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.stripeSubscriptionId.toLowerCase()));
                        break;
                    default:
                        setGlobalUserData(_.sortBy(props.data, columnHeader));
                }
                setLastOrderSelected(columnHeader);
            } else {
                switch (columnHeader) {
                    case 'fishbowlIntegrated':
                    case 'lastLogin':
                        setGlobalUserData(_.sortBy(props.data, (item) => new Date(item.lastLogin)));
                        break;
                    case 'name':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.name.toLowerCase()).reverse());
                        break;
                    case 'defaultContact.email':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.defaultContact.email.toLowerCase()).reverse());
                        break;
                    case 'stripeCustomerId':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.stripeCustomerId.toLowerCase()).reverse());
                        break;
                    case 'stripeSubscriptionId':
                        setGlobalUserData(_.sortBy(props.data, (d) => d.stripeSubscriptionId.toLowerCase()).reverse());
                        break;
                    default:
                        setGlobalUserData(_.sortBy(props.data, columnHeader).reverse());
                }
                setLastOrderSelected('')
            }
        } else {
            setGlobalUserData(_.sortBy(props.data, name => name.toString().toLowerCase()));
            setLastOrderSelected('name');
        }
    }

    return (
        <div>
            <Row className="row-header col-header col-lg-12">
                {
                    props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index} onClick={() => loadData(column.displayName)}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                globalUserData.length < 1 ?
                    <NoData/> :
                    globalUserData.map((row, index) => {
                        return <div
                            className={props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                            key={index}>
                                <Row className='row-data row-header col-lg-12' onClick={(event) => props.toggleEditModal(event, row)}>
                                    {!props.tableColumns.filter(header => {return header.columnId === "company"})[0].hideColumn ? <Col><span title={row.name}>{Util.trimWithEllipses(row.name,30)}</span></Col> : '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "email"})[0].hideColumn ? <Col><span title={row.defaultContact ? row.defaultContact.email : ''}>{Util.trimWithEllipses(row.defaultContact ? row.defaultContact.email : '',30)}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "maxUsers"})[0].hideColumn ? <Col><span>{row.maxUsers}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "stripeCustomerId"})[0].hideColumn ? <Col><span>{row.stripeCustomerId}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "stripeSubscriptionId"})[0].hideColumn ? <Col><span>{row.stripeSubscriptionId}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "fbIntegrated"})[0].hideColumn ? <Col><span>{row.fishbowlIntegrated ? 'Yes' : ''}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "status"})[0].hideColumn ? <Col><span>{row.status}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "expireOn"})[0].hideColumn ? <Col><span>{Util.basicDateFormatter(row.expirationDate)}</span></Col>: '' }
                                    {!props.tableColumns.filter(header => {return header.columnId === "lastLogin"})[0].hideColumn ?  <Col><span>{Util.basicDateFormatter(row.lastLogin)}</span></Col>: '' }
                                </Row>
                        </div>
                    })
            }
        </div>
    )
}

export default GlobalCompaniesTable;
