import React, {useContext, useState, useEffect} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Container, ListGroup, ListGroupItem, Row} from 'reactstrap';
import * as ServerResponseHandler from "../../components/Framework/ServerResponseHandler";
import * as Util from "../../components/Framework/Util";
import LinkWidget from "../../components/Widget/LinkWidget";
import {Link} from 'react-router-dom';
import UserWidget from "../../components/Widget/UserWidget";
import {CompanyContext} from "../../context";
import { ToastContainer, toast } from 'react-toastify';

const moment = require('moment-timezone');
const company = (localStorage.userGlobal !== null && typeof localStorage.userGlobal !== "undefined") ? JSON.parse(localStorage.userGlobal).company : {};

const linkStyle = {
    color: 'black'
};

const clockInIconStyle = {
    backgroundColor: '#869e7b',
    color: '#fff',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '8px',
};

const clockOutIconStyle = {
    backgroundColor: '#cf6b6b',
    color: '#fff',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '8px',
};

const timelineStyle = {
    overflowY: 'scroll',
    height: '60vh',
    padding: '1rem'
};

let timelogs = [];
let employees = [];
let issues = [];
let currentPayPeriod = {};
let interval;

function Dashboard() {
    const userTenant = Util.getUserTenant();
    const [companySettings, setCompanySettings] = useContext(CompanyContext);
    const [ptoRequests, setPtoRequests] = useState([]);
    const [breakTimes, setBreakTimes] = useState([]);
    const [openPayroll, setOpenPayroll] = useState(0);
    const [openProjects, setOpenProjects] = useState(0);
    const [pendingPtoRequests, setPendingPtoRequests] = useState(0);
    const [hoursToday, setHoursToday] = useState(0);
    const [hoursYesterday, setHoursYesterday] = useState(0);
    const [hoursThisPayPeriod, setHoursThisPayPeriod] = useState(0);
    const [hoursThisWeek, setHoursThisWeek] = useState(0);
    const [hoursLastWeek, setHoursLastWeek] = useState(0);
    const [hoursLastPayPeriod, setHoursLastPayPeriod] = useState(0);
    const [employeesClockedIn, setEmployeesClockedIn] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAllData, setShowAllData] = useState(false);
    const [breakObject, setBreakObject] = useState({});


    if (!localStorage.getItem("loggedIn")) {
        window.location.href = "/login";
    }

    useEffect(() => {
        loadData();
        startClock();
        loadIssues();
    }, []);

    function updateTime() {
        let employeesClockedIn = loadEmployeesClockedIn();
        setEmployeesClockedIn(employeesClockedIn);
    }

    function startClock() {
        interval = setInterval(updateTime, 60000);
        return () => {clearInterval(interval)};
    }

    function getTimeframes() {
        const today = moment().startOf('day');

        // Cloning is necessary so that it does not change the date of today
        let yesterday = today.clone().subtract(1, 'days').startOf('day');
        let lastWeek = today.clone().subtract(1, 'week').startOf('day');

        return {
            today: today,
            yesterday: yesterday,
            lastWeek: lastWeek
        }
    }

    function addEntry(arr, entry) {
        let name = entry.name ? entry.name : Util.nameFormatter(entry.employeeNameFirst, entry.employeeNameLast);
        let clockIn = entry.breakIn ? entry.breakIn : entry.roundedClockIn;
        let clockOut = entry.breakOut ? entry.breakOut : entry.roundedClockOut ? entry.roundedClockOut : false;
        if (clockOut && (!entry.isBreak || entry.breakType !== 'Manual')) {
            arr.push({
                time: moment(clockOut),
                iconStyle: clockOutIconStyle,
                user: name,
                inOrOut: entry.isBreak ? 'End' : 'Out',
                imageUrl: entry.imageUrl,
                isBreak: entry.isBreak,
                breakType: entry.breakType
            });
        }

        if(entry.breakType === 'Manual') {
            arr.push({
                time: moment(clockIn),
                iconStyle: clockInIconStyle,
                user: name,
                inOrOut: 'Manual',
                imageUrl: entry.imageUrl,
                isBreak: entry.isBreak,
                breakType: 'Break',
                duration: entry.duration
            });
        } else {
            arr.push({
                time: moment(clockIn),
                iconStyle: clockInIconStyle,
                user: name,
                inOrOut: entry.isBreak ? 'Start' : 'In',
                imageUrl: entry.imageUrl,
                isBreak: entry.isBreak,
                breakType: entry.breakType
            });
        }

        return arr;
    }

    function createTimeline() {
        let timeline = [];
        const _timelogs = timelogs ? timelogs : [];
        _timelogs.forEach(entry => {
            const clockIn = entry.roundedClockIn;
            timeline = addEntry(timeline, entry);

            if(breakObject[entry.id]) {

                breakObject[entry.id].forEach(breaklog => {
                    if(breaklog.breakType) {
                        breaklog.name = Util.nameFormatter(entry.employeeNameFirst, entry.employeeNameLast);
                        breaklog.employeeCode = entry.employeeCode;
                        breaklog.isBreak = true;
                        breaklog.imageUrl = entry.imageUrl;
                        timeline = addEntry(timeline, breaklog);
                    } else {
                        breaklog.name = Util.nameFormatter(entry.employeeNameFirst, entry.employeeNameLast);
                        breaklog.employeeCode = entry.employeeCode;
                        breaklog.isBreak = true;
                        breaklog.imageUrl = entry.imageUrl;
                        breaklog.breakType = 'Manual';
                        breaklog.breakIn = clockIn;
                        timeline = addEntry(timeline, breaklog);
                    }
                });
            }
        });

        timeline.sort((left, right) => right.time - left.time);

        if(!showAllData) {
            timeline.splice(10, timeline.length);
        }

        const timeFrames = getTimeframes();

        let today = timeFrames.today;
        let yesterday = timeFrames.yesterday;
        let dates = {};

        timeline.map((line) => {
            let timeDisplay;
            if (line.time.isSame(today, 'day')) {
                timeDisplay = 'Today';
            } else if (line.time.isSame(yesterday, 'day')) {
                timeDisplay = 'Yesterday'
            } else {
                timeDisplay = Util.basicDateFormatter(line.time);
            }

            if (dates[timeDisplay]) {
                dates[timeDisplay].push(line);
            } else {
                dates[timeDisplay] = [line];
            }
        });

        return Object.keys(dates).map((date, index) => {
            const line = dates[date];
            return <ListGroupItem className="pr-0 pl-0" key={index}>
                <p className="mb-0" style={{fontSize: '14px'}}><em><strong>{date}</strong></em></p>
                {
                    line.map((obj, index) => {
                        return <Row className="pb-2 ml-2 no-gutters" key={index}>
                                <Col className="align-middle d-inline-block col-2">
                                    <p className="mb-0">{obj.duration ? obj.duration/60 + ' Min ' + ' ' : obj.time.format('hh:mm A')}</p>
                                </Col>
                            <Col>
                                    <img className="img-avatar" style={{height: '2em'}} src={obj.imageUrl ? obj.imageUrl : 'https://s3.amazonaws.com/fishbowllabor/StaticContent/default5.jpg'}/>
                                { obj.isBreak ?
                                    <span className="ml-2">{obj.user} - <em style={{color: '#007bff'}}>{obj.inOrOut + ' ' + obj.breakType}</em></span>
                                    :
                                    <span className="ml-2">{obj.user} - <em
                                        className={(obj.inOrOut === 'In' ? 'clocked-in' : 'clocked-out')}>Clocked {obj.inOrOut}</em></span>
                                }
                            </Col>
                            </Row>
                    })
                }
            </ListGroupItem>
        })
    }

    function addConflict(arr, entry, numberOfConflicts) {
        const first = entry[0];
        let name = Util.nameFormatter(first.userFirstName, first.userLastName);
        let clockIn =  first.timeLogRoundedClockIn
        let clockOut =  first.timeLogRoundedClockOut;

        arr.push({
            time: clockOut ? moment(clockIn).format('hh:mm A') + " - " + moment(clockOut).format('hh:mm A') : moment(clockIn).format('hh:mm A'),
            user: name,
            clockIn: moment(clockIn),
            active: !clockOut,
            numberOfConflicts: numberOfConflicts,
            timeLogId: first.timeLogId,
            message: numberOfConflicts === 1 ? 'Time entry has ' + numberOfConflicts + ' issue.' : 'Time entry has ' + numberOfConflicts + ' issues.'
        });

        return arr;
    }

    function createIssuesDisplay() {
        let timeline = [];
        const _conflicts = issues ? issues : [];
        _conflicts.forEach(group => {
            timeline = addConflict(timeline, group, group.length);
        });

        timeline.sort((left, right) => right.clockIn - left.clockIn);

        const timeFrames = getTimeframes();

        let today = timeFrames.today;
        let yesterday = timeFrames.yesterday;
        let dates = {};

        timeline.map((line) => {
            let timeDisplay;
            if (line.clockIn.isSame(today, 'day')) {
                timeDisplay = 'Today';
            } else if (line.clockIn.isSame(yesterday, 'day')) {
                timeDisplay = 'Yesterday'
            } else {
                timeDisplay = Util.basicDateFormatter(line.clockIn);
            }

            if (dates[timeDisplay]) {
                dates[timeDisplay].push(line);
            } else {
                dates[timeDisplay] = [line];
            }
        });

        return Object.keys(dates).map((date, index) => {
            const line = dates[date];
            return <ListGroupItem className="pr-0 pl-0" key={index}>
                <p className="mb-0" style={{fontSize: '14px'}}><em><strong>{date}</strong></em></p>
            {
                line.map((obj, index) => {
                    return <Row className="pb-2 ml-2 no-gutters" key={index}>
                        <Col className="align-middle d-inline-block col-2">
                        {obj.active ? <p className="mb-0">{obj.time} <span style={{ color: 'seagreen' }}>(active)</span></p> : <p className="mb-0">{obj.time}</p> }
                        </Col>
                        <Col className="align-middle d-inline-block col-2">
                            <p className="mb-0">{obj.user}</p>
                        </Col>
                        <Col className="align-middle d-inline-block col-8">
                            <p style={{ fontStyle: 'italic' }} className="mb-0">{obj.message}
                                <Link className="text-success" to={'/manage/time-cards?resolveTimeLogId=' + obj.timeLogId}> Resolve
                            </Link></p>
                        </Col>
                    </Row>
                })
            }
        </ListGroupItem>
        })
    }

    function loadMoreTimeline() {
        setShowAllData(true);
    }

    function loadClockedInUsers() {
        return employeesClockedIn.map((employee, index) => {
            return (
                <UserWidget key={index} employee={employee} name={employee.name}
                            project={employee.project}/>
            )
        });
    }

    function groupBy(arr, prop) {
        const map = new Map(Array.from(arr, obj => [obj[prop], []]));
        arr.forEach(obj => map.get(obj[prop]).push(obj));
        return Array.from(map.values());
    }

    function loadIssues() {
        fetch(Util.apiUrl(`time_log_issue`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(result => {
                const list = result.resultList;
                const grouped = groupBy(list, 'timeLogId');
                issues = grouped;
            }).catch(error => ServerResponseHandler.handleError(error))

            .finally(() => {

            });
    }

    function loadHoursSummary() {
        const current = currentPayPeriod;
        const timeFrames = getTimeframes();

        let today = timeFrames.today;
        let yesterday = timeFrames.yesterday;
        let lastWeek = timeFrames.lastWeek;

        let currentStartDate = moment(current.startDate);
        let currentEndDate = moment(current.endDate);

        let hoursToday = 0;
        let hoursYesterday = 0;
        let hoursThisWeek = 0;
        let hoursLastWeek = 0;
        let hoursThisPayPeriod = 0;
        let hoursLastPayPeriod = 0;

        let openPayrollCount = [];
        timelogs.forEach(timelog => {
            let clockIn = moment(timelog.roundedClockIn);

            let hours = timelog.durationForPayroll;
            // Calculate time if user is currently clocked in
            if (timelog.durationForPayroll === 0) {
                hours = moment.duration(moment().diff(clockIn, 'seconds'), 'seconds').asSeconds();
            }

            // if (companySettings.paidBreaks) {
            //     let breakTime = breakTimes.find(breakObj => breakObj.timelogId === timelog.id);
            //     if (breakTime) {
            //         hours += breakTime.duration;
            //     }
            // }

            // Today
            if (clockIn.isSame(today, 'day')) {
                hoursToday += hours;
            }

            // Yesterday
            if (clockIn.isSame(yesterday, 'day')) {
                hoursYesterday += hours;
            }

            // This Week
            if (clockIn.isSame(today, 'week')) {
                hoursThisWeek += hours;
            }

            // Last Week
            if (clockIn.isSame(lastWeek, 'week')) {
                hoursLastWeek += hours;
            }

            // Pay Periods
            if (clockIn.isBetween(moment(currentPayPeriod.startDate), moment(currentPayPeriod.endDate), 'days', '[]')) {
                if (openPayrollCount.indexOf(timelog.employeeCode) === -1) {
                    openPayrollCount.push(timelog.employeeCode);
                }

                hoursThisPayPeriod += hours
            } else {
                hoursLastPayPeriod += hours
            }
        });

        ptoRequests.forEach(pto => {
            let startDate = moment(pto.startDate);

            if (pto.status === 'Approved') {
                // Today
                const ptoSeconds = pto.hours * 3600;
                if (startDate.isSame(today, 'day')) {
                    hoursToday += ptoSeconds;
                }

                // Yesterday
                if (startDate.isSame(yesterday, 'day')) {
                    hoursYesterday += ptoSeconds;
                }

                // This Week
                if (startDate.isSame(today, 'week')) {
                    hoursThisWeek += ptoSeconds;
                }

                // Last Week
                if (startDate.isSame(lastWeek, 'week')) {
                    hoursLastWeek += ptoSeconds;
                }

                if (startDate.isBetween(currentStartDate, currentEndDate, 'days', '[]')) {
                    if (openPayrollCount.indexOf(pto.employeeCode) === -1) {
                        openPayrollCount.push(pto.employeeCode);
                    }

                    hoursThisPayPeriod += ptoSeconds
                } else {
                    hoursLastPayPeriod += ptoSeconds
                }
            }
        });
        setOpenPayroll(openPayrollCount.length);
        setHoursToday(Util.formatTimeFromSeconds(hoursToday));
        setHoursYesterday(Util.formatTimeFromSeconds(hoursYesterday));
        setHoursThisPayPeriod(Util.formatTimeFromSeconds(hoursThisPayPeriod));
        setHoursThisWeek(Util.formatTimeFromSeconds(hoursThisWeek));
        setHoursLastWeek(Util.formatTimeFromSeconds(hoursLastWeek));
        setHoursLastPayPeriod(Util.formatTimeFromSeconds(hoursLastPayPeriod));
    }

    function findEmployeeById(id) {
        return employees.find(employee => employee.id === id);
    }

    function getBreakTimes(breakObject) {
        let breakTimes = [];
        for (let timeLogId in breakObject) {
            let duration = 0;
            breakObject[timeLogId].forEach(function (breakLog) {
                duration += breakLog.duration;
            });
            breakTimes.push({timeLogId: timeLogId, duration: duration});
        }
        return breakTimes;
    }

    function loadEmployeesClockedIn() {
        let employeesClockedIn = [];
        let today = getTimeframes().today;
        let employeeHours = [];

        timelogs.forEach(timelog => {
            let clockIn = moment(timelog.roundedClockIn);
            let index = employeeHours.findIndex(employee => employee.id === timelog.employeeCode);
            let hours = timelog.durationForPayroll;
            if (timelog.durationForPayroll === 0 && !timelog.roundedClockOut) {
                hours = moment.duration(moment().diff(clockIn, 'seconds'), 'seconds').asSeconds();
            }

            if (index === -1) {
                index = employeeHours.push({
                    id: timelog.employeeCode,
                    hoursToday: 0,
                    hoursThisWeek: 0
                }) - 1;
            }

            if (clockIn.isSame(today, 'day') || timelog.duration === 0) {
                employeeHours[index].hoursToday += hours;
            }

            if (clockIn.isSame(today, 'week')) {
                employeeHours[index].hoursThisWeek += hours;
            }

            if (timelog.roundedClockOut) {
                return;
            }

            let employee = findEmployeeById(timelog.employeeCode);
            let i = employeeHours.findIndex(emp => emp.id === timelog.employeeCode);
            let todayHours = employeeHours[i].hoursToday;
            let weeklyHours = Util.formatTimeFromSeconds(employeeHours[i].hoursThisWeek);

            let name;
            if (employee) {
                name = Util.nameFormatter(employee.nameFirst, employee.nameLast);
            }

            let project = 'None';
            if (timelog.projectNumber) {
                project = timelog.projectNumber;
            }

            let imageUrl;
            if (employee && !employee.imageUrl) {
                //TODO: Change to default image/store default image in DB
                imageUrl = 'https://s3.amazonaws.com/fishbowllabor/StaticContent/default5.jpg'
            } else {
                imageUrl = employee ? employee.imageUrl : null;
            }

            employeesClockedIn.push({
                imageUrl: imageUrl,
                name: name,
                project: project,
                todayHours: todayHours,
                weeklyHours: weeklyHours,
                currentBreak: timelog.currentBreak
            });
        });

        return employeesClockedIn;
    }

    function loadTimelogs() {
        setLoading(true);

        fetch(Util.apiUrl(`reports/pay-periods`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(payperiods => {
                setLoading(false);
                currentPayPeriod = payperiods.current;
                const startPayperiod = payperiods.periodList[1] ? payperiods.periodList[1] : payperiods.current;
                const timelogSearch = Util.isAdmin()
                    ? {
                        //startDate: moment(startPayperiod.startDate).startOf('day'),
                        //endDate: moment(payperiods.current.endDate).endOf('day')
                    }
                    : {
                        //startDate: moment(startPayperiod.startDate).startOf('day'),
                        //endDate: moment(payperiods.current.endDate).endOf('day'),
                        departmentId: userTenant.departmentId
                    };

                const timelogsUrl = Util.apiSearchUrl(`timelog`, timelogSearch);
                const apiUrl = Util.apiSearchUrl(`timelog/get-breaks`, timelogSearch);
                let promises = [];
                promises.push(
                    fetch(apiUrl, {credentials: 'include'})
                        .then(response => ServerResponseHandler.getResponse(response))
                        .then(json => {
                            const breakObject = Util.mapArray('timeLogId', json.resultList);
                            setBreakTimes(!companySettings.paidBreaks ? [] : getBreakTimes(breakObject));
                            setBreakObject(breakObject);
                        })
                        .catch(error => ServerResponseHandler.handleError(error))
                );
                promises.push(
                    fetch(timelogsUrl, {credentials: 'include'})
                        .then(response => ServerResponseHandler.getResponse(response))
                        .then(json => {
                            setShowAllData(!!(!json.resultList || json.resultList.length <= 10));
                            timelogs = json.resultList;
                            setEmployeesClockedIn(loadEmployeesClockedIn());
                        })
                        .catch(error => ServerResponseHandler.handleError(error))
                );
                Promise.all(promises).then(loadHoursSummary);
            }).catch(error => ServerResponseHandler.handleError(error))

            .finally(() => {
                if(loading) {
                    setLoading(false);
                }
            });
    }

    function loadData() {
        const ptoSearch = Util.isAdmin()
            ? {ptoStatus: 'All'}
            : {
                ptoStatus: 'All',
                departmentId: userTenant.departmentId
            };

        const employeesCountUrl = Util.apiSearchUrl(`users`, null);
        const projectsUrl = Util.apiSearchUrl(`projects`, {projectStatus: 'Open'});
        const ptoRequestUrl = Util.apiSearchUrl(`pto`, ptoSearch);

        let promises = [];
        setLoading(true);
        promises.push(
            fetch(employeesCountUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => employees = json.resultList)
                .catch(error => ServerResponseHandler.handleError(error))
        );

        promises.push(
            fetch(projectsUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => setOpenProjects(json.maxResults))
                .catch(error => ServerResponseHandler.handleError(error))
        );

        promises.push(
            fetch(ptoRequestUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    let count = 0;
                    json.resultList.forEach(pto => {
                        if (pto.status === 'Pending') {
                            count++;
                        }
                    });
                    setPtoRequests(json.resultList);
                    setPendingPtoRequests(count);
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );

        Promise.all(promises).then(() => setLoading(false));

        loadTimelogs();
    }

    const isAdmin = Util.isAdmin();

    const startDate = Util.basicDateFormatter(moment(currentPayPeriod.startDate));
    const endDate = Util.basicDateFormatter(moment(currentPayPeriod.endDate));
    const payPeriod = <span className="float-right"
                            style={{fontSize: '17.5px', lineHeight: '25.2px'}}>{startDate} - {endDate}</span>;

    return (
        <div className="animated fadeIn mt-3">
            <Container fluid>
                {
                    company.onTrial ?
                <Row>
                    <Col lg="12" sm="12" xs="12">
                        <Row>
                            <Col xs="12">
                                <Card className="card-accent-primary-center" style={{minHeight: '150px'}}>
                                    <CardBody>
                                        <Link to="/company/billing" style={linkStyle}>
                                            <CardTitle tag="h2">Subscribe Now!</CardTitle>
                                            <div className="text-center font-xl">Are you enjoying your Free
                                                Trial of Fishbowl Time? Click here to Subscribe Today!
                                            </div>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row> :
                        ''
                }
                <Row>
                    {isAdmin
                        ?
                        <LinkWidget link={'/employees'}
                                    linkStyle={linkStyle}
                                    title={'Employees'}
                                    content={employees.length}/>
                        :
                        <Col lg="3" md="6" sm="12" xs="12">
                            <Card className="card-accent-primary">
                                <CardBody>
                                    <CardTitle className="text-center mb-0">Managed Employees</CardTitle>
                                    <div className="text-center font-3xl">
                                        {employees.length}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    <LinkWidget link="/reports/payroll"
                                linkStyle={linkStyle}
                                title="Open Payroll"
                                content={openPayroll}/>
                    <LinkWidget link="/projects"
                                linkStyle={linkStyle}
                                title={'Open ' + companySettings.projectPluralName}
                                content={openProjects}/>
                    <LinkWidget link="/pto"
                                linkStyle={linkStyle}
                                title="PTO Requests"
                                content={pendingPtoRequests}/>
                </Row>
                <Row>
                    <Col lg="4" md="12">
                        <Card className="card-accent-primary">
                            <CardBody>
                                <CardTitle>Timeline</CardTitle>
                                <ListGroup flush style={timelineStyle} className="pr-0 pl-0">
                                    {createTimeline()}
                                </ListGroup>
                                <Button disabled={showAllData} color="primary" className="float-right mt-2"
                                        onClick={loadMoreTimeline}>Load More</Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="8" sm="12" xs="12">
                        <Row>
                            <Col xs="12">
                                <Card className="card-accent-primary" style={{minHeight: '150px'}}>
                                    <CardBody>
                                        <CardTitle>Clocked In Now</CardTitle>
                                        <Row>
                                            {loadClockedInUsers()}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card className="card-accent-primary">
                                    <CardBody>
                                        <Row>
                                            <Col xs="12">
                                                <CardTitle>Hours Summary {payPeriod}</CardTitle>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" xs="12" className="text-center">
                                                <h5>Today</h5>
                                                {hoursToday}
                                            </Col>
                                            <Col sm="4" xs="12" className="text-center">
                                                <h5>Yesterday</h5>
                                                {hoursYesterday}
                                            </Col>
                                            <Col sm="4" xs="12" className="text-center">
                                                <h5>Pay Period</h5>
                                                {hoursThisPayPeriod}
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="4" xs="12" className="text-center">
                                                <h5>This Week</h5>
                                                {hoursThisWeek}
                                            </Col>
                                            <Col sm="4" xs="12" className="text-center">
                                                <h5>Last Week</h5>
                                                {hoursLastWeek}
                                            </Col>
                                            <Col sm="4" xs="12" className="text-center">
                                                <h5>Last Pay Period</h5>
                                                {hoursLastPayPeriod}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card className="card-accent-primary">
                                    <CardBody>
                                        <CardTitle>Time Entry Issues</CardTitle>
                                            <ListGroup flush className="pr-0 pl-0" style={{ overflowY: 'scroll', height: '60vh', padding: '1rem' }}>
                                                {createIssuesDisplay()}
                                            </ListGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default Dashboard;
