import React, { useEffect, useState } from 'react';
import {NavLink} from 'react-router-dom';
import {Badge, NavItem, NavLink as RsNavLink, Tooltip} from 'reactstrap';
import classNames from 'classnames';
import nav from './_nav';
import SidebarFooter from './../SidebarFooter';
import SidebarForm from './../SidebarForm';
import SidebarHeader from './../SidebarHeader';
import moment from 'moment';
import 'font-awesome/scss/font-awesome.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import SidebarMinimizer from "../SidebarMinimizer";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import useTooltip from "../../Hooks"
import * as Util from "../Framework/Util";
import * as _ from "lodash";
import SupportModal from '../Modal/SupportModal';
import {toast} from "react-toastify";

function Sidebar(props) {
    const company = (localStorage.userGlobal && typeof localStorage.userGlobal !== "undefined") ? JSON.parse(localStorage.userGlobal).company : {};
    const [tooltip, setTooltip] = useTooltip({});
    const [supportModalOpen, setSupportModalOpen] = useState(false);

    const debounceLocalStorageData = _.debounce(loadLocalStorageData, 1000,{leading: true, trailing: false});

    useEffect(debounceLocalStorageData);

    function loadLocalStorageData() {
        if(props.location.pathname === '/logout') {
            return;
        }
        const apiUrl = Util.apiUrl(`users/get_logged_in_user`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json) {
                if (json.errorMessage) {
                    return;
                }
                Util.storeSession(json);
            }
        }).catch(error => ServerResponseHandler.handleError(error.message)
        ).finally();
    }

    const handleClick = (e) => {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    };

    const toggleSupportModal = (e) => {
        setSupportModalOpen(!supportModalOpen);
    }

    const handleClickGrandParent = (e) => {
        e.preventDefault();
        e.target.parentElement.parentElement.classList.toggle('open');
    };

    const activeRoute = (routeName, props) => {
        return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';

    };

    const hideMobile = () => {
        if (document.body.classList.contains('sidebar-mobile-show')) {
            document.body.classList.toggle('sidebar-mobile-show')
        }
    };

    const sidebarHover = () => {
        if (document.body.classList.contains('collapsed')) {
            document.body.classList.toggle('sidebar-minimized');
        }
    };

    const backToAccount = () => {
        const formUrl = Util.apiUrl(`switch_user/logout`);
        let querystring = require('querystring');
        fetch(formUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }

                    Util.storeSession(json);
                    window.location.href = "/executor";
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    const subscribe = () => {
        props.history.push('/company/billing');
    };

    const trialExp = () => {
        if(!company.onTrial) {
            return;
        }
        const daysRemaining = -moment().diff(company.expirationDate, 'days');
        return <div className="trial-message" onClick={subscribe}>
            <div>
                Free Trial Expires in <span className={'text-accent'}>{' ' + daysRemaining + ''}</span>Days
            </div>
            <div className={'text-accent'}>
                Subscribe Now
            </div>
        </div>
    };
    // badge addon to NavItem
    const badge = (badge) => {
        if (badge) {
            const classes = classNames(badge.class);
            return (<Badge className={classes} color={badge.variant}>{badge.text}</Badge>)
        }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
        return (item.wrapper && item.wrapper.element ? (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)) : item.name)
    };

    // nav list section title
    const title = (title, key) => {
        const classes = classNames('nav-title', title.class);
        return (<li key={key} className={classes}>{wrapper(title)} </li>);
    };

    // nav list divider
    const divider = (divider, key) => {
        const classes = classNames('divider', divider.class);
        return (<li key={key} className={classes}/>);
    };

    // nav label with nav link
    const navLabel = (item, key) => {
        const classes = {
            item: classNames('hidden-cn', item.class),
            link: classNames('nav-label', item.class ? item.class : ''),
            icon: classNames(
                !item.icon ? 'fa fa-circle' : item.icon,
                item.label.variant ? `text-${item.label.variant}` : '',
                item.label.class ? item.label.class : ''
            )
        };
        return (
            navLink(item, key, classes)
        );
    };

    // nav item with nav link
    const navItem = (item, key) => {
        const classes = {
            item: classNames(item.class),
            link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
            icon: classNames(item.icon)
        };
        return (
            navLink(item, key, classes)
        )
    };

    // nav link
    const navLink = (item, key, classes) => {
        const url = item.url ? item.url : '';
        return (
            <NavItem key={key} className={classes.item}>
                {isExternal(url) ?
                    <RsNavLink href={url} className={'d-flex align-items-center' + classes.link} active>
                        <i className="material-icons md-14"></i>
                        <div id={'exNavLabel' + key} className="nav-item-name">{item.name}{badge(item.badge)}</div>
                        <Tooltip target={'exNavLabel' + key} isOpen={tooltip['exNavLabel' + key]} toggle={() => setTooltip('exNavLabel' + key)}>{item.name}</Tooltip>
                    </RsNavLink>
                    :
                    <NavLink to={url} className={'d-flex align-items-center ' + classes.link}
                             activeClassName="active" onClick={hideMobile}>
                        <i className="material-icons md-14">{classes.icon}</i>
                        <div id={'navLabel' + key} className="nav-item-name ellipsis">{item.name}{badge(item.badge)}</div>
                        <Tooltip target={'navLabel' + key} isOpen={tooltip['navLabel' + key]} toggle={() => setTooltip('navLabel' + key)}>{item.name}</Tooltip>
                    </NavLink>
                }
            </NavItem>
        )
    };

    // nav dropdown
    const navAction = (item, key, action) => {
        if(action === 'support') {
            return (
                <li key={key} className="nav-item">
                    <a className="d-flex align-items-center nav-link" href="#"
                        onClick={toggleSupportModal}><i
                        className="material-icons md-14" onClick={toggleSupportModal}>{item.icon}</i>
                        <div className="nav-item-name ellipsis" onClick={toggleSupportModal}>{item.name}</div>
                    </a>
                </li>)
        }

        if(action === 'backtoexecutor') {
            return (
                <li key={key} className="nav-item">
                <a className="d-flex align-items-center nav-link" href="#"><i
            className="material-icons md-14" onClick={backToAccount}>{item.icon}</i>
                <div className="nav-item-name ellipsis" onClick={backToAccount}>{item.name}</div>
                </a>
                </li>)
        }

        return null;
    };

    // nav dropdown
    const navDropdown = (item, key) => {
        return (
            <li key={key} className={activeRoute(item.url, props)}>
                <a className="d-flex align-items-center nav-link nav-dropdown-toggle" href="#"
                   onClick={handleClick}><i
                    className="material-icons md-14" onClick={handleClickGrandParent}>{item.icon}</i>
                    <div className="nav-item-name" onClick={handleClickGrandParent}>{item.name}</div>
                </a>
                <ul className="nav-dropdown-items">
                    {navList(item.children)}
                </ul>
            </li>)
    };

    // nav type
    const navType = (item, idx) =>
        item === null ? '' :
            item.title ? title(item, idx) :
                item.divider ? divider(item, idx) :
                    item.label ? navLabel(item, idx) :
                        item.children ? navDropdown(item, idx) :
                            item.action ? navAction(item, idx, item.action)
                                : navItem(item, idx);

    // nav list
    const navList = (items) => {
        return items.map((item, index) => navType(item, index));
    };

    const isExternal = (url) => {
        const link = url ? url.substring(0, 4) : '';
        return link === 'http';
    };
    // todo Sidebar nav secondLevel
    // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    const openSupportModal = () => {

    }

    return (
        <React.Fragment>
            <div className={props.mobileMenuOpen ? 'sidebar mobileMenuOpen' : 'sidebar mobileMenuClosed'}>
                <div onMouseEnter={(event) => {
                    sidebarHover()
                }} onMouseLeave={(event) => {
                    sidebarHover()
                }} className="sidebar-container">
                    <div>
                        <SidebarHeader/>
                        <SidebarForm/>
                        {navList(nav.items)}
                    </div>
                    <div>{trialExp()}</div>
                </div>
                <SidebarFooter/>
                <SidebarMinimizer/>
            </div>
            <SupportModal
                isOpen={supportModalOpen}
                toggleModal={toggleSupportModal}
            />
        </React.Fragment>
    )
}
export default Sidebar;