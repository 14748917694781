import React, {useState, useEffect} from 'react';
import {Button, Card, CardBody, CardFooter, Container, InputGroup, Label, Row, Col, Input} from "reactstrap";
import {CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import moment from "moment";
const initialCompany = {
    businessNumber: "",
    city: "",
    country: "United States",
    dateCreated: "",
    dateLastModified: "",
    id: "",
    imageUrl: "",
    mainPhone: "",
    name: "",
    state: "",
    street1: "",
    street2: "",
    street3: "",
    street4: "",
    timezone: null,
    url: "",
    zip: "",
};

function CompanyDetails() {
    const companySettings = Util.getCompanySettings();

    const [company, setCompany] = useCompanyFormInput(initialCompany);
    const [loading, setLoading] = useState(false);
    const [useInternationalDates, setUseInternationalDates] = useState(companySettings['useInternationalDates']);
    const [timezone, setTimezone] = useState("");
    const [timezones, setTimezones] = useState([]);

    useEffect(() => {
        loadData();
        loadTimezones();
    }, []);

    const setStateInput = (value) => {
        let newCompany = Object.assign({}, company);
        newCompany.state = value;
        setCompany(newCompany);
    };

    const setCountryInput = (value) => {
        let newCompany = Object.assign({}, company);
        newCompany.country = value;
        setCompany(newCompany);
    };

    const showFormErrors = () => {
        let isFormValid = true;

        document.getElementById('company-form').querySelectorAll('[required]').forEach(input => {
            input.classList.add('active');
            if (!input.value || input.value === '') {
                isFormValid = false;
            }
        });

        return !isFormValid;
    };

    const  loadTimezones = () => {
        const apiUrl = Util.apiUrl(`util/time_zone_list`);
        const params = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                setTimezones(json);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {});
    }

    const saveForm = () => {
        if (showFormErrors()) {
            console.error('form is invalid: do not submit');
            return;
        }

        if(company.street !== '') {
            const streets = company.street.split("\n");
            let index = 0;
            while (index < streets.length) {
                const fieldValue = streets[index];
                const fieldName = 'street' + ++index;
                company[fieldName] = fieldValue;
            }
        }

        company.timezone = !timezone ? "" : timezone.name;

        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(company)
        };
        saveData(params);
    };

    const saveImage = (imageUrl) => {
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({imageUrl: imageUrl})
        };
        saveData(params);
    };

    const saveData = (params) => {
        companySettings['useInternationalDates'] = useInternationalDates;
        let apiUrl = Util.apiUrl(`company/settings`);
        setLoading(true);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(companySettings)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                localStorage.setItem("companySettings", JSON.stringify(json))
            })
            .catch(error => ServerResponseHandler.handleError(error))

        apiUrl = Util.apiUrl(`company`);
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Company was saved', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
        .finally(() => setLoading(false));
    };

    const loadData = () => {
        setLoading(true);
        const apiUrl = Util.apiUrl(`company`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let company = json;
                let address = company.street1;
                if (company.street2) {
                    address += "\n" + company.street2;
                }
                if (company.street3) {
                    address += "\n" + company.street3;
                }
                if (company.street4) {
                    address += "\n" + company.street4;
                }
                company.street = address;
                setCompany(json);
                setTimezone(company.timezone);
                localStorage.setItem("companyTenant", JSON.stringify(json));
            })
            .catch(error => ServerResponseHandler.handleError(error))
        .finally(() => setLoading( false));
    };

    const selectImage = (e) => {
        getBase64(e.target.files[0], base64 => {
            setCompany({target: {value: base64, name: 'imageUrl'}});
            saveImage(base64);
        });
    };

    const getBase64 = (file, cb) => {
        if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
            toast.error('Image must be JPEG, PNG, or GIF', {position: 'bottom-right'});
            return;
        }

        if (file.size > 5000000) {
            toast.error('Image size too big. It must be 5MB or smaller', {position: 'bottom-right'});
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            cb(reader.result)
        };
        reader.onerror = error => {
            console.error('Error: ', error);
        };
    };

    function handleDatesChange() {
        setUseInternationalDates(!useInternationalDates);
    }

    return (
        <div className="animated fadeIn">
            <Row className="align-items-center">
                <Col className="col-sm-12 col-md-6 text-center align-middle mb-4">
                    <div>
                        <div className="align-middle">
                            {
                                company.imageUrl === null || company.imageUrl === ''
                                    ? <img className="default-image-icon md-card-image"
                                           src="/img/image_placeholder.png" width={300}/>
                                    : <img className="default-image-icon md-card-image img-thumbnail"
                                           src={company.imageUrl} width={300}/>
                            }
                            <br/>
                            <Button color="primary" onClick={() => document.getElementById('image').click()} style={{marginTop: '10px'}}>Image Upload</Button>
                            <input id='image' type="file" className="btn btn-sm" onChange={selectImage} hidden/>
                        </div>
                    </div>
                </Col>
                <Col>
                    <Container fluid>
                        <Card className="card-accent-primary p-4">
                            <CardBody>
                                <form onSubmit={saveForm} id="company-form" noValidate>
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <Label>Name</Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="Name" name="name" value={company.name} onChange={setCompany}
                                                       className="form-control" required/>
                                            </InputGroup>

                                            <Label>Phone</Label>
                                            <InputGroup className="mb-3">
                                                <input type="tel" placeholder="(123)456-7890" name="mainPhone"
                                                       minLength={10} maxLength={24}
                                                       value={company.mainPhone} onChange={setCompany} className="form-control" required/>
                                            </InputGroup>

                                            <Label>Website</Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="www.company.com" name="url"
                                                       value={company.url} onChange={setCompany} className="form-control"/>
                                            </InputGroup>
                                            <Label>Street</Label>
                                            <InputGroup className="mb-3">
                                            <textarea placeholder="Street" name="street" className="form-control"
                                                      value={company.street}
                                                      onChange={setCompany} required>
                                            </textarea>
                                            </InputGroup>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <Label>City</Label>
                                                        <InputGroup className="mb-3">
                                                            <input type="text" placeholder="City" name="city" value={company.city} onChange={setCompany}
                                                                className="form-control" required/>
                                                        </InputGroup>
                                                    </div>

                                                <div className="col-md-6">
                                                    <Label>State</Label>
                                                    <InputGroup className="mb-3">
                                                        <RegionDropdown classes="form-control" id="state" name="state"
                                                                        valueType="short" required
                                                                        country={company.country} value={company.state} onChange={setStateInput}/>
                                                    </InputGroup>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <Label>Country</Label>
                                                    <InputGroup className="mb-3">
                                                        <CountryDropdown classes="form-control" id="country" name="country"
                                                                         required value={company.country} onChange={setCountryInput}/>
                                                    </InputGroup>
                                                </div>

                                                <div className="col-md-6">
                                                    <Label>Postal Code</Label>
                                                    <InputGroup className="mb-3">
                                                        <input type="text" placeholder="Postal Code" name="zip" value={company.zip} onChange={setCompany}
                                                               className="form-control" required/>
                                                    </InputGroup>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                <Label>Timezone</Label>
                                                <InputGroup className="mb-3">
                                                    <select name="timezone"
                                                        onChange={(e) => {
                                                            const tz = timezones.find(x => x.name === e.target.value);
                                                            setTimezone(tz);
                                                        }}
                                                        value={timezone ? timezone.name : "none"}
                                                        className="form-control pull-right"
                                                        style={{width: 150 + 'px'}}
                                                    >
                                                        {timezones.map(timezone => (
                                                            <option key={timezone.name} value={timezone.name}>{timezone.displayName}</option>
                                                        ))}
                                                    </select>
                                                </InputGroup>
                                            </div>
                                            </div>

                                            <div className="mb-3">
                                                <Label style={{paddingLeft: '10px'}}>Display dates in DD/MM/YYYY format</Label>
                                                <Label className="switch switch-icon switch-pill switch-info pull-left"
                                                       title="internationalDates">
                                                    <Input type="checkbox" name="internationalDates"
                                                           className="switch-input"
                                                           onChange={handleDatesChange}
                                                           checked={useInternationalDates} />
                                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                    <span className="switch-handle"/>
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                            <CardFooter>
                                <div className="pull-right">
                                    <Button color="success" className="header-button" disabled={loading} onClick={() => saveForm()}>
                                        {loading ? <i className="fa fa-spinner fa-spin mr-2"></i> : ''}SAVE</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </div>
    )
}

function useCompanyFormInput(initialValue) {
    const [company, setCompany] = useState(initialValue);

    function handleChange(e) {
        let newCompany = Object.assign({}, company);
        if(!e.target) {
            newCompany = e;
        } else if (e.target.name === 'mainPhone') {
            newCompany.mainPhone = e.target.value.replace(/^(\d{3})(\d{3})(\d)+$/, "($1)$2-$3");
        } else {
            newCompany[e.target.name] = e.target.value;
        }
        setCompany(newCompany);
    }

    return [
        company,
        handleChange
    ]
}

export default CompanyDetails;