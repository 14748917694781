import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Container, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import * as ServerResponseHandler from "../../components/Framework/ServerResponseHandler";
import * as Util from "../../components/Framework/Util";
import ReactTable from "react-table";

class LinkWidget extends Component {
    constructor(props) {
        super(props);
        this.userTenant = Util.getUserTenant();
        this.companySettings = Util.getCompanySettings();
        this.state = {

        };
    }

    render() {
        return (
            <Col lg="3" md="6" sm="12" xs="12">
                <Card className="card-accent-primary">
                    <CardBody>
                            <Link to={this.props.link} style={this.props.linkStyle}>
                                <CardTitle
                                    className="text-center mb-0">{this.props.title}</CardTitle>
                                <div className="text-center font-3xl">{this.props.content}</div>
                            </Link>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    loadTimelogs = () => {
        this.setState({loading: true});

        fetch(Util.apiUrl(`reports/pay-periods`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(payperiods => {
                this.setState({currentPayPeriod: payperiods.current});
                const timelogSearch = Util.isAdmin()
                    ? {
                        startDate: moment(payperiods.periodList[1].startDate).startOf('day'),
                        endDate: moment(payperiods.current.endDate).endOf('day')
                    }
                    : {
                        startDate: moment(payperiods.periodList[1].startDate).startOf('day'),
                        endDate: moment(payperiods.current.endDate).endOf('day'),
                        departmentId: this.userTenant.departmentId
                    };

                const timelogsUrl = Util.apiSearchUrl(`timelog`, timelogSearch);

                fetch(timelogsUrl, {credentials: 'include'})
                    .then(response => ServerResponseHandler.getResponse(response))
                    .then(json => {
                        this.setState({timelogs: json.resultList});

                        let breaksPromise = Promise.resolve(null);
                        if (this.companySettings.paidBreaks) {
                            let apiUrl = Util.apiSearchUrl(`timelog/get-breaks`, timelogSearch);
                            breaksPromise = fetch(apiUrl, {credentials: 'include'})
                                .then(response => ServerResponseHandler.getResponse(response))
                                .then(json => {
                                    let breakTimes = [];
                                    json.resultList.forEach(breaklog => {
                                        let index = breakTimes.findIndex(breakObj => breakObj.timelogId === breaklog.timeLogId);
                                        if (index === -1) {
                                            breakTimes.push({
                                                timelogId: breaklog.timeLogId,
                                                duration: breaklog.duration
                                            });
                                        } else {
                                            breakTimes[index].duration += breaklog.duration;
                                        }
                                    });
                                    this.setState({breakTimes: breakTimes});
                                })
                                .catch(error => ServerResponseHandler.handleError(error));
                        }

                        breaksPromise.then(() => this.loadHoursSummary(payperiods.current));
                    }).catch(error => ServerResponseHandler.handleError(error));
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    };

    loadData = () => {
        const ptoSearch = Util.isAdmin()
            ? {ptoStatus: 'All'}
            : {
                ptoStatus: 'All',
                departmentId: this.userTenant.departmentId
            };

        const employeesCountUrl = Util.apiSearchUrl(`users`, null);
        const projectsUrl = Util.apiSearchUrl(`projects`, {projectStatus: 'Open'});
        const ptoRequestUrl = Util.apiSearchUrl(`pto`, ptoSearch);

        let promises = [];
        this.setState({loading: true});
        promises.push(
            fetch(employeesCountUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => this.setState({employees: json.resultList}))
                .catch(error => ServerResponseHandler.handleError(error))
        );

        promises.push(
            fetch(projectsUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => this.setState({openProjects: json.maxResults}))
                .catch(error => ServerResponseHandler.handleError(error))
        );

        promises.push(
            fetch(ptoRequestUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    let count = 0;
                    json.resultList.forEach(pto => {
                        if (pto.status === 'Pending') {
                            count++;
                        }
                    });
                    this.setState({ptoRequests: json.resultList, pendingPtoRequests: count});
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );

        Promise.all(promises).then(() => this.setState({loading: false}));

        this.loadTimelogs();
    };
}

export default LinkWidget;
