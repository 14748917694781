import React, {Component} from "react";
import PropTypes from 'prop-types';
import * as Util from "../Framework/Util";
import AsyncSelect from 'react-select/lib/Async';
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import _ from "lodash";

export default class SelectOvertimeRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overtimeRuleId: '',
            overtimeRule: this.props.overtimeRule
        }
    }

    handleChange = newOvertimeRule => {
        // If the user backspaces all the way, newOvertimeRule becomes an empty array which errors out in the props.onChange method
        if (newOvertimeRule && newOvertimeRule.constructor === Array) {
            newOvertimeRule = null;
        }

        if (newOvertimeRule) {
            this.setState({
                overtimeRuleId: newOvertimeRule.id,
                overtimeRule: newOvertimeRule
            });
        } else {
            this.setState({
                overtimeRuleId: '',
                overtimeRule: null
            });
        }

        this.props.onChange(newOvertimeRule);
    };

    loadOvertimeRules = input => {
        const apiUrl = Util.apiSearchUrl(`company/settings/overtimeRules`, {data: input});
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                return _.filter(json.resultList, function(o) { return (o.deletedOn === null)})
            })
            .catch(error => ServerResponseHandler.handleError(error));
    };

    render() {
        return (
            <div className="fb-select">
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable
                    value={this.state.overtimeRule}
                    noOptionsMessage={() => 'No Overtime Rules found'}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    loadOptions={this.loadOvertimeRules}
                    placeholder={'Select an Overtime Rule...'}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

SelectOvertimeRule.propTypes = {
    onChange: PropTypes.func.isRequired,
    overtimeRule: PropTypes.object
};