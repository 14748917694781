import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Container, InputGroup, Row} from 'reactstrap';
import * as Util from "../../../components/Framework/Util";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            username: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="mx-4">
                                <CardBody className="p-4 text-center">
                                    <form onSubmit={this.handleSubmit} id="login-form" noValidate>
                                        <img style={{width: '100%'}} src="https://s3.amazonaws.com/fishbowllabor/StaticContent/fbtimelogo.png" />
                                        <p>&nbsp;</p>

                                        <div className="errorDiv" id="usernameError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">person_outline</span>
                                            </span>
                                            </div>
                                            <input type="text" placeholder="Username" name="username" ref="username"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <Button id="btn" ref="btn" disabled={this.state.loading} color="success" block>
                                            {this.state.loading
                                                ?
                                                <span><i className="fa fa-spinner fa-spin mr-2"></i>Processing...</span>
                                                : <span>Reset Password</span>}
                                        </Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    handleChange(e) {
        e.target.classList.add('active');
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.username) {
            toast.error("Username is required", {position: 'bottom-right'});
            return;
        }

        const self = this;
        self.setState({loading: true});
        let payload = {
            username: this.state.username,
        };

        const apiUrl = Util.apiUrl(`register/reset_password`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json != null) {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                window.location.href = "/reset_password/success";
            } else {
                toast.error("Unable to reset password at this time.", {position: 'bottom-right'});
            }
        }).catch(error => ServerResponseHandler.handleError(error)
        ).finally(() => self.setState({loading: false})
        );
    }
}

export default Register;
