import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";


class PositionsTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                self.props.data.length < 1 ?
                    <NoData/> :
                self.props.data.map((row, index) => {
                    return <div
                        className={self.props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                        key={index}>
                        <Row className='row-data row-header col-lg-12'
                             onClick={(event) => self.props.toggleEditModal(event, row)}>
                            <Col><span>{row.name}</span></Col>
                            <Col><span>{row.laborItem ? row.laborItem.name : 'N/A'}</span></Col>
                            {
                                !self.props.tableSettings.canDelete
                                    ?
                                    ''
                                    :
                                    <Col className="cursor-text">
                                                     <span title="Delete"
                                                           onClick={() => self.props.toggleConfirmDeleteModal(row)}
                                                           className="delete action-icon material-icons">clear</span>
                                    </Col>
                            }
                        </Row>
                    </div>
                })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default PositionsTable;
