import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from "../../../components/Framework/PrivateRoute";
import { ToastContainer, toast } from 'react-toastify';

import ManageTimeOffRequests from "./ManageTimeOffRequests";
import ManageTimeCards from "./ManageTimeCards";

class Manage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            isAdmin: localStorage.getItem("isAdmin"),
            isManager: localStorage.getItem("isManager"),
        };
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <Switch>
                    <PrivateRoute exact path="/manage/pto" name="Time Off Requests" component={ManageTimeOffRequests}/>
                    <PrivateRoute path="/manage" name="Time Cards" component={ManageTimeCards}/>
                </Switch>
            </React.Fragment>
        )
    }
}

export default Manage;