import React, { useReducer, createContext } from 'react';
import * as Util from './components/Framework/Util'

function reducer(state, action) {
    switch(action.type) {
        case 'UPDATE_COMPANY_SETTINGS':
            return {...state, companySettings: action.payload};
        default:
            throw Error('reducer error');
    }
}

const CompanyContext = createContext();

export {CompanyContext}

export default function Store(props) {
    const stateHook = useReducer(reducer, Util.getCompanySettings());
    return (
        <CompanyContext.Provider value={stateHook}>
            {props.children}
        </CompanyContext.Provider>
    )
}

