import React, {Component} from 'react';

class SidebarMinimizer extends Component {

    sidebarMinimize() {
        let sidebarMinimized = document.body.classList.toggle('sidebar-minimized');
        document.body.classList.toggle('collapsed');
        let open = document.getElementsByClassName('open');

        if(sidebarMinimized && open.length > 0) {
            open[0].classList.remove('open');
        }
    }

    brandMinimize() {
        document.body.classList.toggle('brand-minimized');
    }

    render() {
        return (
            <button className="sidebar-minimizer" type="button" onClick={(event) => {
                this.sidebarMinimize();
                this.brandMinimize()
            }}></button>
        )
    }
}

export default SidebarMinimizer;
