import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {AdminRoute} from "../../../components/Framework/PrivateRoute";
import * as Util from "../../../components/Framework/Util";

import Qbo from "./Qbo";
import Qbd from './Qbd';
import { ToastContainer, toast } from 'react-toastify';

class Integrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: Util.isAdmin(),
            isManager: Util.isManager(),
        };
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Switch>
                    {
                        this.state.isAdmin ?
                            (<AdminRoute exact path="/integrations/qbd" name="QuickBooks Desktop" component={Qbd}/>)
                            : ''
                    }
                    {
                        this.state.isAdmin ?
                            (<AdminRoute path="/integrations/qbo" name="QuickBooks Online" component={Qbo}/>)
                            : ''
                    }
                </Switch>
            </div>
        )
    }
}

export default Integrations;