import React, {Component} from "react";
import PropTypes from 'prop-types';
import * as Util from '../Framework/Util';
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import AsyncSelect from 'react-select/lib/Async';

export default class SelectEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeId: '',
            employee: this.props.employee,
        }
    }

    handleChange = newEmployee => {
        // If the user backspaces all the way, newEmployee becomes an empty array which errors out in the props.onChange method
        if (newEmployee && newEmployee.constructor === Array) {
            newEmployee = null;
        }

        if (newEmployee) {
            this.setState({
                employeeId: newEmployee.id,
                employee: newEmployee
            });
        } else {
            this.setState({
                employeeId: '',
                employee: null
            });
        }

        this.props.onChange(newEmployee);
    };

    loadEmployees = input => {
        const search = {active: true};
        search.data = input;

        const apiUrl = Util.apiSearchUrl(`users`, search);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                for (const emp of json.resultList) {
                    emp.details = `${Util.nameFormatter(emp.nameFirst, emp.nameLast)}`;
                }
                return json.resultList;
            })
            .catch(error => ServerResponseHandler.handleError(error));
    };

    render() {
        return (
            <div className="fb-select">
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable
                    value={this.state.employee}
                    noOptionsMessage={() => 'No Employees found'}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.details}
                    loadOptions={this.loadEmployees}
                    placeholder={'Select an Employee...'}
                    onChange={this.handleChange}
                />
            </div>
        )
    }

}

SelectEmployee.propTypes = {
    onChange: PropTypes.func.isRequired,
    employee: PropTypes.object
};