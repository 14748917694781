import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Container, InputGroup, Row} from 'reactstrap';
import * as qs from 'query-string';
import ReactDOM from "react-dom";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";

let to = require('to-case');

class PasswordResetFinalize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            password: '',
            username: '',
            confirm: '',
            pathParams: qs.parse(location.search),
            errorMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const pathParams = JSON.parse(JSON.stringify(this.state.pathParams));
        if (!pathParams.username || !pathParams.verificationCode) {
            this.setState({valid: false, errorMessage: 'The registration code does not exist or has expired. Please try registering again.'});
        } else {
            this.setState({valid: true});
        }
    }

    render() {
        if (this.state.valid) {
            return (
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6">
                                <Card className="mx-4">
                                    <CardBody className="p-4 text-center">
                                        <form onSubmit={this.handleSubmit} id="login-form" noValidate>
                                            <img style={{width: '100%'}} src="https://s3.amazonaws.com/fishbowllabor/StaticContent/fbtimelogo.png" /><br/><br/>

                                            <div className="errorDiv" id="passwordError"/>
                                            <InputGroup className="mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <span className="material-icons">lock</span>
                                                    </span>
                                                </div>
                                                <input type="password" placeholder="Password" name="password" ref="password"
                                                       className="form-control" onChange={this.handleChange}
                                                       autoComplete="new-password"
                                                       required
                                                       pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,}"
                                                       title="Must contain at least one number and one uppercase and lowercase letter, and at least 9 or more characters" />
                                            </InputGroup>

                                            <div className="errorDiv" id="passwordConfirmError"/>
                                            <InputGroup className="mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <span className="material-icons">lock_open</span>
                                                    </span>
                                                </div>
                                                <input type="password" placeholder="Confirm Password" name="passwordConfirm" ref="passwordConfirm"
                                                       className="form-control" onChange={this.handleChange} autoComplete="new-password" required/>
                                            </InputGroup>

                                            <Button id="btn" ref="btn" color="success" block>Save Password</Button>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        } else {
            return (
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6">
                                <Card className="mx-4">
                                    <CardBody className="p-4">
                                        <h1>Password Reset Failed.</h1>
                                        <p className="text-muted">{this.state.errorMessage}</p>
                                        <Button color="danger" block onClick={this.loginButton}>Login</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }

    loginButton() {
        window.location.href='/login';
    }

    handleChange(e) {
        e.target.classList.add('active');
        this.setState({[e.target.name]: e.target.value});
        this.showInputError(e.target.name);
    }

    showInputError(refName) {
        const validity = this.refs[refName].validity;
        const name = this.refs[refName].name;
        const error = document.getElementById(refName + 'Error');
        const isPasswordConfirm = refName === 'passwordConfirm';

        if (validity === undefined || validity === null) {
            return true;
        }

        if (isPasswordConfirm) {
            if (this.refs.password.value !== this.refs.passwordConfirm.value) {
                this.refs.passwordConfirm.setCustomValidity('Passwords do not match');
            } else {
                this.refs.passwordConfirm.setCustomValidity('');
            }
        }

        if (!validity.valid) {
            if (validity.valueMissing) {
                error.textContent = to.sentence(name) + ' is a required field';
            } else if (validity.typeMismatch) {
                error.textContent = to.sentence(name) + ' should be a valid email address';
            } else if (validity.patternMismatch) {
                error.textContent = this.refs[refName].title;
            } else if (validity.customError) {
                error.textContent = 'Passwords do not match';
            }
            return false;
        }

        error.textContent = '';
        return true;
    }

    showFormErrors() {
        const inputs = document.querySelectorAll('input');
        let isFormValid = true;

        inputs.forEach(input => {
            input.classList.add('active');

            let isInputValid = true;
            if (input.name) {
                isInputValid = this.showInputError(input.name);
            }

            if (!isInputValid) {
                isFormValid = false;
            }
        });

        return isFormValid;
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.showFormErrors()) {
            return;
        }

        this.disableButton();

        const self = this;
        const pathParams = JSON.parse(JSON.stringify(this.state.pathParams));

        let payload = {
            username: pathParams.username,
            verificationCode: pathParams.verificationCode,
            password: this.state.password,
        };

        const apiUrl = Util.apiUrl(`register/reset_password_finalize`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json != null) {
                if (!json.hasOwnProperty('errorMessage')) {
                    self.login();
                } else {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                }
            }
        }).catch(error => ServerResponseHandler.handleError(error)
        );
    }

    disableButton() {
        const submitButton = ReactDOM.findDOMNode(this.refs.btn);
        submitButton.setAttribute('disabled', true);
        submitButton.classList.remove('btn-success');
        submitButton.classList.add('btn-primary');
        document.getElementById("btn").innerHTML = 'Processing...';
    }

    login() {
        const pathParams = JSON.parse(JSON.stringify(this.state.pathParams));

        let payload = {
            username: pathParams.username,
            password: this.state.password
        };
        let querystring = require('querystring');

        const apiUrl = Util.apiUrl(`resources/login`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: querystring.stringify(payload),
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json != null) {
                if (!json.errorMessage) {
                    Util.storeSession(json);
                    window.location.href = "/dashboard";
                } else {
                    window.location.href = "/login";
                }
            } else {
                window.location.href = "/login";
            }
        }).catch(error => {
            ServerResponseHandler.handleError(error);
            window.location.href = "/login";
        });
    }
}

export default PasswordResetFinalize;
