import React from 'react';

function TimeLogIssue(props) {
    const generateMessage = () => {
        return props.issue.issueTypeMessage;
    }

    return (
        <span>{generateMessage()}</span>
    );
}

export default  TimeLogIssue;