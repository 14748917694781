import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Row,
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import HoursOfOperationTable from "../../../components/DataTables/HoursOfOperationTable";
import PtoSettings from "./PayComponents/PtoSettings";
import PayPeriodSettings from "./PayComponents/PayPeriodSettings";
import OvertimeSettings from "./PayComponents/OvertimeSettings";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const companySettings = Util.getCompanySettings();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
const canDelete = isAdminOrManager;
let savedPinLength;

class Pay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pinLength: '',
            timeFormat: '',
            nameFormat: '',
            allowGeoLocation: false,
            payPeriodType: '',
            payPeriodStarts: '',
            roundToNearest: 5,
            flagShiftsExceeding: 0,
            flagShiftsExceedingInHours: 0,
            allowAddTime: false,
            allowEditTime: false,
            allowFutureTime: false,
            paidBreaks: false,
            breaksInProjects: false,
            paidLunchBreaks: false,
            lunchBreaksInProjects: false,
            autoBreakDeductMinutes: 0,
            autoBreakHours: 0,
            enableProjects: false,
            requireProjectClockIn: false,
            archiveProjectAfter: '15',
            hoursOfOperationList: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadCompanyHoursOfOperation = this.loadCompanyHoursOfOperation.bind(this);
        this.saveHoursOfOperation = this.saveHoursOfOperation.bind(this);

        this.loadData();
        this.loadCompanyHoursOfOperation(true);

    }

    componentDidUpdate = (prevProps, prevState, prevContext) => {
        const editableContent = document.getElementsByClassName('contentEditable');
        Array.prototype.filter.call(editableContent, element => {
            element.addEventListener('keypress', evt => {
                if (evt.which === 13) {
                    element.blur();
                }
            })
        });
    };

    handleChange(e, saveData) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value}, () => {if(saveData) {this.saveData();}});
            Util.showInputError(this, e.target.name);
        }
    }

    handleCheckBoxChange(e, saveData) {
        switch (e.target.name) {
            case 'allowBreaks':
                if (!e.target.checked) {
                    this.setState({paidBreaks: false, breaksInProjects: false});
                }
                break;
            case 'paidBreaks':
                if (!e.target.checked) {
                    this.setState({breaksInProjects: false});
                }
                break;
            case 'paidLunchBreaks':
                if (!e.target.checked) {
                    this.setState({lunchBreaksInProjects: false});
                }
                break;
            case 'allowLunchBreaks':
                if (!e.target.checked) {
                    this.setState({paidLunchBreaks: false, lunchBreaksInProjects: false});
                }
                break;
        }
        this.setState({[e.target.name]: e.target.checked}, () => {if(saveData) {this.saveData();}});
    }

    saveData() {
        if (!this.showFormErrors()) {
            console.error('form is invalid: do not submit');
            return;
        }
        if(this.state.pinLength !== savedPinLength) {
            toast.error('All employees will need to reset their PINs', {position: 'bottom-right', timeout: 7000});
        }

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/settings`);

        let data = this.state;
        data.flagShiftsExceeding = data.flagShiftsExceedingInHours * 3600;

        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(this.state),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                localStorage.setItem("companySettings", JSON.stringify(json));
                savedPinLength = this.state.pinLength;
                toast.success('Settings were saved', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    loadData() {
        const apiUrl = Util.apiUrl(`company/settings`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                json.flagShiftsExceedingInHours = (json.flagShiftsExceeding / 3600).toFixed(2);
                this.setState(json);
                savedPinLength = json.pinLength;
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false});
            });
    }


    /*================ Hours Of Operation Functions ================*/
    loadCompanyHoursOfOperation(initial) {
        if(!initial) {
            this.setState({
                hoursOfOperationList: [],
                loading: true
            });
        }

        const apiUrl = Util.apiUrl(`company/settings/hoursOfOperation`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => {
                return ServerResponseHandler.getResponse(response);
            })
            .then(json => {
                let receivedDays = json.resultList;
                let expectedDays = [
                    "Sunday", 
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                ];
                let hoo = expectedDays.map((day,i) => {
                    let match = receivedDays ? receivedDays.filter(d=>d.dayOfWeek === day) : null;
                    if(!match || match.length <= 0){
                        return {
                            dayOfWeek: day,
                            active: false,
                            startTime: "",
                            endTime: "",
                            changed: false,
                        };
                    }
                    match.changed = false;
                    if(!match[0].active) {
                        match[0].startTime = "";
                        match[0].endTime = "";
                    }

                    return match[0];
                });

                this.setState({hoursOfOperationList: hoo});
            })
            .catch(error => {ServerResponseHandler.handleError(error)})
            .finally(() => {
                this.setState({loading: false})
            });
    }

    saveHoursOfOperation(dayToSave, method){
        if(dayToSave){
            this.setState({loading: true});
            const apiUrl = Util.apiUrl(`company/settings/hoursOfOperation/`);
            const params = {
                method: method,
                credentials: 'include',
                headers: {'Content-Type': 'application/json;charset=UTF-8'},
                body: JSON.stringify(dayToSave),
            };
            fetch(apiUrl, params)
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    toast.success('Hours of operation for ' + dayToSave.dayOfWeek + " saved", {position: 'bottom-right'});
                    this.loadCompanyHoursOfOperation();
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => this.setState({loading: false}));
        }
        else{
            toast.error("Unable to save hours of operation update", {position: 'bottom-right'});
        }
    }

    render() {
        const settings = this.state;
        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <Row id="company-form">
                        <Col md="12" lg="4">
                            <PayPeriodSettings/>
                            <OvertimeSettings/>
                            <PtoSettings/>
                        </Col>
                        <Col md="12" lg="4"> {/*================ Hours Of Operation Section ================*/}
                            <Card className="card-accent-primary">
                                <CardHeader>
                                    <Row className="justify-content-between align-items-center no-gutters">
                                        <Col><div>Hours Of Operation</div></Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <HoursOfOperationTable
                                        data={settings.hoursOfOperationList}
                                        saveDataParent={this.saveHoursOfOperation}
                                        tableSettings={{
                                            canEdit: canEdit
                                        }}
                                    />
                                </CardBody>
                            </Card>
                                    </Col>
                        <Col md="12" lg="4"> {/*================ Time Clock Settings Section ================*/}
                            <Card className="card-accent-primary">
                                <CardHeader>
                                    Time Clock Settings
                                </CardHeader>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label>Pin Length</Label>
                                        <select name="pinLength" ref="pinLength"
                                                onChange={(e) => {this.handleChange(e, true)}}
                                                value={settings.pinLength}
                                                className="form-control pull-right"
                                                style={{width: 60 + 'px'}}
                                        >
                                            <option value="4">4</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Display GeoLocation</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Attempt to get employees location on clock in/out and break in/out">
                                            <Input type="checkbox" name="allowGeoLocation" ref="allowGeoLocation"
                                                   className="switch-input"
                                                   checked={settings.allowGeoLocation}
                                                   onChange={(e) => {this.handleCheckBoxChange(e, true)}}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Time Format</Label>
                                        <select name="timeFormat" ref="timeFormat"
                                                onChange={(e) => {this.handleChange(e, true)}}
                                                value={settings.timeFormat}
                                                className="form-control pull-right"
                                                style={{width: 160 + 'px'}}
                                        >
                                            <option value="0.00 hrs">0.00 hrs</option>
                                            <option value="HH:MM">HH:MM</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Name Format</Label>
                                        <select name="nameFormat" ref="nameFormat"
                                                onChange={(e) => {this.handleChange(e, true)}}
                                                value={settings.nameFormat}
                                                className="form-control pull-right"
                                                style={{width: 160 + 'px'}}
                                        >
                                            <option value="Firstname Lastname">First Last</option>
                                            <option value="Lastname Firstname">Last First</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Round to Nearest</Label>
                                        <select name="roundToNearest" ref="roundToNearest"
                                                onChange={(e) => {this.handleChange(e, true)}}
                                                value={settings.roundToNearest}
                                                className="form-control pull-right"
                                                style={{width: 125 + 'px'}}
                                        >
                                            <option value={0}>Don&#39;t Round</option>
                                            <option value={1}>1 min</option>
                                            <option value={5}>5 mins</option>
                                            <option value={10}>10 mins</option>
                                            <option value={15}>15 mins</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Flag Shifts Exceeding (hrs)</Label>
                                        <Input title="Flag any shifts exceeding X hours"
                                               name="flagShiftsExceedingInHours" ref="flagShiftsExceeding"
                                               onChange={(e) => {this.handleChange(e, false)}}
                                               onBlur={this.saveData}
                                               type="number"
                                               min="0"
                                               step="0.01"
                                               value={settings.flagShiftsExceedingInHours}
                                               className="pull-right"
                                               style={{width: 80 + "px"}}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label>Add Time</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow employees to manually add time">
                                            <Input type="checkbox" name="allowAddTime" ref="allowAddTime"
                                                   className="switch-input"
                                                   checked={settings.allowAddTime}
                                                   onChange={(e) => {this.handleCheckBoxChange(e, true)}}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Edit Time</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow employees to manually edit time">
                                            <Input type="checkbox" name="allowEditTime" ref="allowEditTime"
                                                   className="switch-input"
                                                   checked={settings.allowEditTime}
                                                   onChange={(e) => {this.handleCheckBoxChange(e, true)}}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Future Time</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow employees to enter time for the future">
                                            <Input type="checkbox" name="allowFutureTime" ref="allowFutureTime"
                                                   className="switch-input"
                                                   checked={settings.allowFutureTime && (settings.allowAddTime || settings.allowEditTime)}
                                                   onChange={(e) => {this.handleCheckBoxChange(e, true)}}
                                                   disabled={!(settings.allowAddTime || settings.allowEditTime)}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    showFormErrors = () => {
        let isFormValid = true;
        for (const input of document.getElementById('company-form').querySelectorAll('input')) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        }

        return isFormValid;
    };
}

export default Pay;