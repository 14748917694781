import React, {Component} from 'react';
import {Button, Row, Col, Container, Card, CardBody} from "reactstrap";
import QRCode from 'qrcode.react';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";

class EmployeeQrReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const self = this;
        const apiUrl = Util.apiUrl(`users/simple_list`);
        fetch(apiUrl, {
            credentials: 'include',
        }).then(response => {
            return ServerResponseHandler.getResponse(response);
        }).then(function (json) {
            let data = JSON.parse(JSON.stringify(json));
            self.setState({data});
        }).catch(error => {
            return ServerResponseHandler.handleError(error);
        });

        fetch(Util.apiUrl(`reports/pay-periods`), {
            credentials: 'include',
        }).then(response => {
            return ServerResponseHandler.getResponse(response);
        }).catch(error => {
            return ServerResponseHandler.handleError(error);
        });
    }

    render() {
        let userList = this.state.data;

        return (
            <div className="animated fadeIn">
                <Container fluid className={'dont-print'}>
                    <Card className={'mt-2'}>
                        <CardBody>
                            <Row lg="12" sm="12" md="6" id="codeList">
                                {userList.map((userData, index) => (
                                    <Col className="col-2" key={index}>
                                        <div className="qr-badge">
                                            <QRCode value={userData.userId.toString()}/>
                                            <br/>{userData.name}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <div className="float-right margin-top">
                                <Button  color='success' className="success btn"
                                        onClick={() => window.print()}>Print</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
                <div className={'print'}>
                    {userList.map((userData, index) => (
                        <div className="print-child" key={index}>
                            <div className="qr-badge">
                                <QRCode value={userData.userId.toString()}/>
                                <br/>{userData.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default EmployeeQrReport;