import React, {useState} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody, Modal, ModalFooter
} from "reactstrap";
import SelectGlobalCompany from "../../components/Selects/SelectGlobalCompany";

function AddCompanyModal(props) {
    const [selectedCompany, setSelectedCompany] = useState(null);

    function handleCompanySelectChange(company) {
        setSelectedCompany(company);
    }

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Managed Company</ModalHeader>
            <ModalBody>
                <div className='mb-3'>
                    <Label>Company</Label>
                        <SelectGlobalCompany company={selectedCompany} onChange={handleCompanySelectChange}/>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => props.save(selectedCompany)}>Save</Button>{' '}
                <Button color="secondary" onClick={() => props.toggleModal()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddCompanyModal;