import React, {useState} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody, Modal
} from "reactstrap";

import SelectProject from "../Selects/SelectProject";
import SelectPosition from "../Selects/SelectPosition";
import * as Util from "../Framework/Util"
const companySettings = Util.getCompanySettings();

function ClockInModal(props) {

    const [position, setPosition] = useState(null);
    const [project, setProject] = useState(null);

    function handlePositionChange(position) {
        if (!position) {
            setPosition({name: ''});
        } else {
            setPosition(position);
        }
    }

    function handleProjectChange(project) {
        if (!project) {
            setProject({name: ''});
        } else {
            setProject(project);
        }
    }

    function submit(event) {
        let shiftLog = {
            project: project,
            position: position
        };
        props.submit(event, shiftLog);
        setProject(null);
        setPosition(null);
    }

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.toggle}>{props.employee.name}</ModalHeader>
            <ModalBody id="modal-form">
                {
                    !props.enableProjects ? '' :
                    <div className='mb-3'>
                        <Label>{companySettings.projectSingleName}</Label>
                        <div>
                            <SelectProject project={project} onChange={handleProjectChange}/>
                        </div>
                    </div>
                }
                {
                    !props.enablePositions ? '' :
                    <div className='mb-3'>
                        <Label>Position</Label>
                        <div>
                            <SelectPosition project={position} onChange={handlePositionChange}/>
                        </div>
                    </div>
                }
                <Button className='mt-3' style={{width: '100%'}} color="success" onClick={(event) => submit(event)}>Clock In</Button>{' '}
            </ModalBody>
        </Modal>
    )
}

export default ClockInModal;