import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Util from '../Framework/Util';
import AsyncSelect from 'react-select/lib/Async';
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";

export default class SelectDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departmentId: '',
            department: this.props.department,
        }
    }

    handleChange = newDepartment => {
        // If the user backspaces all the way, newDepartment becomes an empty array which errors out in the props.onChange method
        if (newDepartment && newDepartment.constructor === Array) {
            newDepartment = null;
        }

        if (newDepartment) {
            this.setState({
                departmentId: newDepartment.id,
                department: newDepartment
            });
        } else {
            this.setState({
                departmentId: '',
                department: null
            });
        }

        this.props.onChange(newDepartment);
    };

    loadDepartments = input => {
        const apiUrl = Util.apiSearchUrl(`company/departments/search`, {data: input});
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => json.resultList)
            .catch(error => ServerResponseHandler.handleError(error));
    };

    render() {
        return (
            <div className="fb-select">
                <AsyncSelect
                       cacheOptions
                       defaultOptions
                       isClearable
                       value={this.state.department}
                       noOptionsMessage={() => 'No Departments found'}
                       getOptionValue={(option) => option.id}
                       getOptionLabel={(option) => option.name}
                       loadOptions={this.loadDepartments}
                       placeholder={'Select a Department...'}
                       onChange={this.handleChange}
                />
            </div>
        )
    }
}

SelectDepartment.propTypes = {
    onChange: PropTypes.func.isRequired,
    department: PropTypes.object
};