import React, {Component} from 'react';
import {Row, Col, Label, Input} from 'reactstrap';
import NoData from "./NoData";

class EmployeePositionsTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index} lg={column.columnSize}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                !self.props.data || self.props.data.length < 1 ?
                    <NoData/> :
                self.props.data.map((row, index) => {
                    return <div className='data-row margin-top' key={index}>
                        <Row className='row-data row-header col-lg-12 align-items-center'>
                                <Label className="switch switch-icon switch-pill switch-info pull-left"
                                    style={{ marginTop: '14px' }}
                                    title="positionChecked">
                                    <Input type="checkbox" name="positionChecked"
                                    className="switch-input"
                                    id={row.positionId}
                                    onChange={(event) => self.props.toggleCheckedRow(event, index, row)}
                                    checked={self.props.selected.hasOwnProperty(row.positionId)} />
                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                    <span className="switch-handle"/>
                                </Label>
                            <Col><span>{row.name}</span></Col>
                            <Col>
                                    <input type="text" value={row.hourlyRate} autoComplete="off" className="form-control" onChange={(event) => self.props.positionRate(event, index, row)}/>
                            </Col>
                        </Row>
                    </div>
                })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default EmployeePositionsTable;
