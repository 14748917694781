import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Util from "./Util";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {DateRange} from 'react-date-range';
import SearchSection from "./SearchSection";
import SelectProject from "../Selects/SelectProject";
import SelectPayPeriods from "../Selects/SelectPayPeriods";
import SelectPayPeriodRule from "../Selects/SelectPayPeriodRule";
import * as ServerResponseHandler from "./ServerResponseHandler";
import SelectDepartment from "../Selects/SelectDepartment";
import SelectEmployee from "../Selects/SelectEmployee";
import SelectProjectStatus from "../Selects/SelectProjectStatus";
import SelectPtoStatus from "../Selects/SelectPtoStatus";
import {CSVLink} from "react-csv";
import Select from "react-select";

const moment = require('moment-timezone');
const companySettings = Util.getCompanySettings();

export class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.userTenant = Util.getUserTenant();
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            isPtoFilter: this.props.isPtoFilter,
            positionId: '',
            projectId: '',
            departmentId: '',
            employeeCode: '',
            data: '',
            active: this.props.activeFlag,
            ptoStatus: this.props.ptoStatus,
            projectStatus: this.props.projectStatus,
            payPeriodRule: null,
            payPeriod: null,
            payPeriodRules: [],
            department: null,
            employee: null,
            position: null,
            project: null,
            sortingOption: null,
            filterByEmployee: false,
            showOpen: this.props.showOpen,
            isShowOpen: false,
            isShowAllPendingOpen: false,
            tableColumnToggleData: this.props.tableColumns,
            tableColumnToggleDisplay: null,
            downloadDropdownOpen: false
        };
        this.previousState = this.state;
        this.clickHidden = this.clickHidden.bind(this);
        this.fetchAndClick = this.fetchAndClick.bind(this);
        this.loadPayPeriodRules = this.loadPayPeriodRules.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.handleFocus = this.handleFocus.bind(this);

        this.hiddenCsvRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.loadSearchDefault) {
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                projectStatus: this.props.projectStatus,
                active: this.props.activeFlag,
            });
        }

        if (this.props.getSearchBarApi) {
            this.props.getSearchBarApi({
                clearSearchParams: this.clearSearchParams, getParams: () => {
                    let toRet = {...this.state};
                    delete toRet.tableColumnToggleData;
                    delete toRet.tableColumnToggleDisplay;
                    delete toRet.allowToggleTableColumns;
                    return toRet;
                }
            });
        }

        if (this.props.includePayPeriods) {
            this.loadPayPeriodRules(this.loadPayPeriod);
        }
        if (this.props.includePositions) {
            this.parsePositionData();
        }

        if(this.props.allowToggleTableColumns) this.getToggledColumns()
    }

    loadPayPeriodRules(loadPayPeriod) {
        const apiUrl = Util.apiSearchUrl(`pay_period_rule`);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let selectedRule = null;
                if (json.resultList && json.resultList.length > 0) {
                    selectedRule = {
                        "id": "",
                        "name": "All",
                    };
                    json.resultList.push(selectedRule);
                };

                this.setState({
                    payPeriodRules: json.resultList,
                    payPeriodRule: selectedRule
                });

                this.previousState.payPeriodRules = json.resultList;
                this.previousState.payPeriodRule = selectedRule;

                loadPayPeriod(selectedRule ? selectedRule.id : null);
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    parsePositionData = () => {
        const userDetails = Util.getUserTenant();
        if (userDetails.userTenantPositionList) {
            let positions = [];
            userDetails.userTenantPositionList.forEach(pos => positions.push({
                id: pos.positionId,
                name: pos.position.name
            }));
            this.setState({positions: positions});
        }
    };

    handleCheckBoxChange = (e) => {
        this.setState({[e.target.name]: e.target.checked});
    };

    handleDateChange = (range) => {
        const search = {startDate: range.startDate.startOf('day'), endDate: range.endDate.startOf('day')};
        this.setState(search);
    };

    ptoInitDates = () => {
        const search = {startDate: moment(), endDate: moment()};
        this.setState(search);
    }

    handleDepartmentChange = (department) => {
        if (!department) {
            this.setState({departmentId: '', department: null});
        } else {
            this.setState({departmentId: department.id, department: department});
        }
    };

    handleStatusChange = (status) => {
        if (!status) {
            this.setState({status: ''});
        } else {
            this.setState({status: status.name});
        }
    };

    handlePayPeriodRuleChange = (payPeriodRule) => {
        if (!payPeriodRule) {
            this.setState({payPeriodRule: null});
        } else {
            this.setState({
                payPeriodRule: payPeriodRule,
                searchParams: payPeriodRule
            });
        }
    };

    handlePayPeriodChange = (payPeriod) => {
        if (!payPeriod) {
            this.setState({payPeriod: null});
        } else {
            this.setState({
                payPeriod: payPeriod,
                startDate: moment(payPeriod.startDate),
                endDate: moment(payPeriod.endDate),
                searchParams: payPeriod
            });
        }
    };

    handleEmployeeChange = (employee) => {
        if (!employee) {
            this.setState({employeeCode: '', employee: null});
        } else {
            this.setState({employeeCode: employee.id, employee: employee});
        }
    };

    handlePositionChange = (position) => {
        if (!position) {
            this.setState({positionId: '', position: null});
        } else {
            this.setState({positionId: position.id, position: position});
        }
    };

    handleProjectChange = (project) => {
        if (!project) {
            this.setState({projectId: '', project: null});
        } else {
            this.setState({projectId: project.id, project: project});
        }
    };

    handleProjectStatusChange = (projectStatus) => {
        this.setState({projectStatus: projectStatus});
    };

    handlePtoStatusChange = (ptoStatus) => {
        this.setState({ptoStatus: ptoStatus});
    };

    handleTextChange = (e) => {
        this.setState({data: e.target.value});
    };

    handleActiveChange = (e) => {
        this.setState({active: e.target.checked});
    };

    toggleSearch = () => {
        this.setState({showSearch: !this.state.showSearch});
    };

    revertState = () => {
        let showSearch = this.state.showSearch;
        this.setState(this.previousState);
        this.setState({showSearch: showSearch});
    };

    setDefaultPerams = (params) => {
        const keys = Object.keys(params);
        keys.forEach((key) => {
            let stateObj = {};
            stateObj[key] = params[key];
            this.setState(stateObj);
        });
    };

    search = () => {

        this.previousState = this.state;
        const search = {...this.state};
        this.toggleSearch();

        const searchParams = {
            startDate: Util.formatDateToUtc(search.startDate.startOf('day')),
            endDate: Util.formatDateToUtc(search.endDate.endOf('day')),
            departmentId: search.departmentId,
            employeeCode: search.employeeCode,
            positionId: search.positionId,
            projectId: search.projectId,
            data: search.data,
            active: search.active,
            ptoStatus: search.ptoStatus,
            projectStatus: search.projectStatus,
            filterByEmployee: search.filterByEmployee,
            payPeriodRule: search.payPeriodRule,
        };

        this.props.setSearchParams(searchParams);
        this.props.onSearch(searchParams);

        if(this.props.tableColumns){
            this.props.updateColumnArray(this.state.tableColumnToggleData);
        }
    };

    fetchAndClick() {
        this.props.buttons.download.fetchData(this.clickHidden);
    }

    clickHidden(ref) {
        document.getElementById("hidden-csv-btn").click();
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            this.search();
        }
    }

    handleFocus(e) {
        e.target.select();
    }

    clearSearchParams = () => {
        this.setState({startDate: moment().startOf('week'), endDate: moment().endOf('week'),});
    };

    isPayPeriodEnded = (payPeriod) => {
        return moment(payPeriod.endDate).isBefore(moment());
    }

    isPayPeriodApproved(payPeriodRule, payPeriod) {
        return payPeriodRule.lastApproved && moment(payPeriodRule.lastApproved).isAfter(moment(payPeriod.endDate));
    }

    toggleShowAll(isShowOpen){
        if (isShowOpen){
            this.props.onSearch({showOpen: true});
        } else {
            this.props.onSearch();
        }
        this.setState({isShowOpen: isShowOpen});
    }

    toggleShowAllPending(isShowAllPending){
        if (isShowAllPending){
            this.props.onSearch({showAllPending: true});
        } else {
            this.props.onSearch();
        }
        this.setState({isShowAllPending: isShowAllPending});
    }

    updateTableColumns(row){
        let tableColumns = this.state.tableColumnToggleData;
        tableColumns.forEach(column => {
            if (column.columnId === row.target.id) {
                column.hideColumn = !row.target.checked;
            }
        });
        this.setState(
            {'tableColumnToggleData': tableColumns},
            () => this.getToggledColumns());
    }

    getToggledColumns(){
        if (this.props.allowToggleTableColumns){
            let tableColumnToggleDisplay = this.state.tableColumnToggleData.map((row) =>
                <div className='data-row margin-top' key={row.columnId}>
                    <Row className='row-data row-header col-lg-12'>
                        <span className={'col-lg-2'}>{row.displayName}</span>
                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                               title="columnChecked">
                            <Input type="checkbox" name="columnChecked"
                                   className="switch-input"
                                   id={row.columnId}
                                   onChange={(row) => this.updateTableColumns(row)}
                                   checked={!row.hideColumn}/>
                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                            <span className="switch-handle"/>
                        </Label>
                    </Row>
                </div>
            );
            let header = [<Label className="search-label" key={"header"}>Show/Hide Table Columns</Label>];
            let toRet = header.concat(tableColumnToggleDisplay);
            this.setState({"tableColumnToggleDisplay": toRet});
        }
    }

    render() {
        const search = this.state;

        let searchGeneral = '';
        let searchDateRange = '';
        let searchEmployee = '';
        let searchDepartment = '';
        let searchProject = '';
        let searchProjectStatus = '';
        let searchPosition = '';
        let searchPtoStatus = '';
        let searchActive = '';
        let searchPayPeriods = '';
        let searchLabel = '';
        let filterByEmployee = '';
        const statusOptions = [{id: 1, name: 'All'}, {id: 2, name: 'Active'}, {id: 3, name: 'Inactive'}];

        if (this.props.includeGeneral) {
            searchGeneral = (
                <SearchSection title="Search">
                    <input name="data" ref="data" value={search.data} autoFocus onFocus={this.handleFocus} className="form-control"
                           onChange={this.handleTextChange} onKeyPress={this.onKeyUp}/>
                </SearchSection>
            );
        }

        if (this.props.includePayPeriods) {
            searchPayPeriods = (
                <React.Fragment>
                    {this.state.payPeriodRules && this.state.payPeriodRules.length > 1 ?
                        <SearchSection title="Pay Period Rule">
                            <SelectPayPeriodRule
                                onChange={this.handlePayPeriodRuleChange}
                                payPeriodRule={this.state.payPeriodRule}
                            />
                        </SearchSection> : null }
                    <SearchSection title="Pay Period">
                        <SelectPayPeriods
                            onChange={this.handlePayPeriodChange}
                            payPeriod={this.state.payPeriod}
                            payPeriodRule={this.state.payPeriodRule}
                        />
                    </SearchSection>
                </React.Fragment>
            );
        }

        if (this.props.includeDateRange) {
            searchDateRange = (
                <SearchSection title="Date Range">
                    <DateRange
                        linkedCalendars={true}
                        ranges={this.props.dateRanges}
                        onInit={this.props.isPtoFilter ? this.ptoInitDates : this.handleDateChange}
                        onChange={this.handleDateChange}
                        startDate={this.props.isPtoFilter ? moment() : search.startDate}
                        endDate={this.props.isPtoFilter ? moment() : search.endDate}
                        theme={{
                            Calendar: {width: "250px"},
                            PredefinedRanges: {marginLeft: 10, marginTop: 10}
                        }}
                        className="search-date-range"
                    />
                </SearchSection>
            );
        }

        if (this.props.includeEmployee) {
            searchEmployee = (
                <SearchSection title="Employee">
                    <SelectEmployee employee={this.state.employee} onChange={this.handleEmployeeChange}/>
                </SearchSection>
            );
        }

        if (this.props.includeDepartment) {
            searchDepartment = (
                <SearchSection title="Department">
                    <SelectDepartment department={this.state.department} onChange={this.handleDepartmentChange}/>
                </SearchSection>
            );
        }

        if (this.props.includeProject) {
            searchProject = (
                <SearchSection title={companySettings.projectSingleName}>
                    <SelectProject project={this.state.project} onChange={this.handleProjectChange}/>
                </SearchSection>
            );
        }

        if (this.props.includeProjectStatus) {
            searchProjectStatus = (
                <SearchSection title={companySettings.projectSingleName + " Status"}>
                    <SelectProjectStatus projectStatus={this.state.projectStatus}
                                         onChange={this.handleProjectStatusChange}
                                         includeAll={true}/>
                </SearchSection>
            );
        }

        if (this.props.includePtoStatus) {
            searchPtoStatus = (
                <SearchSection title="Time Off Request Status">
                    <SelectPtoStatus ptoStatus={this.state.ptoStatus} onChange={this.handlePtoStatusChange}/>
                </SearchSection>
            );
        }

        if (this.props.includeActiveFlag && !this.props.includeAllStatus) {
            searchActive = (
                <SearchSection title="Active">
                    <Label className="switch switch-icon switch-pill switch-info" style={{margin: 10 + 'px'}}>
                        <Input type="checkbox" name="active" ref="active" className="switch-input"
                               checked={this.state.active} onChange={this.handleActiveChange}/>
                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                        <span className="switch-handle"/>
                    </Label>
                </SearchSection>
            );
        }

        if (this.props.includeAllStatus) {
            searchActive = (
                <SearchSection title="Status">
                    <div className="fb-select">
                        <Select
                            isClearable
                            ref="statusInput"
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            value={this.state.status} options={statusOptions}
                            onChange={this.handleStatusChange}
                            placeholder={'Select a Status...'}
                        />
                    </div>
                </SearchSection>
            );
        }

        if (this.props.includeSearchLabel) {
            let label = "Today";
            let today = Util.basicDateFormatter(moment());
            let startDate = Util.basicDateFormatter(search.startDate);
            let endDate = Util.basicDateFormatter(search.endDate);

            if (startDate !== endDate) {
                label = startDate + " - " + endDate;
            } else if (startDate !== today) {
                label = startDate;
            }
            searchLabel = (
                <h4 className="d-inline align-middle">{label}</h4>)
        }

        if (this.props.allowFilterByEmployee) {
            filterByEmployee = (
                <div className="mb-3" style={{maxWidth: '200px'}}>
                    <Label>Filter By Employee</Label>
                    <Label className={'switch switch-icon switch-pill switch-info pull-right'}
                           title="Filter results by employee.">
                        <Input type="checkbox" name="filterByEmployee" ref="filterByEmployee"
                               className={'switch-input'}
                               checked={this.state.filterByEmployee}
                               onChange={(e) => {
                                   this.handleCheckBoxChange(e)
                               }}
                        />
                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                        <span className="switch-handle"/>
                    </Label>
                </div>
            );
        }

        const isEnded = this.state.payPeriod ? this.isPayPeriodEnded(this.state.payPeriod) : null;
        const isApproved = this.state.payPeriodRule && this.state.payPeriod ? this.isPayPeriodApproved(this.state.payPeriodRule, this.state.payPeriod) : null;
        const canApprove = isEnded && !isApproved;

        return (
            <div className="searchBar no-wrap">
                {!(this.props.includeShowOpenButton && !this.state.isShowOpen) ? '' :
                    <button className="header-button btn btn-outline-secondary" title="Search"
                            onClick={ ()=>this.toggleShowAll(true) }>
                        Show Open
                    </button>
                }
                {!(this.props.includeShowOpenButton && this.state.isShowOpen) ? '' :
                    <button className="header-button btn btn-outline-secondary" title="Search"
                            onClick={()=>this.toggleShowAll(false) }>
                        Show All
                    </button>
                }
                {this.props.includeShowAllPendingButton &&
                    <button className="header-button btn btn-outline-secondary" title="Search"
                        onClick={()=>this.toggleShowAllPending(!this.state.isShowAllPendingOpen) }>
                        Show All Pending
                    </button>
                }
                <button className="header-button btn btn-outline-secondary" title="Search"
                        onClick={this.toggleSearch}>
                    Filter
                </button>
                {searchLabel}
                <div className="pull-right">
                    {
                        !this.props.buttons || !this.props.buttons.approveAll
                            ? ''
                            : <button className="header-button btn btn-outline-success"
                                      disabled={this.props.buttons.loading}
                                      onClick={(e) => this.props.buttons.approveAll(e)}>
                                Approve All
                            </button>
                    }
                    {
                        !this.props.buttons || !this.props.buttons.add
                            ? ''
                            : <button className="header-button btn btn-outline-success"
                                      disabled={this.props.buttons.loading}
                                      onClick={(e) => this.props.buttons.add(e)}>
                                <i className="material-icons">add</i>
                            </button>
                    }
                    {
                        this.props.buttons && this.props.buttons.download &&
                            <>
                            {
                                this.props.buttons.download.useFetch ?
                                    <React.Fragment>
                                    <button className="header-button btn btn-outline-primary"
                                    disabled={this.props.buttons.download.loading}
                                    onClick={this.fetchAndClick}
                                    title="Download CSV">
                                        <i className="material-icons">file_download</i>
                                    </button>
                                    <CSVLink  id="hidden-csv-btn" data={this.props.buttons.download.data ? this.props.buttons.download.data : []}
                                    uFEFF={false}
                                    headers={this.props.buttons.download.csvHeader}
                                    ref={this.hiddenCsvRef}
                                    filename={this.props.buttons.download.csvFilename} target="_blank">
                                    </CSVLink>
                                    </React.Fragment>
                                    :
                                    <>
                                        {
                                            this.props.buttons.download.showCustomize ?
                                                    <>
                                                        <ButtonDropdown
                                                            isOpen={this.state.downloadDropdownOpen} toggle={() => { this.setState({ downloadDropdownOpen: !this.state.downloadDropdownOpen }) }}>
                                                        <DropdownToggle caret>
                                                        Download CSV
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                        <DropdownItem onClick={() => { document.getElementById("hidden-csv-btn").click(); }}>Download CSV</DropdownItem>
                                                        <DropdownItem onClick={this.props.buttons.download.customizeCallback}>Customize Fields</DropdownItem>
                                                        </DropdownMenu>
                                                        </ButtonDropdown>
                                                        <CSVLink data={this.props.buttons.download.data}
                                                            style={{ position: 'absolute', visibility: 'hidden' }}
                                                            uFEFF={false}
                                                            headers={this.props.buttons.download.csvHeader}
                                                            id="hidden-csv-btn"
                                                            filename={this.props.buttons.download.csvFilename} target="_blank">
                                                            <button className="header-button btn btn-outline-primary"
                                                            disabled={this.props.buttons.download.loading}
                                                            title="Download CSV">Download CSV
                                                        <i className="material-icons">file_download</i>
                                                            </button>
                                                            </CSVLink>
                                                    </>
                                                :
                                                    <CSVLink data={this.props.buttons.download.data}
                                                        uFEFF={false}
                                                        headers={this.props.buttons.download.csvHeader}
                                                        id="hidden-csv-btn"
                                                        filename={this.props.buttons.download.csvFilename} target="_blank">
                                                        <button className="header-button btn btn-outline-primary"
                                                        disabled={this.props.buttons.download.loading}
                                                        title="Download CSV">
                                                    <i className="material-icons">file_download</i>
                                                        </button>
                                                        </CSVLink>
                                        }
                                    </>
                            }
                            </>
                    }
                    {
                        !this.props.buttons.importCsv
                            ? ''
                            : <div className='pull-right'>
                                <button className="header-button btn btn-outline-primary"
                                        disabled={this.props.buttons.download.loading}
                                        title="Import CSV"
                                        onClick={() => this.props.buttons.importCsv()}>
                                    <i className="material-icons">file_upload</i>
                                </button>
                            </div>
                    }
                    {
                        this.props.buttons.approvePayroll && canApprove &&
                        <div className='pull-right'>
                            <Button color='success' className='header-button'
                                    onClick={() => this.props.buttons.approvePayroll.onClick()}>Approve Payroll</Button>
                        </div>
                    }
                    {
                        !this.props.buttons.generateReport
                            ? ''
                            : <div className='pull-right'>
                                <Button color='success' className='header-button'
                                        dataToggle={!isApproved || !isEnded ? "tooltip" : null}
                                        dataPlacement="left"
                                        title={!isApproved || !isEnded ? !isEnded ? 'Pay period is not finished yet.' : 'Pay period is not approved yet.' : ''}
                                        onClick={() => this.props.buttons.toggleModal()}>Generate Report</Button>
                            </div>
                    }
                    {
                        !this.props.buttons.savePdf
                            ? ''
                            : <div className='pull-right'>
                                <Button color='success' className='header-button'
                                        onClick={() => this.props.buttons.savePdf()}>Save as PDF</Button>
                            </div>
                    }
                </div>
                {
                    this.props.buttons.approvePayroll && !isApproved &&
                    <span style={{ fontSize: '10px', width: '300px', display: 'block', float: 'right', marginRight: '12px' }}><span style={{ fontWeight: 'bold' }}>Note: </span>This Pay Period has not yet been approved and may contain conflicting information. Additionally, its PTO accrual has not been calculated.</span>
                }
                <Modal isOpen={this.state.showSearch} className="modal-lg">
                    <ModalHeader toggle={() => {
                        this.revertState();
                        this.toggleSearch();
                    }}>Search</ModalHeader>
                    <ModalBody id="modal-form">
                        {searchDateRange}
                        {filterByEmployee}
                        {searchPayPeriods}
                        {searchGeneral}
                        {searchEmployee}
                        {searchDepartment}
                        {searchProject}
                        {searchProjectStatus}
                        {searchPosition}
                        {searchPtoStatus}
                        {searchActive}
                        {this.state.tableColumnToggleDisplay}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.search()}>Search</Button>{' '}
                        <Button color="secondary" onClick={() => {
                            this.revertState();
                            this.toggleSearch();
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    loadPayPeriod = (payPeriodRuleId) => {
        Util.getPayPeriods(this.props.loadSearchDefault ? this.state : null, payPeriodRuleId)
            .then(search => {
                const startDate = search.startDate;
                const endDate = search.endDate;

                this.previousState.startDate = startDate;
                this.previousState.endDate = endDate;

                this.setState({startDate: startDate, endDate: endDate});
            })
            .catch(error => ServerResponseHandler.handleError(error));
    };

    loadTimeCards = (search) => {
        fetch(Util.apiSearchUrl(`reports/time-cards`, search), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => this.setState({timeCardList: json}))
            .then(() => this.getPdfData())
            .then(() => toPdfTimeCards(this.state.pdfData, this.getSelectedPayPeriodDays(), 'Time Card Report', 'timeCardReport', 'p'))
            .catch(error => ServerResponseHandler.handleError(error));
    };
}

SearchBar
    .propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    activeFlag: PropTypes.bool,
    ptoStatus: PropTypes.string,
    projectStatus: PropTypes.string,
    dateRanges: PropTypes.object,
    includeDateRange: PropTypes.bool,
    includePosition: PropTypes.bool,
    includeProject: PropTypes.bool,
    includeDepartment: PropTypes.bool,
    includeEmployee: PropTypes.bool,
    includeGeneral: PropTypes.bool,
    includeActiveFlag: PropTypes.bool,
    includePtoStatus: PropTypes.bool,
    includeProjectStatus: PropTypes.bool,
    includePayPeriods: PropTypes.bool,
    includeSearchLabel: PropTypes.bool,
    loadSearchDefault: PropTypes.bool,
    setSearchParams: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    allowToggleTableColumns: PropTypes.bool,
    tableColumns: PropTypes.array,
    updateColumnArray: PropTypes.func,

};

SearchBar
    .defaultProps = {
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
    activeFlag: true,
    ptoStatus: 'All',
    projectStatus: 'All',
    dateRanges: Util.defaultDateRanges,
    setSearchParams: () => {
    },

    includeDateRange: false,
    includePosition: false,
    includeProject: false,
    includeDepartment: false,
    includeEmployee: false,
    includeGeneral: false,
    includeActiveFlag: false,
    includePtoStatus: false,
    includeProjectStatus: false,
    includePayPeriods: false,
    includeSearchLabel: false,
    loadSearchDefault: false,
    allowToggleTableColumns: false,
}