import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import * as Util from "../Framework/Util";

class NextInvoiceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.createTable = this.createTable.bind(this);
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index} lg={column.width}>{column.displayName}</Col>
                    })
                }
            </Row>

            {
                !self.props.data ? '' :
                self.props.data.map((date, index) => {
                    return <div key={index}>
                        {
                            index === 0 ? <div className="margin-top"></div> : <div className="dropdown-divider"></div>
                        }
                        {
                            <div className="container-fluid">
                                <Row className='row-date col-11'>
                                    <Col className="">{date.name}</Col>
                                </Row>
                            </div>
                        }
                        <div className="data">
                            {
                                date.invoiceEvents.map((line, index) => {
                                    return <div
                                        className="data-row" key={index}>
                                            {
                                                <Row className={"row-header col-lg-12"} >
                                                    <Col style={{textAlign: 'left'}} lg="10"><span style={{color: 'gray'}}>{line.description}</span></Col>
                                                    <Col style={{textAlign: 'right'}} lg="2"><span>{Util.formatCurrency(line.amount/100, true)}</span></Col>
                                                </Row>
                                            }
                                        </div>
                                })
                            }
                        </div>
                    </div>
                })
            }
            {
                !self.props.nextInvoice ? '' :
                    <Row className={"row-date row-header col-lg-12 float-right"}>
                        <Col lg="8"></Col>
                        <Col lg="4" style={{textAlign: 'right'}}><span className="center-vertical">{'TOTAL: ' + Util.formatCurrency(self.props.nextInvoice.total / 100, true)}</span></Col>
                    </Row>
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default NextInvoiceTable;
