import React from 'react';
import {Container, Row, Col} from 'reactstrap';


const PayrollReportTotals = (props) => {
    return <Container fluid>
        <Row className={'mt-3 justify-content-end'}>
            <Col className={'col-4 border mr-4 mb-3'}>
                <h5 className='text-center'>Totals</h5>
                <Row>
                    <Col className="col-5">{'PTO Hours'}</Col>
                    <Col className="col-3 pr-0 pl-0">{props.totals.totalPtoHours}</Col>
                    <Col className="col-3">{props.totals.totalPtoCurrency}</Col>
                </Row>
                <Row>
                    <Col className="col-5">{'Regular Hours'}</Col>
                    <Col className="col-3 pr-0 pl-0">{props.totals.totalRegularHours}</Col>
                    <Col className="col-3">{props.totals.totalRegularCurrency}</Col>
                </Row>
                <Row>
                    <Col className="col-5">{'Overtime Hours'}</Col>
                    <Col className="col-3 pr-0 pl-0">{props.totals.totalOvertimeHours}</Col>
                    <Col className="col-3">{props.totals.totalOvertimeCurrency}</Col>
                </Row>
                <Row>
                    <Col className="col-5">{'Total Hours'}</Col>
                    <Col className="col-3 pr-0 pl-0">{props.totals.totalHours}</Col>
                    <Col className="col-3">{props.totals.totalCurrency}</Col>
                </Row>
            </Col>
        </Row>
    </Container>
};
export default PayrollReportTotals
