import React, {createRef} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody, Modal, ModalFooter, InputGroup
} from "reactstrap";

function NoteModal(props) {
    const noteRef = createRef();
    function submit() {
        noteRef.current.classList.add('active');
        props.saveNote()
    }

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Shift Note</ModalHeader>
            <ModalBody>
                <Label>Shift Note</Label>
                <InputGroup className="mb-3">
                    <input type="text" placeholder="Shift Note" name="note" ref={noteRef}
                           value={props.note}
                           required={props.required}
                           className="form-control" onChange={props.handleNoteChange} />
                </InputGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={submit}>Save</Button>{' '}
                <Button color="secondary" onClick={() => props.toggleModal()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default NoteModal;