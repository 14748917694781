import React from 'react';
import {Row, Col} from 'reactstrap';


function EmployeeTimeCardsTotals(props) {
    return (<div className={'mt-3 p-3'}>
            <div className={'mb-2'}><h4 className={'text-center'}>Employee Totals</h4></div>
            <Row className={'row-header col-header col-lg-12'}>
                {
                    props.tableHeaders.map((column, index) => {
                        return <Col key={index}>{column}</Col>
                    })
                }
            </Row>

            <Row className={'row-header col-lg-12'}>
                <Col>{props.totals.totalRegular}</Col>
                <Col>{props.totals.totalOvertime}</Col>
                <Col>{props.totals.totalPtoHours}</Col>
                <Col>{props.totals.totalHours}</Col>
            </Row>
        </div>
    )
}

export default EmployeeTimeCardsTotals;

