import React, {Component} from 'react';
import config from "react-global-configuration";
import { ToastContainer, toast } from 'react-toastify';

class Logout extends Component {

    render() {
        logout();

        return (
            <div>
                <ToastContainer />
            </div>
        );

        function logout() {
            localStorage.clear();

            fetch(config.get('apiBaseUrl') + 'resources/logout', {
                method: 'GET',
                credentials: 'include',
            }).then(response => {
                console.debug(response);
                window.location.href = "/login";
            }).catch(error => {
                toast.error(error, {position: 'bottom-right'});
                console.log(error);
            });
        }
    }
}

export default Logout;