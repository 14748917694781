import React from 'react';
import DateFnsUtils from '@date-io/date-fns'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as Util from "../Framework/Util";

const muiTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: 'white',
            }
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: 'grey'
            },
            toolbarBtnSelected: {
                color: '#ff7900'
            }
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: 'white'
            }
        },
        MuiTab: {
            textColorInherit: {
                backgroundColor: 'white',
                color: '#ff7900'
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#ff7900',
                "&:hover": {
                    backgroundColor: '#ff7900'
                },
            },
        },
        PrivateTabIndicator: {
            colorSecondary: {
                backgroundColor: '#ff7900'
            }
        },
        MuiTypography: {
            h3: {
                fontSize: 2.2 + "rem",
                lineHeight: 1.2
            },
            h4: {
                fontSize: 2.2 + "rem",
                lineHeight: 1.2
            },
            subtitle1: {
                fontSize: 0.8 + "rem"
            },
            colorPrimary: {
                color: '#ff7900'
            }
        },
    }
});

function FBDatePicker(props) {
    return (
        <ThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    id={props.id}
                    className="form-control"
                    title={props.title}
                    value={props.value}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    margin="dense"
                    format={Util.getCompanySettings().useInternationalDates ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                    onChange={props.onChange}
                    style={props.style}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            padding: '0.375rem 0.75rem',
                            borderRadius: '4px',
                            border: '1px solid #CCCCCC',
                            fontSize: 0.875 + "rem",
                            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}
                    }}
                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}

export default FBDatePicker;