import React, {Component} from "react";
import {
    Button, Card,
    CardBody,
    CardHeader,
    Col, Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as ServerResponseHandler from "../../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../../components/Framework/Util";
import moment from "moment";
import PayPeriodRulesTable from "../../../../components/DataTables/PayPeriodRulesTable";
import FBDatePicker from "../../../../components/DateTimePickers/FBDatePicker";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const companySettings = Util.getCompanySettings();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
const canDelete = isAdminOrManager;

class PayPeriodSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalPayPeriodSettingsOpened: false,
            payPeriodRuleList: [],
            selectedPayPeriod: {},
            originalSelectedPayPeriodDefault: false,
            originalSelectedPayPeriod: null,
            confirmDeletePayPeriodDialog: false,
            warningPayrollModalOpen: false,
            semiMonthlyWarningModal: false
        }

        this.getTableColumnsPayPeriod = this.getTableColumnsPayPeriod.bind(this);
        this.toggleModalPayPeriodSettings = this.toggleModalPayPeriodSettings.bind(this);
        this.loadPayPeriodData = this.loadPayPeriodData.bind(this);
        this.handlePayPeriodSubmit = this.handlePayPeriodSubmit.bind(this);
        this.closeModalPayPeriodSettings = this.closeModalPayPeriodSettings.bind(this);
        this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this);
        this.toggleConfirmDeletePayPeriodModal = this.toggleConfirmDeletePayPeriodModal.bind(this);
        this.deletePayPeriod = this.deletePayPeriod.bind(this);
        this.getDefaultPayPeriod = this.getDefaultPayPeriod.bind(this);

        this.loadPayPeriodData(true);
    }

    getTableColumnsPayPeriod() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Default', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}
        ];
    }

    handlePayPeriodChange(e, name) {
        if (e.target) {
            e.target.classList.add('active');
            let updatedPayPeriod = {...this.state.selectedPayPeriod};
            updatedPayPeriod[name] = event.target.type === 'checkbox' ? e.target.checked : e.target.value;
            this.setState({ selectedPayPeriod: updatedPayPeriod });
        }
    }

    handlePayPeriodStartsChange(date) {
        let updatedPayPeriod = {...this.state.selectedPayPeriod};
        updatedPayPeriod['payPeriodStarts'] = moment(date);
        this.setState({ selectedPayPeriod: updatedPayPeriod });
    }

    getDefaultPayPeriod(pp) {
        const def = this.state.payPeriodRuleList.find(x => x.default);
        return def ? def : pp;
    }

    toggleConfirmDeletePayPeriodModal(row) {
        this.setState({
            selectedPayPeriod: !row ? {} : row,
            confirmDeletePayPeriodDialog: !this.state.confirmDeletePayPeriodDialog,
        });
    }

    toggleWarningPayrollModal = () => {
        this.setState({ warningPayrollModalOpen: !this.state.warningPayrollModalOpen });
    }

    loadPayPeriodData(initial = false) {
        if(initial) this.state.loading = true;
        else {
            this.setState({
                payPeriodRuleList: [],
                loading: true
            });
        }

        const apiUrl = Util.apiUrl(`pay_period_rule`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (initial) this.state.payPeriodRuleList = json.resultList;
                else this.setState({payPeriodRuleList: json.resultList});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false})
            });
    }

    toggleModalPayPeriodSettings(event, row) {
        if (event != null && event.target.classList.contains('delete')) {
            return false;
        }

        if (row) {
            this.setState({
                selectedPayPeriod : {
                    id: row.id,
                    name: row.name,
                    payPeriodStarts: row.payPeriodStarts,
                    payPeriodType: row.payPeriodType,
                    weekStartDay: row.weekStartDay,
                    default: row.default,
                    lastApproved: row.lastApproved
                },
                originalSelectedPayPeriodDefault: row.default,
                originalSelectedPayPeriod: row,
                modalPayPeriodSettingsOpened: true
            });
        } else {
            const defaultPayPeriod = this.getDefaultPayPeriod(null);

            this.setState({
                selectedPayPeriod : {
                    id: null,
                    name: '',
                    payPeriodStarts: moment(),
                    lastApproved: null,
                    payPeriodType: 'Weekly',
                    weekStartDay: defaultPayPeriod ? defaultPayPeriod.weekStartDay : 'Sunday',
                    default: this.state.payPeriodRuleList.length === 0
                },
                originalSelectedPayPeriodDefault: false,
                modalPayPeriodSettingsOpened: true
            });
        }
    }

    closeModalPayPeriodSettings() {
        this.setState({ modalPayPeriodSettingsOpened: false });
    }

    saveWithSemiMonthlyConfirmation = (event) => {
        event.preventDefault();

        if(this.state.selectedPayPeriod.payPeriodType === 'Semi-Monthly' && moment(this.state.selectedPayPeriod.payPeriodStarts).format('D') != 1) {
            this.setState({ semiMonthlyWarningModal: true });
        } else {
            this.saveWithConfirmation();
        }
    }

    saveWithConfirmation = () => {
        if(!this.state.selectedPayPeriod.id) {
            this.handlePayPeriodSubmit();
        }

        const original = this.state.originalSelectedPayPeriod;
        const current = this.state.selectedPayPeriod;
        const willAffectPayroll =
            original.lastApproved &&
            (
                original.payPeriodType !== current.payPeriodType ||
                original.payPeriodStarts !== current.payPeriodStarts ||
                original.weekStartDay !== current.weekStartDay
            );

        if(willAffectPayroll) {
            this.toggleWarningPayrollModal();
        } else {
            this.handlePayPeriodSubmit();
        }
    }

    handlePayPeriodSubmit() {
        const apiUrl = this.state.selectedPayPeriod.id ? Util.apiUrl(`pay_period_rule/${this.state.selectedPayPeriod.id}`) : Util.apiUrl(`pay_period_rule`);
        const method = this.state.selectedPayPeriod.id ? 'PATCH' : 'POST';

        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(this.state.selectedPayPeriod),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
            if (json.errorMessage) {
                toast.error(json.errorMessage, {position: 'bottom-right'});
                return;
            }

            toast.success('Pay period rule was saved', {position: 'bottom-right'});
            this.loadPayPeriodData();
            this.closeModalPayPeriodSettings();
        })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    deletePayPeriod() {
        const apiUrl = Util.apiUrl(`pay_period_rule/${this.state.selectedPayPeriod.id}`);
        const method = 'PATCH';

        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify({ deletedOn: moment() }),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Pay period rule was deleted', {position: 'bottom-right'});
                this.loadPayPeriodData();
                this.toggleConfirmDeletePayPeriodModal(null);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    render() {
        const settings = this.state;
        return (
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between align-items-center no-gutters">
                        <Col><div>Pay Period Settings</div></Col>
                        <Col>
                            <button className="header-button btn btn-outline-success pull-right"
                                    onClick={() => this.toggleModalPayPeriodSettings()}><i
                                className="material-icons">add</i></button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <PayPeriodRulesTable
                        data={settings.payPeriodRuleList}
                        tableColumns={this.getTableColumnsPayPeriod()}
                        toggleConfirmDeleteModal={this.toggleConfirmDeletePayPeriodModal}
                        toggleEditModal={this.toggleModalPayPeriodSettings}
                        tableSettings={{
                            canDelete: true,
                            canEdit: true
                        }}
                    />
                </CardBody>
                <Modal isOpen={this.state.confirmDeletePayPeriodDialog}>
                    <ModalHeader>Delete Pay Period</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete pay period rule?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.deletePayPeriod()}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => this.toggleConfirmDeletePayPeriodModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modalPayPeriodSettingsOpened}>{/*================ New Overtime Rule Modal ================*/}
                    <form onSubmit={this.saveWithSemiMonthlyConfirmation} id="modal-form" noValidate>
                        <ModalHeader>Pay Period Rule</ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Label>Name<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <input type="text" placeholder="Name" name="payPeriodSetting" ref="payPeriodSetting"
                                           value={this.state.selectedPayPeriod.name}
                                           className="form-control" onChange={(e) => { this.handlePayPeriodChange(e, 'name') }} required/>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Label>Pay Period<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <select name="payPeriodType" ref="payPeriodType"
                                            onChange={(e) => {this.handlePayPeriodChange(e, 'payPeriodType')}}
                                            value={this.state.selectedPayPeriod.payPeriodType}
                                            className="form-control pull-right"
                                            style={{width: 160 + 'px'}}
                                    >
                                        <option value="Weekly">Weekly</option>
                                        <option value="Bi-Weekly">Bi-Weekly</option>
                                        <option value="Semi-Monthly">Semi-Monthly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Label>Pay Period Starts<span className="error">*</span></Label>
                                <FBDatePicker
                                    id="payPeriodStart"
                                    className="pull-right"
                                    value={moment(this.state.selectedPayPeriod.payPeriodStarts)}
                                    onChange={(e) => {this.handlePayPeriodStartsChange(e)}}
                                />
                            </div>
                            <div className="mb-3">
                                <Label>Week Start Day<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <select name="weekStartDay" ref="weekStartDay"
                                            disabled={!this.state.selectedPayPeriod.default}
                                            title="Week start day is disabled if default is off."
                                            onChange={(e) => {this.handlePayPeriodChange(e, 'weekStartDay')}}
                                            value={this.state.selectedPayPeriod.weekStartDay}
                                            className="form-control pull-right"
                                            style={!this.state.selectedPayPeriod.default ? {cursor: 'default', opacity: '0.5', width: 160 + "px"} : {width: 160 + "px"}}
                                    >
                                        <option value="Sunday">Sunday</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                    </select>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Label>Default</Label>
                                <Label className="switch switch-icon switch-pill switch-info pull-right"
                                       style={this.state.originalSelectedPayPeriodDefault || this.state.payPeriodRuleList.length === 0 ? {cursor: 'default', opacity: '0.5'} : {}}
                                       title={this.state.originalSelectedPayPeriodDefault || this.state.payPeriodRuleList.length === 0 ? 'The default pay period rule cannot be toggled off. To choose a new default, toggle it on for another rule.' :
                                           'The default pay period rule will auto populate when new employees are created.'}>
                                    <Input type="checkbox" name="payPeriodIsDefault" ref="payPeriodIsDefault"
                                           className="switch-input"
                                           disabled={this.state.originalSelectedPayPeriodDefault || this.state.payPeriodRuleList.length === 0}
                                           checked={this.state.selectedPayPeriod.default} onChange={(e) => { this.handlePayPeriodChange(e, 'default') }}/>
                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                    <span className="switch-handle"/>
                                </Label>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success">Save</Button>{' '}
                            <Button color="secondary" onClick={() => this.closeModalPayPeriodSettings()}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <ConfirmModal
                    isOpen={this.state.warningPayrollModalOpen}
                    title="Update pay period settings"
                    message="This pay period rule contains approved pay periods. Changing the configuration may lead to unwished payroll results. Are you sure you want to update pay period settings?"
                    confirmText="Yes"
                    confirm={this.handlePayPeriodSubmit}
                    cancel={this.toggleWarningPayrollModal}
                />
                <ConfirmModal
                    isOpen={this.state.semiMonthlyWarningModal}
                    title="Update pay period settings"
                    message='Semi-Monthly Pay Periods are typically from the 1st-15th and 16th-last day of a month, if this is your intention the "Pay Period Starts" date should be set to the first day of the current month. Setting it to a different day will cause Fishbowl Time to start the second of each "Semi Monthly" period 15 days after the first day selected.'
                    confirmText="Yes"
                    confirm={() => { this.saveWithConfirmation(); this.setState({ semiMonthlyWarningModal: false }) }}
                    cancel={() => { this.setState({ semiMonthlyWarningModal: false }) }}
                 />
            </Card>
        )
    }
}

export default PayPeriodSettings;