import React from 'react';
import {
    Button,
    ModalHeader,
    ModalBody, ModalFooter, Modal
} from "reactstrap";

function ConfirmModal(props) {
    return (
        <Modal toggle={props.cancel} isOpen={props.isOpen}>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>
                <p>{props.message}</p>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => { props.confirm(); props.cancel(); }}>{props.confirmText}</Button>{' '}
                <Button color="secondary" onClick={() => props.cancel()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmModal;