import React, {Component} from "react";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: localStorage.getItem("isAdmin"),
        };
    }

    validatePageUpdate(pageNum){
        if(pageNum <= this.props.availablePages && pageNum >= 1) this.props.updatePage(pageNum);
    }

    render() {
        return (
            <div className="col-4">
                <button className="btn" onClick={() => this.validatePageUpdate(1)}>
                    <i id="pagination-first-page" className="material-icons">first_page</i></button>
                <button className="btn" onClick={() => this.validatePageUpdate(this.props.currentPage - 1)}>
                    <i id="pagination-previous-page" className="material-icons">chevron_left</i></button>
                <span id="page-indicator">{this.props.currentPage} of {this.props.availablePages}</span>
                <button className="btn" onClick={() => this.validatePageUpdate(this.props.currentPage + 1)}>
                    <i id="pagination-next-page" className="material-icons">chevron_right</i></button>
                <button className="btn" onClick={() => this.validatePageUpdate(this.props.availablePages)}>
                    <i id="pagination-last-page" className="material-icons">last_page</i></button>
            </div>
        );
    }
}

export default Pagination