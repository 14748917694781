import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Util from "../Framework/Util";
import Select from "react-select";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";

export default class SelectPayPeriods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payPeriod: this.props.payPeriod,
            dataList: [],
        };
    }

    componentDidMount() {
        this.loadListData();
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.payPeriodRule && !this.props.payPeriodRule) || (!prevProps.payPeriodRule && this.props.payPeriodRule)
        || (prevProps.payPeriodRule && this.props.payPeriodRule && prevProps.payPeriodRule.id != this.props.payPeriodRule.id)) {
            this.loadListData();
            this.handleChange(null);
        }
    }

    handleChange = (payPeriod) => {
        if (payPeriod && payPeriod.constructor === Array) {
            payPeriod = null;
        }

        this.setState({payPeriod: payPeriod});
        this.props.onChange(payPeriod);
    };

    loadListData = () => {
        if(!this.props.payPeriodRule)
            return;

        const apiUrl = Util.apiUrl(`reports/pay-periods/${this.props.payPeriodRule.id}`);
        fetch(apiUrl, {credentials: 'include'})
            .then((response) => ServerResponseHandler.getResponse(response))
            .then((json) => {
                let index = 0;
                for (const entry of json.periodList) {
                    entry.display = Util.basicDateFormatter(entry.startDate) + ' - ' + Util.basicDateFormatter(entry.endDate);
                    entry.id = index++;
                }

                json.periodList[0].display += " (Current)";
                this.setState({dataList: json.periodList, payPeriod: json.periodList[0]});
                this.props.onChange(json.periodList[0]);
            });
    };

    render() {
        return (
            <div className="fb-select">
                <Select
                    isClearable
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.display}
                    value={this.state.payPeriod}
                    options={this.state.dataList}
                    onChange={this.handleChange}
                    placeholder={'Select a Pay Period....'}
                />
            </div>
        );
    };
}

SelectPayPeriods.propTypes = {
    onChange: PropTypes.func.isRequired,
    payPeriod: PropTypes.object,
};