import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, TabPane, Nav, NavItem, NavLink, TabContent} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import ReactTable from "react-table";
import moment from "moment";
import CompanyDetails from "./CompanyDetails";
import Departments from "./Departments";
import Break from "./Break";
import Pay from "./Pay";
import Holidays from "./Holidays";
import Project from "./Project";
import Terminals from "./Terminals";
import Billing from "./Billing";
import {Elements, StripeProvider} from "react-stripe-elements";
import './Company.scss';
import Notifications from "./Notifications";
const companySettings = Util.getCompanySettings();
const stripeKey = localStorage.getItem("stripeKey");

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeTab: '1',
            reloadUsers: false
        };

        this.toggle = this.toggle.bind(this);
        this.reloadUsers = this.reloadUsers.bind(this);
    }

    componentDidMount() {
        let tab = this.props.location.pathname === '/company' ? 'settings' : this.props.location.pathname.replace('/company/', '');
        this.setState({activeTab: tab});
    }

    reloadUsers() {
        this.setState({ reloadUsers: !this.state.reloadUsers });
    }

    toggle(tab) {
        let path = '/company/' + tab;
        this.props.history.push(path);
        this.setState({
            activeTab: tab,
        });
    }

    render() {
        return (
            <div className="h-100">
                <ToastContainer />
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'settings'}
                            onClick={() => { this.toggle('settings'); }}
                        >
                            Company Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'departments'}
                            onClick={() => { this.toggle('departments'); }}
                        >
                            Departments
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'pay'}
                            onClick={() => { this.toggle('pay'); }}
                        >
                            Pay
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'breaks'}
                            onClick={() => { this.toggle('breaks'); }}
                        >
                            Breaks
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={this.state.activeTab === 'holidays'}
                                onClick={() => { this.toggle('holidays'); }}
                        >
                            Holidays
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={this.state.activeTab === 'notifications'}
                                 onClick={() => { this.toggle('notifications'); }}
                        >
                            Notifications
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'project'}
                            onClick={() => { this.toggle('project'); }}
                        >
                            {companySettings.projectSingleName + ' / Positions'}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'terminals'}
                            onClick={() => { this.toggle('terminals'); }}
                        >
                            Terminals
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'billing'}
                            onClick={() => { this.toggle('billing'); }}
                        >
                            Billing
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} style={{height: 'calc(100% - 38px)'}}>
                    <TabPane tabId="settings">
                        <CompanyDetails/>
                    </TabPane>
                    <TabPane tabId="departments">
                        <Departments reloadUsers={this.reloadUsers} />
                    </TabPane>
                    <TabPane tabId="pay">
                        <Pay/>
                    </TabPane>
                    <TabPane tabId="holidays">
                        <Holidays/>
                    </TabPane>
                    <TabPane tabId="notifications">
                        <Notifications/>
                    </TabPane>
                    <TabPane tabId="project">
                        <Project companySettings={{companySettings}}/>
                    </TabPane>
                    <TabPane tabId="terminals">
                        <Terminals/>
                    </TabPane>
                    <TabPane tabId="billing">
                        <StripeProvider apiKey={stripeKey}>
                            <Elements>
                                <Billing/>
                            </Elements>
                        </StripeProvider>
                    </TabPane>
                    <TabPane tabId="breaks">
                        <Break/>
                    </TabPane>
                </TabContent>
            </div>
        )
    }

    showFormErrors = () => {
        let isFormValid = true;
        for (const input of document.getElementById('company-form').querySelectorAll('input')) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        }

        return isFormValid;
    };

    saveData = () => {
        if (!this.showFormErrors()) {
            console.error('form is invalid: do not submit');
            return;
        }

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/settings`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(this.state),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                localStorage.setItem("companySettings", JSON.stringify(json));
                toast.success('Settings were saved', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    };

    savePosition = (method, name, objId = '') => {
        if (!name) {
            toast.error('Position name is empty', {position: 'bottom-right'});
            return;
        }

        const obj = {name: name};

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/positions/${objId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(obj),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Position was saved', {position: 'bottom-right'});
                this.loadPositionData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    };
}

export default Company;