import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InputGroup, Label} from "reactstrap";

export default class SearchSection extends Component {
    render() {
        return (
            <div>
                <Label className="search-label">{this.props.title}</Label>
                <InputGroup className="mb-3 search-field">
                    {this.props.children}
                </InputGroup>
            </div>
        );
    }
}

SearchSection.propTypes = {
    title: PropTypes.string.isRequired,
};