import * as Util from "./Util";
import * as ServerResponseHandler from "./ServerResponseHandler";
import moment from 'moment';

export function loadPayrollModels() {
    const apiUrl = Util.apiUrl(`payroll`);
    fetch(apiUrl, {
        method: 'GET',
        credentials: 'include'
    })
        .then(response => ServerResponseHandler.getResponse(response))
        .then(json => {
            const approvalRequired = json.find(x => !x.lastApproved || moment(x.lastApproved).isBefore(moment(x.endDate)));
            if(approvalRequired) {
                localStorage.setItem("approvalRequired", true);
            } else {
                localStorage.setItem("approvalRequired", false);
            }

        })
        .catch(error => ServerResponseHandler.handleError(error))
        .finally();
}

export function isApprovalRequired() {
    return localStorage.getItem("approvalRequired") === "true";
}