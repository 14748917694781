import React, {Component} from 'react';
import {Row, Col, ModalHeader, ModalBody, ModalFooter, Button, Modal} from 'reactstrap';
import NoData from "./NoData";

class ManageTimeOffRequestTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.toggleHolidayWarningModal = this.toggleHolidayWarningModal.bind(this);
        this.state = {
            holidayWarningModalOpen: false,
            holidayEmployeeRow: {}
        };
    }

    toggleHolidayWarningModal(employee) {
        this.setState({ holidayWarningModalOpen: !this.state.holidayWarningModalOpen,
                              holidayEmployeeRow: employee});
    }

    successAndToggleOff() {
        this.props.updateEmployeePtoStatus(this.state.holidayEmployeeRow, 'Approved');
        this.toggleHolidayWarningModal(null);
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>

            {
                self.props.data.length < 1 ?
                    <NoData/> :
                    self.props.data.map((group, index1) => {
                        return <div key={index1}>
                            {
                                index1 === 0 ? <div className="margin-top"></div> : <div className="dropdown-divider"></div>
                            }
                            <div className="container-fluid">
                                <Row className='row-date col-12'>
                                    <Col>{group[0].name}</Col>
                                </Row>
                            </div>
                            {
                                group.map((employee, index2) => {
                                    return <div key={index2}>
                                        <div className="data">
                                            <div
                                                className={'data-row'}
                                                key={index2}>
                                                {
                                                    <Row className={"row-header col-lg-12"}>
                                                        <Col>{employee.date}</Col>
                                                        <Col>{employee.row.type}</Col>
                                                        <Col>{employee.totalHours}</Col>
                                                        <Col>{employee.row.note ? employee.row.note : "-"}</Col>
                                                        <Col>{employee.row.status}</Col>
                                                        {
                                                            <Col> { employee.row.status === 'Pending' ?
                                                                <Row className="justify-content-center">
                                                                    <span title="Accept"
                                                                               onClick={() =>
                                                                                    employee.row.type === 'Holiday' ?
                                                                                        this.toggleHolidayWarningModal(employee.row) :
                                                                                        self.props.updateEmployeePtoStatus(employee.row, 'Approved')
                                                                                }
                                                                               className="action accept action-icon material-icons">check</span>
                                                                    <span title="Deny"
                                                                               onClick={() => self.props.updateEmployeePtoStatus(employee.row, 'Denied')}
                                                                               className="action deny action-icon material-icons">block</span>
                                                                   <span title="Delete"
                                                                               onClick={() => self.props.toggleConfirmDeleteModal(employee.row, null)}
                                                                               className="action delete action-icon material-icons">clear</span>
                                                                </Row> :
                                                                <span title="Delete"
                                                                      onClick={() => self.props.toggleConfirmDeleteModal(employee.row, null)}
                                                                      className="delete action-icon material-icons">clear</span>

                                                            }
                                                            </Col>
                                                        }
                                                    </Row>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    })
            }

                <Modal isOpen={this.state.holidayWarningModalOpen}>
                    <ModalHeader>Approve Holiday Pto</ModalHeader>
                    <ModalBody>
                        Are you sure you want to approve this holiday pto request?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.successAndToggleOff() }>Approve</Button>{' '}
                        <Button color="secondary"
                        onClick={() => self.toggleHolidayWarningModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                </div>
    }

    render() {
        return this.createTable();
    }
}

export default ManageTimeOffRequestTable;
