import React, {createRef} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody, Modal, ModalFooter, InputGroup, Card
} from "reactstrap";

function SupportModal(props) {
    return (
        <Modal toggle={() => props.toggleModal()} isOpen={props.isOpen}>
            <ModalHeader style={{ margin: 'auto' }}>Support
            </ModalHeader>
            <ModalBody style={{ textAlign: 'center' }}>
                <h5>How can we help?</h5>
                <div className="mb-2" style={{ width: '100%' }}>
                    <i className="material-icons md-48">support_agent</i>
                </div>
                <span>We would love to help you with any needs you may have. Please contact support below, or click about to learn more about the product.</span>
                <div className="mt-5 mb-2">
                    <Button href="https://www.fishbowltime.com/support" target="_blank" className="mb-2" style={{ width: '84%', borderRadius: '4px' }} color="warning" outline>
                        Support
                    </Button>
                    <Button href="https://www.fishbowlinventory.com/wiki/Fishbowl_Time" target="_blank" className="mb-2" style={{ width: '84%', borderRadius: '4px' }} color="secondary">About</Button>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" outline onClick={() => props.toggleModal()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default SupportModal;