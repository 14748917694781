import React, {Component} from "react";
import {
    Button, Card,
    CardBody,
    CardHeader,
    Col, Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as ServerResponseHandler from "../../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../../components/Framework/Util";
import moment from "moment";
import PtoRulesTable from "../../../../components/DataTables/PtoRulesTable";
import AccrualRulesTable from "../../../../components/DataTables/AccrualRulesTable";
import _ from "lodash";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const companySettings = Util.getCompanySettings();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
const canDelete = isAdminOrManager;

class PtoSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ptoRuleList: [],
            selectedPto: {},
            accrualRuleList: [],
            selectedAccrual: {},
            editedAccrualRule: {},
            previouslySavedAccrualRulesToDelete: [],
            confirmDeletePtoDialog: false,
            confirmDeleteAccrualDialog: false,
            confirmStartEndGaps: false,
            confirmStartEndGapsMessage: "",
            immediately: false,
            indefinitely: false,
            showIncludeHolidayHours: false,
        }

        this.loadPtoData = this.loadPtoData.bind(this);
        this.getTableColumnsPto = this.getTableColumnsPto.bind(this);
        this.handlePtoChange = this.handlePtoChange.bind(this);
        this.toggleConfirmDeletePtoModal = this.toggleConfirmDeletePtoModal.bind(this);
        this.toggleConfirmStartEndGapsModal = this.toggleConfirmStartEndGapsModal.bind(this);
        this.toggleModalPtoSettings = this.toggleModalPtoSettings.bind(this);
        this.closeModalPtoSettings = this.closeModalPtoSettings.bind(this);
        this.handlePtoSubmit = this.handlePtoSubmit.bind(this);
        this.savePto = this.savePto.bind(this);
        this.deletePto = this.deletePto.bind(this);
        this.loadAccrualData = this.loadAccrualData.bind(this);
        this.sortAccrualRules = this.sortAccrualRules.bind(this);
        this.getTableColumnsAccrual = this.getTableColumnsAccrual.bind(this);
        this.handleAccrualChange = this.handleAccrualChange.bind(this);
        this.toggleConfirmDeleteAccrualModal = this.toggleConfirmDeleteAccrualModal.bind(this);
        this.toggleModalAccrualSettings = this.toggleModalAccrualSettings.bind(this);
        this.closeModalAccrualSettings = this.closeModalAccrualSettings.bind(this);
        this.updateAccrualRules = this.updateAccrualRules.bind(this);
        this.handleAccrualSubmit = this.handleAccrualSubmit.bind(this);
        this.handleAccrualDelete = this.handleAccrualDelete.bind(this);
        this.saveAccrual = this.saveAccrual.bind(this);
        this.deleteAccrual = this.deleteAccrual.bind(this);
    }

    componentDidMount() {
        this.loadPtoData();
    }

    /*================== Pto functions =================*/
    loadPtoData() {
        this.setState({
            ptoRuleList: [],
            loading: true
        });

        const apiUrl = Util.apiUrl(`pto_rule`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => this.setState({ptoRuleList: json.resultList}))
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    getTableColumnsPto() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Default', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}

        ];
    }

    handlePtoChange(e, name) {
        if (e.target) {
            e.target.classList.add('active');
            let updatedPto = {...this.state.selectedPto};
            updatedPto[name] = event.target.type === 'checkbox' ? e.target.checked : e.target.value;
            this.setState({selectedPto: updatedPto});
        }
    }

    toggleConfirmDeletePtoModal(row) {
        this.setState({
            selectedPto: !row ? {} : row,
            confirmDeletePtoDialog: !this.state.confirmDeletePtoDialog,
        });
    }

    toggleConfirmStartEndGapsModal() {
        this.setState({confirmStartEndGaps: !this.state.confirmStartEndGaps})
    }

    toggleModalPtoSettings(event, row) {
        if (event != null && event.target.classList.contains('delete')) {
            return false;
        }

        if (row) {
            this.setState({
                selectedPto: {
                    id: row.id,
                    name: row.name,
                    includeHolidays: row.includeHolidays,
                    default: row.default
                },
                modalPtoSettingsOpened: true
            }, () => this.loadAccrualData());
        } else {
            this.setState({
                selectedPto: {
                    id: null,
                    name: '',
                    includeHolidays: false,
                    default: false
                },
                modalPtoSettingsOpened: true
            }, () => this.loadAccrualData());
        }
    }

    closeModalPtoSettings() {
        this.setState({modalPtoSettingsOpened: false, previouslySavedAccrualRulesToDelete: []});
    }

    handlePtoSubmit(event) {
        event.preventDefault();

        let accrualRuleList = this.sortAccrualRules(this.state.accrualRuleList);

        if (!accrualRuleList || accrualRuleList.length === 0) {
            toast.error("At least one accrual rule must be added to the pto rule.", {position: 'bottom-right'});
            return;
        }

        if (accrualRuleList.length > 1) {
            for (let i = 0; i < accrualRuleList.length - 1; i++) {
                if (!(accrualRuleList[i].endUnit === accrualRuleList[i + 1].startUnit && (Number)(accrualRuleList[i].endNum) === (Number)(accrualRuleList[i + 1].startNum))) {
                    toast.error("The end of each rule must match the start of the next rule.", {position: 'bottom-right'});
                    return;
                }
            }
        }

        if (accrualRuleList[0].startNum !== 0 && !(accrualRuleList[accrualRuleList.length-1].endNum === 100 && accrualRuleList[accrualRuleList.length-1].endUnit === "Years")) {
            this.setState({
                confirmStartEndGaps: !this.state.confirmStartEndGaps,
                confirmStartEndGapsMessage: "Your first accrual rule does not start immediately and your last accrual rule does not continue indefinitely.  Are you sure you want to save?"
            });
        } else if (accrualRuleList[0].startNum !== 0) {
            this.setState({
                confirmStartEndGaps: !this.state.confirmStartEndGaps,
                confirmStartEndGapsMessage: "Your first accrual rule does not start immediately.  Are you sure you want to save?"
            });
        } else if (!(accrualRuleList[accrualRuleList.length-1].endNum === 100 && accrualRuleList[accrualRuleList.length-1].endUnit === "Years")) {
            this.setState({
                confirmStartEndGaps: !this.state.confirmStartEndGaps,
                confirmStartEndGapsMessage: "Your last accrual rule does not continue indefinitely.  Are you sure you want to save?"
            });
        } else {
            this.savePto();
        }
    }

    savePto() {
        const apiUrl = this.state.selectedPto.id ? Util.apiUrl(`pto_rule/${this.state.selectedPto.id}`) : Util.apiUrl(`pto_rule`);
        const method = this.state.selectedPto.id ? 'PATCH' : 'POST';

        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(this.state.selectedPto),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                this.setState({
                    selectedPto: {
                        id: json.id
                    }
                }, () => this.updateAccrualRules());

                toast.success('Pto rule was saved', {position: 'bottom-right'});
                this.loadPtoData();
                if (this.state.confirmStartEndGaps) {
                    this.toggleConfirmStartEndGapsModal();
                }
                this.closeModalPtoSettings();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    deletePto() {
        const apiUrl = Util.apiUrl(`pto_rule/${this.state.selectedPto.id}`);
        const method = 'PATCH';

        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify({deletedOn: moment()}),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Pto rule was deleted', {position: 'bottom-right'});
                this.loadPtoData();
                this.toggleConfirmDeletePtoModal(null);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    /*================== Accrual functions =================*/
    loadAccrualData() {
        this.setState({
            accrualRuleList: [],
            loading: true
        });

        const ptoRuleId = this.state.selectedPto.id;
        const search = {ptoRuleId: ptoRuleId};
        const apiUrl = Util.apiSearchUrl(`accrual_rule`, search);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                let sortedAccrualRuleList = this.sortAccrualRules(_.filter(json.resultList, function(o) { return (o.ptoRuleId === ptoRuleId)}));
                let includeHolidays = false;
                sortedAccrualRuleList.forEach((accrualRule) => {
                    if (accrualRule.frequency === 'Per Hour') {
                        includeHolidays = true;
                    }
                });
                this.setState({accrualRuleList: sortedAccrualRuleList, showIncludeHolidayHours: includeHolidays})
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    sortAccrualRules(accrualRuleList) {
        let days = 0;
        accrualRuleList.forEach(accrualRule => {
            accrualRule.startDays = this.convertToDays(accrualRule.startUnit, accrualRule.startNum);
        })
        return _.sortBy(accrualRuleList, (a) => a.startDays);
    }

    convertToDays(unit, num) {
        let days = 0;
        switch(unit) {
            case "Days": {days = num; break}
            case "Weeks": {days = num * 7; break}
            case "Months": {days = num * 30; break}
            case "Years": {days = num * 365; break}
        }
        return days;
    }

    getTableColumnsAccrual() {
        return [
            {displayName: 'Hours', hideColumn: false},
            {displayName: 'Frequency', hideColumn: false},
            {displayName: 'Start', hideColumn: false},
            {displayName: 'End', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}

        ];
    }

    handleAccrualChange(e, name) {
        if (name === 'immediately') {
            this.setState({immediately: !this.state.immediately})
        }
        if (name === 'indefinitely') {
            this.setState({indefinitely: !this.state.indefinitely})
        }
        if (name === 'startNum' || name === 'endNum') {
            e.target.value = e.target.value.replace(/[^\d]/g, "");
        }
        if (name === 'hours') {
            e.target.value = e.target.value.replace(/[^\d.]/g, "").replace(/(\..*)\./g, '$1');
        }
        if (e.target) {
            e.target.classList.add('active');
            let updatedAccrual = {...this.state.selectedAccrual};
            updatedAccrual[name] = event.target.type === 'checkbox' ? e.target.checked : e.target.value;
            this.setState({selectedAccrual: updatedAccrual});
        }
    }

    toggleConfirmDeleteAccrualModal(row) {
        this.setState({
            selectedAccrual: !row ? {} : row,
            confirmDeleteAccrualDialog: !this.state.confirmDeleteAccrualDialog,
        })
    }

    toggleModalAccrualSettings(event, row) {
        if (event != null && event.target.classList.contains('delete')) {
            return false;
        }

        if (row) {
            this.setState({
                immediately: row.startNum === 0,
                indefinitely: row.endNum === 100 && row.endUnit === 'Years',
                selectedAccrual: {
                    id: row.id,
                    hours: row.hours,
                    frequency: row.frequency,
                    startNum: row.startNum,
                    startUnit: row.startUnit,
                    endNum: row.endNum,
                    endUnit: row.endUnit,
                    ptoRuleId: row.ptoRuleId,
                },
                modalAccrualSettingsOpened: true
            }, () => {this.setState({editedAccrualRule: this.state.selectedAccrual})});
        } else {
            this.setState({
                immediately: false,
                indefinitely: false,
                selectedAccrual: {
                    id: null,
                    hours: 1,
                    frequency: 'Per Pay Period',
                    startNum: 1,
                    startUnit: 'Years',
                    endNum: 2,
                    endUnit: 'Years',
                    ptoRuleId: this.state.selectedPto.id,
                },
                modalAccrualSettingsOpened: true
            });
        }
    }

    closeModalAccrualSettings() {
        this.setState({modalAccrualSettingsOpened: false, editedAccrualRule: {}});
    }

    updateAccrualRules() {
        const ptoRuleId = this.state.selectedPto.id;
        this.state.accrualRuleList.forEach(accrualRule => {
            accrualRule.ptoRuleId = ptoRuleId;
            if (accrualRule.id) {
                this.saveAccrual('PATCH', accrualRule);
            } else {
                this.saveAccrual('POST', accrualRule);
            }
        });
        this.state.previouslySavedAccrualRulesToDelete.forEach(accrualRule => {
            this.deleteAccrual(accrualRule);
        })
    }

    handleAccrualSubmit(event) {
        event.preventDefault();

        let selectedAccrual = {...this.state.selectedAccrual}
        let editedAccrualRule = {...this.state.editedAccrualRule};
        let accrualRuleList = this.state.accrualRuleList;

        if (this.state.immediately) {
            selectedAccrual.startNum = 0;
        }
        if (this.state.indefinitely) {
            selectedAccrual.endNum = 100;
            selectedAccrual.endUnit = 'Years';
        }
        if (selectedAccrual.hours === "") {
            toast.error("Hours are required.", {position: 'bottom-right'});
            return;
        }
        if (selectedAccrual.startNum === "") {
            toast.error("Start number is required.", {position: 'bottom-right'});
            return;
        }
        if (selectedAccrual.endNum === "") {
            toast.error("End number is required.", {position: 'bottom-right'});
            return;
        }
        if (this.convertToDays(selectedAccrual.startUnit, selectedAccrual.startNum) >= this.convertToDays(selectedAccrual.endUnit, selectedAccrual.endNum)) {
            toast.error("Start must be before the end.", {position: 'bottom-right'});
            return;
        }
        if (_.isEmpty(editedAccrualRule)) {
            for (let i = 0; i < accrualRuleList.length; i++) {
                if (accrualRuleList[i].startUnit === selectedAccrual.startUnit && (Number)(accrualRuleList[i].startNum) === (Number)(selectedAccrual.startNum) &&
                    accrualRuleList[i].endUnit === selectedAccrual.endUnit && (Number)(accrualRuleList[i].endNum) === (Number)(selectedAccrual.endNum)) {
                    toast.error("A rule with this start and end already exists.", {position: 'bottom-right'});
                    return;
                }
            }
        }
        if (!_.isEmpty(editedAccrualRule)) {
            let index;
            for (let i = 0; i < accrualRuleList.length; i++) {
                if (accrualRuleList[i].startUnit === editedAccrualRule.startUnit && (Number)(accrualRuleList[i].startNum) === (Number)(editedAccrualRule.startNum) &&
                    accrualRuleList[i].endUnit === editedAccrualRule.endUnit && (Number)(accrualRuleList[i].endNum) === (Number)(editedAccrualRule.endNum)) {
                    index = i;
                    break;
                }
            }
            accrualRuleList.splice(index, 1);
        }
        accrualRuleList.push(selectedAccrual);
        let includeHolidays = false;
        accrualRuleList.forEach((accrualRule) => {
            if (accrualRule.frequency === 'Per Hour') {
                includeHolidays = true;
            }
        })
        this.setState({accrualRuleList: accrualRuleList, showIncludeHolidayHours: includeHolidays});
        this.closeModalAccrualSettings();
    }

    handleAccrualDelete(event) {
        event.preventDefault();

        let selectedAccrual = {...this.state.selectedAccrual}
        let accrualRuleList = this.state.accrualRuleList;
        let previouslySavedAccrualRulesToDelete = this.state.previouslySavedAccrualRulesToDelete;

        for (let i = 0; i < accrualRuleList.length; i++) {
            if (accrualRuleList[i].startUnit === selectedAccrual.startUnit && (Number)(accrualRuleList[i].startNum) === (Number)(selectedAccrual.startNum)) {
                accrualRuleList.splice(i, 1);
                if (selectedAccrual.id) {
                    previouslySavedAccrualRulesToDelete.push(selectedAccrual);
                }
                break;
            }
        }

        this.setState({accrualRuleList: accrualRuleList, previouslySavedAccrualRulesToDelete: previouslySavedAccrualRulesToDelete});
        toast.success('Accrual rule was deleted', {position: 'bottom-right'});
        this.toggleConfirmDeleteAccrualModal(null);
    }

    saveAccrual(method, accrualRule) {
        const apiUrl = method === 'PATCH' ? Util.apiUrl(`accrual_rule/${accrualRule.id}`) : Util.apiUrl(`accrual_rule`);

        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(accrualRule),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                this.loadAccrualData();
                this.closeModalAccrualSettings();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    deleteAccrual(accrualRule) {
        const apiUrl = Util.apiUrl(`accrual_rule/${accrualRule.id}`);
        const method = 'PATCH';

        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify({deletedOn: moment()}),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    render() {
        const settings = this.state;
        return (
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between align-items-center no-gutters">
                        <Col>
                            <div>PTO Settings</div>
                        </Col>
                        <Col>
                            <button className="header-button btn btn-outline-success pull-right"
                                    onClick={() => this.toggleModalPtoSettings()}><i
                                className="material-icons">add</i></button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <PtoRulesTable
                        data={settings.ptoRuleList}
                        tableColumns={this.getTableColumnsPto()}
                        toggleConfirmDeleteModal={this.toggleConfirmDeletePtoModal}
                        toggleEditModal={this.toggleModalPtoSettings}
                        tableSettings={{
                            canDelete: true,
                            canEdit: true
                        }}
                    />
                </CardBody>
                <Modal isOpen={this.state.confirmDeletePtoDialog}>
                    <ModalHeader>Delete PTO Rule</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the PTO rule?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.deletePto()}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => this.toggleConfirmDeletePtoModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.confirmStartEndGaps}>
                    <ModalHeader>Accrual Settings Warning</ModalHeader>
                    <ModalBody>
                        {this.state.confirmStartEndGapsMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.savePto()}>Save</Button>{' '}
                        <Button color="secondary"
                                onClick={() => this.toggleConfirmStartEndGapsModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modalPtoSettingsOpened}
                       className="modal-lg">{/*================ New PTO Rule Modal ================*/}
                    <ModalHeader>PTO Rule</ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <Label>Name<span className="error">*</span></Label>
                            <InputGroup className="mb-3">
                                <input type="text" placeholder="Name" name="ptoRuleSetting" ref="ptoRuleSetting"
                                       value={this.state.selectedPto.name}
                                       className="form-control" onChange={(e) => {
                                    this.handlePtoChange(e, 'name')
                                }} required/>
                            </InputGroup>
                        </div>
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <Row className="justify-content-between align-items-center no-gutters">
                                    <Col>
                                        <div>Accrual Settings</div>
                                    </Col>
                                    <Col>
                                        <button className="header-button btn btn-outline-success pull-right"
                                                onClick={() => this.toggleModalAccrualSettings()}><i
                                            className="material-icons">add</i></button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AccrualRulesTable
                                    data={this.sortAccrualRules(settings.accrualRuleList)}
                                    tableColumns={this.getTableColumnsAccrual()}
                                    toggleConfirmDeleteModal={this.toggleConfirmDeleteAccrualModal}
                                    toggleEditModal={this.toggleModalAccrualSettings}
                                    tableSettings={{
                                        canDelete: canDelete,
                                        canEdit: canEdit
                                    }}
                                />
                            </CardBody>
                        </Card>
                        {
                            this.state.showIncludeHolidayHours
                            ?
                            <div className="mb-3">
                                <Label>Include holiday hours when calculating &#39;Per Hour&#39; pto</Label>
                                <Label className="switch switch-icon switch-pill switch-info pull-right"
                                       title={'This includes holiday time off requests as well as paid company holidays.'}>
                                    <Input type="checkbox" name="includeHolidays" ref="includeHolidays"
                                           className="switch-input"
                                           checked={this.state.selectedPto.includeHolidays} onChange={(e) => {
                                        this.handlePtoChange(e, 'includeHolidays')
                                    }}/>
                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                    <span className="switch-handle"/>
                                </Label>
                            </div>
                            : null
                        }
                        <div className="mb-3">
                            <Label>Default</Label>
                            <Label className="switch switch-icon switch-pill switch-info pull-right"
                                   title={'The default pto rule will auto populate when new employees are created.'}>
                                <Input type="checkbox" name="ptoRuleIsDefault" ref="ptoRuleIsDefault"
                                       className="switch-input"
                                       checked={this.state.selectedPto.default} onChange={(e) => {
                                    this.handlePtoChange(e, 'default')
                                }}/>
                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                <span className="switch-handle"/>
                            </Label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.handlePtoSubmit}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => this.closeModalPtoSettings()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.confirmDeleteAccrualDialog}>
                    <ModalHeader>Delete Accrual Rule</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the accrual rule?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.handleAccrualDelete}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => this.toggleConfirmDeleteAccrualModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.modalAccrualSettingsOpened}
                    style={{maxWidth: '600px'}}>{/*================ New Accrual Rule Modal ================*/}
                    <form id="modal-form" noValidate>
                        <ModalHeader>Accrual Rule</ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Label>Hours<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <input type="text" name="accrualRuleSetting" ref="accrualRuleSetting"
                                           value={this.state.selectedAccrual.hours}
                                           className="form-control" onChange={(e) => {
                                        this.handleAccrualChange(e, 'hours')
                                    }} required/>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Label>Frequency<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <select name="frequency" ref="frequency"
                                            onChange={(e) => {
                                                this.handleAccrualChange(e, 'frequency')
                                            }}
                                            value={this.state.selectedAccrual.frequency}
                                            className="form-control pull-right"
                                            style={{width: 160 + 'px'}}
                                    >
                                        <option value="Per Pay Period">Per Pay Period</option>
                                        <option value="Per Hour">Per Hour</option>
                                        <option value="Per Week">Per Week</option>
                                        <option value="Per Month">Per Month</option>
                                        <option value="Per Year">Per Year</option>
                                    </select>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Row>
                                    <Col>
                                        <Label>Start (how long after hire date)<span className="error">*</span></Label>
                                    </Col>
                                    <Col>
                                        <span style={{paddingLeft: '10px'}}>Immediately</span>
                                        <Label className="switch switch-icon switch-pill switch-info pull-left">
                                            <Input type="checkbox" name="startImmediately" ref="startImmediately"
                                                   className="switch-input"
                                                   checked={this.state.immediately} onChange={(e) => {
                                                this.handleAccrualChange(e, 'immediately')
                                            }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </Col>
                                </Row>
                                {
                                    !this.state.immediately ?
                                        <Row>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <input type="text" name="startNum" ref="startNum"
                                                           value={this.state.selectedAccrual.startNum}
                                                           className="form-control" onChange={(e) => {
                                                        this.handleAccrualChange(e, 'startNum')
                                                    }} required/>
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <select name="startUnit" ref="startUnit"
                                                            onChange={(e) => {
                                                                this.handleAccrualChange(e, 'startUnit')
                                                            }}
                                                            value={this.state.selectedAccrual.startUnit}
                                                            className="form-control pull-right"
                                                            style={{width: 160 + 'px'}}
                                                    >
                                                        <option value="Days">Days</option>
                                                        <option value="Weeks">Weeks</option>
                                                        <option value="Months">Months</option>
                                                        <option value="Years">Years</option>
                                                    </select>
                                                </InputGroup>
                                            </Col>
                                        </Row> : null
                                }
                            </div>
                            <div className="mb-3">
                                <Row>
                                    <Col>
                                        <Label>End (how long after hire date)<span className="error">*</span></Label>
                                    </Col>
                                    <Col>
                                        <Label style={{paddingLeft: '10px'}}>Indefinitely</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-left">
                                            <Input type="checkbox" name="endIndefinitely" ref="endIndefinitely"
                                                   className="switch-input"
                                                   checked={this.state.indefinitely} onChange={(e) => {
                                                this.handleAccrualChange(e, 'indefinitely')
                                            }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </Col>
                                </Row>
                                {
                                    !this.state.indefinitely ?
                                        <Row>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <input type="text" name="endNum" ref="endNum"
                                                           value={this.state.selectedAccrual.endNum}
                                                           className="form-control" onChange={(e) => {
                                                        this.handleAccrualChange(e, 'endNum')
                                                    }} required/>
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <select name="endUnit" ref="endUnit"
                                                            onChange={(e) => {
                                                                this.handleAccrualChange(e, 'endUnit')
                                                            }}
                                                            value={this.state.selectedAccrual.endUnit}
                                                            className="form-control pull-right"
                                                            style={{width: 160 + 'px'}}
                                                    >
                                                        <option value="Days">Days</option>
                                                        <option value="Weeks">Weeks</option>
                                                        <option value="Months">Months</option>
                                                        <option value="Years">Years</option>
                                                    </select>
                                                </InputGroup>
                                            </Col>
                                        </Row> : null
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={this.handleAccrualSubmit}>Save</Button>{' '}
                            <Button color="secondary" onClick={() => this.closeModalAccrualSettings()}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </Card>
        )
    }
}

export default PtoSettings;