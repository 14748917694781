import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import * as Util from "../Framework/Util";

class PtoHolidayTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                <Col key="0">Holiday</Col>
                <Col key="1">Start Date</Col>
                <Col key="2">End Date</Col>
                <Col key="3">PTO</Col>
            </Row>
            {
                self.props.data.length < 1 ?
                    <NoData/> :
                    self.props.data.map((row, index) => {
                        return <div className='data-row margin-top' key={index}>
                            <Row style={{lineHeight: '37px'}} className='row-data row-header col-lg-12'>
                                <Col>
                                    <span>{row.name}</span>
                                </Col>
                                <Col>
                                    <span>{Util.basicDateFormatter(row.startDate, false, false)}</span>
                                </Col>
                                <Col>
                                    <span>{Util.basicDateFormatter(row.endDate, false, false)}</span>
                                </Col>
                                <Col>
                                    {row.pto ?
                                        <span title="PTO"
                                              className="material-icons" style={{verticalAlign: "middle"}}>check</span> : '' }
                                </Col>
                            </Row>
                        </div>
                    })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default PtoHolidayTable;
