import React from 'react';
import {
    Button,
    ModalHeader,
    ModalBody, ModalFooter, Modal
} from "reactstrap";

function CustomModal(props) {
    return (
        <Modal id={props.id} toggle={props.toggle} isOpen={props.isOpen}>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>
            <p>{props.message}</p>
            <br />
            </ModalBody>
        <ModalFooter>
            {props.buttons.map(button => (
                <React.Fragment key={button.id}>
                    <Button
                        color={button.color}
                        onClick={button.onClick}
                        className={button.className}>{button.text}</Button>{' '}
                </React.Fragment>
            ))}
        </ModalFooter>
    </Modal>
)
}

export default CustomModal;