import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Input, Label, Modal, ModalFooter} from 'reactstrap';

import * as ServerResponseHandler from '../../../components/Framework/ServerResponseHandler';
import {toPdfProjectDetails} from '../../../components/Framework/HtmlToPdf';
import FBDatePicker from "../../../components/DateTimePickers/FBDatePicker";
import * as Util from '../../../components/Framework/Util';
import SelectProject from "../../../components/Selects/SelectProject";
import ProjectDetailsReport from "../../../components/ReportTemplates/ProjectDetailsReport/ProjectDetailsReport";
import moment from "moment";
import {CSVLink} from "react-csv";
import Header from "../../../components/Header";

const companySettings = Util.getCompanySettings();

class ProjectDetailReport extends Component {
    constructor(props) {
        super(props);
        this.userTenant = Util.getUserTenant();
        this.state = {
            projectDetailList: [],
            maxResults: 0,
            loading: false,
            payPeriodDataList: [],
            currentPayPeriod: {},
            selectedPayPeriod: {},
            searchParams: {},
            csvData: [],
            showCost: true,
            showEmployeeSummary: true,
            showPositionSummary: true,
            singleIncludeArchivedProjects: false,
            singleIncludeClosedProjects: false,
            multipleShowArchivedProjects: false,
            multipleShowClosedProjects: false,
            projectList: [],
            pdfData: {},
            isOpen: false,
            startDate: null,
            endDate: null,
            useInternationalDates: Util.getCompanySettings().useInternationalDates,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleProjectSelectChange = this.handleProjectSelectChange.bind(this);
        this.getEmployeeSummary = this.getEmployeeSummary.bind(this);
        this.getPositionSummary = this.getPositionSummary.bind(this);
        this.getPdfData = this.getPdfData.bind(this);
        this.getSelectedPayPeriodDays = this.getSelectedPayPeriodDays.bind(this);
        this.loadProjects = this.loadProjects.bind(this);
        this.loadProjectData = this.loadProjectData.bind(this);
        this.toggleReport = this.toggleReport.bind(this);
        this.print = this.print.bind(this);
        this.handleDateFocusChange = this.handleDateFocusChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        // this.loadProjects();
        // this.loadProjectData({}, false);
        // this.processPdfAndCsvData();
    }

    pdfColumnHeaders() {
        return [
            {title: 'Date', dataKey: 'date', showColumn: true},
            {title: 'Employee', dataKey: 'employee', showColumn: true},
            {title: 'Position', dataKey: 'position', showColumn: true},
            {title: 'Hours', dataKey: 'hours', showColumn: true},
            {title: 'Cost', dataKey: 'cost', showColumn: this.state.showCost},
        ];
    }

    csvColumnHeaders() {
        let csvColumnHeaders = [
            {label: 'Project', key: 'project'},
            {label: 'Date', key: 'date'},
            {label: 'Employee', key: 'employee'},
            {label: 'Position', key: 'position'},
            {label: 'Hours', key: 'hours'},
        ];

        if (this.state.showCost)
            csvColumnHeaders.push({label: 'Cost', key: 'cost'})

        return csvColumnHeaders;
    }

    grandTotalSummaryHeaders() {
        return [
            {header: 'Total Hours', showColumn: true},
            {header: 'Total Cost', showColumn: this.state.showCost}
        ];
    }

    totalSummaryHeaders() {
        return [
            {header: 'ProjectName', showColumn: true},
            {header: 'Hours', showColumn: true},
            {header: 'Cost', showColumn: this.state.showCost}
        ];
    }

    positionSummaryHeaders() {
        return [
            {header: 'Position Name', showColumn: true},
            {header: 'Hours', showColumn: true},
            {header: 'Cost', showColumn: this.state.showCost}
        ];
    }

    employeeSummaryHeaders() {
        return [
            {header: 'Employee Name', showColumn: true},
            {header: 'Hours', showColumn: true},
            {header: 'Cost', showColumn: this.state.showCost}
        ]
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    handleCheckBoxChange(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleProjectSelectChange(project) {
        let searchParams = this.state.searchParams;
        searchParams.projectId = project ? project.id : null;

        this.setState({
            selectedProject: project,
            searchParams: searchParams
        });
    }

    handleDateFocusChange(focusField, dateField, focused) {
        this.setState({[focusField]: focused.focused});
        if (focused.focused) return;

        let element = document.getElementById(dateField);
        if (element && !Util.isEmpty(element.value)) {
            let dateValue = moment(element.value);
            if (!dateValue.isValid()) element.value = '';
        }
    }

    handleDateChange(field, date) {
        let searchParams = {...this.state.searchParams};
        searchParams[field] = field === 'startDate' ? moment(date).startOf('day') : moment(date).endOf('day');
        this.setState({
            searchParams: searchParams,
            [field]: date
        });
    }

    getEmployeeSummary() {
        let employeeMap = new Map();

        for (const project of this.state.projectDetailList) {
            for (const employee of project.timeLogList) {
                if (employeeMap.get(employee.userTenantId)) {
                    let employeeObject = employeeMap.get(employee.userTenantId);
                    employeeObject.totalDuration += (employee.duration - (companySettings.breaksInProjects ? 0 : employee.breakRoundedDuration) - (companySettings.lunchBreaksInProjects ? 0 : employee.lunchBreakRoundedDuration));
                    employeeObject.totalCost += +employee.regularPay;
                    employeeMap.set(employee.userTenantId, employeeObject);
                } else {
                    employeeMap.set(employee.userTenantId, {
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        totalDuration: +(employee.duration - (companySettings.breaksInProjects ? 0 : employee.breakRoundedDuration) - (companySettings.lunchBreaksInProjects ? 0 : employee.lunchBreakRoundedDuration)),
                        totalCost: +employee.regularPay,
                    });
                }
            }
        }
        let employeeArr = [];
        for (const entry of employeeMap) {
            employeeArr.push(entry[1]);
        }
        return employeeArr;
    }

    getPositionSummary() {
        let positionMap = new Map();

        for (const project of this.state.projectDetailList) {
            for (const employee of project.timeLogList) {
                if (positionMap.get(employee.position)) {
                    let position = positionMap.get(employee.position);
                    position.totalDuration += (+employee.duration - (companySettings.breaksInProjects ? 0 : employee.breakRoundedDuration) - (companySettings.lunchBreaksInProjects ? 0 : employee.lunchBreakRoundedDuration));
                    position.totalCost += +employee.regularPay;
                    positionMap.set(employee.position, position);
                } else {
                    positionMap.set(employee.position, {
                        name: employee.position,
                        totalDuration: +employee.duration - (companySettings.breaksInProjects ? 0 : employee.breakRoundedDuration) - (companySettings.lunchBreaksInProjects ? 0 : employee.lunchBreakRoundedDuration),
                        totalCost: +employee.regularPay,
                    });
                }
            }
        }

        let positionArr = [];
        for (const entry of positionMap) {
            positionArr.push(entry[1]);
        }
        return positionArr;
    }

    getGrandTotalSummary() {
        let projectGrandTotals = {totalCost: 0, totalDuration: 0}
        for (const project of this.state.projectDetailList) {
            projectGrandTotals.totalCost += Number(project.projectTotalCost)
            projectGrandTotals.totalDuration += Number(project.projectTotalDuration)
        }

        return projectGrandTotals;
    }

    toggleReport() {
        this.setState({isOpen: !this.state.isOpen})
    }

    getPdfData() {
        let projectEntry = this.processPdfAndCsvData();
        this.setState({
            pdfData: projectEntry,
            isOpen: !this.state.isOpen
        });
    }

    processPdfAndCsvData() {
        this.getEmployeeSummary();
        let project = [];
        let timeLogList;
        let tempCsvData = [];

        for (const entry of this.state.projectDetailList) {
            timeLogList = [];
            for (const timelog of entry.timeLogList) {
                let date = moment(Util.formatDateFromUtc(timelog.clockInDate, true));
                let newRecord = {
                    rawDate: date,
                    date: Util.basicDateFormatter(date),
                    project: timelog.project,
                    employee: timelog.firstName + ' ' + timelog.lastName,
                    hours: Util.formatTimeFromSeconds(timelog.durationForPayroll ? timelog.durationForPayroll : (timelog.duration - (companySettings.breaksInProjects ? 0 : timelog.breakRoundedDuration) - (companySettings.lunchBreaksInProjects ? 0 : timelog.lunchBreakRoundedDuration))),
                    cost: Util.formatCurrency(+timelog.regularPay, true),
                    position: timelog.position,
                    note: timelog.note,
                };
                timeLogList.push(newRecord);
                tempCsvData.push(newRecord);
            }

            project.push({
                name: entry.name,
                duration: entry.projectTotalDuration,
                cost: +entry.projectTotalCost,
                projectStatus: entry.status
                    ? 'Status: ' + entry.status
                    : 'Status: N/A',
                timeLogList: timeLogList,
            })
        }

        let projectEntry = {
            title: companySettings.projectSingleName + ' Details Report',
            subTitle: this.state.startDate && this.state.endDate ? Util.basicDateFormatter(this.state.startDate) + ' - ' + Util.basicDateFormatter(this.state.endDate) : '',
            project: this.state.selectedProject
                ? companySettings.projectSingleName + ": " + this.state.selectedProject.name
                : 'All ' + companySettings.projectPluralName,
            employeeSummaryMap: this.getEmployeeSummary(),
            positionSummaryMap: this.getPositionSummary(),
            grandTotalsSummary: this.getGrandTotalSummary(),
            showCost: this.state.showCost,
            showEmployeeSummary: this.state.showEmployeeSummary,
            showPositionSummary: this.state.showPositionSummary,
            singleIncludeArchivedProjects: this.state.singleIncludeArchivedProjects,
            singleIncludeClosedProjects: this.state.singleIncludeClosedProjects,
            multipleShowArchivedProjects: this.state.multipleShowArchivedProjects,
            multipleShowClosedProjects: this.state.multipleShowClosedProjects,
            projectList: project,
        };

        this.setState({csvData: tempCsvData});
        return projectEntry;
    }

    getSelectedPayPeriodDays() {
        let payPeriodDays = new Set();
        let currentDay = moment(this.state.startDate);
        let endDate = moment(this.state.endDate);
        endDate = endDate.add(1, 'day');
        while (!currentDay.isSame(endDate)) {
            payPeriodDays.add(Util.basicDateFormatter(currentDay));
            currentDay.add(1, 'day');
        }

        return payPeriodDays;
    }

    loadProjects() {
        fetch(Util.apiUrl(`reports/projects`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                json.unshift({
                    id: null,
                    name: 'No ' + companySettings.projectSingleName
                });

                this.setState({projectList: json});
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    async loadProjectData(search, showReport, returnCsvData = false) {
        this.setState({loading: true});
        await fetch(Util.apiSearchUrl(`reports/project-detail`, search), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let projectList = [];

                for (const entry of json) {
                    if(entry.status === "Open") projectList.push(entry);
                    else if (entry.status === "Archived" && this.state.multipleShowArchivedProjects) projectList.push(entry);
                    else if (entry.status === "Closed" && this.state.multipleShowClosedProjects) projectList.push(entry);
                }

                if (projectList.length === 0) projectList = json;
                this.setState({projectDetailList: projectList});
            })
            .then(() => showReport ? this.getPdfData() : this.processPdfAndCsvData())
            .catch(error => ServerResponseHandler.handleError(error));
        return true;
    }

    downloadReport() {
        toPdfProjectDetails(this.state.pdfData, this.getSelectedPayPeriodDays(), companySettings.projectSingleName + ' Details Report', 'projectDetailsReport', 'p')
    }

    print() {
        this.setState({isOpen: false});
        setTimeout(() => {
            window.print()
        });
    }

    render() {
        return (
            <div className='animated fadeIn'>
                <div id='projectDetailsReport' className='col-md-auto'>
                    <Col lg='12'>
                        <Card className='mt-3 card-accent-primary dont-print'>
                            <CardHeader>
                                {companySettings.projectSingleName} Details Report
                                <div className='mb-3 pull-right dont-print'>
                                    <CSVLink
                                        className="header-button btn btn-outline-primary"
                                        style={{borderRadius: 0.3+'em'}}
                                        title="Download CSV"
                                        data={this.state.csvData}
                                        headers={this.csvColumnHeaders()}
                                        filename={'ProjectDetailsReport.csv'} target="_blank"
                                        asyncOnClick={true}
                                        onClick={(event, done) => {
                                            this.loadProjectData().then(() => done());
                                        }}>
                                        <i className="material-icons">file_download</i>
                                    </CSVLink>
                                    <Button disabled={!this.state.pdfData} color='success'
                                            className='header-button button-padding'
                                            onClick={() =>
                                                this.loadProjectData(this.state.searchParams, true)}>
                                        Generate Report
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Card className='card-accent-primary dont-print'>
                                    <CardBody>
                                        <Label className='font-weight-bolder h5'>Filter Options</Label>
                                        <hr className='mt-1 mb-3' />
                                        <div className='mb-4 mt-3 clearfix'>
                                            <Label>Date Range</Label>
                                            <div className="w-25 pull-right layout-row">
                                                <FBDatePicker
                                                    id="startDate"
                                                    value={this.state.startDate}
                                                    onChange={date => this.handleDateChange('startDate', date)}
                                                    placeholder="Start Date"
                                                />
                                                <FBDatePicker
                                                    id="endDate"
                                                    value={this.state.endDate}
                                                    onChange={date => this.handleDateChange('endDate', date)}
                                                    placeholder="End Date"
                                                />
                                            </div>
                                        </div>

                                        <Label className='font-weight-bold'>Single {companySettings.projectSingleName} Filter Options</Label>
                                        <hr className='mt-1 mb-3' />
                                        <div className='mt-2 mb-3'>
                                            <Label>{'Include Closed ' + companySettings.projectPluralName + ' in Search'}</Label>
                                            <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                   title={'Show Closed ' + companySettings.projectPluralName + ' in Search'}>
                                                <Input type='checkbox' name='singleIncludeClosedProjects'
                                                       ref='singleIncludeClosedProjects'
                                                       className='switch-input pull-right'
                                                       checked={this.state.singleIncludeClosedProjects}
                                                       onChange={this.handleCheckBoxChange}/>
                                                <span className='switch-label' data-on={'\uf00c'}
                                                      data-off={'\uf00d'}/>
                                                <span className='switch-handle'/>
                                            </Label>
                                        </div>
                                        <div className='mb-3'>
                                                <Label>{'Include Archived ' + companySettings.projectPluralName}</Label>
                                                <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                       title={'Show Archived ' + companySettings.projectPluralName}>
                                                    <Input type='checkbox' name='singleIncludeArchivedProjects'
                                                           ref='singleIncludeArchivedProjects'
                                                           className='switch-input pull-right'
                                                           checked={this.state.singleIncludeArchivedProjects}
                                                           onChange={this.handleCheckBoxChange}/>
                                                    <span className='switch-label' data-on={'\uf00c'}
                                                          data-off={'\uf00d'}/>
                                                    <span className='switch-handle'/>
                                                </Label>
                                            </div>
                                        <div className='mb-4'>
                                            <Label>{companySettings.projectSingleName}</Label>
                                            <div className="w-25 pull-right">
                                                <SelectProject project={this.state.selectedProject}
                                                               onChange={this.handleProjectSelectChange}
                                                               includeArchived={this.state.singleIncludeArchivedProjects}
                                                               includeClosed={this.state.singleIncludeClosedProjects}
                                                />
                                            </div>
                                        </div>

                                        <Label className='font-weight-bold'>Multiple {companySettings.projectSingleName} Report Options</Label>
                                        <hr className='mt-1 mb-3' />

                                        <div className='mb-3'>
                                            <Label>{'Include Closed ' + companySettings.projectPluralName}</Label>
                                            <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                   title={'Show Closed ' + companySettings.projectPluralName}>
                                                <Input type='checkbox' name='multipleShowClosedProjects'
                                                       ref='multipleShowClosedProjects'
                                                       className='switch-input'
                                                       checked={this.state.multipleShowClosedProjects}
                                                       onChange={this.handleCheckBoxChange}/>
                                                <span className='switch-label' data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className='switch-handle'/>
                                            </Label>
                                        </div>
                                        <div className='mb-4'>
                                            <Label>{'Include Archived ' + companySettings.projectPluralName}</Label>
                                            <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                   title={'Show Archived ' + companySettings.projectPluralName}>
                                                <Input type='checkbox' name='multipleShowArchivedProjects'
                                                       ref='multipleShowArchivedProjects'
                                                       className='switch-input'
                                                       checked={this.state.multipleShowArchivedProjects}
                                                       onChange={this.handleCheckBoxChange}/>
                                                <span className='switch-label' data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className='switch-handle'/>
                                            </Label>
                                        </div>

                                        <Label className='font-weight-bolder h5'>Report Options</Label>
                                        <hr className='mt-1 mb-3' />

                                        <div className='mb-3'>
                                            <Label>Show Cost</Label>
                                            <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                   title='Show Cost'>
                                                <Input type='checkbox' name='showCost' ref='showCost'
                                                       className='switch-input'
                                                       checked={this.state.showCost}
                                                       onChange={this.handleCheckBoxChange}/>
                                                <span className='switch-label' data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className='switch-handle'/>
                                            </Label>
                                        </div>
                                        <div className='mb-3'>
                                            <Label>Show Employee Summary</Label>
                                            <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                   title='Show Employee Summary'>
                                                <Input type='checkbox' name='showEmployeeSummary'
                                                       ref='showEmployeeSummary'
                                                       className='switch-input'
                                                       checked={this.state.showEmployeeSummary}
                                                       onChange={this.handleCheckBoxChange}/>
                                                <span className='switch-label' data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className='switch-handle'/>
                                            </Label>
                                        </div>
                                        <div className='mb-3'>
                                            <Label>Show Position Summary</Label>
                                            <Label className='switch switch-icon switch-pill switch-info pull-right'
                                                   title='Show Positions Summary'>
                                                <Input type='checkbox' name='showPositionSummary'
                                                       ref='showPositionSummary'
                                                       className='switch-input'
                                                       checked={this.state.showPositionSummary}
                                                       onChange={this.handleCheckBoxChange}/>
                                                <span className='switch-label' data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className='switch-handle'/>
                                            </Label>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Modal isOpen={this.state.isOpen} toggle={this.toggleReport}
                                       className="modal-lg dont-print">
                                    {!this.state.pdfData ? '' :
                                        <ProjectDetailsReport
                                            pdfColumnHeaders={this.pdfColumnHeaders()}
                                            grandTotalSummaryHeaders={this.grandTotalSummaryHeaders()}
                                            totalSummaryHeaders={this.totalSummaryHeaders()}
                                            positionSummaryHeaders={this.positionSummaryHeaders()}
                                            employeeSummaryHeaders={this.employeeSummaryHeaders()}
                                            pdfData={this.state.pdfData}
                                        />
                                    }
                                    <ModalFooter>
                                        <Button color="success" onClick={() => this.print()}>Print
                                            Report</Button>{' '}
                                        <Button color="secondary" onClick={() => this.toggleReport()}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </CardBody>
                        </Card>
                        <div className={'print'}>
                            {!this.state.pdfData.projectList ? '' :
                                <ProjectDetailsReport
                                    pdfColumnHeaders={this.pdfColumnHeaders()}
                                    grandTotalSummaryHeaders={this.grandTotalSummaryHeaders()}
                                    totalSummaryHeaders={this.totalSummaryHeaders()}
                                    positionSummaryHeaders={this.positionSummaryHeaders()}
                                    employeeSummaryHeaders={this.employeeSummaryHeaders()}
                                    pdfData={this.state.pdfData}
                                />
                            }
                        </div>
                    </Col>
                </div>
            </div>
        )
    }
}

export default ProjectDetailReport;