import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";


class MyTimeOffTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                self.props.data.length < 1 ?
                    <NoData/> :
                    self.props.data[0].map((row, index) => {
                        return <div className={self.props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                            key={index}>
                            <Row className='row-data row-header col-lg-12'>
                                <Col><span>{row.date}</span></Col>
                                <Col><span>{row.row.type}</span></Col>
                                <Col><span>{row.totalHours}</span></Col>
                                <Col><span>{row.row.note ? row.row.note : "-"}</span></Col>
                                <Col><span>{row.row.status}</span></Col>
                                {
                                    !self.props.tableSettings.canDelete
                                        ?
                                        ''
                                        :
                                            !self.props.tableSettings.isAdminOrManager && row.row.status == 'Approved'
                                            ?
                                            <Col className="cursor-text">
                                                <span title="Contact your manager" className="delete action-icon material-icons">lock</span>
                                            </Col>
                                            :
                                            <Col className="cursor-text">
                                                 <span title="Delete"
                                                       onClick={() => self.props.toggleConfirmDeleteModal(row.row)}
                                                       className="delete action-icon material-icons">clear</span>
                                            </Col>
                                }
                            </Row>
                        </div>
                    })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default MyTimeOffTable;
