import React, {Component} from 'react';
import moment from "moment";
import {
    Button,
    Container,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import ReactTable from "react-table";
import * as Util from "../../../components/Framework/Util";
import { ToastContainer, toast } from 'react-toastify';
import {SearchBar} from "../../../components/Framework/SearchBar";
import SelectEmployee from "../../../components/Selects/SelectEmployee";
import DepartmentsTable from "../../../components/DataTables/DepartmentsTable";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || Util.getCompanySettings().allowEditTime;
const canDelete = isAdminOrManager;

class Departments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxResults: 0,
            loading: false,
            page: 1,
            sizePerPage: 20,

            departmentList: [],

            id: null,
            name: '',
            managerTenantId: '',
            managerTenant: null
        };

        this.getTableColumns = this.getTableColumns.bind(this);
        this.showFormErrors = this.showFormErrors.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleManagerChange = this.handleManagerChange.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveData = this.saveData.bind(this);
        this.deleteDepartment = this.deleteDepartment.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    getTableColumns() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Manager', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}
        ];
    }

    showFormErrors() {
        const inputs = document.getElementById('modal-form').querySelectorAll('input');
        for (const input of inputs) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                return false;
            }
        }
        return true;
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    handleManagerChange(manager) {
        if (!manager) {
            this.setState({managerTenantId: '', managerTenant: null});
        } else {
            this.setState({managerTenantId: manager.id, managerTenant: manager});
        }
    }

    toggleNewModal() {
        this.setState({
            newDialog: !this.state.newDialog,
            id: null,
            name: '',
            managerTenantId: '',
            managerTenant: null
        });
    }

    toggleEditModal(event, row) {
        if (event.target.classList.contains('delete')) {
            return false;
        }
        this.setState({
            id: row.id,
            name: row.name,
            managerTenantId: row.managerTenantId,
            managerTenant: row.managerTenantId ? {
                id: row.managerTenantId,
                details: Util.nameFormatter(row.managerNameFirst, row.managerNameLast)
            } : null,
            newDialog: !this.state.newDialog,
        });
    }

    toggleConfirmDeleteModal(row) {
        this.setState({
            row: row,
            confirmDeleteDialog: !this.state.confirmDeleteDialog,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.showFormErrors()) {
            console.error('form is invalid: do not submit');
            return;
        }

        this.setState({newDialog: !this.state.newDialog});

        let payload = {
            name: this.state.name,
            managerTenantId: this.state.managerTenantId,
        };

        if (!this.state.id) {
            this.saveData('POST', payload);
        } else {
            this.saveData('PATCH', payload, this.state.id);
        }
    }

    saveData(method, department, departmentId = '', deleted = false) {
        this.setState({loading: true});

        const apiUrl = Util.apiUrl(`company/departments/${departmentId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(department),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                if (deleted) {
                    toast.success('Department was deleted', {position: 'bottom-right'});
                } else {
                    toast.success('Department was saved', {position: 'bottom-right'});
                }
                this.loadData();
                this.props.reloadUsers();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    deleteDepartment() {
        let payload = {
            name: this.state.row.name,
            managerTenantId: this.state.row.managerTenantId,
            id: this.state.row.id,
            deletedOn: Util.formatDateToUtc(moment())
        };
         this.saveData('PATCH', payload, this.state.row.id, true);
         this.setState({confirmDeleteDialog: false});
    }

    loadData(search = null) {
        this.setState({
            loading: true,
            id: null,
            name: '',
            managerTenantId: '',
            managerTenant: null
        });

        const apiUrl = Util.apiSearchUrl(`company/departments`, search);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => this.setState({departmentList: json.resultList, maxResults: json.maxResults}))
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    render() {
        const department = this.state;
        const buttons = {
            add: this.toggleNewModal,
            savePdf: false,
            loading: this.state.loading
        };

        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <Modal isOpen={this.state.newDialog} className="modal-lg">
                        <form onSubmit={this.handleSubmit} id="modal-form" noValidate>
                            <ModalHeader toggle={this.toggleNewModal}>
                                {
                                    !department.id ? 'New Department' : 'Department: ' + department.name
                                }
                            </ModalHeader>
                            <ModalBody>
                                <Label>Name<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <input type="text" placeholder="Name" name="name" ref="name" value={department.name}
                                           className="form-control" onChange={this.handleChange} required
                                           pattern=".*\S+.*"/>
                                </InputGroup>

                                <Label>Manager</Label>
                                <div className="mb-3">
                                    <SelectEmployee employee={department.managerTenant}
                                                    onChange={this.handleManagerChange}/>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success">Save</Button>{' '}
                                <Button color="secondary" onClick={() => this.toggleNewModal()}>Cancel</Button>
                            </ModalFooter>
                        </form>
                    </Modal>
                    <Modal isOpen={this.state.confirmDeleteDialog}>
                        <ModalHeader>Delete Labor Item</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete this Department?
                            <br/>
                            <strong>{this.state.laborName}</strong>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => this.deleteDepartment()}>Delete</Button>{' '}
                            <Button color="secondary"
                                    onClick={() => this.toggleConfirmDeleteModal(null)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <div className="col-md-auto react-bs-table-container">
                            <Row className='col-12'>
                                <Card className="col-lg-12 card-accent-primary">
                                        <CardHeader>
                                            <SearchBar
                                                buttons={buttons}
                                                onSearch={this.loadData}
                                                includeGeneral
                                            />
                                        </CardHeader>
                                        <CardBody>
                                            <DepartmentsTable
                                                data={this.state.departmentList}
                                                tableColumns={this.getTableColumns()}
                                                toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                                toggleEditModal={this.toggleEditModal}
                                                tableSettings={{
                                                    canDelete: canDelete,
                                                    canEdit: canEdit
                                                }}

                                            />
                                    </CardBody>
                                </Card>
                            </Row>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Departments;