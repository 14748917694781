import React from 'react';
import {Row, Col} from 'reactstrap';


function EmployeePtoTotals(props) {
    return (<div className={'mt-3 p-3'}>
            <div className={'mb-2'}><h4 className={'text-center'}>PTO Totals</h4></div>
            <Row className={'row-header col-header col-lg-12'}>
                {
                    props.ptoTableHeaders.map((column, index) => {
                        return <Col key={index}>{column}</Col>
                    })
                }
            </Row>

            <Row className={'row-header col-lg-12'}>
                <Col>{props.ptoTotals.sickHours}</Col>
                <Col>{props.ptoTotals.vacationHours}</Col>
                <Col>{props.ptoTotals.holidayHours}</Col>
                <Col>{props.ptoTotals.totalPtoHours}</Col>
            </Row>
        </div>
    )
}

export default EmployeePtoTotals;