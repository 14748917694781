const pdfConverter = require('jspdf');
require('jspdf-autotable');
import * as Util from './Util';


export function toPdf(source, filename) {
    const pdf = new pdfConverter('p', 'pt', 'letter');
    let html = document.getElementById(source).outerHTML;
    const qrCodes = document.getElementsByTagName('canvas');
    const searchString = '<canvas height="256" width="256" style="height: 128px; width: 128px;"></canvas>';
    for (let code of qrCodes) {
        const replaceString = '</br><img src="' + code.toDataURL('image/jpeg', 1.0) + '" />';
        html = html.replace(searchString, replaceString);
    }

    const specialElementHandlers = {
        '#editor': function (element, renderer) {
            return true;
        },
    };

    const margins = {
        top: 50,
        left: 60,
        width: 545
    };

    pdf.fromHTML(
        html,
        margins.left,
        margins.top,
        {
            'width': margins.width,
            'elementHandlers': specialElementHandlers
        },
        function () {
            pdf.save(filename + '.pdf');
        },
    );
}

export function toPdfPayRoll(columns, rowData, totalData, subTitle, filename, orientation) {
    if (rowData.length === 0) {
        return Util.reportNoDataAlert();
    }
    const pdf = new pdfConverter(orientation, 'pt', 'letter');

    let title = JSON.parse(localStorage.getItem('companyTenant')).name;
    let xOffset = (pdf.internal.pageSize.getWidth() / 2) - (pdf.getStringUnitWidth(title) * pdf.internal.getFontSize() / 2) + 62;

    pdf.autoTable(columns, rowData, {
        theme: 'striped',
        headStyles: {
            fillColor: [235, 235, 235],
            textColor: [0, 0, 0],
        },
        styles: {
            fillColor: [244, 244, 244],
            textColor: [0, 0, 0],
            lineWidth: 1,
            lineColor: 150,
        },
        alternateRowStyles: {
            fillColor: [255, 255, 255],
        },

        margin: {top: 100},

        addPageContent: function (data) {
            pdf.text(title, xOffset, 40, 'center');
            pdf.text(subTitle, 40, 80);

            let currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
            let totalPages = pdf.internal.getNumberOfPages();
            let footer = 'Page ' + currentPage + ' of ' + totalPages;
            pdf.setFontSize(12);
            pdf.text(footer, 40, pdf.internal.pageSize.getHeight() - 30);

            let timeStamp = Util.getTimeStamp();
            pdf.text(timeStamp, rightAlignTextOffset(timeStamp, pdf, data), pdf.internal.pageSize.getHeight() - 30);
        },
    });

    const totalColumn = [
        {title: '', dataKey: 'title'},
        {title: '', dataKey: 'totalHours'},
        {title: '', dataKey: 'totalCurrency'},
    ];

    const totalRow = [
        {'title': 'PTO Hours:', 'totalHours': totalData.totalPtoHours, 'totalCurrency': totalData.totalPtoCurrency},
        {
            'title': 'Regular Hours:',
            'totalHours': totalData.totalRegularHours,
            'totalCurrency': totalData.totalRegularCurrency
        },
        {
            'title': 'Overtime Hours:',
            'totalHours': totalData.totalOvertimeHours,
            'totalCurrency': totalData.totalOvertimeCurrency
        },
        {'title': 'Total Hours:', 'totalHours': totalData.totalHours, 'totalCurrency': totalData.totalCurrency},
    ];
    pdf.autoTable(totalColumn, totalRow, {
        theme: 'plain',
        tableWidth: 250,
        showHeader: 'never',
        styles: {
            fontStyle: 'bold',
            halign: 'right',
            lineWidth: 1,
            lineColor: 150
        },
        startY: pdf.autoTable.previous.finalY + 20,
        margin: {left: 503}
    });

    pdf.save(filename + '.pdf');
}

export function toPdfTimeCards(data, payPeriodDays, subTitle, filename, orientation) {
    if (data.timeCardList.length === 0) {
        return Util.reportNoDataAlert();
    }
    const pdf = new pdfConverter(orientation, 'pt', 'letter');
    let rowData = [];
    let totalPagesExp = '{total_pages_count_string}';
    let topMargin = 60;
    let hasFooter = false;

    pdf.autoTableSetDefaults({
        theme: 'striped',
        headStyles: {
            fillColor: [235, 235, 235],
            textColor: [0, 0, 0],
        },
        styles: {
            fillColor: [244, 244, 244],
            textColor: [0, 0, 0],
            lineWidth: 1,
            lineColor: 150,
        },
        alternateRowStyles: {
            fillColor: [255, 255, 255],
        },
        margin: {top: topMargin + 70, bottom: 60},
    });

    data.timeCardList.forEach(entry => {
        if (rowData.length > 0) {
            pdf.addPage();
        }

        rowData = [];
        pdf.text(entry.firstName + ' ' + entry.lastName, 40, topMargin + 60);
        let dayCount = 0;
        payPeriodDays.forEach(day => {
            entry.timeCardData.forEach(timeLog => {
                if (timeLog.date === day) {
                    dayCount++;
                    rowData.push({
                        date: timeLog.date,
                        clockIn: timeLog.clockIn,
                        clockOut: timeLog.clockOut,
                        break: timeLog.break,
                        ptoSick: timeLog.sick,
                        ptoVac: timeLog.vac,
                        ptoHoliday: timeLog.holiday,
                        total: timeLog.total,
                        position: timeLog.position,
                        project: timeLog.project,
                    });
                }
            });
            if (dayCount === 0) {
                rowData.push({
                    date: day,
                    clockIn: '',
                    clockOut: '',
                    break: '',
                    ptoSick: '',
                    ptoVac: '',
                    ptoHoliday: '',
                    total: '',
                    position: '',
                    project: '',
                });
            } else {
                entry.timeCardData.splice(0, dayCount);
                dayCount = 0;
            }
        });

        let pdfColumns = [
            {title: 'Date', dataKey: 'date'},
            {title: 'In', dataKey: 'clockIn'},
            {title: 'Out', dataKey: 'clockOut'},
            {title: 'Break', dataKey: 'break'},
            {title: 'Sick', dataKey: 'ptoSick'},
            {title: 'Vac', dataKey: 'ptoVac'},
            {title: 'Holiday', dataKey: 'ptoHoliday'},
            {title: 'Total', dataKey: 'total'},
            {title: 'Position', dataKey: 'position'},
            {title: 'Project', dataKey: 'project'},
        ];

        if (!data.showProjects) {
            pdfColumns.splice(8, 1);
        }
        let nextCell = 0;
        pdf.autoTable(pdfColumns, rowData, {
            drawCell: function (cell, data) {
                if (data.column.dataKey === 'date') {
                    let duplicateCount = 0;
                    if (data.table.rows.length !== nextCell) {
                        while (data.row.raw.date === data.table.rows[nextCell].raw.date) {
                            duplicateCount++;
                            nextCell++;
                            if (nextCell === data.table.rows.length) {
                                break;
                            }
                        }
                    }
                    if (0 % duplicateCount === 0) {
                        pdf.rect(cell.x, cell.y, data.table.width, cell.height * duplicateCount, 'S');
                        pdf.autoTableText(data.row.raw.date, cell.x + cell.width / 2, cell.y + cell.height * duplicateCount / 2, {
                            halign: 'center',
                            valign: 'middle'
                        });
                    }
                    return false;
                }
            },
            addPageContent: function (pdfData) {
                let companyName = JSON.parse(localStorage.getItem('companyTenant')).name;
                pdf.setFontSize(22);
                pdf.text(data.title, centerTextOffset(data.title, pdf), topMargin);
                pdf.setFontSize(14);
                pdf.text(data.subTitle, centerTextOffset(data.subTitle, pdf), topMargin + 20);
                pdf.text(companyName, centerTextOffset(companyName, pdf), topMargin + 40);
                pdf.text(data.department, rightAlignTextOffset(data.department, pdf, pdfData), topMargin + 60);

                let str = 'Page ' + pdf.internal.getCurrentPageInfo().pageNumber;
                if (typeof pdf.putTotalPages === 'function') {
                    str = str + ' of ' + totalPagesExp;
                }
                pdf.setFontSize(12);
                let pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
                pdf.text(str, pdfData.settings.margin.left, pageHeight - 30);

                let timeStamp = Util.getTimeStamp();
                pdf.text(timeStamp, rightAlignTextOffset(timeStamp, pdf, pdfData), pageHeight - 30);
                hasFooter = true;

            },
        });

        let pdfTotalCol = [
            {title: 'Regular', dataKey: 'regular'},
            {title: 'Overtime', dataKey: 'overtime'},
            {title: 'Actual Hrs', dataKey: 'hrs'},
            {title: 'PTO Hrs', dataKey: 'ptoHrs'},
        ];

        let pdfTotalRow = [{
            regular: entry.totalRegular,
            overtime: entry.totalOvertime,
            hrs: entry.totalHours,
            ptoHrs: '0.00'
        }];

        let previousY = pdf.autoTable.previous.finalY + 40;
        if (pdf.autoTable.previous.finalY > 515) {
            previousY = 60;
            hasFooter = false;
            pdf.addPage();
        }

        pdf.autoTable(pdfTotalCol, pdfTotalRow, {
            startY: previousY + 30,
            pageBreak: 'auto',
            addPageContent: function (data) {

                pdf.setFontSize(14);
                pdf.text('PayrollReportTotals', centerTextOffset('PayrollReportTotals', pdf), previousY);

                pdf.setLineWidth(2);
                pdf.setDrawColor(0);
                let yValue = previousY + 175;

                let message = 'I certify that the hours on this time card are an accurate record of all time worked during this pay period';
                pdf.setFontSize(11);
                pdf.text(message, centerTextOffset(message, pdf), yValue - 50);
                let lineLength = 210;
                pdf.setFontSize(12);

                pdf.line(40, yValue, lineLength + 40, yValue);
                pdf.text('Employee', 40, yValue + 13);
                pdf.text('Date', lineLength + 40 - pdf.getStringUnitWidth('Date') * pdf.internal.getFontSize(), yValue + 12);

                pdf.line(363, yValue, lineLength + 363, yValue);
                pdf.text('Manager', 363, yValue + 13);
                pdf.text('Date', lineLength + 363 - pdf.getStringUnitWidth('Date') * pdf.internal.getFontSize(), yValue + 12);

                if (!hasFooter) {
                    let str = 'Page ' + pdf.internal.getCurrentPageInfo().pageNumber;
                    if (typeof pdf.putTotalPages === 'function') {
                        str = str + ' of ' + totalPagesExp;
                    }
                    pdf.setFontSize(12);
                    let pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
                    pdf.text(str, data.settings.margin.left, pageHeight - 30);

                    let timeStamp = Util.getTimeStamp();
                    pdf.text(timeStamp, rightAlignTextOffset(timeStamp, pdf, data), pageHeight - 30);
                }
            }
        });
    });

    if (typeof pdf.putTotalPages === 'function') {
        pdf.putTotalPages(totalPagesExp);
    }
    pdf.save(filename + '.pdf');
}

export function toPdfProjectDetails(data, payPeriodDays, subTitle, filename, orientation) {
    if (data.projectList.length === 0) {
        return Util.reportNoDataAlert();
    }
    const pdf = new pdfConverter(orientation, 'pt', 'letter');
    let rowData = [];
    let totalPagesExp = '{total_pages_count_string}';
    let topMargin = 60;
    let previousY = 0;
    let hoursAndCostWidth = 70;
    if (pdf.autoTable.previous.finalY !== undefined) {
        pdf.autoTable.previous.finalY = 100;
    }

    pdf.autoTableSetDefaults({
        theme: 'striped',
        headStyles: {
            fillColor: [235, 235, 235],
            textColor: [0, 0, 0],
        },
        styles: {
            fillColor: [244, 244, 244],
            textColor: [0, 0, 0],
            lineWidth: 1,
            lineColor: 150,
        },
        columnStyles: {
            date: {columnWidth: 90},
            hours: {columnWidth: hoursAndCostWidth},
            cost: {columnWidth: hoursAndCostWidth}
        },
        alternateRowStyles: {
            fillColor: [255, 255, 255],
        },
        margin: {top: topMargin + 70, bottom: 60, right: 40},
        addPageContent(pdfData) {
            let companyName = JSON.parse(localStorage.getItem('companyTenant')).name;
            pdf.setFontSize(22);
            pdf.text(data.title, centerTextOffset(data.title, pdf), topMargin);
            pdf.setFontSize(14);
            pdf.text(data.subTitle, centerTextOffset(data.subTitle, pdf), topMargin + 20);
            pdf.text(companyName, centerTextOffset(companyName, pdf), topMargin + 40);
            pdf.text(data.project, rightAlignTextOffset(data.project, pdf, pdfData), topMargin + 60);

            let str = 'Page ' + pdf.internal.getCurrentPageInfo().pageNumber;
            if (typeof pdf.putTotalPages === 'function') {
                str = str + ' of ' + totalPagesExp;
            }
            pdf.setFontSize(12);
            let pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
            pdf.text(str, pdfData.settings.margin.left, pageHeight - 30);

            let timeStamp = Util.getTimeStamp();
            pdf.text(timeStamp, rightAlignTextOffset(timeStamp, pdf, pdfData), pageHeight - 30);
        },

    });

    let pdfColumns = [
        {title: 'Date', dataKey: 'date'},
        {title: 'Project', dataKey: 'project'},
        {title: 'Employee', dataKey: 'employee'},
        {title: 'Position', dataKey: 'position'},
        {title: 'Hours', dataKey: 'hours'},
        {title: 'Cost', dataKey: 'cost'},
    ];
    if (!data.showCost) {
        pdfColumns.splice(5, 1);
    }
    let grandTotalHours = 0;
    let grandTotalCost = 0;

    data.projectList.forEach(projectList => {
        grandTotalHours += +projectList.duration;
        grandTotalCost += projectList.cost;

        rowData = [];
        projectList.timeLogList.forEach(entry => {
            rowData.push({
                rawDate: entry.rawDate,
                date: entry.date,
                project: entry.project,
                employee: entry.employee,
                position: entry.position,
                hours: entry.hours,
                cost: entry.cost,
            });
        });

        rowData.sort(function (a, b) {
            return a.rawDate - b.rawDate;
        });

        previousY = topMargin + 70;
        if (pdf.autoTable.previous.finalY !== undefined) {
            previousY = pdf.autoTable.previous.finalY + 30;
        }

        pdf.autoTable(pdfColumns, rowData, {
            startY: previousY,
            addPageContent(pdfData) {
                pdf.setFontSize(12);
                pdf.text(projectList.projectStatus, 40, pdfData.table.cursor.y - pdfData.table.height - 10)
            }
        });

        const subTotalColumn = [
            {title: '', dataKey: 'title'},
            {title: '', dataKey: 'totalHours'},
            {title: '', dataKey: 'totalCost'},
        ];
        let subtractWidth = 0;
        if (!data.showCost) {
            subtractWidth = hoursAndCostWidth;
            subTotalColumn.splice(2, 1);
        }
        pdf.autoTable(subTotalColumn, [{
            title: "Sub Total: ",
            totalHours: Util.formatTimeFromSeconds(projectList.duration),
            totalCost: Util.formatCurrency(projectList.cost, true)
        }], {
            startY: pdf.autoTable.previous.finalY + 10,
            theme: 'plain',
            showHeader: 'never',
            styles: {
                fontStyle: 'bold',
                halign: 'right',
                lineWidth: 1,
                lineColor: 150
            },
            margin: {left: 363 + subtractWidth},
            columnStyles: {
                title: {columnWidth: 70, fontStyle: 'bold'},
                totalHours: {columnWidth: hoursAndCostWidth, fontStyle: 'bold'},
                totalCost: {columnWidth: hoursAndCostWidth, fontStyle: 'bold'}
            }
        });
    });

    const totalColumn = [
        {title: '', dataKey: 'title'},
        {title: '', dataKey: 'totalHours'},
        {title: '', dataKey: 'totalCost'},
    ];
    if (!data.showCost) {
        totalColumn.splice(2, 1);
    }
    pdf.autoTable(totalColumn, [{
        title: "Grand Total: ",
        totalHours: Util.formatTimeFromSeconds(grandTotalHours),
        totalCost: Util.formatCurrency(grandTotalCost, true)
    }], {
        startY: pdf.autoTable.previous.finalY + 10,
        theme: 'plain',
        showHeader: 'never',
        styles: {
            fontStyle: 'bold',
            halign: 'right',
            lineWidth: 1,
            lineColor: 150
        },
        columnStyles: {
            title: {halign: 'left'},
            totalHours: {columnWidth: hoursAndCostWidth, fontStyle: 'bold'},
            totalCost: {columnWidth: hoursAndCostWidth, fontStyle: 'bold'}
        }
    });

    if (data.showEmployeeSummary) {
        const employeeSummaryColumn = [
            {title: 'Employee Name', dataKey: 'name'},
            {title: 'Hours', dataKey: 'hours'},
            {title: 'Cost', dataKey: 'cost'},
        ];
        if (!data.showCost) {
            employeeSummaryColumn.splice(2, 1);
        }

        let employeeSummary = [];
        data.employeeSummaryMap.forEach(employee => {
            employeeSummary.push({
                name: employee.firstName + ' ' + employee.lastName,
                hours: employee.totalDuration,
                cost: employee.totalCost,
            })
        });

        pdf.autoTable(employeeSummaryColumn, employeeSummary, {
            startY: pdf.autoTable.previous.finalY + 40,
            addPageContent(pdfData) {
                pdf.setFontSize(14);
                pdf.text('Employee Summary', 40, pdfData.table.cursor.y - pdfData.table.height - 10);
            }
        });
    }

    if (data.showPositionSummary) {
        const positionSummaryColumn = [
            {title: 'Position', dataKey: 'name'},
            {title: 'Hours', dataKey: 'hours'},
            {title: 'Cost', dataKey: 'cost'},
        ];
        if (!data.showCost) {
            positionSummaryColumn.splice(2, 1);
        }

        let positionSummary = [];
        data.positionSummaryMap.forEach(position => {
            positionSummary.push({
                name: position.name,
                hours: position.totalDuration,
                cost: position.totalCost,
            })
        });

        pdf.autoTable(positionSummaryColumn, positionSummary, {
            startY: pdf.autoTable.previous.finalY + 40,
            addPageContent(pdfData) {
                pdf.setFontSize(14);
                pdf.text('Position Summary', 40, pdfData.table.cursor.y - pdfData.table.height - 10);
            }
        })
    }

    if (typeof pdf.putTotalPages === 'function') {
        pdf.putTotalPages(totalPagesExp);
    }

    pdf.save(filename + '.pdf');
}

function centerTextOffset(text, pdf) {
    let pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
    return ((pageWidth - (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize())) / 2);
}

function rightAlignTextOffset(text, pdf, data = null) {
    let pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
    return pageWidth - (data.settings.margin.right || pdf.autoTableState.defaults.margin.right) - pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()
}

