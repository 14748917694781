import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import * as Util from "../../components/Framework/Util";


class BillingHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.createTable = this.createTable.bind(this);
        this.openInvoice = this.openInvoice.bind(this);
    }

    openInvoice(invoice) {
        window.open(invoice.hosted_invoice_url);
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                self.props.data.length < 1 ?
                    <NoData/> :
                self.props.data.map((transaction, index) => {
              return <div key={index}>
                        {
                            index === 0 ? <div className="margin-top"></div> : <div className="'data-row margin-top'"></div>
                        }
                        <div className="data">
                            <div className="data-row" key={index}>
                                {
                                    <Row style={{cursor: 'pointer'}} onClick={() => {this.openInvoice(transaction.invoice)}} className={"row-header col-lg-12"} >
                                        <Col><span>{transaction.createdDate}</span></Col>
                                        <Col><span>{Util.formatCurrency(transaction.amount/100, true)}</span></Col>
                                        <Col><span>{'**** **** **** ' + (transaction.source ? transaction.source.last4 : '')}</span></Col>
                                    </Row>
                                }
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default BillingHistoryTable;
