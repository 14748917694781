import React from 'react';
import {
    Button,
    ModalHeader,
    ModalBody, Modal, ModalFooter
} from "reactstrap";

function TimeClockPinModal(props) {
    return (
        <Modal toggle={() => props.toggleModal()} isOpen={props.showModal}>
            <ModalHeader style={{margin: 'auto'}}>Time Clock Pin Reset
            </ModalHeader>
            <ModalBody style={{textAlign: 'center'}}>
                <div>
                    <span>Your new time clock pin is:</span>
                    <br />
                    <span><h1><b>{props.pin}</b></h1></span>
                </div>
                <div>
                    <span><i><b>You will not see this pin again.</b></i></span>
                    <br/>
                    <span><i>If you forget it, you&#39;ll need to reset your time clock pin again.</i></span>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button color="secondary" outline onClick={() => props.toggleModal()}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TimeClockPinModal;