import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import * as Util from "../Framework/Util";

const companySettings = Util.getCompanySettings();

export default class SelectProjectStatus extends Component {
    constructor(props) {
        super(props);

        if (props.includeAll) {
            this.projectStatusOptions = [
                {value: 'All', label: 'All'},
                {value: 'Open', label: 'Open'},
                {value: 'On Hold', label: 'On Hold'},
                {value: 'Closed', label: 'Closed'},
                {value: 'Archived', label: 'Archived'}
            ];
        } else {
            this.projectStatusOptions = [
                {value: 'Open', label: 'Open'},
                {value: 'On Hold', label: 'On Hold'},
                {value: 'Closed', label: 'Closed'},
                {value: 'Archived', label: 'Archived'}
            ];
        }

        this.state = {
            projectStatus: {value: this.props.projectStatus, label: this.props.projectStatus}
        }
    }

    handleChange = newProjectStatus => {
        if (newProjectStatus && newProjectStatus.constructor === Array) {
            newProjectStatus = 'Open';
        }

        this.setState({projectStatus: newProjectStatus});
        this.props.onChange(newProjectStatus.value);
    };

    render() {
        return (
            <div className="fb-select">
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    value={this.state.projectStatus} options={this.projectStatusOptions}
                    onChange={this.handleChange}
                    placeholder={'Select a ' + companySettings.projectSingleName + ' Status...'}
                />
            </div>
        )
    }
}

SelectProjectStatus.propTypes = {
  onChange: PropTypes.func.isRequired,
  projectStatus: PropTypes.string
};

SelectProjectStatus.defaultProps = {
  projectStatus: 'Open'
};