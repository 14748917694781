import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";

export default class SelectPtoVacationType extends Component {
    constructor(props) {
        super(props);
        this.ptoTypeOptions = [
            {value: 'Vacation', label: 'Vacation'},
            {value: 'Sick', label: 'Sick'},
            {value: 'Holiday', label: 'Holiday'}
        ];

        this.state = {
            ptoType: {value: this.props.ptoType, label: this.props.ptoType}
        }
    }

    handleChange = newPtoType => {
        if (newPtoType && newPtoType.constructor === Array) {
            newPtoType = 'Vacation';
        }

        this.setState({ptoType: newPtoType});
        this.props.onChange(newPtoType.value);
    };

    render() {
        return (
            <div className="fb-select">
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    value={this.state.ptoType} options={this.ptoTypeOptions}
                    onChange={this.handleChange}
                    placeholder={'Select a Vacation Type...'}
                />
            </div>
        )
    }
}

SelectPtoVacationType.propTypes = {
  onChange: PropTypes.func.isRequired,
  ptoType: PropTypes.string
};

SelectPtoVacationType.defaultProps = {
  ptoType: 'Vacation'
};