import React, {Component} from 'react';
import {TabPane, Nav, NavItem, NavLink, TabContent} from "reactstrap";
import ProjectSchedule from "./ProjectSchedule";
import ProjectScheduleTimeline from "./ProjectScheduleTimeline";
import ProjectEventModal from "./ProjectEventModal";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import * as Util from '../../../components/Framework/Util';
import * as ServerResponseHandler from '../../../components/Framework/ServerResponseHandler';
import '../../../../scss/schedule/calendar.scss'
import ConfirmDeleteModal from "../../../components/Modal/ConfirmDeleteModal";

let userGlobal = Util.getUserGlobal();

const defaultAvatarImgUrl = 'https://s3.amazonaws.com/fishbowllabor/StaticContent/default5.jpg';
const eventColors = {
    "red": '#AD5050',
    "blue": '#4D73A8',
    "green": '#4FA84D',
    "aluminium": '#888A8C',
    "grey": '#D0D0D0',
    "orange": '#F5A624'
};

const eventStatusColors = {
    attention: eventColors.red,
    unassigned: eventColors.blue,
    ready: eventColors.green,
    complete: eventColors.aluminium,
    closed : eventColors.grey,
    hold: eventColors.orange
};

class Schedule extends Component {
    constructor(props) {        
        super(props);
        this.state = {
            loading: true,
            activeTab: '',
            showEventModal: false,
            selectedEvent: '',
            events: [],
            users: [],
            availableEmployeeHours: {
                "Monday": 0,
                "Tuesday": 0,
                "Wednesday": 0,
                "Thursday": 0,
                "Friday": 0,
                "Saturday": 0,
                "Sunday": 0
            },
            hoursOfOperation: [],
            deleteDialog: {
                header: 'Add Employee',
                message: ['Employee doesn\'t have the available hours to cover this project, are you sure you want to continue?'],
                confirm: this.confirmAddEmployee,
                cancel: this.toggleConfirmDeleteModal,
                confirmText: "Add Employee",
                userId: null,
                eventId: null
            },
            confirmDeleteDialog: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.confirmAddEmployee = this.confirmAddEmployee.bind(this);
        this.projectCalendar = React.createRef();
    }

    async componentDidMount() {
        await this.loadData();

        this.setState({loading: false});

        let tab = this.props.location.pathname === '/schedule' 
            ? 'projects' 
            : this.props.location.pathname.replace('/schedule/', '');
        this.setState({activeTab: tab});
    }

    loadData = async () => {
        await this.loadHoursOfOperation();
        await this.loadHolidays();
        await this.loadUsers();
        await this.loadEvents();        
    }

    loadEvents = async () => {
    
        const apiUrl = Util.apiUrl(`calendar_event`);
        const response = await fetch(apiUrl, { credentials: 'include' });
        const results = await ServerResponseHandler.getResponse(response);
    
        const events = [...this.state.events];
    
        for (const res of results.resultList) {
    
          if ( res.project.projectStatus === 'Archived' ) {
            continue;
          }
    
          const tenants = [];
          let tenantCanWork = true;
          let attendeesBudget = 0;
    
          for (const userTenant of res.userTenantCalendarEventList) {
            const user = this.state.users.find(
              u => u.id === userTenant.userTenantId
            );
    
            if (user === undefined) {
              continue;
            }
    
            const userSchedule = user.workingSchedule.find(
              s => s.day === moment(res.startDate).format('dddd')
            );
            
            const userBudget = userSchedule === undefined ? 0 : userSchedule.workingHours;
            if (userSchedule === undefined) {
              tenantCanWork = false;
            }
    
            attendeesBudget += userBudget;
    
            tenants.push({
              userTenantId: userTenant.id,
              userId: userTenant.userTenantId,
              name: user.nameFirst + ' ' + user.nameLast,
              image: user.imageUrl ? user.imageUrl : defaultAvatarImgUrl,
              budgetTime: userBudget,
            });
          }
    
          const durationHours = Math.ceil(res.duration / 60 / 60);
    
          const event = {
            id: res.id,
            title: res.project.number,
            name: res.project.name,
            start: res.startDate,
            end: res.endDate,        
            extendedProps: {
              attendees: tenants,
              duration: res.duration,
              durationHours,
              attendeesBudget,
              projectId: res.project.id,
              projectStatus: res.project.projectStatus,
              pickStatus: res.project.pickStatus,
              type: 'project',
              bomCategory: res.project.bomCategory,
              receiveDate: res.project.receiveDate
            }
          }
    
          event.color = this.getEventColor(event);      
    
          events.push(event);
        }
    
        this.setState({ events });    
    }

    confirmAddEmployee = () => {
        this.addUserToEvent(this.state.deleteDialog.userId, this.state.deleteDialog.eventId, true);
        this.setState({confirmDeleteDialog: false});
    }

    toggleConfirmDeleteModal = () => {
        this.setState({ confirmDeleteDialog: !this.state.confirmDeleteDialog });
    }

    loadUsers = async () => {
        const apiUrl = Util.apiSearchUrl(`users`, { active: true });
    
        const response = await fetch(apiUrl, { credentials: 'include' });
        const results = await ServerResponseHandler.getResponse(response);
    
        const users = [];
        const availableEmployeeHours = {...this.state.availableEmployeeHours};
    
        for (const user of results.resultList) {
          const userScheduleArr = [];

          if(user.userTenantScheduleList){
              for (const userSchedule of user.userTenantScheduleList) {
                  const workingTimeStart = moment('01/01/2020 ' + userSchedule.startTime);
                  const workingTimeEnd = moment('01/01/2020 ' + userSchedule.endTime);
                  const workingHours = workingTimeEnd.diff(workingTimeStart, 'hours');

                  availableEmployeeHours[userSchedule.dayOfWeek] += workingHours;

                  userScheduleArr.push({
                      day: userSchedule.dayOfWeek,
                      startTime: userSchedule.startTime,
                      endTime: userSchedule.endTime,
                      workingHours,
                  });
              }
          }

          users.push({
            id: user.id,
            nameFirst: user.nameFirst,
            nameLast: user.nameLast,
            imageUrl: user.imageUrl ? user.imageUrl : defaultAvatarImgUrl,
            workingSchedule: userScheduleArr,
          });
        }
        
        this.setState({ users });
        this.setState({ availableEmployeeHours });
    }

    loadHoursOfOperation = async () => {
        const apiUrl = Util.apiUrl('company/settings/hoursOfOperation');
        const response = await fetch(apiUrl, { credentials: "include" });
        const results = await ServerResponseHandler.getResponse(response);
    
        const hoursOfOperation = [];
        for ( const result of results.resultList ) { 
          if ( !result.active ) continue;
          hoursOfOperation.push({
            daysOfWeek: [parseInt(moment().day(result.dayOfWeek).format('d'),10)],
            startTime: moment('2020-01-01 ' + result.startTime).format('HH:mm'),
            endTime: moment('2020-01-01 ' + result.endTime).format('HH:mm')
          }); 
        }
    
        this.setState({ hoursOfOperation });
    }

    loadHolidays = async () => {
        let searchObj = {
            active: true
        };

        const apiUrl = Util.apiSearchUrl('company/settings/holidays', searchObj);
        const response = await fetch(apiUrl, { credentials: "include" });
        const results = await ServerResponseHandler.getResponse(response);
    
        const events = [];
        for ( const result of results.resultList ) {
          const startDateTime = moment(result.date).startOf('day').format('YYYY-MM-DD HH:mm');
          const endDateTime = moment(result.date).endOf('day').format('YYYY-MM-DD HH:mm');

          const startDate = moment(result.date).startOf('day').format('YYYY-MM-DD');
          const endDate = moment(result.date).endOf('day').format('YYYY-MM-DD');

          const existingTime = events.find(x => x.extendedProps.type === 'holiday' && x.start === startDateTime);
          const existingDate = events.find(x => x.extendedProps.type === 'holiday' && x.start === startDate);

          let name = result.name;
          if(result.pto)
              name = name + '(paid)';

          if(existingTime && existingDate) {
              existingTime.title = existingTime.title + " / " + name;
              existingDate.title = existingDate.title + " / " + name;
          } else {
              // push timed event for time grid view
              events.push({
                  title: name,
                  start: startDateTime,
                  end: endDateTime,
                  rendering: 'background',
                  extendedProps: {
                      type: 'holiday',
                      pto: result.pto
                  }
              });

              // push event without time for month grid view
              events.push({
                  title: name,
                  start: startDate,
                  end: endDate,
                  rendering: 'background',
                  extendedProps: {
                      type: 'holiday',
                      pto: result.pto
                  }
              });
          }
        }
    
        this.setState({ events });
    }

    coveredHoursScheduleCalculate = (userSchedule, projectTimeIntervals, day) => {
        const userStartTime = moment(day.format("YYYY-MM-DD") + " " + userSchedule.startTime);
        const userEndTime = moment(day.format("YYYY-MM-DD") + " " + userSchedule.endTime);
        let tmpProjectTimeIntervals = projectTimeIntervals;

        projectTimeIntervals.forEach((projectInterval => {
            if(!(userStartTime.isSameOrBefore(projectInterval.start) && userEndTime.isSameOrBefore(projectInterval.start))
                || !(userStartTime.isSameOrAfter(projectInterval.end) && userEndTime.isSameOrAfter(projectInterval.end))) {

                let tmpInterval = tmpProjectTimeIntervals.find(x => x.start.isSame(projectInterval.start) && x.end.isSame(projectInterval.end));

                if(userStartTime.isSameOrBefore(projectInterval.start) && userEndTime.isSameOrAfter(projectInterval.end)) {
                    tmpInterval.allCovered = true;
                }

                if(userStartTime.isSameOrBefore(projectInterval.start) && userEndTime.isBefore(projectInterval.end)) {
                    tmpInterval.start = userEndTime;
                }

                if(userStartTime.isAfter(projectInterval.start) && userEndTime.isSameOrAfter(projectInterval.end)) {
                    tmpInterval.end = userStartTime;
                }

                if(userStartTime.isAfter(projectInterval.start) && userEndTime.isBefore(projectInterval.end)) {
                    const firstInterval = { allCovered: false, start: tmpInterval.start, end: userStartTime };
                    const secondInterval = { allCovered: false, start: userEndTime, end: tmpInterval.end };

                    const index = tmpProjectTimeIntervals.indexOf(tmpInterval);
                    tmpProjectTimeIntervals.splice(index, 1);
                    tmpProjectTimeIntervals.push(firstInterval);
                    tmpProjectTimeIntervals.push(secondInterval);
                }
            }
        }));

        return tmpProjectTimeIntervals;
    }

    getUnCoveredHoursForDay = (event, day) => {
        const atendeesIds = event.extendedProps.attendees.map(x => x.userId);
        const startTime = day.isSame(event.start, 'day') ? moment(moment(event.start).format("YYYY-MM-DD h:mm a")) : day.startOf('day');
        const endTime = day.isSame(event.end, 'day') ? moment(moment(event.end).format("YYYY-MM-DD h:mm a")) : moment(day.format("YYYY-MM-DD") + " 24");

        let projectTimeIntervals = [{ allCovered: false, start: startTime, end: endTime }];

        if ( this.state.users && this.state.users.length > 0 ) {
            const userSchedules = this.state.users
                .filter(u => atendeesIds.includes(u.id) && u.workingSchedule.some(x => x.day == day.format('dddd')))
                .map(u => u.workingSchedule.find(x => x.day == day.format('dddd')));

            userSchedules.forEach((userSchedule) => {
                projectTimeIntervals = this.coveredHoursScheduleCalculate(userSchedule, projectTimeIntervals, day);
            });
        }

        const unCoveredHours = projectTimeIntervals.filter(x => !x.allCovered)
            .map(x => moment.duration(x.end.diff(x.start)).asHours())
            .reduce((a,b) => a + b, 0);

        return  unCoveredHours;
    }

    getCoveredHours = (event) => {
        let uncoveredEventHours = 0;

        for (let m = moment(event.start); m.isSameOrBefore(moment(event.end)); m.add(1, 'days')) {
            uncoveredEventHours += this.getUnCoveredHoursForDay(event, m);
        }

        const coveredHours = moment.duration(moment(event.end).diff(moment(event.start))).asHours() - uncoveredEventHours;
        return coveredHours < 0 ? 0 : coveredHours;
    }

    getEventColor = (event) => {
        const { extendedProps } = event;
        const date = moment(event.start);
        const availableEmployeeHours = this.state.availableEmployeeHours[date.format('dddd')];
        const coveredHours = this.getCoveredHours(event);
    
        let eventColor = null;

        if ( extendedProps.projectStatus === 'Closed' ) {
          eventColor = eventStatusColors['closed'];
        } else if ( extendedProps.projectStatus === 'On Hold' ) {    
          eventColor = eventStatusColors['hold'];
        } else if ( extendedProps.durationHours > availableEmployeeHours ) {
          eventColor = eventStatusColors['attention'];
        } else if(userGlobal.company.fishbowlIntegrated && !['Committed', 'Finished'].includes(extendedProps.pickStatus)) {
            eventColor = eventColors.red;
        } else if ( extendedProps.attendees.length === 0 ) {
            eventColor = eventStatusColors['unassigned'];
        } else if ( extendedProps.durationHours > coveredHours ) {
            eventColor = eventStatusColors['attention'];
        } else if ( extendedProps.attendeesBudget < extendedProps.durationHours ) {
          eventColor = eventStatusColors['attention'];
        } else {
            eventColor = eventStatusColors['ready'];
        }
    
        return eventColor;
    }

    toggle(tab) {
        let path = '/schedule/' + tab;
        this.props.history.push(path);
        this.setState({
            activeTab: tab,
        });
    }

    openEventModal = (selectedEvent) => {
        this.setState({selectedEvent});
        this.setState({showEventModal: true});
    }

    openEventModalById = (eventId) => {
        const event = this.state.events.find(e => e.id === eventId);
        this.openEventModal(event);        
    }

    toggleEventModal = () => {
        this.setState({ showEventModal: !this.state.showEventModal });        
        setTimeout(() => {
          this.projectCalendar.current.renderCalendar();
        }, 1);
    }

    updateEvent = (event) => {
        const { events } = this.state;
        const { extendedProps } = event;
    
        if (extendedProps.attendees.length) {
          const newDuration = extendedProps.duration / extendedProps.attendees.length;
    
          const newEndDate = moment(event.start)
            .add(newDuration, 's')
            .toISOString();
    
          if (newEndDate !== event.end) {
            fetch(Util.apiUrl('calendar_event'), {
              credentials: 'include',
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                id: event.id,
                endDate: newEndDate,
              }),
            });
            event.end = newEndDate;
          }
        }
    
        event.color = this.getEventColor(event);
    
        this.setState({
          events: events.map(el => (el.id === event.id ? { ...event } : el)),
        });
    
        this.setState({selectedEvent: {...event}});
      }
    
      detachUser = (id) => {
        fetch(Util.apiUrl('calendar_event/userTenantCalendarEvent/' + id), {
          credentials: 'include',
          method: 'DELETE',
        });
      }
    
      createEvent = async (startDate, duration, projectId) => {
        const event = this.state.events.find(x => x.extendedProps.projectId == projectId);

        startDate = moment(startDate).toISOString();
        const endDate = moment(startDate)
          .add(duration, 's')
          .toISOString();
    
        const response = await fetch(Util.apiUrl('calendar_event'), {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startDate,
            endDate,
            duration,
            projectId,
          }),
        });
    
        const eventResponse = await ServerResponseHandler.getResponse(response);
    
        const durationHours = Math.ceil(eventResponse.duration / 60 / 60);   
        
        const newEvent = {
          id: eventResponse.id,
          title: event ? event.title : "",
          start: eventResponse.startDate,
          end: eventResponse.endDate,      
          extendedProps: {
            attendees: [],
            duration: eventResponse.duration,
            durationHours,
            attendeesBudget: 0,
            projectId: eventResponse.projectId
          }
        };
    
        newEvent.color = this.getEventColor(newEvent);
    
        const events = [...this.state.events];
        events.push(newEvent);    
        this.setState({ events });
      }
    
      deleteEvent = async (eventId) => {
        await fetch(Util.apiUrl('calendar_event') + '/' + eventId, {
          credentials: 'include',
          method: 'DELETE',
        });
    
        const events = [...this.state.events];
        const index = events.findIndex(e => e.id === eventId);
    
        if ( index > -1 ) {
          events.splice(index, 1);
        }
    
        this.setState({events});
      }

    updateEventDates = (ev) => {
        const event = {...ev};
        const { id, start, end, extendedProps } = event;
        const { events } = this.state;    
            
        fetch(Util.apiUrl('calendar_event'), {
          credentials: "include",
          method: "PUT",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            id, 
            startDate: start, 
            endDate: end,
            duration: extendedProps.duration
          })
        });    
    
        event.color = this.getEventColor(event);
        
        this.setState({
          events: events.map(el => (el.id === event.id ? { ...event } : el))
        });        
    }

    addUserToEvent = async (userId, eventId, skipConfirmation) => {
        const attendee = this.state.users.find(u => u.id === userId);
        const event = {...this.state.events.find(s => s.id === eventId)};
    
        if (event.extendedProps.attendees.find(a => a.userId === attendee.id) !== undefined) {
          toast.error('Employee already in schedule', { position: 'bottom-right' });
          return;
        }
    
        const scheduleDayOfWeek = moment(event.start).format('dddd');
        const workingScheduleObj = attendee.workingSchedule.find(
          a => a.day === scheduleDayOfWeek
        );
    
        if (workingScheduleObj === undefined) {
          toast.error("Employee doesn't work on selected day", {
            position: 'bottom-right',
          });
          return;
        }

        if(!skipConfirmation) {
            const startTimeString = moment(event.start).format("MM-DD-YYYY") + " " + workingScheduleObj.startTime;
            const startTimeDate = moment(startTimeString, 'MM-DD-YYYY hh:mm A');
            const endTimeString = moment(event.end).format("MM-DD-YYYY") + " " + workingScheduleObj.endTime;
            const endTimeDate = moment(endTimeString, 'MM-DD-YYYY hh:mm A');

            if((moment(event.start).isSame(moment(event.end), 'day') &&
                (startTimeDate.isAfter(moment(event.start)) || endTimeDate.isBefore(moment(event.end))))
                || (!moment(event.start).isSame(moment(event.end)) && startTimeDate.isAfter(moment(event.start)))) {
                    let updatedDeleteDialog = this.state.deleteDialog;
                    updatedDeleteDialog.userId = userId;
                    updatedDeleteDialog.eventId = eventId;

                    this.setState({confirmDeleteDialog: true, deleteDialog: updatedDeleteDialog});
                    return;
            }
        }
    
        // add user to event
        const apiUrl = Util.apiUrl(
          'calendar_event/userTenantCalendarEvent/' + eventId + '/' + attendee.id
        );
    
        const response = await fetch(apiUrl, {
          credentials: 'include',
          method: 'POST',
        });
        const results = await ServerResponseHandler.getResponse(response);
    
        // add user and change event
        event.extendedProps.attendees.push({
          userTenantId: results.id,
          userId: attendee.id,
          name: attendee.nameFirst + ' ' + attendee.nameLast,
          image: attendee.imageUrl ? attendee.imageUrl : defaultAvatarImgUrl,
          budgetTime: workingScheduleObj.workingHours,
        });
        
        event.extendedProps.attendeesBudget += workingScheduleObj.workingHours;

        event.color = this.getEventColor(event);
    
        const { events } = this.state;
    
        this.setState({
          events: events.map(el => (el.id === event.id ? event : el)),
        });
    }    

    render() {

        if ( this.state.loading ) return null;

        return (
            <div>
                <ToastContainer />
                {!this.state.confirmDeleteDialog ? '' :
                    <ConfirmDeleteModal
                        isOpen={this.state.confirmDeleteDialog}
                        deleteDialog={this.state.deleteDialog}
                    />
                }
                <div className="h-100">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={this.state.activeTab === 'projects'}
                                onClick={() => { this.toggle('projects'); }}
                            >
                                Projects
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={this.state.activeTab === 'timeline'}
                                onClick={() => { this.toggle('timeline'); }}
                            >
                                Timeline
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} style={{height: 'calc(100% - 38px)'}}>
                        <TabPane tabId="projects">
                            {this.state.activeTab === 'projects' &&                        
                                <ProjectSchedule 
                                    ref={this.projectCalendar}
                                    openEventModal={this.openEventModal}
                                    users={this.state.users}
                                    events={this.state.events}
                                    availableEmployeeHours={this.state.availableEmployeeHours}
                                    addUserToEvent={this.addUserToEvent}
                                    hoursOfOperation={this.state.hoursOfOperation}
                                />
                            }
                        </TabPane>
                        <TabPane tabId="timeline">
                            {this.state.activeTab === 'timeline' &&
                                <ProjectScheduleTimeline
                                    openEventModalById={this.openEventModalById}
                                    events={this.state.events}
                                    users={this.state.users}
                                />
                            }                        
                        </TabPane>
                    </TabContent>                    
                </div>
                <ProjectEventModal
                    isOpen={this.state.showEventModal}
                    toggleEventModal={this.toggleEventModal}
                    event={this.state.selectedEvent}
                    updateEvent={this.updateEvent}
                    detachUser={this.detachUser}
                    createEvent={this.createEvent}
                    deleteEvent={this.deleteEvent}
                    updateEventDates={this.updateEventDates}
                    events={this.state.events}
                    users={this.state.users}
                />
            </div>
        )
    }

}

export default Schedule;