import React from 'react';
import {Input, Label} from "reactstrap";

const CSVExportFilters = (props) => {
    return (
        <div>
            {props.fieldsConfiguration.map((item, index) => (
                <div key={"field-config-container-" + index} className='mb-1'>
                    <Label>{item.label}</Label>
                    <Label className='switch switch-icon switch-pill switch-info pull-right'
                        title='Show notes'>
                        <Input type='checkbox' name='showNotes'
                        className='switch-input'
                        disabled={item.required}
                        checked={item.active}
                        onChange={(event) => props.handleFieldChange(event, item.key)}/>
                            <span className='switch-label' data-on={'\uf00c'} data-off={'\uf00d'}/>
                            <span className='switch-handle'/>
                     </Label>
                </div>
            ))}
        </div>
    );
}

export default CSVExportFilters;
