import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import * as Util from "../Framework/Util";
import NoData from "./NoData";

class ProjectsTimeLogTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }


    createTable() {
        const customerSettings = Util.getCompanySettings();
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>

            {
                !self.props.data || self.props.data.length < 1 ?
                    <NoData/> :
                    self.props.data.map((date, index) => {
                        return <div key={index}>
                            {
                                index === 0 ? <div className="margin-top"></div> :
                                    <div className="dropdown-divider"></div>
                            }
                            {
                                customerSettings.includeOvertimeInProjectCost ?
                                    <Row className={'row-date col-lg-12'}>
                                        <Col>{date.name}</Col>
                                        <Col></Col>
                                        <Col></Col>
                                        <Col>{Util.convertTimeToHours(date.totalTime)}</Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                    :
                                    <Row className={'row-date col-lg-12'}>
                                        <Col>{date.name}</Col>
                                        <Col></Col>
                                        <Col></Col>
                                        <Col>{Util.convertTimeToHours(date.totalTime)}</Col>
                                        <Col></Col>
                                    </Row>
                            }
                            <div className="data">
                                {
                                    date.timeEvents.map((row, index) => {
                                        const overtimeHours = row.durationForProjects.dailyOvertimeDuration + row.durationForProjects.dailyDoubleOvertimeDuration;
                                        return <div
                                            className={self.props.tableSettings.canEdit ? 'data-row-edit' : 'data-row'}
                                            key={index}>
                                            {
                                                <Row className={"row-header col-lg-12"}>
                                                    <Col><span>{row.employeeName}</span></Col>
                                                    <Col><span>{row.clockInTime}</span></Col>
                                                    {
                                                        row.csvClockOutTime !== "Working" ?
                                                            <Col><span
                                                                 >{row.clockOutTime ? row.clockOutTime : ''}</span></Col>
                                                            : <Col><h5><span
                                                                className="badge badge-pill badge-success">Working</span>
                                                            </h5></Col>
                                                    }
                                                    {
                                                        row.csvClockOutTime !== "Working" ? <Col><span
                                                                 >{Util.convertTimeToHours(customerSettings.breaksInProjects ? row.durationForProjects.totalDuration : (customerSettings.roundToNearest !== 0 ? row.roundedDuration : row.duration) ? (customerSettings.roundToNearest !== 0 ? row.roundedDuration : row.duration) : 0)}</span></Col>
                                                            : <Col><span  >In Progress</span></Col>
                                                    }
                                                    {
                                                        customerSettings.includeOvertimeInProjectCost ? (
                                                            row.csvClockOutTime !== "Working" ? <Col><span
                                                                >{Util.convertTimeToHours(overtimeHours)}</span></Col>
                                                                : <Col><span>In Progress</span></Col>
                                                        ) : ""
                                                    }
                                                    {
                                                        <Col><span
                                                        >{Util.convertTimeToHours((customerSettings.roundToNearest !== 0 ? row.breakRoundedDuration + row.lunchBreakRoundedTime : row.breakTime + row.lunchBreakTime) ? (customerSettings.roundToNearest !== 0 ? row.breakRoundedDuration + row.lunchBreakRoundedTime : row.breakTime + row.lunchBreakTime) : 0)}</span></Col>
                                                    }
                                                    <Col><span  >{Util.formatCurrency(row && row.totalCost ? row.totalCost : 0, true)}</span></Col>

                                                </Row>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default ProjectsTimeLogTable;
