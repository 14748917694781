import React, {Component} from 'react';
import {Button, Col, Container, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, CardHeader, Card, CardBody} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import {SearchBar} from "../../../components/Framework/SearchBar";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import '../../../../scss/vendors/react-select/react-select.scss';
import TerminalsTable from "../../../components/DataTables/TerminalsTable";
import config from "react-global-configuration";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || Util.getCompanySettings().allowEditTime;
const canDelete = isAdminOrManager;

class Terminals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            terminalList: [],
            row: null,
            loading: false,
            page: 1,
            sizePerPage: 20,
            newDialog: false,
            deleting: false,
            id: '',
            name: '',
            loginType: 'Full Login',
            restrictionType: 'None',
            ipRangeFrom: '',
            ipRangeTo: '',
            requireShiftNote: false,
            active: true,
            default: false,
            terminalUrl: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleRestrictionChange = this.handleRestrictionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.saveData = this.saveData.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.toggleLogoutWarningModal = this.toggleLogoutWarningModal.bind(this);
        this.getTableColumns = this.getTableColumns.bind(this);
        this.getNumberOfHeaderColumns = this.getNumberOfHeaderColumns.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.setTerminalData = this.setTerminalData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    getTableColumns() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Type', hideColumn: false},
            {displayName: 'Restrictions', hideColumn: false},
            {displayName: 'Active', hideColumn: false},
            {displayName: 'Default', hideColumn: false},
            {displayName: 'Terminal Link', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}
        ];
    }

    setTerminalData(data) {
        if(data) {
            const companyId = Util.getUserGlobal().company.id;
            data.forEach((terminal) => {
                terminal.companyId = companyId;
            });
            this.setState({terminalList: data});
        }
    }

    getNumberOfHeaderColumns() {
        let tableColumns = this.getTableColumns();
        let columnCount = 0;
        tableColumns.map((column) => {
            if (column.hideColumn) {
                columnCount += 1;
            }
        });
        return columnCount;
    }

    toggleNewModal() {
        this.setState({
            newDialog: !this.state.newDialog,
            terminalId: null,
            name: '',
            loginType: 'Full Login',
            restrictionType: 'None',
            ipRangeFrom: '',
            ipRangeTo: '',
            requireShiftNote: false,
            active: true,
            default: false,
        });
    }

    toggleEditModal(event, row) {
        if (event.target.classList.contains('delete')) {
            return false;
        }
        this.setState({
            newDialog: !this.state.newDialog,
            terminalId: row.id,
            name: row.name,
            loginType: row.loginType,
            restrictionType: row.restrictionType,
            ipRangeFrom: row.ipRangeFrom,
            ipRangeTo: row.ipRangeTo,
            requireShiftNote: row.requireShiftNote,
            active: row.active,
            default: row.default,
        });
    }

    toggleLogoutWarningModal(event, row) {
        this.setState({
            row: row,
            terminalUrl: row ? '/terminal/' + row.id + '/' + row.companyId : this.state.terminalUrl,
            logoutWarningDialog: !this.state.logoutWarningDialog,
        });
    }

    toggleConfirmDeleteModal(row) {
        this.setState({
            row: row,
            confirmDeleteDialog: !this.state.confirmDeleteDialog,
        });
    }

    handleChange(e) {
        if (e.target !== undefined) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    handleCheckBoxChange(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleRestrictionChange(e) {
        this.handleChange(e);
        if (e.target.value !== "IP Range") {
            this.setState({
                restrictionType: e.target.value,
                ipRangeFrom: '',
                ipRangeTo: '',
            });
        }
    }

    showFormErrors() {
        let inputs = document.getElementById('terminal-form').querySelectorAll('input');
        let isFormValid = true;

        inputs.forEach(input => {
            input.classList.add('active');

            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        });

        inputs = document.getElementById('terminal-form').querySelectorAll('select');
        inputs.forEach(input => {
            input.classList.add('active');

            const isInputValid = Util.showInputError(this, input.name);

            if (!isInputValid) {
                isFormValid = false;
            }
        });

        return isFormValid;
    }

    handleSubmit() {
        if (!this.showFormErrors()) {
            return;
        }

        this.setState({
            newDialog: !this.state.newDialog,
        });

        let payload = {
            "name": this.state.name,
            "description": this.state.description,
            "loginType": this.state.loginType,
            "restrictionType": this.state.restrictionType,
            "ipRangeFrom": this.state.ipRangeFrom,
            "ipRangeTo": this.state.ipRangeTo,
            "requireShiftNote": this.state.requireShiftNote,
            "active": this.state.active,
            "default": this.state.default,
        };

        if (Util.isEmpty(this.state.terminalId)) {
            this.saveData('POST', payload);
        } else {
            this.saveData('PATCH', payload, this.state.terminalId);
        }
    }

    saveData(method, terminal, terminalId = '') {
        const self = this;
        const apiUrl = Util.apiUrl(`terminals/${terminalId}`);
        fetch(apiUrl, {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(terminal),
        }).then(response => {
            return ServerResponseHandler.getResponse(response);
        }).then(function (json) {
            if (json.errorMessage === undefined) {
                toast.success('Terminal was saved', {position: 'bottom-right'});
                self.loadData();
            } else {
                toast.error(json.errorMessage, {position: 'bottom-right'});
            }
        }).catch(error => {
            return ServerResponseHandler.handleError(error);
        });
    }

    logout() {
        this.setState({loading: true});
        localStorage.clear();

        fetch(config.get('apiBaseUrl') + 'resources/logout', {
            method: 'GET',
            credentials: 'include',
        }).then(response => {
            console.debug(response);
            window.location.href = "/login";
        }).catch(error => {
            console.log(error);
        });
        this.setState({loading: false, logoutWarningDialog: false});
    }

    deleteTerminal() {
        this.setState({loading: true});
        const terminalToDelete =  this.state.row;
        terminalToDelete.deletedOn = moment();
        const apiUrl = Util.apiUrl(`terminals/${terminalToDelete.id}`);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify(terminalToDelete),
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Terminal was successfully deleted', {position: 'bottom-right'});
                this.loadData(this.state.searchParams);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, confirmDeleteDialog: false}));
    }

    loadData(search = null) {
        const self = this;
        this.setState({loading: true});
        const apiUrl = Util.apiSearchUrl(`terminals`, search);
        fetch(apiUrl, {
            credentials: 'include',
        }).then(response =>{
            return ServerResponseHandler.getResponse(response);
        }).then(function (json) {
            if(json && json.resultList) {
                self.setTerminalData(json.resultList);
            }
        }).catch(error => {
            return ServerResponseHandler.handleError(error);
        }).finally(() => {
            self.setState({loading: false})
        });
    }

    setSearchParams(search) {
        this.setState({searchParams: search});
    }

    render() {
        const csvProperties = {
            csvHeader: [
                {label: 'Name', key: 'name'},
                {label: 'Type', key: 'loginType'},
                {label: 'Restrictions', key: 'restrictionType'},
                {label: 'IP Address From', key: 'ipAddressFrom'},
                {label: 'IP Address To', key: 'ipAddressTo'},
                {label: 'Require Shift Note', key: 'requireShiftNote'},
                {label: 'Active', key: 'active'},
                {label: 'Default', key: 'default'},
            ],
            data: this.state.terminalList,
            csvFilename: 'terminals.csv',
            loading: this.state.loading
        };

        const buttons = {
            add: this.toggleNewModal,
            savePdf: false,
            download: csvProperties,
            loading: this.state.loading
        };

        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <Modal isOpen={this.state.newDialog}>
                        <ModalHeader>{this.state.terminalId ? 'Edit Terminal' : 'New Terminal'}</ModalHeader>
                        <ModalBody id="terminal-form">
                            <Label>Name</Label>
                            <InputGroup className="mb-3">
                                <input type="text" placeholder="Name" name="name" ref="name" value={this.state.name}
                                       className="form-control" onChange={this.handleChange} required/>
                            </InputGroup>

                            <Label>Login Type</Label>
                            <InputGroup className="mb-3">
                                <Input type="select" placeholder="Login Type" name="loginType" ref="loginType"
                                       id="loginType"
                                       className="form-control" onChange={this.handleChange}
                                       defaultValue={this.state.loginType}>
                                    <option>Full Login</option>
                                    <option>Drop Down</option>
                                    <option>Drop Down with Pin</option>
                                </Input>
                            </InputGroup>

                            <Label>Restrictions</Label>
                            <InputGroup className="mb-3">
                                <Input type="select" placeholder="Restrictions" name="restrictionType"
                                       ref="restrictionType" id="restrictionType"
                                       className="form-control" onChange={this.handleRestrictionChange}
                                       defaultValue={this.state.restrictionType}>
                                    <option>None</option>
                                    <option>IP Range</option>
                                    <option>Devices</option>
                                </Input>
                            </InputGroup>
                            <div className="errorDiv" id="restrictionsError"/>
                            {
                                this.state.restrictionType !== 'IP Range' ? '' :
                                <InputGroup className="mb-3" id="restrictions">
                                    <Col md="4">
                                        <input type="text" placeholder="192.168.0.1" name="ipRangeFrom" ref="ipRangeFrom"
                                               value={this.state.ipRangeFrom}
                                               className="form-control" onChange={this.handleChange}/>
                                    </Col>
                                    <Col md="1">
                                        -
                                    </Col>
                                    <Col md="4">
                                        <input type="text" placeholder="192.168.0.255" name="ipRangeTo" ref="ipRangeTo"
                                               value={this.state.ipRangeTo}
                                               className="form-control" onChange={this.handleChange}/>
                                    </Col>
                                </InputGroup>
                            }
                            <div className="mb-3">
                                <Label>Require Shift Note</Label>
                                <Label className="switch switch-icon switch-pill switch-info pull-right">
                                    <Input type="checkbox" name="requireShiftNote" ref="requireShiftNote"
                                           className="switch-input"
                                           checked={this.state.requireShiftNote} onChange={this.handleCheckBoxChange}/>
                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                    <span className="switch-handle"/>
                                </Label>
                            </div>
                            <div className="mb-3">
                                <Label>Active</Label>
                                <Label className="switch switch-icon switch-pill switch-info pull-right">
                                    <Input type="checkbox" name="active" ref="active" className="switch-input"
                                           checked={this.state.active} onChange={this.handleCheckBoxChange}/>
                                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                    <span className="switch-handle"/>
                                </Label>
                            </div>
                            {/*<div className="mb-3">*/}
                                {/*<Label>Default</Label>*/}
                                {/*<Label className="switch switch-icon switch-pill switch-info pull-right">*/}
                                    {/*<Input type="checkbox" name="default" ref="default" className="switch-input"*/}
                                           {/*checked={this.state.default} onChange={this.handleCheckBoxChange}/>*/}
                                    {/*<span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>*/}
                                    {/*<span className="switch-handle"/>*/}
                                {/*</Label>*/}
                            {/*</div>*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => this.handleSubmit()}>{this.state.terminalId ? 'Save' : 'Create'}</Button>{' '}
                            <Button color="secondary" onClick={() => this.toggleNewModal()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.logoutWarningDialog}>
                        <ModalHeader>Logout Warning</ModalHeader>
                        <ModalBody>
                            It is recommended you log out of the admin account while the terminal is open, as it remains accessible to terminal users on this browser.
                        </ModalBody>
                        <ModalFooter>
                            <a target="_blank" rel="noreferrer" href={this.state.terminalUrl}>
                                <Button color="success" onClick={() => this.logout()}>Logout</Button>{' '}
                            </a>
                            <a target="_blank" rel="noreferrer" href={this.state.terminalUrl}>
                                <Button color="secondary" onClick={() => this.toggleLogoutWarningModal(null)}>Cancel</Button>
                            </a>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.confirmDeleteDialog}>
                        <ModalHeader>Delete Terminal</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete Terminal?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => this.deleteTerminal()}>Delete</Button>{' '}
                            <Button color="secondary"
                                    onClick={() => this.toggleConfirmDeleteModal(null)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <Card className="col-lg-12 card-accent-primary">
                        <CardHeader>
                            <SearchBar
                                buttons={buttons}
                                onSearch={this.loadData}
                                includeGeneral={true}
                                includeActiveFlag={true}
                                setSearchParams={this.setSearchParams}
                            />
                        </CardHeader>
                        <CardBody>
                            <TerminalsTable
                                data={this.state.terminalList}
                                getTableColumns={this.getTableColumns()}
                                toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                toggleLogoutWarningModal={this.toggleLogoutWarningModal}
                                toggleEditModal={this.toggleEditModal}
                                tableSettings={{
                                    numberOfHeaderColumns: this.getNumberOfHeaderColumns(),
                                    canDelete: canDelete,
                                    canEdit: canEdit
                                }}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Terminals;