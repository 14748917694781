import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, Container, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from "reactstrap";
import * as Util from "../../../components/Framework/Util";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import NotificationRuleTable from "../../../components/DataTables/NotificationRuleTable";
import {ToastContainer, toast} from 'react-toastify';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedNotificationRule: {
                id: 0,
                name: '',
                active: false,
                emails: '',
                phones: '',
                time: 0,
            },
            notificationDialogOpen: false,
        };

        this.loadNotificationRules = this.loadNotificationRules.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.saveData = this.saveData.bind(this);

        this.loadNotificationRules(true);
    }

    loadNotificationRules(initial = false) {
        if(initial) this.state.loading = true;
        else this.setState({loading: true});

        const apiUrl = Util.apiUrl(`notification_rule`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                    this.setState({notificationRuleList: json.resultList});
                }
            )
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false})
            });
    }

    openEditModal(row) {
        let selected = this.state.notificationRuleList.find(x => x.id == row.id);
        if (!selected)
            return;

        this.setState({
            selectedNotificationRule: {
                id: selected.id,
                name: selected.name,
                active: selected.active,
                emails: selected.emails,
                phones: selected.phones,
                time: selected.time
            }, notificationDialogOpen: true
        });
    }

    handleChange(e) {
        let selected = {...this.state.selectedNotificationRule};
        if (e.target.name === 'emails') {
            selected.emails = e.target.value;
        } else if (e.target.name === 'phones') {
            selected.phones = e.target.value;
        } else {
            selected.active = e.target.checked;
        }
        this.setState({selectedNotificationRule: selected});
    }

    toggleNotificationModal() {
        this.setState({notificationDialogOpen: !this.state.notificationDialogOpen});
    }

    saveData(event) {
        event.preventDefault();
        let notification = this.state.selectedNotificationRule;

        if (notification.active && !notification.emails && !notification.phones) {
            toast.error('Email or phone is required if active', {position: 'bottom-right'});
            return;
        }

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`notification_rule/${notification.id}`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(notification),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                this.toggleNotificationModal();
                toast.success('Notification rule was saved', {position: 'bottom-right'});
                this.loadNotificationRules();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    render() {
        const settings = this.state;
        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card className="card-accent-primary">
                                <CardHeader>
                                    Notifications
                                </CardHeader>
                                <CardBody>
                                    <NotificationRuleTable
                                        data={settings.notificationRuleList}
                                        openEditModal={this.openEditModal}
                                    />
                                </CardBody>
                            </Card>
                            <Modal isOpen={this.state.notificationDialogOpen}
                                   className="modal-md">{/*================ New Holiday Modal ================*/}
                                <ModalHeader>{this.state.selectedNotificationRule.name}</ModalHeader>
                                <ModalBody>
                                    <div className="mb-3">
                                        <Label>Active</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right">
                                            <Input type="checkbox" name="active" ref="pto"
                                                   className="switch-input"
                                                   checked={this.state.selectedNotificationRule.active}
                                                   onChange={(e) => {
                                                       this.handleChange(e)
                                                   }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Emails</Label>
                                        <InputGroup title="Up to 5 emails, separated by commas">
                                            <input type="text" placeholder="Emails" name="emails"
                                                   value={this.state.selectedNotificationRule.emails}
                                                   className="form-control"
                                                   onChange={(e) => {
                                                       this.handleChange(e)
                                                   }}/>
                                        </InputGroup>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Phones</Label>
                                        <InputGroup title="Up to 5 phones, separated by commas">
                                            <input type="text" placeholder="Phones" name="phones"
                                                   value={this.state.selectedNotificationRule.phones}
                                                   className="form-control"
                                                   onChange={(e) => {
                                                       this.handleChange(e)
                                                   }}/>
                                        </InputGroup>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="success" onClick={(e) => this.saveData(e)}>Save</Button>{' '}
                                    <Button color="secondary"
                                            onClick={() => this.toggleNotificationModal()}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Notifications;