import React from 'react';
import {Row, Col} from 'reactstrap';
import * as Util from "../../../Framework/Util";
import {getCompanySettings} from "../../../Framework/Util";


function ProjectDetailsGrandTotals(props) {
    return (<div className={'mt-3 p-3'}>
            <div className={'mb-2'}><h4 className={'text-center'}>{getCompanySettings().projectSingleName} Grand Totals</h4></div>
            <Row className={'row-header col-header col-lg-12'}>
                {
                    props.tableHeaders.map((column, index) => {
                        return column.showColumn ? <Col key={index} className={column.employeeName === 'Employee Name' ? props.class : ''}>{column.header}</Col> : ''
                    })
                }
            </Row>
        {props.totals ?
            <Row className={'row-header col-lg-12'}>
                <Col>{Util.formatTimeFromSeconds(props.totals.totalDuration)}</Col>
                {props.showColumn ? <Col>{Util.formatCurrency(props.totals.totalCost.toFixed(2))}</Col> : ''}
            </Row> : '' }
        </div>
    )
}

export default ProjectDetailsGrandTotals;
