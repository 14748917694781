import React from 'react';
import {Row, Col} from 'reactstrap';
import * as Util from "../../../Framework/Util";

function EmployeeTimeCardsPdfData(props) {
const flagShiftsExceeding = Util.getCompanySettings().flagShiftsExceeding;
    console.log(props);

    return (
        <div>
            {
                props.pdfData.map((column, index) => {

                    return (
                        <React.Fragment key={index}>
                            <Row key={index} className={column.note ? 'text-center row-header' : 'mb-2 text-center row-header'}>
                                <Col>{column.date}</Col>
                                <Col>{column.clockIn}</Col>
                                <Col>{column.clockOut}</Col>
                                <Col>{column.break}</Col>
                                <Col><span className={flagShiftsExceeding && flagShiftsExceeding < column.totalInSeconds ? 'alert' : ''}>{column.total}</span></Col>
                                <Col>{column.position}</Col>
                                {props.showProjects ? <Col>{column.project}</Col> : ''}
                            </Row>
                            {column.note && props.showNotes &&
                            <Row key={index} className={'mb-2 text-center row-header'}>
                                <Col className="col-2">Note:</Col>
                                <Col className="col-10" style={{ whiteSpace: 'pre-wrap', textAlign: 'left', fontStyle: 'italic', color: 'dimgrey', padding: 0 }}>{column.note}</Col>
                            </Row>}
                        </React.Fragment>
                    );
                })
            }
        </div>
    )
}

export default EmployeeTimeCardsPdfData;
