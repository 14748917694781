import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Button,
    Label, Input
} from "reactstrap";
import BillingHistoryTable from "../../../components/DataTables/BillingHistoryTable";
import CheckoutForm from "../../../components/Stripe/CheckoutForm";
import {Elements, StripeProvider} from 'react-stripe-elements';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import CreditCard from "../../../components/CreditCard/CreditCard";
import NextInvoiceTable from "../../../components/DataTables/NextInvoiceTable";

let getToken;
const stripeKey = localStorage.getItem("stripeKey");

class Billing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            charges: [],
            loading: false,
            card: {
                name: "",
                address_city: "",
                address_line1: "",
                address_state: "",
                last4: "",
                ex_year: "",
                ex_month: ""
            },
            currentSubscription: {
                quantity: 5
            },
            newUserCount: 5,
            legacyStripe: false,
            expired: localStorage.accountExpired == true,
            nextInvoiceData: null,
            activeUsersCount: null
        };

        this.toggleDialog = this.toggleDialog.bind(this);
        this.setSubmit = this.setSubmit.bind(this);
        this.stripeSubmit = this.stripeSubmit.bind(this);
        this.getData = this.getData.bind(this);
        this.setSubscriptionData = this.setSubscriptionData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateSubscription = this.updateSubscription.bind(this);
        this.getTableColumns = this.getTableColumns.bind(this);
        this.setHistoryData = this.setHistoryData.bind(this);
        this.setNextInvoice = this.setNextInvoice.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.getUserOptions = this.getUserOptions.bind(this);
        this.loadActiveUsersCount = this.loadActiveUsersCount.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);
    }

    componentDidMount() {
        this.getData();
        this.loadActiveUsersCount();
    }

    setSubmit(get) {
        getToken = get;
    }

    toggleDialog(dialogName) {
        let routeToBillingInfo = false;
        if (dialogName === 'editSubscriptionDialog' && this.state.card.last4 === '') {
            routeToBillingInfo = true;
        }
        let stateObj = {routeToBillingInfo: routeToBillingInfo};
        stateObj[dialogName] = !this.state[dialogName];
        this.setState(stateObj);
    }

    stripeSubmit(ev) {
        if(this.state.routeToBillingInfo) {
            this.setState({routeToBillingInfo: false});
        }
        getToken(ev);
        this.toggleDialog('updateBillingDialog');
    }

    getUserOptions() {
        let userOptions = [];

        const startFrom = this.state.activeUsersCount > 5 ? this.state.activeUsersCount : 5;

        let userCountStart = startFrom - 1;
        for (let i = userCountStart; i < 1000; i++) {
            userOptions.push(i + 1);
        }

        return userOptions;
    }

    setSubscriptionData(customer) {
        if (customer.subscriptions && customer.subscriptions.data.length > 0) {
            const currentSubscription = customer.subscriptions.data[0];
            currentSubscription.nextPaymentDate = Util.basicDateFormatter(moment(new Date(currentSubscription.current_period_end * 1000)));
            if(this.state.legacyStripe){
                currentSubscription.cost = 20 + (currentSubscription.quantity - 5) * 2;
            }
            else{
                currentSubscription.cost = this.calculatePrice(currentSubscription.quantity);
            }
            this.setState({currentSubscription: currentSubscription, newUserCount: currentSubscription.quantity});
        }
    }

    setHistoryData(data) {
        const historyData = data ? data : [];
        historyData.map((transaction) => {
            transaction.createdDate = Util.basicDateFormatter(moment(new Date(transaction.created * 1000)));
        });
        this.setState({charges: historyData});
    }

    formatDate(start, end) {
        if (moment(start).isSame(moment(end), 'year')) {
            return moment(start * 1000).format('MMM DD') + ' - ' + moment(end * 1000).format('MMM DD, YYYY');
        } else {
            return moment(start * 1000).format('MMM DD, YYYY') + ' - ' + moment(end * 1000).format('MMM DD, YYYY');

        }
    }

    setNextInvoice(nextInvoice) {
        let data = [];
        let dates = {};
        let firstStartDate = 9999999999;
        let lastEndDate = {end: 0, start: 0};
        if(nextInvoice.lines && nextInvoice.lines.data) {
            nextInvoice.lines.data.map((line) => {
                lastEndDate = line.period.end > lastEndDate.end ? {
                    end: line.period.end,
                    start: line.period.start
                } : lastEndDate;
                firstStartDate = line.period.start < firstStartDate ? line.period.start : firstStartDate;
                let endDate = line.period.end;
                if (dates[endDate]) {
                    dates[endDate].push(line);
                } else {
                    dates[endDate] = [line];
                }
            });
            for (let date in dates) {
                let line = {
                    name: parseInt(date) === lastEndDate.end ? this.formatDate(lastEndDate.start, lastEndDate.end) : this.formatDate(firstStartDate, parseInt(date)),
                    invoiceEvents: dates[date]
                };
                data.push(line);
            }
            this.setState({nextInvoiceData: data, nextInvoice: nextInvoice});
        }
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    getTableColumns(table) {
        let columns = [];
        if (table === 'history') {
            columns = [
                {displayName: 'Date', hideColumn: false},
                {displayName: 'Amount', hideColumn: false},
                {displayName: 'Card#', hideColumn: false}
            ];
        } else if (table === 'nextInvoice') {
            columns = [
                {displayName: 'Description', hideColumn: false, width: 10},
                {displayName: 'Amount', hideColumn: false, width: 2}
            ];
        }
        return columns;
    }

    cancelSubscription() {
        const apiUrl = Util.apiUrl(`billing/cancel`);
        const params = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                this.getData();
                this.setState({
                    cancelConfirmDialog: false
                });
                if (!this.state.routeToBillingInfo) {
                    toast.success('Subscription updated', {position: 'bottom-right'});
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    loadActiveUsersCount() {
        fetch(Util.apiUrl(`users/active_count`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({activeUsersCount: json});
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    calculatePrice(newUserCount) {
        let result = 20 + (newUserCount - 5) * 2;
        if(newUserCount <= 5){
            result = 20;
        }
        else if(newUserCount > 5 && newUserCount < 21){
            result = 10 + 2 * newUserCount;
        }
        else if(newUserCount > 20 && newUserCount < 51){
            result = 10 + 1.25 * newUserCount;
        }
        else if(newUserCount > 50 && newUserCount < 101){
            result = 10 + .99 * newUserCount;
        }
        else if(newUserCount > 100){
            result = 10 + .79 * newUserCount;
        }
        return Number(result).toFixed(2);
    }

    updateSubscription(cardToken) {
        if(this.state.routeToBillingInfo) {
            this.setState({updateBillingDialog: true});
            return;
        }
        this.setState({loading: true});
        let payload = {userCount: this.state.newUserCount};
        if (cardToken) {
            payload.token = cardToken.id
        }

        const apiUrl = Util.apiUrl(`billing/subscribe`);
        const params = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                this.setState({
                    updateBillingDialog: this.state.routeToBillingInfo,
                    editSubscriptionDialog: false,
                    routeToBillingInfo: false
                });
                if (!this.state.routeToBillingInfo) {
                    toast.success('Subscription updated', {position: 'bottom-right'});
                    let userGlobal = Util.getUserGlobal();
                    userGlobal.company.onTrial = false;
                    Util.setUserGlobal(userGlobal);
                    localStorage.setItem("accountExpired", "false");
                    window.location.reload(true);
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false});
            });
    }

    getData() {
        const customerUrl = Util.apiUrl(`billing/customer`, null);
        const cardUrl = Util.apiUrl(`billing/card`, null);
        const chargesUrl = Util.apiUrl(`billing/charges`, null);
        const nextInvoiceUrl = Util.apiUrl(`billing/upcoming_invoice`, null);

        let promises = [];
        this.setState({loading: true, editSubscriptionDialog: false});
        promises.push(
            fetch(customerUrl, {method: 'GET', credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    this.setSubscriptionData(JSON.parse(json))
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );
        promises.push(
            fetch(cardUrl, {method: 'GET', credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    if (JSON.parse(json).last4) {
                        this.setState({card: JSON.parse(json)});
                    }
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );
        promises.push(
            fetch(chargesUrl, {method: 'GET', credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    this.setHistoryData(JSON.parse(json).data)
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );
        promises.push(
            fetch(nextInvoiceUrl, {method: 'GET', credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.errorMessage) {
                        this.setState({nextInvoice: null});
                        return;
                    }
                    this.setNextInvoice(JSON.parse(json));
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );
        Promise.all(promises).then(() => this.setState({loading: false}));
        let userGlobal = Util.getUserGlobal();
        this.setState({legacyStripe: userGlobal.company.legacyStripePlan});
    }

    render() {
        function accountExpired() {
            const accountExpired = localStorage.getItem("accountExpired");
            return JSON.parse(accountExpired) === true;
        }

        let subscriptionState = 'Trial';
        if(this.state.card.last4 !== "") {
            if(!this.state.nextInvoice) {
                subscriptionState = 'Cancelled';
            } else if(accountExpired()) {
                subscriptionState = 'Expired';
            } else {
                subscriptionState = 'Active';
            }
        }

        return (
            <div className="animated fadeIn">
            <Modal isOpen={this.state.editSubscriptionDialog}>
            <ModalHeader toggle={() => this.toggleDialog('editSubscriptionDialog')}>Edit
        Subscription</ModalHeader>
        <ModalBody>
        <br/>
        {!this.state.currentSubscription ? '' :
    <Container>
        <Row>
        <Col>
        <Label>Users</Label>
        {this.state.activeUsersCount ?
        <Input type="select" name="newUserCount" id="newUserCount"
        value={this.state.newUserCount} onChange={this.handleChange}>
            {
                this.getUserOptions().map((number, index) => {
                    return <option key={index}>{number}</option>
                })
            }
            </Input> : null }
            </Col>
            <Col>
            {this.state.legacyStripe ?
                <h4 style={{
                    textAlign: 'center',
                    marginTop: '32px'
                }}>{'$' + (20 + (this.state.newUserCount - 5) * 2)}</h4>
                :
                <h4 style={{
                    textAlign: 'center',
                    marginTop: '32px'
                }}>{'$' + (this.calculatePrice(this.state.newUserCount))}</h4>
            }
        </Col>
        </Row>
        </Container>
    }
    </ModalBody>
        <ModalFooter>
        {
            subscriptionState !== 'Active' ? '' :
    <span><a style={{ cursor: 'pointer' }} onClick={() => this.toggleDialog('cancelConfirmDialog')} href={void(0)}>Cancel Subscription</a></span>
    }
    <div style={{layout: 'inline-block'}}></div>
        <Button color="success" style={{ cursor: 'pointer' }} disabled={this.state.loading} onClick={() => this.updateSubscription()}>
        {this.state.loading
            ? <i className="fa fa-spinner fa-spin mr-2"></i>
        : ''}
        Save
        </Button>{' '}
        <Button color="secondary" style={{ cursor: 'pointer' }}
        onClick={() => this.toggleDialog('editSubscriptionDialog')}>Cancel</Button>
        </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.cancelConfirmDialog}>
            <ModalHeader toggle={() => this.toggleDialog('cancelConfirmDialog')}>Cancel
        Subscription</ModalHeader>
        <ModalBody>
        <br/>
        Are you sure you want to cancel your subscription?
    </ModalBody>
        <ModalFooter>
        <Button style={{ cursor: 'pointer' }} color="success" onClick={this.cancelSubscription}>Continue With Cancellation</Button>{' '}
        <Button style={{ cursor: 'pointer' }} color="secondary"
        onClick={() => this.toggleDialog('cancelConfirmDialog')}>Back</Button>
        </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.updateBillingDialog}>
            <form onSubmit={this.stripeSubmit} id="modal-laborItem-form" noValidate>
        <ModalHeader toggle={() => this.toggleDialog('updateBillingDialog')}>
        Update Billing Information
        </ModalHeader>
        <ModalBody>
        <div className="example">
            <StripeProvider apiKey={stripeKey}>
            <Elements>
            <CheckoutForm setSubmit={this.setSubmit} card={this.state.card}
        updateSubscription={this.updateSubscription}/>
        </Elements>
        </StripeProvider>
        </div>
        </ModalBody>
        <ModalFooter>
        <Button color="success" disabled={this.state.loading}>
            {this.state.loading
                    ? <i className="fa fa-spinner fa-spin mr-2"></i>
    : ''}
        Save</Button>{' '}
        <Button color="secondary" style={{ cursor: 'pointer' }}
        onClick={() => this.toggleDialog('updateBillingDialog')}>Cancel</Button>
        </ModalFooter>
        </form>
        </Modal>
        <Modal isOpen={this.state.nextInvoiceDialog} className="modal-md">
            <form id="modal-laborItem-form" noValidate>
        <ModalHeader toggle={() => this.toggleDialog('nextInvoiceDialog')}>
        Next Invoice
        </ModalHeader>
        <ModalBody>
        <NextInvoiceTable nextInvoice={this.state.nextInvoice} data={this.state.nextInvoiceData}
        tableSettings={{canDelete: false, canEdit: false}}
        tableColumns={this.getTableColumns('nextInvoice')}/>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" style={{ cursor: 'pointer' }}
        onClick={() => this.toggleDialog('nextInvoiceDialog')}>Close</Button>
        </ModalFooter>
        </form>
        </Modal>
        <Row>
        <Col lg="5" md="12" className="mb-md-3">
            <Card className="no-padding-top card-accent-primary" style={{height: "200px"}}>
    <CardHeader>
        <Container>
        <Row className="justify-content-between align-items-center no-gutters">
            <Col><div>Current Users:</div></Col>
        <Col className='text-right'>
            <span><a className="float-right header-text-link" style={{ cursor: 'pointer' }}
        onClick={() => this.toggleDialog('editSubscriptionDialog')}
        href={void(0)}>{subscriptionState === 'Trial' || subscriptionState === 'Cancelled' ? 'Subscribe' : 'Edit Subscription'}</a></span>
        </Col>
        </Row>
        </Container>
        </CardHeader>
        <CardBody>
        {this.state.expired ?
        <Col>
        <div>
        <span style={{fontWeight: "500"}}>Current Subscription:</span><span
        style={{color: 'red'}}>{' Account has expired.  Please update billing info.'}</span>
        </div>
        </Col>
    : this.currentSubscription && this.currentSubscription.nextPaymentDate ?
    <Col>
        <div>
        <span style={{fontWeight: "500"}}>Current Subscription:</span><span
        style={{color: 'gray'}}>{' None'}</span>
        </div>
        </Col>
    :
    <Col>
        <div>
        <span style={{fontWeight: "500"}}>Current Subscription:</span><span
        style={{color: 'gray'}}>{this.state.currentSubscription.cost ? ' ' + this.state.currentSubscription.quantity + ' User @ $' + this.state.currentSubscription.cost + '.00' : ' No Current Subscription'}</span>
        </div>
        {subscriptionState === 'Active' || subscriptionState === 'Expired' ?
        <div>
        <span style={{fontWeight: "500"}}>Next Bill:</span><span
            style={{color: 'gray !important'}}><a style={{ marginLeft: "15px", cursor: 'pointer' }}
            onClick={() => this.toggleDialog('nextInvoiceDialog')}
            href={void(0)}>{Util.formatCurrency(this.state.nextInvoice.total / 100, true) + ' Due ' + this.state.currentSubscription.nextPaymentDate}</a></span>
        </div>
        : subscriptionState === 'Cancelled' ?
        <div>
        <span style={{fontWeight: "500"}}>Next Bill:</span><span
            style={{color: 'gray'}}> Subscription Cancelled</span>
        </div>
        : ''
        }
    </Col>
    }
    </CardBody>
        </Card>
        </Col>
        <Col className="col-lg-3 col-12 offset-lg-2">
            {!this.state.card ? '' :
    <div>
        <CreditCard updateCard={() => this.toggleDialog('updateBillingDialog')}
        card={this.state.card}/>

        </div>
    }
    </Col>
        </Row>
        <Card className="card-accent-primary mt-4">
            <CardHeader>Payment History</CardHeader>
        <CardBody>
        <BillingHistoryTable nextInvoice={this.state.nextInvoice} data={this.state.charges}
        tableColumns={this.getTableColumns('history')}
        tableSettings={{canDelete: false, canEdit: false}}/>
        </CardBody>
        </Card>
        </div>
    )
    }
}

export default Billing;