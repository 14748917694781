import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import PositionsTable from "../../../components/DataTables/PositionsTable";
import moment from "moment";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const userGlobal = Util.getUserGlobal();
let canEdit = isAdminOrManager;
const canDelete = isAdminOrManager;

class Project extends Component {
    constructor(props) {
        super(props);
        canEdit = isAdminOrManager || props.companySettings.allowEditTime;
        this.state = {
            loading: true,
            timeFormat: '',
            nameFormat: '',
            allowGeoLocation: false,
            payPeriodType: '',
            payPeriodStarts: '',
            roundToNearest: 5,
            flagShiftsExceeding: 0,
            allowAddTime: false,
            allowEditTime: false,
            allowFutureTime: false,
            paidBreaks: false,
            autoBreakDeductMinutes: 0,
            autoBreakHours: 0,
            enableProjects: false,
            requireProjectClockIn: false,
            archiveProjectAfter: '15',
            positionList: [],
            positionName: '',
            positionId: null,
            laborItemId: 0,
            overtimeRuleList: [],
            overtimeRuleId: '',
            ruleName: '',
            ruleWeeklyOvertime: 0,
            ruleDailyOvertime: 0,
            ruleDailyDoubleOvertime: 0,
            ruleActive: true,
            ruleIsDefault: false,
            laborItemList: [],
            newDialog: false,
            laborId: null,
            laborName: '',
            laborActive: true,
            enablePositions: true,
            requirePositionClockIn: false,
            fbIntegrated: props.companySettings.fishbowlIntegrated,
            qboIntegrated: props.companySettings.qboIntegrated,
            qbdIntegrated: props.companySettings.qbdIntegrated,
            projectSingleName: props.companySettings.projectSingleName,
            projectPluralName: props.companySettings.projectPluralName,
            bomCategorySingleName: props.companySettings.bomCategorySingleName,
            includeOvertimeInProjectCost: false,
            companySettings: {}
        };

        this.showFormErrors = this.showFormErrors.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.deletePosition = this.deletePosition.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.getTableColumns = this.getTableColumns.bind(this);
        this.getPositionTableColumns = this.getPositionTableColumns.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.loadPositionData = this.loadPositionData.bind(this);
        this.handlePositionSubmit = this.handlePositionSubmit.bind(this);

        this.loadData(true)
        this.loadPositionData(true);
        this.loadLaborItemData(true);
    }

    componentDidUpdate = (prevProps, prevState, prevContext) => {
        const editableContent = document.getElementsByClassName('contentEditable');
        Array.prototype.filter.call(editableContent, element => {
            element.addEventListener('keypress', evt => {
                if (evt.which === 13) {
                    element.blur();
                }
            })
        });
    }

    getTableColumns() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Active', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}
        ];
    }


    getPositionTableColumns() {
        return [
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Labor Item', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete}
        ];
    }

    getNumberOfHeaderColumns() {
        let tableColumns = this.getTableColumns();
        let columnCount = 0;
        tableColumns.map((column) => {
            if (column.hideColumn) {
                columnCount += 1;
            }
        });
        return columnCount;
    }

    toggleConfirmDeleteModal(row) {
        this.setState({
            row: row,
            confirmDeleteDialog: !this.state.confirmDeleteDialog,
        });
    }

    handleChange(e, saveData) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value}, () => {if(saveData) {this.saveData();}});
            Util.showInputError(this, e.target.name);
        }
    }

    handleCheckBoxChange(e, saveData) {
        switch (e.target.name) {
            case 'enableProjects':
                if (!e.target.checked) {
                    this.setState({requireProjectClockIn: false});
                    this.setState({includeOvertimeInProjectCost:false});
                }
                break;
            case 'enablePositions':
                if (!e.target.checked) {
                    this.setState({requirePositionClockIn: false});
                }
        }
        this.setState({[e.target.name]: e.target.checked}, () => {if(saveData) {this.saveData();}});
    }


    toggleNewModal() {
        this.setState({
            newDialog: !this.state.newDialog,
            positionName: '',
            positionId: '',
        });
    }

    toggleEditModal(event, row) {
        if (event.target.classList.contains('delete')) {
            return false;
        }
        this.setState({
            newDialog: !this.state.newDialog,
            positionName: row.name,
            positionId: row.id,
            laborItemId: row.laborItemId
        });
    }

    loadData(initial) {
        if(initial) this.state.loading = true;
        else this.setState({loading: true});

        const apiUrl = Util.apiUrl(`company/settings`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState(json);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false});
            });

        //needed to refresh Project/Work Order name after saving
        fetch(Util.apiUrl(`users/get_logged_in_user`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({companySettings: json.CompanySettings});
                localStorage.setItem("companySettings", JSON.stringify(json.CompanySettings));
                localStorage.setItem("userGlobal", JSON.stringify(json.UserGlobal));
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    loadLaborItemData() {
        const apiUrl = Util.apiUrl(`projects/laborItems`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                json.resultList.unshift({id: 0, name: 'None'});
                this.setState({laborItemList : json.resultList});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.state.loading = false);
    }

    savePosition(method, position, objId = '') {
        if (!position.name) {
            toast.error('Position name is empty', {position: 'bottom-right'});
            return;
        }

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/positions/${objId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(position),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Position was saved', {position: 'bottom-right'});
                this.loadPositionData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    deletePosition() {
        const deletedRow = this.state.row;
        deletedRow.deletedOn = moment();
        this.savePosition('PATCH', deletedRow, deletedRow.id)
        this.setState({loading: false, confirmDeleteDialog: false});
    }

    loadPositionData(initial) {
        if(initial) this.state.loading = true;
        else {
            this.setState({
                loading: true
            });
        }

        const apiUrl = Util.apiUrl(`company/positions`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({positionList: json.resultList})
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({loading: false});
            });
    }

    handlePositionSubmit(event) {
        event.preventDefault();

        if (!this.state.positionName) {
            toast.error('Position name is empty', {position: 'bottom-right'});
            return;
        } else if (!this.state.laborItemId && this.state.fbIntegrated) {
            toast.error('Labor Item is required when integrated with Fishbowl', {position: 'bottom-right'});
            return;
        }


        this.setState({newDialog: !this.state.newDialog});
        let laborItemId = null;
        if (this.state.laborItemId) {
            laborItemId = this.state.laborItemId;
        }
        let position = {
            name: this.state.positionName,
            laborItemId: laborItemId
        };

        if (!this.state.positionId) {
            this.savePosition('POST', position);
        } else {
            this.savePosition('PATCH', position, this.state.positionId);
        }

    }

    saveData() {
        if (!this.showFormErrors()) {
            console.error('form is invalid: do not submit');
            return;
        }
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/settings`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(this.state),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Settings were saved', {position: 'bottom-right'});
                localStorage.setItem("companySettings", JSON.stringify(json));
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    saveDataAndReload = () => {
        this.saveData();
        this.loadData();
    }

    render() {
        return (
            <div className="animated fadeIn">
                {/*<ToastContainer />*/}
                <Modal isOpen={this.state.newDialog}>
                    <form id="modal-form" onSubmit={this.handlePositionSubmit} noValidate>
                        <ModalHeader>Position</ModalHeader>
                        <ModalBody>
                            <Label>Name<span className="error">*</span></Label>
                            <InputGroup className="mb-3">
                                <input type="text" placeholder="Name" name="positionName" ref="positionName"
                                       value={this.state.positionName}
                                       className="form-control" onChange={this.handleChange} required/>
                            </InputGroup>
                            <Label>Labor Item<span className="error">{this.state.fbIntegrated ? '*' : ''}</span></Label>
                            <InputGroup className="mb-3">
                                {this.state.fbIntegrated ?
                                    <Input type="select" placeholder="Labor Item" name="laborItemId" ref="laborItemId"
                                           id="laborItemId"
                                           className="form-control" onChange={this.handleChange}
                                           defaultValue={this.state.laborItemId}
                                           required>
                                        {this.state.laborItemList.map((laborItem, index) => (
                                            <option key={index} value={laborItem.id}>{laborItem.name}</option>
                                        ))}
                                    </Input> :
                                    <Input type="select" placeholder="Labor Item" name="laborItemId" ref="laborItemId"
                                           id="laborItemId"
                                           className="form-control" onChange={this.handleChange}
                                           defaultValue={this.state.laborItemId}>
                                        {this.state.laborItemList.map((laborItem, index) => (
                                            <option key={index} value={laborItem.id}>{laborItem.name}</option>
                                        ))}
                                    </Input>
                                }
                                </InputGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success">Save</Button>{' '}
                            <Button color="secondary" onClick={() => this.toggleNewModal()}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={this.state.confirmDeleteDialog}>
                    <ModalHeader>Delete Position</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this position?
                        <br/>
                        <strong>{this.state.laborName}</strong>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.deletePosition()}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => this.toggleConfirmDeleteModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Row className="col-12" id="company-form">
                    <Col md="12" lg="5" className="data-rows-mt">
                        <Card className="card-accent-primary">
                            <CardHeader className="no-button-margin">{this.state.companySettings.projectSingleName +' Settings'}</CardHeader>
                            <CardBody>
                                <div className="mb-3">
                                    <Label>{'Use ' + this.state.projectPluralName}</Label>
                                    <Label className={'switch switch-icon switch-pill switch-info pull-right'}
                                           style={this.state.qboIntegrated || this.state.qbdIntegrated || this.state.fbIntegrated ? {cursor: 'default', opacity: '0.5'} : {}}
                                           title={'Activating '+ this.state.projectPluralName + ' allows you to easily track employee time towards specific ' + this.state.projectPluralName + ', set budgeted ' + this.state.projectSingleName + ' hours, and see accurate ' + this.state.projectSingleName + ' labor costs. This cannot be disabled while integrated with QuickBooks Online, QuickBooks Desktop, or Fishbowl.'}>
                                        <Input type="checkbox" name="enableProjects" ref="enableProjects"
                                               className={'switch-input'}
                                               checked={this.state.enableProjects}
                                               onChange={(e) => {
                                                   this.handleCheckBoxChange(e, true)
                                               }}
                                               disabled={this.state.qboIntegrated || this.state.qbdIntegrated || this.state.fbIntegrated}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </div>
                                <div className="mb-3">
                                    <Label>{'Require Employees to clock into ' + this.state.projectPluralName}</Label>
                                    <Label className="switch switch-icon switch-pill switch-info pull-right"
                                           style={this.state.qboIntegrated || this.state.qbdIntegrated ? {cursor: 'default', opacity: '0.5'} : {}}
                                           title="This cannot be disabled while integrated with QuickBooks Online or QuickBooks Desktop.">
                                        <Input type="checkbox" name="requireProjectClockIn"
                                               ref="requireProjectClockIn"
                                               className="switch-input"
                                               checked={this.state.requireProjectClockIn}
                                               onChange={(e) => {
                                                   this.handleCheckBoxChange(e, true)
                                               }}
                                               disabled={this.state.qboIntegrated || this.state.qbdIntegrated || !this.state.enableProjects}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </div>
                                <div className="mb-3">
                                    <Label>Use Positions</Label>
                                    <Label className={'switch switch-icon switch-pill switch-info pull-right'}
                                           style={this.state.fbIntegrated ? {cursor: 'default', opacity: '0.5'} : {}}
                                           title="This cannot be disabled while integrated with Fishbowl.">
                                        <Input type="checkbox" name="enablePositions" ref="enablePositions"
                                               className={'switch-input'}
                                               checked={this.state.enablePositions}
                                               onChange={(e) => {
                                                   this.handleCheckBoxChange(e, true)
                                               }}
                                               disabled={this.state.fbIntegrated}
                                            />
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </div>
                                <div className="mb-3">
                                    <Label>Require Employees to clock into Positions</Label>
                                    <Label className="switch switch-icon switch-pill switch-info pull-right"
                                           style={this.state.fbIntegrated ? {cursor: 'default', opacity: '0.5'} : {}}
                                           title="This cannot be disabled while integrated with Fishbowl.">
                                        <Input type="checkbox" name="requirePositionClockIn"
                                               ref="requirePositionClockIn"
                                               className="switch-input"
                                               checked={this.state.requirePositionClockIn}
                                               onChange={(e) => {
                                                   this.handleCheckBoxChange(e, true)
                                               }}
                                               disabled={!this.state.enablePositions || this.state.fbIntegrated}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </div>
                                <div className="mb-3">
                                    <Label>{'Include overtime in '+ this.state.companySettings.projectSingleName +' cost?'}</Label>
                                    <Label className="switch switch-icon switch-pill switch-info pull-right"
                                           title="This will determine whether overtime is calculated in the total project cost.">
                                        <Input type="checkbox" name="includeOvertimeInProjectCost"
                                               className="switch-input"
                                               checked={this.state.includeOvertimeInProjectCost}
                                               onChange={(e) => {
                                                   this.handleCheckBoxChange(e, true)
                                               }}
                                               disabled={!this.state.enableProjects}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </div>
                                <div className="mb-3">
                                    <Label>What do you call a Project?</Label>
                                    <InputGroup>
                                        <Input type="text" name="projectSingleName"
                                               ref="projectSingleName"
                                               maxLength={253}
                                               onChange={(e) => {this.handleChange(e)}}
                                               value={this.state.projectSingleName || ''}
                                               style={{width: 160 + "px"}}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="mb-3">
                                    <Label>What do you call a BOM Category?</Label>
                                    <InputGroup>
                                        <Input type="text" name="bomCategorySingleName"
                                            ref="bomCategorySingleName"
                                            maxLength={253}
                                            onChange={(e) => {this.handleChange(e)}}
                                            value={this.state.bomCategorySingleName || ''}
                                            style={{width: 160 + "px"}}
                                        />
                                    </InputGroup>
                                </div>
                                <div className={'text-right mt-4'}>
                                    <Button color="success" onClick={this.saveDataAndReload}>Save</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4">
                        {
                            this.state.enablePositions ? <Card className="card-accent-primary">
                                <CardHeader>
                                    <Container className="pl-0 pr-0">
                                        <Row className="justify-content-between align-items-center no-gutters">
                                            <Col><div>Positions</div></Col>
                                            <Col className="text-right">
                                                <button className="header-button btn btn-outline-success"
                                                        onClick={() => this.toggleNewModal()}><i
                                                    className="material-icons">add</i></button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardHeader>
                                <CardBody>
                                    <PositionsTable
                                        data={this.state.positionList}
                                        tableColumns={this.getPositionTableColumns()}
                                        toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                        toggleEditModal={this.toggleEditModal}
                                        tableSettings={{
                                            canDelete: canDelete,
                                            canEdit: canEdit
                                        }}
                                    />
                                </CardBody>
                            </Card> : null
                        }
                    </Col>
                </Row>
            </div>
        )
    }

    showFormErrors() {
        let isFormValid = true;
        for (const input of document.getElementById('company-form').querySelectorAll('input')) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        }
        return isFormValid;
    }
}

export default Project;