import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DateRange} from "react-date-range";
import moment from "moment";
import {defaultDateRanges} from "../Framework/Util";
import * as Util from "../Framework/Util";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";

class FilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: localStorage.getItem("isAdmin"),
            editedByUserList: [],
            createdByUserList: [],
        };

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    filter() {
        this.props.applyFilter(this.props.lastEditedBy, this.props.createdBy, this.props.startDate, this.props.endDate);
        this.props.toggleModal();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.modal === false && this.props.modal === true){
            this.getCreatedByUserList();
            this.getEditedByUserList();
        }
    }

    getEditedByUserList() {
        let url = 'audit/editedByUserList';
        if (this.props.currentAuditType !== undefined && this.props.currentAuditType !== "")
            url += '?type=' + this.props.currentAuditType;

        fetch(Util.apiUrl(url), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then((json) => this.parseEditedByUserList(json))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    getCreatedByUserList() {
        let url = 'audit/createdByUserList';
        if (this.props.currentAuditType !== undefined && this.props.currentAuditType !== "")
            url += '?type=' + this.props.currentAuditType;

        fetch(Util.apiUrl(url), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then((json) => this.parseCreatedByUserList(json))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    parseCreatedByUserList(list) {
        let userList = this.getOptionsFromUsersList(list);
        this.setState({createdByUserList: userList});
    }

    parseEditedByUserList(list) {
        let userList = this.getOptionsFromUsersList(list);
        this.setState({editedByUserList: userList});
    }

    getOptionsFromUsersList(list) {
        let userList = [];
        userList.push(<option key="0" value="">All Users</option>)

        for (const user of list) {
            userList.push(<option key={user["id"]}
                                  value={user["id"]}>{user["nameFirst"] + " " + user["nameLast"]}</option>)
        }
        return userList;
    }

    handleDateChange(range) {
        let startDate = range.startDate.startOf('day').format();
        let endDate = range.endDate.endOf('day').format();

        this.props.updateStartDate(startDate);
        this.props.updateEndDate(endDate);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} size="lg">
                <ModalHeader>
                    <button className="pull-left" style={{border: "none"}} onClick={() => this.props.toggleModal()}><i
                        className="material-icons">close</i></button>
                    <div className="item-center h2">Filter</div>
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row mb-3 h3">Pick Your Dates</div>
                        <DateRange
                            ranges={defaultDateRanges}
                            onChange={this.handleDateChange}
                            startDate={moment()}
                            endDate={moment()}
                            theme={{
                                Calendar: {width: "250px"},
                                PredefinedRanges: {marginLeft: 10, marginTop: 10}
                            }}
                            className="search-date-range"
                        />
                        <div className="row h3">Last Edited By</div>
                        <div className="row mb-3">
                            <select className='w-100 rounded p-2'
                                    style={{backgroundColor: 'gainsboro', border: 'none'}}
                                    onChange={(e) => {
                                        this.props.updateEditedBy(e.target.value)
                                    }}
                                    defaultValue={this.props.lastEditedBy != null ? this.props.lastEditedBy : ""}>
                                {this.state.editedByUserList}
                            </select>
                        </div>

                        <div className="row h3">Created By</div>
                        <div className="row mb-3">
                            <select className='w-100 rounded p-2'
                                    style={{backgroundColor: 'gainsboro', border: 'none'}}
                                    onChange={(e) => {
                                        this.props.updateCreatedBy(e.target.value)
                                    }}
                                    defaultValue={this.props.createdBy != null ? this.props.createdBy : ""}>
                                {this.state.createdByUserList}
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        this.filter()
                    }}>Filter</Button>
                </ModalFooter>
            </Modal>);
    }
}

export default FilterModal