import React, {Component} from "react";
import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as Util from "../../../components/Framework/Util";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import * as qs from "querystring";

const Spinner = require('react-spinkit');
const companySettings = Util.getCompanySettings();

class Qbo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isExportLoading: false,

            isIntegrated: false,
            qboInfo: '',
            qboConfirmModal: false,

            qboCompanyInfo: '',
            pathParams: qs.parse(location.search),
        };
    }

    componentDidMount() {
        this.loadData();
    }

    toggleQboModal = () => {
        this.setState({qboConfirmModal: !this.state.qboConfirmModal});
    };

    qboIntegrate = () => {
        this.setState({qboConfirmModal: false});
        window.location.href = '/api/integrations/qbo';
    };

    qboDisconnect = () => {
        this.setState({loading: true});

        const apiUrl = Util.apiUrl('integrations/qbo-disconnect');
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right', timeout: 5000});
                    return;
                }

                this.setState({isIntegrated: false, qboCompanyInfo: ''});
                const apiUrl = Util.apiUrl('company/settings');
                fetch(apiUrl, {credentials: 'include'})
                    .then(response => ServerResponseHandler.getResponse(response))
                    .then(json => {
                        localStorage.setItem("companySettings", JSON.stringify(json));
                        this.setState({isIntegrated: json.qboIntegrated});
                    })
                    .catch(error => ServerResponseHandler.handleError(error));
                toast.success("You have been disconnected from QBO", {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false})
        );
    };

    exportDataToQbo = () => {
        this.setState({loading: true, isExportLoading: true});

        const apiUrl = Util.apiUrl('integrations/qbo-export-data');
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right', timeout: 5000});
                } else {
                    toast.success("Data exported to QBO", {position: 'bottom-right'});
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, isExportLoading: false})
        );
    };

    render() {
        const state = this.state;

        const qbo = state.isIntegrated
            ? (<div className="text-center">
                <Button color="success" onClick={this.exportDataToQbo} disabled={state.loading}>
                    {state.isExportLoading ? <i className="fa fa-spinner fa-spin mr-2"></i> : ''}Export Data</Button>{' '}
                <Button color="default" onClick={this.qboDisconnect} disabled={state.loading}>Disconnect from
                    QuickBooks</Button>
            </div>)
            : (<div>
                <div className="text-center">
                    <img src={'/img/qbo/quickbooks_logo_horz.png'} style={{width: '500px', height: '136px'}}/>
                    <h4>Connect to QuickBooks Online</h4>
                    <div className="mb-2">{state.qboInfo.description}</div>
                    <img src={'/img/qbo/C2QB_white_btn_med_default.png'}
                         style={{width: '136px', height: '48px', cursor: 'pointer'}}
                         onMouseOver={e => (e.currentTarget.src = '/img/qbo/C2QB_white_btn_med_hover.png')}
                         onMouseOut={e => (e.currentTarget.src = '/img/qbo/C2QB_white_btn_med_default.png')}
                         onClick={this.toggleQboModal}/>
                </div>
            </div>);

        const companyName = state.isIntegrated && state.qboCompanyInfo.companyName
            ? <h3 className="text-center mb-4">{state.qboCompanyInfo.companyName}</h3>
            : '';

        return (
            <div className="animated fadeIn">
                <div className="module-header">
                    QuickBooks Online
                </div>
                <Container fluid>
                    {
                            this.state.pathParams['?error']
                                ? <div className="s-alert-error text-center" style={{padding: '5px'}}>{this.state.pathParams['?error']}</div>
                                : ''

                    }
                    <br/>
                    <div className="clearfix">
                        {companyName}
                        {qbo}
                    </div>
                    <Modal isOpen={this.state.qboConfirmModal} toggle={this.toggleQboModal}
                          >
                        <ModalHeader>Integrate with QuickBooks Online</ModalHeader>
                        <ModalBody>
                            <div style={{overflowWrap: 'break-word'}}>{'Integrating with QuickBooks Online will enable ' + companySettings.projectPluralName + ' and require employees to clock into ' + companySettings.projectPluralName +
                                '. This will also export all of your current ' + companySettings.projectPluralName + ' as jobs under the customer Fishbowl Time to QuickBooks Online. ' +
                                'If any time logs are not assigned a ' + companySettings.projectSingleName + ' you will be required to assign a ' + companySettings.projectSingleName +
                                ' before you are able to export to QuickBooks Online.'}
                            </div>
                            <br/>
                            <div>ATTENTION: Integrating Fishbowl Time with a QuickBooks Online account where Payroll is enabled will cause the integration to fail. The ability to integrate with Payroll is not currently available to the public.</div>
                            <br/>
                            <div>Are you sure you want to continue?</div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.qboIntegrate}>Yes</Button>{' '}
                            <Button color="secondary" onClick={this.toggleQboModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </div>
        )
    }

    loadData = () => {
        this.setState({loading: true});

        let fetches = [];

        // After user integrates, company settings change. Update local storage here.
        const apiUrl = Util.apiUrl('company/settings');
        fetches.push(
            fetch(apiUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    localStorage.setItem("companySettings", JSON.stringify(json));
                    this.setState({isIntegrated: json.qboIntegrated});
                    if (json.qboIntegrated) {
                        const qboCompanyInfo = Util.apiUrl('integrations/qbo-company-info');
                        fetches.push(
                            fetch(qboCompanyInfo, {credentials: 'include'})
                                .then(response => ServerResponseHandler.getResponse(response))
                                .then(json => {
                                    if (json.errorMessage) {
                                        toast.error(json.errorMessage, {position: 'bottom-right', timeout: 5000});
                                    } else {
                                        this.setState({qboCompanyInfo: json})
                                    }
                                })
                                .catch(error => ServerResponseHandler.handleError(error))
                        );
                    }
                })
                .catch(error => ServerResponseHandler.handleError(error))
        );

        const qboInfoUrl = Util.apiUrl('integrations');
        fetches.push(
            fetch(qboInfoUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => this.setState({qboInfo: json.resultList[0]}))
                .catch(error => ServerResponseHandler.handleError(error))
        );

        Promise.all(fetches).then(() => {
            this.setState({loading: false});
        });
    };
}

export default Qbo;