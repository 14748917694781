import React, {useState, useEffect, createRef} from 'react';
import {
    Button, Label, Row, Tooltip
} from "reactstrap";
import * as Util from "../../../components/Framework/Util";
import SelectProject from "../../../components/Selects/SelectProject";
import Select from 'react-select';
import {ToastContainer, toast} from 'react-toastify';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import ProjectModal from "../../../components/Modal/ProjectModal";
import * as _ from "lodash";
import useTooltip from "../../../Hooks"

const moment = require('moment-timezone');

function TimeClockOptions(props) {
    const selectedProject = props.currentShift && props.currentShift.projectId ? _.find(props.projects, {id: props.currentShift.projectId}) : null;
    const [tooltip, setTooltip] = useTooltip({});
    const [currentShift, setCurrentShift] = useState(props.currentShift);
    const [position, setPosition] = useState(null);
    const [positions, setPositions] = useState([]);
    const [project, setProject] = useState(selectedProject);
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [projectModal, setProjectModal] = useState(false);
    const [endProject, setEndProject] = useState(false);
    const [loading, setLoading] = useState(false);

    let positionRef = createRef();

    useEffect(parseUserPositions, []);
    useEffect(startTimer, []);
    useEffect(props.refresh, []);

    const positionsEnabled = props.companySettings.enablePositions;
    const projectsEnabled = props.companySettings.enableProjects;
    let optionClockInPosition = '';
    let optionClockInProject = '';
    let optionBreak = '';
    let optionLunch = '';
    let optionProject = '';
    let optionNote = '';
    let countUpTimer;

    function startTimer() {
        if (currentShift) {
            const timeLog = currentShift;
            let breakOffset = timeLog.lunchBreakTime;
            breakOffset = props.companySettings.paidBreaks ? breakOffset += timeLog.breakTime : breakOffset;
            breakOffset = timeLog.currentBreak && timeLog.currentBreak.breakIn ? 0 : breakOffset;
            const startTime = timeLog.currentBreak && timeLog.currentBreak.breakIn ? timeLog.currentBreak.breakIn : timeLog.clockIn;
            countUpTimer = setInterval(function () {
                let now = moment(new Date().toString());
                let time = Math.round(now.diff(moment(startTime)) / 1000) - breakOffset;
                document.getElementById('timer').innerHTML = Util.formatTimer(time, true);
            }, 1000);
        }
        return () => {
            clearInterval(countUpTimer)
        };
    }

    function parseUserPositions() {
        if (props.userTenant && props.userTenant.userTenantPositionList) {
            let positions = [];
            props.userTenant.userTenantPositionList.filter(x => !x.position.deletedOn).forEach(pos => {
                const position = {id: pos.positionId, name: pos.position.name};
                positions.push(position);
                if (currentShift && currentShift.positionId && pos.positionId === currentShift.positionId) {
                    setPosition(position);
                }
            });
            setPositions(_.sortBy(positions, [position => position.name.toLowerCase()]));
        }
    }

    function handleProjectChange(selectedProject) {
        if (!selectedProject) {
            setProject(null);
        } else {
            setProject(selectedProject);
        }
    }

    function handlePositionChange(selectedPosition) {
        positionRef.current.classList.remove('invalid')
        if (!selectedPosition) {
            setPosition(null);
        } else {
            setPosition(selectedPosition);
        }
    }

    function toggleProjectModal(currentTimeLog, endProject = false, switchProject = false) {
        setEndProject(endProject);

        if (currentTimeLog) {
            if (currentTimeLog.positionId) {
                setPosition({id: currentTimeLog.positionId, name: currentTimeLog.positionName});
            }

            if (currentTimeLog.projectId) {
                setProject({
                    id: currentTimeLog.projectId,
                    number: currentTimeLog.projectNumber,
                    name: currentTimeLog.projectName
                });
                setCurrentProjectId(currentTimeLog.projectId);
            }
        }
        setProject(null);
        setProjectModal(!projectModal);
    }

    function handleSubmit(e, closeProject) {
        let requiredFields = {
            project: project,
            position: position
        };
        props.submit(e, requiredFields, !!closeProject);
    }

    function alertSuccessful(finishProject) {
        if (finishProject || endProject) {
            toast.success('Closed ' + props.companySettings.projectPluralName, {position: 'bottom-right'});
        } else if (currentProjectId && project.id && currentProjectId !== project.id) {
            toast.success('Switched ' + props.companySettings.projectPluralName, {position: 'bottom-right'});
        } else {
            toast.success('Completed', {position: 'bottom-right'});
        }
    }

    function changeProject(finishProject = false) {
        if (currentProjectId === project.id) {
            toast.error('You must select a different ' + props.companySettings.projectSingleName + ' to switch to.', {position: 'bottom-right'});
            return;
        }
        const end = finishProject || endProject;
        if (props.companySettings.requirePositionClockIn && !position && !end) {
            positionRef.current.classList.add('invalid');
            toast.error('You must select a position.', {position: 'bottom-right'});
        } else {
            let geoLocation = null;

            if (props.coords) {
                geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
            }

            setProjectModal(false);
            const shiftEntry = {
                employeeCode: props.employee.userId,
                geoLocation: geoLocation,
                positionId: position ? position.id : null,
                projectId: project ? project.id : null,
                closeProject: end
            };

            setLoading(true);
            const apiUrl = Util.apiUrl(`terminals/change-project/${props.companyId}`);
            fetch(apiUrl, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json;charset=UTF-8'},
                body: JSON.stringify(shiftEntry),
            })
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    alertSuccessful(finishProject);
                    setCurrentShift(json);
                    setTimeout(() => {
                        location.reload()
                    }, 1500);
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => setLoading(false));
        }
    }

    const btnEndProject = props.companySettings.requireProjectClockIn
        ? <button className="btn btn-outline-secondary clock-button w-75"
                  onClick={() => toggleProjectModal(currentShift, true)}><i
            className="fas fa-wrench mr-1"></i>{'Close ' + props.companySettings.projectSingleName}</button>
        : <button className="btn btn-outline-secondary clock-button w-75"
                  onClick={() => changeProject(true)}><i
            className="fas fa-wrench mr-1"></i>{'Close ' + props.companySettings.projectSingleName}</button>;

    if (currentShift) {
        if (!currentShift.currentBreak) {
            optionNote = <div>
                <button className="btn btn-outline-secondary clock-button w-75"
                        onClick={props.toggleNoteModal}>
                    <i className="fas fa-sticky-note mr-1"></i> Add Shift Note
                </button>
            </div>;
            optionBreak = <div>
                <button className="btn btn-outline-secondary clock-button w-75"
                        onClick={() => props.logBreak('Break')}><i className="fa fa-clock-o fa-lg mr-1"></i> Start Break
                </button>
            </div>;
            optionLunch = <div>
                <button className="btn btn-outline-secondary clock-button w-75"
                        onClick={() => props.logBreak('Lunch')}><i className="fas fa-utensils fa-lg mr-1"></i> Start
                    Lunch
                </button>
            </div>;
            if (projectsEnabled) {
                if (!currentShift.projectId) {
                    optionProject = <div>
                        <button className="btn btn-outline-secondary clock-button w-75"
                                onClick={() => toggleProjectModal(currentShift, false)}>
                            <i className="fas fa-cog mr-1"></i>{'Start ' + props.companySettings.projectSingleName}
                        </button>
                    </div>;
                } else {
                    optionProject = <div>
                        <div>
                            <button className="btn btn-outline-secondary clock-button w-75"
                                    onClick={() => toggleProjectModal(currentShift, false, true)}>
                                <i className="fas fa-cog mr-1"></i>{'Switch ' + props.companySettings.projectSingleName}
                            </button>
                        </div>
                        <div>
                            {btnEndProject}
                        </div>
                    </div>;
                }
            }
        }
    } else {
        if (projectsEnabled && props.projects.length > 0) {
            optionClockInProject = (<div>
                <Label id="projectLabel" className="font-weight-bold">{props.companySettings.projectPluralName}</Label>
                <Tooltip target={'projectLabel'} isOpen={tooltip['projectLabel']}
                         toggle={() => setTooltip('projectLabel')}>{props.companySettings.projectPluralName}</Tooltip>
                <div className="mb-3 fb-select">
                    <Select
                        isClearable
                        getOptionValue={(option) => option}
                        getOptionLabel={(option) => option.number + ' - ' + option.name}
                        value={project} options={props.projects}
                        onChange={handleProjectChange}
                        placeholder={'Select a ' + props.companySettings.projectSingleName + '...'}
                    />
                </div>
            </div>)
        }
    }

    if (positions && positions.length > 0 && positionsEnabled) {
        optionClockInPosition = (<div>
            <Label className="font-weight-bold">Positions</Label>
            <div className="mb-3 fb-select" ref={positionRef}>
                <Select
                    isClearable
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option.name}
                    value={position} options={positions}
                    onChange={handlePositionChange}
                    placeholder={'Select a Position...'}
                />
            </div>
        </div>);
    }

    return (
        <div className="text-center">
            <ProjectModal
                isOpen={projectModal}
                optionClockInPosition={optionClockInPosition}
                projects={props.projects}
                project={project}
                handleProjectChange={handleProjectChange}
                changeProject={changeProject}
                toggleModal={toggleProjectModal}
                endProject={endProject}
                clockOut={handleSubmit}
                companySettings={props.companySettings}
                handleChangeEndProject={(event) => {
                    setEndProject(event.target.checked)
                }}
            />
            <div>
                {
                    !currentShift || !currentShift.projectName ? '' :
                        <div><span
                            style={{fontWeight: 'bold'}}>{props.companySettings.projectSingleName + ': '}</span>{currentShift.projectName}
                        </div>
                }
                {
                    !currentShift || !currentShift.positionName ? '' :
                        <div><span style={{fontWeight: 'bold'}}>Position: </span>{currentShift.positionName}</div>
                }
            </div>
            {
                !currentShift ? '' :
                    <div className="clock-action-top">
                        <div className="timeholder">
                            <span id="timer">00:00:00</span>
                        </div>
                    </div>
            }
            {currentShift ? '' : optionClockInPosition}
            {optionClockInProject}
            {props.companySettings.allowBreaks ? optionBreak : ''}
            {props.companySettings.allowLunchBreaks ? optionLunch : ''}
            {optionProject}
            {optionNote}
            <div className="clock-action-bottom">
                {
                    !props.isOpen ? '' :
                        currentShift === null
                            ? (<Button disabled={props.disableSubmit} className="btn-success clockIn" onClick={(e) => {
                                handleSubmit(e)
                            }}
                                       active={true}>CLOCK IN</Button>)
                            : currentShift.currentBreak
                            ? (<Button disabled={props.disableSubmit} className="btn-info clockIn"
                                       onClick={(e) => props.logBreak()}
                                       active={true}>RESUME SHIFT</Button>)
                            : (<Button disabled={props.disableSubmit} className="btn-danger clockIn"
                                       onClick={props.submit}
                                       active={true}>CLOCK OUT</Button>)
                }
            </div>
        </div>
    )
}

export default TimeClockOptions;