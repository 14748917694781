import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as Util from "../Framework/Util";
import AsyncSelect from 'react-select/lib/Async';
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";

// export default function SelectProject(props) {
class SelectProject extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            project: null,
            companySettings: props.companySettings ? props.companySettings : Util.getCompanySettings(),
            options: []
        }

        let _project;
        if (props.project) {
            _project = {...props.project};
            _project.details = _project.number + " - " + _project.name;
        }
        this.state.project = _project;
        this.loadProjects();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this !== undefined && (prevProps.includeClosed !== this.props.includeClosed || prevProps.includeArchived !== this.props.includeArchived
            || (prevProps.employee != this.props.employee || (this.props.employee != null && this.props.employee.employeeCode != prevProps.employee.employeeCode)))){
            this.loadProjects();
        }
    }

    handleChange = (newProject) => {
        // If the user backspaces all the way, newProject becomes an empty array which errors out in the props.onChange method
        if (newProject && newProject.constructor === Array) {
            newProject = null;
        }

        if (newProject) {
            this.setState({project: newProject});
        } else {
            this.setState({project: null});
        }

        if(this !== undefined) this.props.onChange(newProject);
    }

    loadProjects = (input = null) => {
        let search = {};

        if(!input && this !== undefined && !this.props.includeArchived && !this.props.includeClosed) {
            search.projectStatus = 'Open';
        }
        else if(input)
            search.data = input;

        if(this.props.employeeNeeded && !this.props.employee)
            return;

        if(this.props.employee) {
            search.userTenantId = this.props.employee.employeeCode;
        }

        const apiUrl = Util.apiSearchUrl(`projects`, search);

        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let filteredList = [];
                for (const project of json.resultList) {
                    if(input || ((project.projectStatus === 'Closed' && this.props.includeClosed) ||
                    (project.projectStatus === 'Archived' && this.props.includeArchived) ||
                    (project.projectStatus === 'Open'))){
                        project.details = project.number + " - " + project.name;
                        filteredList.push(project);
                    }
                }
                this.setState({options: filteredList});
                return filteredList;
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    render() {
        return (
            <div className="fb-select">
                <AsyncSelect
                    cacheOptions
                    defaultOptions={this.state.options}
                    isClearable
                    value={this.state.project}
                    noOptionsMessage={() => 'No ' + this.state.companySettings.projectPluralName + ' found'}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.details}
                    loadOptions={this.loadProjects}
                    placeholder={'Select a ' + this.state.companySettings.projectSingleName + '...'}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

SelectProject.propTypes = {
    onChange: PropTypes.func.isRequired,
    project: PropTypes.object,
    includeClosed: PropTypes.bool,
    includeArchived: PropTypes.bool
};

SelectProject.defaultProps = {
    includeClosed: false,
    includeArchived: false
}

export default SelectProject;