import React, {createRef} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody, Modal, ModalFooter, InputGroup, Input
} from "reactstrap";

import SelectProject from "../Selects/SelectProject";
import Select from 'react-select';
import * as Util from "../Framework/Util";
import { ToastContainer, toast } from 'react-toastify';

function ProjectModal(props) {
    let projectRef = createRef();
    const companySettings = props.companySettings ? props.companySettings : Util.getCompanySettings();
    function handleProjectChange(e) {
        projectRef.current.classList.remove('invalid');
        props.handleProjectChange(e);
    }

    function submit() {
        if(companySettings.requireProjectClockIn && !props.project) {
            projectRef.current.classList.add('invalid');
            toast.error('You must select a ' + companySettings.projectSingleName + '.', {position: 'bottom-right'});
        }
        else {
            props.changeProject();
        }
    }

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader className="ellipsis">{ (props.endProject ? 'Close and ' : '') + 'Switch ' + companySettings.projectSingleName}</ModalHeader>
            <ModalBody>
                {props.optionClockInPosition}
                <Label className="font-weight-bold">{companySettings.projectSingleName}</Label>
                <div className="mb-3">
                    {
                        props.projects
                            ? <div>
                                <div className="mb-3 fb-select" ref={projectRef}>
                                    <Select
                                        isClearable
                                        className="test"
                                        required={companySettings.requireProjectClockIn}
                                        getOptionValue={(option) => option}
                                        getOptionLabel={(option) => option.number + ' - ' + option.name}
                                        value={props.project} options={props.projects}
                                        onChange={handleProjectChange}
                                        placeholder={'Select a ' + companySettings.projectSingleName + '...'}
                                    />
                                </div></div>
                            :
                            <SelectProject project={props.project} companySettings={companySettings} onChange={props.handleProjectChange}/>
                    }
                </div>
                <InputGroup className="mb-3 pt-3">
                    <label>Close {companySettings.projectSingleName}</label>
                    <Label className="switch switch-icon switch-pill switch-info pull-left"
                        style={{ position: 'absolute', right: '0'}}
                        title="Close">
                            <Input type="checkbox" name="positionChecked"
                                className="switch-input"
                                onChange={(event) => { props.handleChangeEndProject(event); }}
                                checked={props.endProject} />
                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                        <span className="switch-handle"/>
                    </Label>
                </InputGroup>
            </ModalBody>
            <ModalFooter>
                {!props.endProject ? '' : <Button color="danger" onClick={(e) => { props.clockOut(e, true);  props.toggleModal(); }}>CLOCK OUT</Button>}
                <Button color="success" onClick={submit}>Save</Button>{' '}
                <Button color="secondary" onClick={() => props.toggleModal()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ProjectModal;