import React, { useState, useContext, useEffect } from 'react';
import {Redirect, Switch} from 'react-router-dom';
import Sidebar from '../../components/Sidebar/';
import {AdminRoute, ManagerRoute, PrivateRoute} from "../../components/Framework/PrivateRoute";
import {Row, InputGroup, Input, Label} from 'reactstrap';

import User from "../../views/Pages/User/User"
import Dashboard from "../../views/Dashboard/Dashboard";
import TimeClock from "../../views/Pages/TimeClock/TimeClock";
import Manage from "../../views/Pages/Manage/Manage";
import Projects from "../../views/Pages/Projects/Projects";
import Reports from "../../views/Pages/Reports/Reports";
import Schedule from "../../views/Pages/Schedule/Schedule";
import Company from "../../views/Pages/Company/Company";
import Employees from "../../views/Pages/Employees/Employees";
import Integrations from "../../views/Pages/Integrations/Integrations";
import Executor from "../../views/Pages/Executor/Executor";
import Logout from "../../views/Pages/Logout/Logout";
import Pto from "../../views/Pages/Pto/Pto";
import {CompanyContext} from "../../context"
import * as Util from "../../components/Framework/Util";
import * as ServerResponseHandler from "../../components/Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import { loadPayrollModels } from '../../components/Framework/PayrollHelper';


function LoggedIn(props) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showSensitiveData, setShowSensitiveData] = useState(false);
    const [companySettings, setCompanySettings] = useContext(CompanyContext);

    useEffect(() => {;
        loadPayrollModels();
        const executorToggle = getExecutorToggle();

        if(Util.isExecutor()) {
            setShowSensitiveData(executorToggle);
        }
    }, []);

    const toggleMenu = () => {
        const currentState = mobileMenuOpen;
        setMobileMenuOpen(!currentState);
        document.body.classList.remove('collapsed');
        document.body.classList.remove('sidebar-minimized');
    };

    const getExecutorToggle = () => {
        const toggle = localStorage.getItem("executorToggle");
        return JSON.parse(toggle) === true;
    }

    return (
        <div className="app-body">
            <Sidebar{...props} mobileMenuOpen={mobileMenuOpen}/>
            <main className="main">
                <div className="navbar-light container-fluid">
                    <Row className="justify-content-end mt-2 mb-2">
                        <button className="navbar-toggler aside-menu-toggler"
                                type="button" onClick={() => toggleMenu()}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </Row>
                </div>
                {/*} Temporarily deactivating to move to projects page.
                {/*{Util.isExecutor() &&*/}
                {/*<Row className="mt-3 mb-3 ml-5">*/}
                {/*    <InputGroup className="mb-1">*/}
                {/*    <label>Show sensitive data</label>*/}
                {/*    <Label className="switch switch-icon switch-pill switch-info ml-4">*/}
                {/*        <Input type="checkbox" name="tos"*/}
                {/*    className="switch-input"*/}
                {/*    id="tos"*/}
                {/*    checked={showSensitiveData}*/}
                {/*    onChange={() => { localStorage.setItem("executorToggle", !showSensitiveData); setShowSensitiveData(!showSensitiveData) }} />*/}
                {/*    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>*/}
                {/*    <span className="switch-handle"/>*/}
                {/*    </Label>*/}
                {/*    </InputGroup>*/}
                {/*</Row>}*/}
                <RouteCheck sessionDetails={{showSensitiveData: showSensitiveData}} />
            </main>
        </div>
    );
}

const RouteCheck = (sessionDetails) => {
    if (!accountExpired()) {
        return (
            <Switch>
                <PrivateRoute path="/user" name="User" sessionDetails={sessionDetails} component={User}/>
                <ManagerRoute path="/dashboard" name="Dashboard" sessionDetails={sessionDetails} component={Dashboard}/>
                <PrivateRoute path="/timeclock" name="Time Clock" sessionDetails={sessionDetails} component={TimeClock}/>
                <PrivateRoute path="/pto" name="PTO" sessionDetails={sessionDetails} component={Pto}/>
                <ManagerRoute path="/manage" name="Manage" sessionDetails={sessionDetails} component={Manage}/>
                <ManagerRoute path="/projects" name="Projects" sessionDetails={sessionDetails} component={Projects} />
                <ManagerRoute path="/reports" name="Reports" sessionDetails={sessionDetails} component={Reports}/>
                <ManagerRoute path="/schedule" name="Schedule" sessionDetails={sessionDetails} component={Schedule}/>
                <AdminRoute path="/employees" name="Employees" sessionDetails={sessionDetails} component={Employees}/>
                <AdminRoute path="/company" name="Company" sessionDetails={sessionDetails} component={Company}/>
                <AdminRoute path="/integrations" name="Integrations" sessionDetails={sessionDetails} component={Integrations}/>
                <AdminRoute path="/executor" name="executor" sessionDetails={sessionDetails} component={Executor}/>
                <PrivateRoute path="/logout" name="Logout" sessionDetails={sessionDetails} component={Logout}/>
                <Redirect from="/" to="/timeclock/my"/>
            </Switch>
        )
    } else {
        return (
            <Switch>
                <AdminRoute path="/company/billing" sessionDetails={sessionDetails} name="Company" component={Company}/>
                <PrivateRoute path="/logout" name="Logout" sessionDetails={sessionDetails} component={Logout}/>
                <Redirect to="/company/billing"/>
            </Switch>
        )
    }
};

function accountExpired() {
    const accountExpired = localStorage.getItem("accountExpired");
    return typeof accountExpired !== 'undefined' ? JSON.parse(accountExpired) === true : false;
}

export default LoggedIn;