import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";


class TerminalsTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.getTableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                self.props.data.length < 1 ?
                    <NoData/> :
                self.props.data.map((row, index) => {
                    return <div className={self.props.tableSettings.canEdit ? 'data-row-edit' : 'data-row'} key={index}>
                        <Row className='row-data row-header col-lg-12 margin-top'
                             onClick={(event) => self.props.toggleEditModal(event, row)}>
                            <Col><span>{row.name}</span></Col>
                            <Col><span>{row.loginType}</span></Col>
                            <Col><span>{row.restrictionType}</span></Col>
                            <Col><span>{row.active ? 'Yes' : 'No'}</span></Col>
                            <Col><span>{row.default ? 'Yes' : 'No'}</span></Col>
                            {
                                row.companyId ?
                                <Col className="cursor-text">
                                    <span><a onClick={(event) => self.props.toggleLogoutWarningModal(event, row)}
                                             className="badge badge-success mr-1 delete">Open Terminal</a></span>
                                </Col> :
                                <Col className="cursor-text">
                                <span></span>
                                </Col>
                            }
                            {
                                !self.props.tableSettings.canDelete
                                    ?
                                    ''
                                    :
                                    <Col className="cursor-text">
                                                     <span title="Delete"
                                                           onClick={() => self.props.toggleConfirmDeleteModal(row)}
                                                           className="delete action-icon material-icons">clear</span>
                                    </Col>
                            }
                        </Row>
                    </div>
                })
            }
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default TerminalsTable;
