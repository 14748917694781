import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';

class CreditCard extends Component {
    constructor(props) {
        super(props);
        this.createCreditCard = this.createCreditCard.bind(this);
        this.state = {};
    }

    createCreditCard() {
        const self = this;
        return <div className="custom-cc">
            <Row className="justify-content-lg-between align-items-center mb-4">
                <Col className="col-4"><img src="https://cdn2.mindmeister.com/assets/meisterlabs/graphics/chip-cde2b1dcf9202decf0d02dd03fb46a80308ad14aecd30145096428bde05a5da1.svg" alt=""/></Col>
                <Col className="col-3"><img className="cc-height" src="/img/favicon.svg?v=2" alt=""/></Col>
            </Row>
            <Row className="cc-number mb-2 ">
                <Col className="mr-1" lg="2"><span>****</span></Col>
                <Col className="mr-1" lg="2"><span>****</span></Col>
                <Col className="mr-1" lg="2"><span>****</span></Col>
                <Col lg="2"><span>{self.props.card.last4 ? self.props.card.last4 : '****'}</span></Col>
            </Row>
            <Row lg="12" className="justify-content-lg-between">
                <Col><span>{self.props.card.name}</span></Col>
                <Col className="align-text-right"><span>Exp. </span><span>{self.props.card.exp_month ? ' ' + self.props.card.exp_month + '/' + self.props.card.exp_year : 'XX/XX'}</span></Col>
            </Row>
            <Row lg="12">
                <Col><span>Card Holder</span></Col>
                <Col className="align-text-right"> <span><a className="header-button mr-0"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={this.props.updateCard}
                                                            href={void(0)}>{this.props.card.last4 !== '' ? 'Update' : 'Add Billing Info'}</a></span></Col>
            </Row>

        </div>
    }

    render() {
        return this.createCreditCard();
    }
}

export default CreditCard;
