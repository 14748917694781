import React, {Component} from "react";
import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";

const importFilesStyles = {
    background: '#DCDCDC',
    padding: '10px'
};

class Qbd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isExportLoading: false,

            isIntegrated: false,
            qbdInfo: '',
            qbdConfirmModal: false,
            qbdConfirmPositionModal: false,

            qbdCompanyInfo: {},
        };
    }

    componentDidMount() {
        this.loadData();
    }

    toggleQbdModal = () => {
        this.setState({qbdConfirmModal: !this.state.qbdConfirmModal});
    };

    toggleQbdPositionModal = () => {
        this.setState({qbdConfirmPositionModal: !this.state.qbdConfirmPositionModal});
    };

    selectFile = (e) => {
        if (!e.target.files[0].name.match(/.(iif)$/i)) {
            toast.error('File must be an IIF file', {position: 'bottom-right'});
            return;
        }

        let reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = () => {
            const result = reader.result.split('\t');
            this.setState({
                qbdCompanyInfo: {
                    qbdCompanyName: result[9],
                    qbdCreateTime: result[12].replace(/\D/g, '')
                }
            });
        }
    };

    render() {
        const state = this.state;

        const companySettings = Util.getCompanySettings();

        const qbd = state.isIntegrated
            ? (companySettings.enablePositions
                    ? (<div className="text-center">
                        <Button color="success" onClick={this.exportData} disabled={state.loading}>
                            {state.isExportLoading ? <i className="fa fa-spinner fa-spin mr-2"></i> : ''}Export
                            Data</Button>{' '}
                        <Button color="default" onClick={this.qbdDisconnect} disabled={state.loading}>Disconnect from
                            QuickBooks</Button>
                    </div>)
                    : (<div className="text-center">
                        <Button color="success" onClick={this.toggleQbdPositionModal} disabled={state.loading}>
                            {state.isExportLoading ? <i className="fa fa-spinner fa-spin mr-2"></i> : ''}Export
                            Data</Button>{' '}
                        <Button color="default" onClick={this.qbdDisconnect} disabled={state.loading}>Disconnect from
                            QuickBooks</Button>
                    </div>)
            )
            : (<div>
                <div className="text-center">
                    <img src={'/img/qbo/quickbooks_logo_horz.png'} style={{width: '500px', height: '136px'}}/>
                    <h4>Connect to QuickBooks Desktop</h4>
                    <div className="mb-2">Sync your Fishbowl Time payroll to your QuickBooks Desktop company. No more
                        double entry.
                    </div>
                    <img src={'/img/qbo/C2QB_white_btn_med_default.png'}
                         style={{width: '136px', height: '48px', cursor: 'pointer'}}
                         onMouseOver={e => (e.currentTarget.src = '/img/qbo/C2QB_white_btn_med_hover.png')}
                         onMouseOut={e => (e.currentTarget.src = '/img/qbo/C2QB_white_btn_med_default.png')}
                         onClick={this.toggleQbdModal}/>
                </div>
            </div>);

        const companyName = state.isIntegrated && state.qbdCompanyInfo.qbdCompanyName
            ? <h3 className="text-center mb-4">{state.qbdCompanyInfo.qbdCompanyName}</h3>
            : '';

        return (
            <div className="animated fadeIn">
                <div className="module-header">
                    QuickBooks Desktop
                </div>
                <Container fluid>
                    <br/>
                    {companyName}
                    {qbd}
                    <Modal isOpen={state.qbdConfirmModal} toggle={this.toggleQbdModal}
                    >
                        <ModalHeader>Integrate with QuickBooks Desktop</ModalHeader>
                        <ModalBody>
                            <div className="mb-2">
                                <a href="#">Please follow the instructions to
                                    integrate with QuickBooks Desktop</a>
                                <p className="mt-1">{'Integrating with QuickBooks Desktop will enable ' + companySettings.projectPluralName + ' and require employees to ' +
                                'clock into  ' + companySettings.projectPluralName + '.'}</p>
                            </div>
                            <div style={importFilesStyles}>Import your Timer List IIF.
                                <input type="file" className="btn btn-sm" onChange={(e) => this.selectFile(e)}/>
                            </div>
                            <br/>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.qbdIntegrate}
                                    disabled={state.loading}>Confirm</Button>{' '}
                            <Button color="secondary" onClick={this.toggleQbdModal}
                                    disabled={state.loading}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
                <Container fluid>
                    <Modal isOpen={state.qbdConfirmPositionModal} toggle={this.toggleQbdPositionModal}
                    >
                        <ModalHeader>Enable Positions</ModalHeader>
                        <ModalBody>
                            <div>Positions must be enabled to export data to QuickBooks Desktop. Would you like to
                                enable Positions and continue with the export?
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.enableAndExportData}
                                    disabled={state.loading}>Enable and Export</Button>{' '}
                            <Button color="secondary" onClick={this.toggleQbdPositionModal}
                                    disabled={state.loading}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </div>
        )
    }

    enableAndExportData = () => {
        this.toggleQbdPositionModal();
        this.exportData();
    };

    loadData = () => {
        this.setState({loading: true});

        // After user integrates, company settings change. Update session storage here.
        const apiUrl = Util.apiUrl('company/settings');
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                localStorage.setItem("companySettings", JSON.stringify(json));
                this.setState({isIntegrated: json.qbdIntegrated});

                if (json.qbdCompanyName && json.qbdCreateTime) {
                    this.setState({
                        qbdCompanyInfo: {
                            qbdCompanyName: json.qbdCompanyName,
                            qbdCreateTime: json.qbdCreateTime
                        }
                    });
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    };

    exportData = () => {
        this.setState({loading: true, isExportLoading: true});

        const apiUrl = Util.apiUrl('integrations/qbd-export-data');
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const temp = document.createElement('a');
                const file = new Blob([json]);
                temp.href = URL.createObjectURL(file);
                temp.download = 'QuickBooks-Desktop-Timer-Activities' + Date.now() + '.iif';
                temp.click();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, isExportLoading: false}));
    };

    qbdIntegrate = () => {
        if (!this.state.qbdCompanyInfo.qbdCompanyName || !this.state.qbdCompanyInfo.qbdCreateTime) {
            toast.error("Please upload your Timer List IIF file.", {position: 'bottom-right'});
            return;
        }

        const qbdImport = {
            companyName: this.state.qbdCompanyInfo.qbdCompanyName,
            createTime: this.state.qbdCompanyInfo.qbdCreateTime
        };

        const apiUrl = Util.apiUrl(`integrations/qbd`);
        const params = {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(qbdImport),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                localStorage.setItem("companySettings", JSON.stringify(json));
                this.setState({
                    isIntegrated: json.qbdIntegrated,
                    qbdCompanyInfo: {
                        qbdCompanyName: json.qbdCompanyName,
                        qbdCreateTime: json.qbdCreateTime
                    },
                    qbdConfirmModal: false
                });

                toast.success("You are integrated with QuickBooks Desktop", {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    };

    qbdDisconnect = () => {
        const apiUrl = Util.apiUrl(`integrations/qbd-disconnect`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                localStorage.setItem("companySettings", JSON.stringify(json));
                this.setState({
                    isIntegrated: json.qbdIntegrated,
                    qbdCompanyInfo: {
                        qbdCompanyName: json.qbdCompanyName,
                        qbdCreateTime: json.qbdCreateTime
                    }
                });

                toast.success("You have been disconnected from QuickBooks Desktop", {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false}));
    };
}

export default Qbd;