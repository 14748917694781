import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Container, Input, InputGroup, Label, Row} from 'reactstrap';
import {CountryDropdown} from 'react-country-region-selector';
import { ToastContainer, toast } from 'react-toastify';
import * as Util from '../../../components/Framework/Util';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import moment from 'moment';

const to = require('to-case');

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            company: '',
            phone: '',
            country: 'United States',
            tos: false,
            timezone: null,
            timezones: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTosChange = this.handleTosChange.bind(this);
    }

    componentDidMount() {
        this.loadTimezones();
    }

    loadTimezones = () => {
        const apiUrl = Util.apiUrl(`util/time_zone_list`);
        const params = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let currentTimezone = null;
                const abbrev = moment.tz(moment.tz.guess()).format('z');
                if(abbrev) {
                    currentTimezone = json.find(x => x.name === abbrev);
                }

                if(!currentTimezone) {
                    currentTimezone = json.find(x => x.name === 'UNKNOWN');
                }

                this.setState({ timezones: json, timezone: currentTimezone });
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {});
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="mx-4">
                                <CardBody className="p-4 text-center">
                                    <form onSubmit={this.handleSubmit} id="login-form" noValidate>
                                        <img style={{width: '100%'}} src="https://s3.amazonaws.com/fishbowllabor/StaticContent/fbtimelogo.png"/>
                                        <p>&nbsp;</p>

                                        <div className="errorDiv" id="firstNameError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">person</span>
                                            </span>
                                            </div>
                                            <input type="text" placeholder="First Name" name="firstName" ref="firstName"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <div className="errorDiv" id="lastNameError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">person</span>
                                            </span>
                                            </div>
                                            <input type="text" placeholder="Last Name" name="lastName" ref="lastName"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <div className="errorDiv" id="usernameError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">person_outline</span>
                                            </span>
                                            </div>
                                            <input type="username" pattern=".{6,50}" placeholder="Username" name="username" ref="username"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <div className="errorDiv" id="emailError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">mail</span>
                                            </span>
                                            </div>
                                            <input type="email" placeholder="Email" name="email" ref="email"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <div className="errorDiv" id="companyError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">business</span>
                                            </span>
                                            </div>
                                            <input type="text" placeholder="Company" name="company" ref="company"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <div className="errorDiv" id="phoneError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">call</span>
                                            </span>
                                            </div>
                                            <input type="tel" placeholder="Phone" name="phone" ref="phone"
                                                   className="form-control" onChange={this.handleChange} required/>
                                        </InputGroup>

                                        <div className="errorDiv" id="countryError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="material-icons">public</span>
                                            </span>
                                            </div>
                                            <CountryDropdown className="form-control" priorityOptions={['United States', 'US', 'Canada', 'CA', 'Australia', 'AU', 'New Zealand', 'NZ' ]} name="country" ref="country" defaultOptionLabel="Country"
                                                             value={this.state.country}
                                                             onChange={(val) => this.setState({country: val})}/>
                                        </InputGroup>

                                        <div className="errorDiv" id="timezoneError"/>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <span className="material-icons">public</span>
                                                </span>
                                            </div>
                                            <select name="timezone" ref="timezone"
                                                onChange={(e) => {
                                                    const timezone = this.state.timezones.find(x => x.name === e.target.value);
                                                    this.setState({ timezone: timezone });
                                                }}
                                                value={this.state.timezone ? this.state.timezone.name : null}
                                                className="form-control pull-right"
                                                style={{width: 150 + 'px'}}
                                            >
                                                {this.state.timezones.map(timezone => (
                                                    <option key={timezone.name} value={timezone.name}>{timezone.displayName}</option>
                                                ))}
                                             </select>
                                        </InputGroup>

                                        <InputGroup className="mb-3">
                                        <label className="ml-3">Accept<a target="_blank" rel="noreferrer" href="https://www.fishbowlinventory.com/products/fishbowl-time/terms-of-service/" style={{color: "#002aef"}}> Terms of Service</a></label>

                                        <Label className="switch switch-icon switch-pill switch-info"
                                                style={{ position: 'absolute', right: '0'}}
                                                title="tos">
                                                    <Input type="checkbox" name="tos"
                                                    className="switch-input"
                                                    id="tos"
                                                    checked={this.state.tos}
                                                    onChange={this.handleTosChange} />
                                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className="switch-handle"/>
                                            </Label>
                                        </InputGroup>

                                        <Button id="btn" ref="btn" disabled={this.state.loading} color="success" block>
                                            {this.state.loading
                                                ?
                                                <span><i className="fa fa-spinner fa-spin mr-2"></i>Processing...</span>
                                                : <span>Create Account</span>}
                                        </Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        );
    }

    handleChange(e) {
        e.target.classList.add('active');
        this.setState({[e.target.name]: e.target.value});
        this.showInputError(e.target.name);
    }

    showInputError(refName) {
        if (!refName || refName === 'timezone') {
            return true;
        }
        const validity = this.refs[refName].validity;
        const name = this.refs[refName].name;
        const error = document.getElementById(refName + 'Error');

        if (!validity) {
            return true;
        }

        if (!validity.valid) {
            if (validity.valueMissing) {
                error.textContent = to.sentence(name) + ' is a required field';
            } else if (validity.typeMismatch) {
                error.textContent = to.sentence(name) + ' should be a valid email address';
            } else if (refName === "username" && validity.patternMismatch) {
                error.textContent = to.sentence(name) + ' should be between 6 and 50 characters';
            } else if (validity.patternMismatch) {
                error.textContent = to.sentence(name) + ' should be longer than 4 chars';
            }
            return false;
        }

        error.textContent = '';
        return true;
    }

    showFormErrors() {
        const inputs = document.querySelectorAll('input.form-control');
        let isFormValid = true;

        inputs.forEach(input => {
            if(input.id !== 'tos' && input.id !== 'timezone') {
                input.classList.add('active');
                const isInputValid = this.showInputError(input.name);

                if (!isInputValid) {
                    isFormValid = false;
                }
            }
        });

        return isFormValid;
    }

    handleTosChange(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleSubmit(event) {
        event.preventDefault();

        if(!this.state.tos) {
            toast.error('Please Accept Terms of Service to continue.', {position: 'bottom-right'});
            this.showFormErrors();
            return;
        }
        if (!this.showFormErrors()) {
            return;
        }

        const self = this;
        self.setState({loading: true});
        let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            email: this.state.email,
            company: this.state.company,
            country: this.state.country,
            phone: this.state.phone,
            timezone: this.state.timezone.name
        };

        const apiUrl = Util.apiUrl(`register/registrant`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json) {
                if (json.errorMessage) toast.error(json.errorMessage, {position: 'bottom-right'});
                else window.location.href = "/register/success";
            } else toast.error("Unable to complete registration at this time.", {position: 'bottom-right'});
        }).catch(error => ServerResponseHandler.handleError(error.message)
        ).finally(() => self.setState({loading: false}));
    }
}

export default Register;
