import React, {useState} from 'react';

export default function useTooltip(initialValue) {
    const [tooltip, setTooltip] = useState(initialValue);

    function toggleTooltip(tt, ellipsesOverride = false) {
        let element = document.getElementById(tt);
        let ellipsisActive = element.offsetWidth < element.scrollWidth;
        if(ellipsisActive || ellipsesOverride) {
            let Tooltip = {...tooltip};
            Tooltip[tt] = !Tooltip[tt];
            setTooltip(Tooltip);
        }
    }

    return [
        tooltip,
        toggleTooltip
    ]
}