import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import * as Util from "../Framework/Util";

export default class SelectPtoStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ptoStatus: {value: this.props.ptoStatus, label: this.props.ptoStatus},
            ptoStatusOptions: [
                {value: 'Pending', label: 'Pending'},
                {value: 'Denied', label: 'Denied'}
            ]
        }
    }

    componentDidMount() {
        let options = this.state.ptoStatusOptions;
        if (Util.isManager()) {
            options = [{value: 'Approved', label: 'Approved'}, ...options];
        }

        if (this.props.includeAll) {
            options = [{value: 'All', label: 'All'}, ...options];
        }

        this.setState({ptoStatusOptions: options});
    }

    handleChange = newPtoStatus => {
        if (newPtoStatus && newPtoStatus.constructor === Array) {
            newPtoStatus = this.props.includeAll
                                ? 'All'
                                : Util.isManager()
                                    ? 'Approved'
                                    : 'Pending';
        }

        this.setState({ptoStatus: newPtoStatus});
        this.props.onChange(newPtoStatus.value);
    };

    render() {
        return (
            <div className="fb-select">
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    value={this.state.ptoStatus} options={this.state.ptoStatusOptions}
                    onChange={this.handleChange}
                    placeholder={'Select a Pto Status...'}
                />
            </div>
        )
    }
}

SelectPtoStatus.propTypes = {
  onChange: PropTypes.func.isRequired,
  ptoStatus: PropTypes.string,
  includeAll: PropTypes.bool
};

SelectPtoStatus.defaultProps = {
  includeAll: true
};