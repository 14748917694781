import React from 'react';
import {Row, Col, Tooltip} from 'reactstrap';
import useTooltip from "../../../Hooks";


function PdfColumnHeaders(props) {
    const [tooltip, setTooltip] = useTooltip({});

    return (
        <Row className={'row-header col-header'}>
            {
                props.pdfColumnHeaders.map((column, index) => {
                    return  column.showColumn ? <Col key={index} id={'header' + index}>
                        <Tooltip target={'header' + index} isOpen={tooltip['header' + index]} toggle={() => setTooltip('header' + index)}>{column.title}</Tooltip>
                        {column.title}
                    </Col> : ''
                })
            }
        </Row>
    )
}
export default PdfColumnHeaders;
