import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history'
import {Router, Route, Switch} from 'react-router-dom';
import config from "react-global-configuration";
// Styles
// Import Font Awesome Icons Set
import 'font-awesome/scss/font-awesome.scss';
import '@fortawesome/fontawesome-free/css/all.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../node_modules/bootstrap/scss/bootstrap.scss';
import '../scss/style.scss'
// Temp fix for reactstrap
import '../scss/core/_dropdown-menu-right.scss'

import "@babel/polyfill";

// Containers
import Full from './containers/Full/';

import Login from './views/Pages/Login/';
import Register from './views/Pages/Register/';
import RegisterSuccess from './views/Pages/Register/RegisterSuccess';
import RegisterFinalize from './views/Pages/Register/RegisterFinalize';
import Page404 from './views/Pages/Page404/';
import Page500 from './views/Pages/Page500/';
import Print from "./views/Pages/Print";
import PasswordReset from "./views/Pages/PasswordReset/PasswordReset";
import PasswordResetSuccess from "./views/Pages/PasswordReset/PasswordResetSuccess";
import PasswordResetFinalize from "./views/Pages/PasswordReset/PasswordResetFinalize";
import Terminal from "./views/Pages/Terminal/Terminal";

const globalConfig = {
    apiBaseUrl : process.env.REACT_APP_API_BASE_URL
};

config.set(globalConfig);

ReactDOM.render((
    <Router history={createBrowserHistory()}>
        <Switch>
            <Route exact path="/login" name="Login Page" component={Login}/>
            <Route exact path="/terminal/:terminalId/:companyId" name="Terminal" component={Terminal}/>
            <Route exact path="/register" name="Register Page" component={Register}/>
            <Route exact path="/register/success" name="Registration Success" component={RegisterSuccess}/>
            <Route exact path="/register/finalize" name="Finalize Registration" component={RegisterFinalize}/>
            <Route exact path="/reset_password" name="Password Reset" component={PasswordReset}/>
            <Route exact path="/reset_password/success" name="Password Reset" component={PasswordResetSuccess}/>
            <Route exact path="/reset_password/finalize" name="Finalize Password Reset" component={PasswordResetFinalize}/>
            <Route exact path="/404" name="Page 404" component={Page404}/>
            <Route exact path="/500" name="Page 500" component={Page500}/>
            <Route exact path="/print" name="Print" component={Print}/>
            <Route path="/" name="Home" component={Full}/>
        </Switch>
    </Router>
), document.getElementById('root'));
// registerServiceWorker();
