import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import * as qs from 'query-string';

class PasswordResetSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm: '',
            email: '',
            registrationCode: '',
            pathParams: qs.parse(location.search),
        };
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="mx-4">
                                <CardBody className="p-4">
                                    <h1>Password Reset Successful.</h1>
                                    <p className="text-muted">Check your email inbox for further instructions.</p>
                                    <Button color="primary" block onClick={this.backButton}>Back</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    backButton(e) {
        window.location.href='/login';
    }
}

export default PasswordResetSuccess;