import React, {useState, useEffect} from 'react';
import {Row, Col, Tooltip} from 'reactstrap';
import NoData from "./NoData";
import useTooltip from "../../Hooks"
import * as Util from "../Framework/Util";
import _ from "lodash";

function ProjectsTable(props) {
    const [lastOrderSelected, setLastOrderSelected] = useState('');
    const [userData, setUserData] = useState([]);
    const [tooltip, setTooltip] = useTooltip({});
    const customerSettings = Util.getCompanySettings();

    useEffect(() =>{
        loadData();
    }, [props.data])

    function loadData(columnHeader){
        if (columnHeader) {
            switch (columnHeader) {
                case 'Last Activity':
                    columnHeader = 'lastActivity';
                    break;
                case 'Budgeted Hours':
                    columnHeader = 'budgetedHours';
                    break;
                case 'Actual Hours':
                    columnHeader = 'actualHours';
                    break;
                case 'Project Status':
                    columnHeader = 'projectStatus';
                    break;
                case 'Project Pick Status':
                    columnHeader = 'pickStatus';
                    break;
                case 'BOM Category':
                    columnHeader = 'bomCategory';
                    break;
                case 'UUID':
                    columnHeader = 'id';
                case 'Date Last Modified':
                    columnHeader = 'dateLastModified';
                    break;
                default:
                    columnHeader = columnHeader.toLowerCase();
            }

            if (props.data && columnHeader !== lastOrderSelected) {
                switch (columnHeader) {
                    case 'number':
                        setUserData(_.sortBy(props.data, (d) => d.number.toLowerCase()));
                        break;
                    case 'name':
                        setUserData(_.sortBy(props.data, (d) => d.name.toLowerCase()));
                        break;
                    case 'description':
                        setUserData(_.sortBy(props.data, (d) => d.description ? d.description.toLowerCase() : 'zzz')); // Put data without description at bottom
                        break;
                    case 'lastActivity':
                        setUserData(_.sortBy(props.data, (d) => d.lastClockOut));
                        break;
                    case 'budgetedHours':
                        setUserData(_.sortBy(props.data, (d) => parseInt(d.budgetedHours)));
                        break;
                    case 'bomCategory':
                        setUserData(_.sortBy(props.data, (d) => d.bomCategory ? d.bomCategory.toLowerCase() : 'zzz')); // Put data without BOM Category at bottom
                        break;
                    default:
                        setUserData(_.sortBy(props.data, columnHeader));
                }
                setLastOrderSelected(columnHeader);
            } else {
                switch (columnHeader) {
                    case 'number':
                        setUserData(_.sortBy(props.data, (d) => d.number.toLowerCase()).reverse());
                        break;
                    case 'name':
                        setUserData(_.sortBy(props.data, (d) => d.name.toLowerCase()).reverse());
                        break;
                    case 'description':
                        setUserData(_.sortBy(props.data, (d) => d.description ? d.description.toLowerCase() : 'zzz').reverse());
                        break;
                    case 'lastActivity':
                        setUserData(_.sortBy(props.data, (d) => d.lastClockOut).reverse());
                        break;
                    case 'budgetedHours':
                        setUserData(_.sortBy(props.data, (d) => parseInt(d.budgetedHours)).reverse());
                        break;
                    case 'bomCategory':
                        setUserData(_.sortBy(props.data, (d) => d.bomCategory ? d.bomCategory.toLowerCase() : 'zzz').reverse());
                        break;
                    default:
                        setUserData(_.sortBy(props.data, columnHeader).reverse());
                }
                setLastOrderSelected('')
            }
        } else {
            setUserData(_.sortBy(props.data, (d) => d.number.toLowerCase()));
            setLastOrderSelected('number');
        }
    }

    return (
        <div>
            <Row className="row-header col-header col-lg-12">
                {
                    props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index} onClick={() => loadData(column.displayName)}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                userData.length < 1 ?
                    <NoData/> :
                userData.map((row, index) => {
                    return <div
                        className={props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                        key={index}>
                        <Row className='row-data row-header col-lg-12'
                             onClick={(event) => props.toggleEditModal(event, row)}>
                            {props.tableColumns.some(x => x.displayName === 'UUID') && <Col id={'uuid' + row.id} className="ellipsis"><span>{row.id}</span></Col>}
                            <Col className="ellipsis" id={'projectNumber' + index}><span>{row.number}</span></Col>
                            <Col className="ellipsis" id={'projectName' + index}><span>{row.name}</span></Col>
                            <Col className="ellipsis" id={'projectDescription' + index}><span>{row.description}</span></Col>
                            <Col><span>{row.lastActivity ? row.lastActivity : '-'}</span></Col>
                            <Col><span>{row.budgetedHours}</span></Col>
                            <Col><span>{customerSettings.roundToNearest !== 0 ? (row.actualRoundedHours ? Util.formatTimeFromSeconds(row.actualRoundedHours) : '-') : (row.actualHours ? Util.formatTimeFromSeconds(row.actualHours) : '-')}</span></Col>
                            <Col><span>{row.projectStatus}</span></Col>
                            <Col><span>{row.pickStatus}</span></Col>
                            <Col><span>{row.bomCategory}</span></Col>
                            {props.tableColumns.some(x => x.displayName === 'Date Last Modified') && <Col id={'dateLastModified' + index} className="ellipsis"><span>{row.dateLastModified}</span></Col>}

                            {props.tableColumns.some(x => x.displayName === 'Date Last Modified') && <Tooltip target={'dateLastModified' + index} isOpen={tooltip['dateLastModified' + index]} toggle={() => setTooltip('dateLastModified' + index)}>{row.dateLastModified}</Tooltip> }
                            {props.tableColumns.some(x => x.displayName === 'UUID') && <Tooltip target={'uuid' + row.id} isOpen={tooltip['uuid' + row.id]} toggle={() => setTooltip('uuid' + row.id)}>{row.id}</Tooltip> }
                            <Tooltip target={'projectNumber' + index} isOpen={tooltip['projectNumber' + index]} toggle={() => setTooltip('projectNumber' + index)}>{row.number}</Tooltip>
                            <Tooltip target={'projectName' + index} isOpen={tooltip['projectName' + index]} toggle={() => setTooltip('projectName' + index)}>{row.name}</Tooltip>
                            <Tooltip target={'projectDescription' + index} isOpen={tooltip['projectDescription' + index]} toggle={() => setTooltip('projectDescription' + index)}>{row.description}</Tooltip>
                        </Row>
                    </div>
                })
            }
        </div>
    )
}

export default ProjectsTable;