import React, {Component} from "react";
import * as Util from "../Framework/Util";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";

class AuditTypeDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: localStorage.getItem("isAdmin"),
            auditTypeList: [],
            currentAuditType: ''
        };

        this.getAuditTypes();
    }

    getAuditTypes() {
        let url = Util.apiUrl('audit/availableObjectTypes');
        fetch(url, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then((json) => this.configureAuditListDD(json))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    configureAuditListDD(json) {
        let auditList = [];

        for (let key of Object.keys(json).sort()) {
            auditList.push(<option key={key} value={key}>{json[key]}</option>)
        }

        auditList.sort();
        this.setState({auditDDList: auditList});
    }

    render() {
        return (
            <select name="auditType" ref="auditType"
                    onChange={(e) => {
                        this.props.auditTypeChanged(e.target.value)
                    }}
                    className="form-control pull-left input-group"
                    style={{width: 300 + 'px'}}
                    defaultValue="empty"
                   >
                <option key="0" value="empty" hidden disabled>Select an Option</option>

                {this.state.auditDDList}
            </select>
        );
    }
}

export default AuditTypeDropDown;