import React from 'react';

import Store from "../../context"
import LoggedIn from "../LoggedIn/LoggedIn";


function Full(props) {
    return (
        <div className="app">
            <Store>
                <LoggedIn{...props}/>
            </Store>
        </div>
    );
}

export default Full;
