import React, {Component} from 'react';
import {CardElement, Elements, injectStripe} from 'react-stripe-elements';
import {InputGroup, Label} from "reactstrap";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";

class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            card: {
                name: props.card.name,
                address_city: props.card.address_city,
                address_line1: props.card.address_line1,
                address_state: props.card.address_state,
                country: 'United States'
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.getToken = this.getToken.bind(this);
    }

    componentDidMount() {
        this.props.setSubmit(this.getToken);
    }

    handleCountryChange = (country) => {
        if (country) {
            let card = {...this.state.card};
            card.country = country;
            this.setState({card: card});
        }
    };


    handleChange = (e) => {
        if (e.target) {
            e.target.classList.add('active');
            let card = this.state.card;
            card[e.target.name] = e.target.value;
            this.setState({card: card});
        }
    };

    getToken(ev) {
        ev.preventDefault();
        this.props.stripe.createToken(this.state.card)
            .then(({token}) => {
                if (token) {
                    this.props.updateSubscription(token);
                }
            })
            .catch((err) => {
                console.error("Error creating token:", err);
            });
    }

    render() {
        const card = this.state.card;
        return (
            <div className="checkout">
                    <Label>Name<span className="error">*</span></Label>
                    <InputGroup className="mb-3">
                        <input type="text" placeholder="Name" name="name" ref="name" value={card.name}
                               className="form-control tl-required" onChange={this.handleChange} required/>
                    </InputGroup>
                    <Label>Street</Label>
                    <InputGroup className="mb-3">
                        <input type="text" placeholder="Street" name="address_line1" ref="address_line1" value={card.address_line1} className="form-control" onChange={this.handleChange} required/>
                    </InputGroup>

                    <Label>City</Label>
                    <InputGroup className="mb-3">
                        <input type="text" placeholder="City" name="address_city" ref="address_city" value={card.address_city} className="form-control" onChange={this.handleChange} required/>
                    </InputGroup>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <Label>Country</Label>
                            <InputGroup className="mb-3">
                                <CountryDropdown classes="form-control" id="country" name="country"
                                                 required value={card.country} onChange={this.handleCountryChange}/>
                            </InputGroup>
                        </div>

                        <div className="col-md-6">
                            <Label>{card.country === 'United States' ? 'State' : 'Region'}</Label>
                            <InputGroup className="mb-3">
                                <RegionDropdown classes="form-control" id="address_state" name="address_state" ref="address_state" country={card.country} required value={card.address_state} onChange={(val, e) => this.handleChange(e)}/>
                            </InputGroup>
                        </div>
                    </div>

                <br/>
                <CardElement />
            </div>
        );
    }
}

export default injectStripe(CheckoutForm);