import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Card, CardHeader, CardBody} from "reactstrap";
import * as Util from "../../../components/Framework/Util";
import {SearchBar} from "../../../components/Framework/SearchBar";
import TimeOffRequestModal from "../../../components/Modal/TimeOffRequestModal";
import 'react-dates/initialize';
import ManageTimeOffRequestTable from "../../../components/DataTables/ManageTimeOffRequestTable";
import moment from "moment";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || Util.getCompanySettings().allowEditTime;
const canDelete = isAdminOrManager;
const endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

class ManageTimeOffRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 20,
            employee: null,
            newDialog: false,
            ptoRequest: null,
            confirmDeleteDialog: false,
            searchParams: {
                endDate: endDate,
                ptoStatus: "Pending",
                startDate: startDate
            }
        };

        this.getTableColumns = this.getTableColumns.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.clearRequest = this.clearRequest.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.approveAllPtoRequests = this. approveAllPtoRequests.bind(this);}

    setSearchParams(search) {
        this.setState({
            searchParams: search,
        });
    }

    getTableColumns() {
        return [
            {displayName: 'Date', hideColumn: false},
            {displayName: 'Type', hideColumn: false},
            {displayName: 'Hours', hideColumn: false},
            {displayName: 'Notes', hideColumn: false},
            {displayName: 'Status', hideColumn: false},
            {displayName: 'Action', hideColumn: !canDelete}
        ];
    }

    toggleNewModal() {
        this.setState({
            newDialog: !this.state.newDialog,
            ptoRequest: null,
        });
    }

    toggleConfirmDeleteModal(row, shouldDelete) {
        this.setState({
            row: row,
            confirmDeleteDialog: !this.state.confirmDeleteDialog,
        });
        if(shouldDelete) {
            this.props.deletePtoRequest(row.ptoRequestId, row.employeeCode, row)
        }
    }

    clearRequest() {
        this.setState({ptoRequest: null});
    }

    approveAllPtoRequests(){
        let requests = this.props.employeePtoGroup;
        for (let  request of requests) {
            for (let row of request){
                this.props.updateEmployeePtoStatus(row.row, 'Approved');
            }
        }
    }

    render() {
        const csvProperties = {
            csvHeader: [
                {label: 'Employee Name', key: 'name'},
                {label: 'Type', key: 'type'},
                {label: 'Date', key: 'datesRequested'},
                {label: 'Hours', key: 'hours'},
                {label: 'Notes', key: 'note'},
                {label: 'Status', key: 'status'},
                {label: 'Department', key: 'departmentName'},
            ],
            data: this.props.employeeCsvData,
            csvFilename: 'ptoRequests.csv'
        };

        const buttons = {
            approveAll: this.approveAllPtoRequests,
            add: this.toggleNewModal,
            download: csvProperties,
            savePdf: false,
            loading: this.state.loading
        };

        return (
            <div className="animated fadeIn">
                <Container fluid>
                    {
                        !this.state.newDialog ? '' :
                        <TimeOffRequestModal
                            addPtoRequest={this.props.addPtoRequest}
                            newDialog={this.state.newDialog}
                            ptoRequest={this.state.ptoRequest}
                            toggleModal={this.toggleNewModal}
                            showEmployeeList={true}
                        />
                    }
                    <Modal isOpen={this.state.confirmDeleteDialog} className="modal-primary">
                        <ModalHeader>Delete PTO Request</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete this PTO request?
                            <br/>
                            <strong>{this.state.laborName}</strong>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => this.toggleConfirmDeleteModal(this.state.row, true)}>Delete</Button>{' '}
                            <Button color="secondary"
                                    onClick={() => this.toggleConfirmDeleteModal(null)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Card className="col-lg-12 mt-3 card-accent-primary">
                        <CardHeader><h5>Employee PTO Requests</h5></CardHeader>
                        <CardBody>
                            <SearchBar
                                buttons={buttons}
                                loading={this.state.loading}
                                onSearch={this.props.getEmployeePtoRequests}
                                includeSearchLabel={true}
                                includeDateRange={true}
                                includeEmployee={true}
                                includeDepartment={Util.isAdmin()}
                                includePtoStatus={true}
                                setSearchParams={this.setSearchParams}
                                startDate={moment(this.state.searchParams.startDate)}
                                endDate={moment(this.state.searchParams.endDate)}
                                isPtoFilter={true}
                                ptoStatus={this.state.searchParams.ptoStatus}
                                loadSearchDefault={true}
                                includeShowAllPendingButton={true}
                                getSearchBarApi={this.props.setSearchBarApi}
                            />
                            {
                                <ManageTimeOffRequestTable
                                    data={this.props.employeePtoGroup}
                                    updateEmployeePtoStatus={this.props.updateEmployeePtoStatus}
                                    tableColumns={this.getTableColumns()}
                                    toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                    tableSettings={{
                                        canDelete: canDelete,
                                        canEdit: canEdit
                                    }}

                                />
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default ManageTimeOffRequests;