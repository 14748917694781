import React, {Component} from 'react';
import {
    Container,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, CardHeader, Label, Input
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import {geolocated} from 'react-geolocated';
import {SearchBar} from "../../../components/Framework/SearchBar";
import TimeTable from "../../../components/DataTables/TimeTable";
import _ from "lodash";
import TimeLogModal from "../../../components/Modal/TimeLogModal";
import ConfirmDeleteModal from "../../../components/Modal/ConfirmDeleteModal";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import BreakModal from "../../../components/Modal/BreakModal";
import MapContainer from "../../../components/Map/MapContainer";
import BreakRuleTable from "../../../components/DataTables/BreakRuleTable";
import {apiSearchUrl, basicDateFormatter} from "../../../components/Framework/Util";
import DuplicateTimeLogModal from "../../../components/Modal/DuplicateTimeLogModal";
import CustomModal from "../../../components/Modal/CustomModal";
import { ToastContainer, toast } from 'react-toastify';


const moment = require('moment-timezone');
const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || Util.getCompanySettings().allowEditTime;
const canDelete = isAdminOrManager;
const companySettings = Util.getCompanySettings();
const trackLocation = companySettings.allowGeoLocation;
let latitude;
let longitude;
let searchBarApi = {};

class ManageTimeCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shiftBreaks: [],
            deleteShift: null,
            deleteDialog: {isOpen: false},
            maxResults: 0,
            page: 1,
            sizePerPage: 20,
            sorted: null,
            positions: [],
            employee: null,
            project: null,
            position: null,
            loading: false,
            geo: null,
            id: null,
            employeeCode: null,
            employeeName: '',
            projectId: '',
            positionId: '',
            clockIn: '',
            clockOut: '',
            overrideAutoBreak: false,
            breakTimeHours: 0,
            breakTimeMinutes: 0,
            note: '',
            searchParams: {filterByEmployee: false},
            locationTitle: '',
            csvData: [],
            activeTab: 'time-cards',
            payrolls: [],
            confirmApproveModalOpen: false,
            selectedPayPeriodForApproval: null,
            duplicateTimeLogModalOpen: false,
            duplicateTimeLog: null,
            firstLoad: true,
            timeLogLinkId: null,
            backToDashboardModalOpen: false,
            pto: null
        };
        this.parseUserPositions = this.parseUserPositions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.deleteLog = this.deleteLog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showOpen = this.showOpen.bind(this);
        this.parseTableData = this.parseTableData.bind(this);
        this.parseCsvData = this.parseCsvData.bind(this);
        this.loadEmployee = this.loadEmployee.bind(this);
        this.convertTimeToHours = this.convertTimeToHours.bind(this);
        this.getTableColumns = this.getTableColumns.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.deleteLog = this.deleteLog.bind(this);
        this.getNumberOfHeaderColumns = this.getNumberOfHeaderColumns.bind(this);
        this.toggleBreakModal = this.toggleBreakModal.bind(this);
        this.handleBreakSubmit = this.handleBreakSubmit.bind(this);
        this.deleteBreak = this.deleteBreak.bind(this);
        this.toggleGeoLocationModal = this.toggleGeoLocationModal.bind(this);
        this.setSearchBarApi = this.setSearchBarApi.bind(this);
        this.getNewShiftLog = this.getNewShiftLog.bind(this);
        this.createTableColumns = this.createTableColumns.bind(this);
        this.loadPayrolls = this.loadPayrolls.bind(this);
        this.approvePayPeriod = this.approvePayPeriod.bind(this);
        this.approvalRequired = this.approvalRequired.bind(this);
    }

    getTableColumns() {
        return [
            {displayName: this.state.searchParams && this.state.searchParams.filterByEmployee ? 'Date' : 'Employee', hideColumn: false},
            {displayName: 'Clock In', hideColumn: false},
            {displayName: 'Clock Out', hideColumn: false},
            {displayName: 'Time', hideColumn: false},
            {displayName: 'Break', hideColumn: false},
            {displayName: 'Position', hideColumn: !this.state.showPositionColumn},
            {displayName: companySettings.projectSingleName, hideColumn: !this.state.showProjectColumn},
            {displayName: 'Shift Note', hideColumn: false},
            {displayName: 'Delete', hideColumn: !canDelete},
            {displayName: 'Posted', hideColumn: !this.state.showPostedColumn}
        ];
    }

    componentDidMount() {
        this.loadData();
        let tab = this.props.location.pathname === '/manage' ? 'time-cards' : this.props.location.pathname.replace('/manage/', '');
        this.setState({activeTab: tab});
    }

    getNumberOfHeaderColumns() {
        const tableColumns = this.getTableColumns();
        let columnCount = 0;
        tableColumns.map((column) => {
            if (!column.hideColumn) {
                columnCount += 1;
            }
        });
        return columnCount;
    }

    createTableColumns() {
        let columns = [];
        let numberOfColumns = this.getNumberOfHeaderColumns();
        for(let i = 0; i < numberOfColumns; i++) {
            columns.push(<div></div>)
        }
        return columns;
    }

    showOpen() {
        let search = {
            showOpen: true
        }
        this.loadData(search);
    }

    toggle(tab) {
        let path = '/manage/' + tab;
        this.props.history.push(path);
        this.setState({
            activeTab: tab,
        });
    }

    setSearchBarApi(api) {
        searchBarApi = api;
    }

    parseUserPositions(userDetails) {
        if (userDetails.userTenantPositionList) {
            let positions = [];
            userDetails.userTenantPositionList.filter(x => !x.position.deletedOn).forEach(pos => {
                const item = {id: pos.positionId, name: pos.position.name};
                positions.push(item);
                if (this.state.positionId === pos.positionId) {
                    this.setState({positionId: pos.positionId, position: item});
                }
            });

            this.setState({positions: _.sortBy(positions, [position => position.name.toLowerCase()])});
        }
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    setSearchParams(search) {
        this.setState({searchParams: search});
    }

    getNewShiftLog() {
        return {
            id: null,
            project: '',
            projectId: '',
            clockIn:  Util.formatDateFromUtc(Date.now()),
            clockOut: Util.formatDateFromUtc(Date.now()),
            roundedClockIn: Util.formatDateFromUtc(Util.roundDateTo(Date.now(), moment.duration(companySettings.roundToNearest, "minutes"))),
            roundedClockOut: Util.formatDateFromUtc(Util.roundDateTo(Date.now(), moment.duration(companySettings.roundToNearest, "minutes"))),
            overrideAutoBreak: false,
            breakTimeHours: 0,
            breakTimeMinutes: 0,
            breakType: {name: 'Break'},
            note: '',
            shiftBreaks: [],
            myShift: false,
            edit: false
        }
    }

    toggleNewModal() {
        this.setState({selectedShiftLog: this.getNewShiftLog(), newDialog: !this.state.newDialog});
    }

    openResolveModal = (payrollObj, timeLogId) => {
        let search = {
            startDate: moment(payrollObj.startDate).startOf('day'),
            endDate: moment(payrollObj.endDate).endOf('day')
        }
        this.loadData(search, () => { this.openEditModal(timeLogId); });
    }

    loadShiftLog = async (shiftLogId) => {
        const apiUrl = Util.apiUrl('timelog/' + shiftLogId);
        let result = await fetch(apiUrl, {credentials: 'include'});
        return ServerResponseHandler.getResponse(result);
    }

    openEditModal = async (timeLogId) => {
        const timeLogs = this.state.data[0].timeEvents;
        let shiftLog = timeLogs.find(x => x.id === timeLogId);

        if(!shiftLog)
            return;

        if(!canEdit) {
            return;
        }
        if (shiftLog.projectId) {
            shiftLog.project = {id: shiftLog.projectId, number: shiftLog.projectNumber, name: shiftLog.projectName}
        }

        shiftLog.clockIn = Util.formatDateFromUtc(shiftLog.clockIn);
        shiftLog.clockOut = Util.formatDateFromUtc(shiftLog.clockOut);

        this.setState({selectedShiftLog: shiftLog, newDialog: !this.state.newDialog});
        this.loadEmployee(shiftLog.employeeCode, shiftLog);
    }

    openDuplicateModal = async (timeLogId) => {
        if(!canEdit) {
            return;
        }

        let shiftLog = null;
        if(this.state.data.length > 0) {
            const timeLogs = this.state.data[0].timeEvents;
            shiftLog = timeLogs.find(x => x.id === timeLogId);
        }

        if(!shiftLog) {
            let result = await this.loadShiftLog(timeLogId);
            result.employeeName = result.employeeNameFirst + " " + result.employeeNameLast;
            shiftLog = result;
        }

        if(!shiftLog)
            return;

        if (shiftLog.projectId) {
            shiftLog.project = {id: shiftLog.projectId, number: shiftLog.projectNumber, name: shiftLog.projectName}
        }

        this.setState({selectedShiftLog: shiftLog, newDialog: !this.state.newDialog, duplicateTimeLogModalOpen: false});
        this.loadEmployee(shiftLog.employeeCode, shiftLog);
    }

    toggleEditModal(event, shiftLog) {
        if (event.target && (event.target.classList.contains('delete')) || event.target.classList.contains('geoLocation')) {
            return false;
        }
        if(!canEdit) {
            return;
        }
        if (shiftLog.projectId) {
            shiftLog.project = {id: shiftLog.projectId, number: shiftLog.projectNumber, name: shiftLog.projectName}
        }

        //shiftLog.clockIn = Util.formatDateFromUtc(shiftLog.clockIn);
        //shiftLog.clockOut = Util.formatDateFromUtc(shiftLog.clockOut);

        this.setState({selectedShiftLog: shiftLog, newDialog: !this.state.newDialog});
        this.loadEmployee(shiftLog.employeeCode, shiftLog);
    }

    toggleGeoLocationModal(location, locationTitle) {
        if(location) {
            let coords = JSON.parse(location);
            latitude = coords.latitude;
            longitude = coords.longitude;
        }
        this.setState({geoLocationDialog: !this.state.geoLocationDialog, locationTitle: locationTitle})
    }

    toggleConfirmDeleteModal(shift, type) {
        let deleteDialog = {};
        switch (type) {
            case 'deleteShiftBreak':
                deleteDialog = {
                    header: 'Delete Break',
                    message: ['Are you sure you want to delete this break?'],
                    confirm: this.deleteBreak,
                    cancel: this.toggleConfirmDeleteModal,
                };
                break;
            case 'deleteShift':
                deleteDialog = {
                    header: 'Delete Log',
                    message: ['Are you sure you want to delete this shift?', shift ? shift.date  + ' (' + shift.clockInTime + ' - ' + shift.clockOutTime + ')' : ''],
                    warning: shift.datePosted ? 'Warning: This entry has been posted to QuickBooks and will need to be manually removed.' : false,
                    confirm: this.deleteLog,
                    cancel: this.toggleConfirmDeleteModal
                }
        }

        deleteDialog.isOpen = !this.state.deleteDialog.isOpen;
        let stateObj = {confirmDeleteDialog: !this.state.confirmDeleteDialog, deleteDialog: deleteDialog};
        stateObj[type] = shift;
        this.setState(stateObj);
    }

    toggleBreakModal() {
        this.setState({
            breakDialog: !this.state.breakDialog,
            breakTimeHours: 0,
            breakTimeMinutes: 0,
            breakType: {name: 'Break'}
        });
    }

    deleteLog() {
        this.setState({loading: true});
        const deletedShift = this.state.deleteShift;
        deletedShift.deletedOn = moment();

        // fix for not properly deleting time logs
        deletedShift.clockInGeoLocation = JSON.parse(deletedShift.clockInGeoLocation);
        deletedShift.clockOutGeoLocation = JSON.parse(deletedShift.clockOutGeoLocation);

        const apiUrl = Util.apiUrl(`timelog/${deletedShift.id}`);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(deletedShift),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Shift log was deleted', {position: 'bottom-right'});
                this.loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, confirmDeleteDialog: false}));
    }

    handleSubmit(shiftLog, method, breakList = [], callback) {
        const stateObj = {
            newDialog: false,
            project: null,
            projectId: '',
        };

        if (method === 'POST') {
            let payload = [];
            breakList.forEach((shiftBreak) => {
                payload.push({
                    breakIn: Util.formatDateToUtc(shiftBreak.breakIn),
                    breakOut: Util.formatDateToUtc(shiftBreak.breakOut),
                    breakType: shiftBreak.breakType,
                    breakInOffset: moment.parseZone(moment()).utcOffset() * 60,
                    breakOutOffset: moment.parseZone(moment()).utcOffset() * 60});
            });
            shiftLog.clockInOffset = moment.parseZone(moment()).utcOffset() * 60;
            shiftLog.clockOutOffset = moment.parseZone(moment()).utcOffset() * 60;
            payload.unshift(shiftLog);
            this.saveData(method, payload, stateObj, null, callback);
        } else {
            this.saveData(method, shiftLog, stateObj, this.state.selectedShiftLog.id, callback);
        }
    }

    saveData(method, shiftLog, stateObj, shiftId = null, callback) {
        this.setState({loading: true});

        const formUrl = Util.apiUrl(method === 'PATCH' ? `timelog/${shiftId}` : 'timelog/v2');
        fetch(formUrl, {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftLog),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    if(json.errorCode === 'TIMELOG_ALREADY_ENTERED') {
                        const duplicateTimeLog = {
                            id: json.params[2],
                            clockIn: json.params[3],
                            clockOut: json.params[4]
                        };

                        this.setState({ duplicateTimeLogModalOpen: true, duplicateTimeLog: duplicateTimeLog, newDialog: false });
                    } else {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                    }

                    this.setState({loading: false});
                } else {
                    toast.success('Shift log was saved', {position: 'bottom-right'});

                    if(shiftId && this.state.timeLogLinkId === shiftId) {
                        this.loadIssues(shiftId);
                        this.setState({ timeLogLinkId: null })
                    }

                    this.loadData(searchBarApi.getParams());
                    this.setState({...stateObj, loading: false});
                }

                if(callback) callback();
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    groupBy(arr, prop) {
        const map = new Map(Array.from(arr, obj => [obj[prop], []]));
        arr.forEach(obj => map.get(obj[prop]).push(obj));
        return Array.from(map.values());
    }

    loadPayrolls() {
        const self = this;
        const apiUrl = Util.apiUrl('payroll?includeShiftsAndIssues=true');
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                json.forEach(x => {
                    if (x.shiftLogs.resultList) {
                        let data = [];
                        data = Util.formatEmployeeTimeDataByDate(x.shiftLogs);
                        self.parseTableData(data.data);

                        x.data = data;
                    }
                });

                this.setState({ payrolls: json });
                this.loadPto();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false}));
    }

    loadPto(){
        const self = this;
        const apiUrl = Util.apiSearchUrl('pto', {"ptoStatus":"Pending"});
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                this.setState({ pto: json.resultList });
                this.checkForUnapprovedPTOInPayrollPeriods();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false}));
    }

    checkForUnapprovedPTOInPayrollPeriods(){
        for (const payroll of this.state.payrolls) {
            for(const pto of this.state.pto){
                const ptoStart = new Date(pto.startDate).getTime();
                const ptoEnd = new Date(pto.endDate).getTime();
                const payrollStart = new Date(payroll.startDate).getTime();
                const payrollEnd = new Date(payroll.endDate).getTime();
                if((ptoStart >= payrollStart && ptoStart <= payrollEnd) || ( ptoEnd >= payrollStart && ptoEnd <= payrollEnd)){
                    payroll.activePto = true;
                    payroll.canApprove = false;
                }
            }
        }
    }

    loadData(search = null) {
        const self = this;
        this.setState({loading: true, terminal: null});
        Util.getPayPeriods(search).then(search => {
            if(search.payPeriodRule){
                search.payPeriodRuleId = search.payPeriodRule.id;
            }
            const apiUrl = Util.apiSearchUrl('timelog', search);
            fetch(apiUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json.resultList) {
                        let data = [];
                        if(search && search.filterByEmployee) {
                            data = Util.formatEmployeeTimeDataByEmployee(json);
                        } else {
                            data = Util.formatEmployeeTimeDataByDate(json);
                        }
                        let csvData = self.parseCsvData(Util.formatEmployeeTimeDataByDate(json));
                        self.setState({
                            data: data.data,
                            addedColumns: data.addedColumns,
                            showPositionColumn: data.showPositionColumn,
                            showProjectColumn: data.showProjectColumn,
                            showPostedColumn: data.showPostedColumn,
                            csvData: csvData,
                        });
                        if (json.maxResults === 0) {
                            localStorage.setItem("currentTimeLog", null);
                        } else {
                            self.parseTableData(data.data);
                        }

                        const timeLogLinkId = new URLSearchParams(this.props.location.search).get("resolveTimeLogId");
                        if(timeLogLinkId && this.state.firstLoad) {
                            this.setState({ timeLogLinkId: timeLogLinkId });
                            this.openDuplicateModal(timeLogLinkId);
                        }
                    }

                    this.loadPayrolls();
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => self.setState({loading: false, firstLoad: false}));
        })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    parseCsvData(data) {
        let _data = [];
            data.data.forEach(row => {
                row.timeEvents.forEach(log => {
                    let timeLog = {};
                    timeLog.date = row.name;
                    timeLog.employeeName = log.employeeNameFirst + ' ' + log.employeeNameLast;
                    timeLog.clockInTime = moment(log.clockIn).format('h:mm A');
                    timeLog.clockOutTime =  log.clockOut ? moment(log.clockOut).format('h:mm A') : '';
                    timeLog.totalTime = Util.formatTimeFromSeconds(log.duration);
                    timeLog.totalBreakTime = Util.formatTimeFromSeconds(log.breakRoundedTime + log.lunchBreakRoundedTime);
                    timeLog.positionName = log.positionName ? log.positionName : '';
                    timeLog.projectName = log.projectName ? log.projectName : '';
                    timeLog.projectNumber = log.projectNumber ? log.projectNumber : '';
                    timeLog.note = log.shiftNote ? log.shiftNote : '';

                    if(timeLog.totalTime) {
                        timeLog.totalTime  = timeLog.totalTime.replace(" hrs", "")
                    }
                    if(timeLog.totalBreakTime) {
                        timeLog.totalBreakTime  = timeLog.totalBreakTime.replace(" hrs", "")
                    }

                    _data.push(timeLog);
                });
            });
        return _data;
    }

    parseTableData(dataList) {
        const zone = moment.tz.guess();

        dataList.forEach(obj => {
            obj.timeEvents.forEach(obj => {
                if (obj.employeeNameFirst) {
                    obj.employeeName = Util.nameFormatter(obj.employeeNameFirst, obj.employeeNameLast)
                }
                if (obj.roundedClockIn) {
                    const value = obj.roundedClockIn;
                    obj.clockInTime = moment.tz(value, zone).format('h:mm A');
                    obj.date = moment.tz(value, zone).format('dddd, MMM DD');
                }
                if (obj.roundedClockOut) {
                    obj.clockOutTime = moment.tz(obj.roundedClockOut, zone).format('h:mm A');
                    obj.csvClockOutTime = moment.tz(obj.roundedClockOut, zone).format('h:mm A');
                } else if (obj.currentBreak) {
                    if(obj.currentBreak.breakType === 'Lunch') {
                        obj.clockOutTime = <h5 className="mb-1"><span className="badge badge-pill badge-lunch">On Lunch</span></h5>;
                    } else {
                        obj.clockOutTime = <h5 className="mb-1"><span className="badge badge-pill badge-break">On Break</span></h5>;
                    }
                    obj.csvClockOutTime = 'On Break';
                } else {
                    obj.clockOutTime = <h5 className="mb-1"><span className="badge badge-pill badge-success">Working</span></h5>;
                    obj.csvClockOutTime = 'Working';
                }

                if (obj.roundedDuration) {
                    obj.totalTime = Util.formatTimeFromSeconds(obj.roundedDuration);
                }

                if (obj.breakRoundedTime) {
                    obj.totalBreakTime = Util.formatTimeFromSeconds(obj.breakRoundedTime);
                }
            });
        });
    }

    loadEmployee(employeeCode, shiftLog) {
        const apiUrl = Util.apiUrl(`users/${employeeCode}`);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => response.json())
            .then(json => {
                shiftLog.employee = json;
                this.setState({selectedShiftLog: shiftLog});
                this.parseUserPositions(json);
            });
    }

    convertTimeToHours(milliseconds) {
        const time = milliseconds ? milliseconds : 0;
        if (JSON.parse(localStorage.companySettings).timeFormat === "HH:MM") {
            return Util.formatTimer(time, false);
        } else {
            return (time / 3600).toFixed(2) + ' hrs';
        }

    }

    handleBreakSubmit(event, shiftEntry) {
        this.setState({breakDialog: false, loading: true});

        const formUrl = Util.apiUrl(`timelog/${ this.state.selectedShiftLog.id }/add-break`);
        shiftEntry = Util.formatBreakTimesForSerialization(shiftEntry, false);
        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(shiftEntry),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Break was added', {position: 'bottom-right'});
                this.loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, loadBreakData: !this.state.loadBreakData}));
    }

    isPayPeriodApproved(payrollModel) {
        return (!!payrollModel.lastApproved && moment(payrollModel.lastApproved).isAfter(moment(payrollModel.endDate)));
    }

    toggleConfirmApproveModal = () => {
        this.setState({ confirmApproveModalOpen: !this.state.confirmApproveModalOpen });
    }

    approvalRequired = () => {
        if(!this.state.payrolls || this.state.payrolls.length === 0)
            return false;

        let required = false;
        this.state.payrolls.forEach(x => {
            const appR = this.isPayPeriodApproved(x);
            const payPeriodEnded = this.isPayPeriodEnded(x);
            if(appR === false && payPeriodEnded)
                required = true;
        });

        return required;
    }

    isPayPeriodEnded = (payPeriod) => {
        return moment(payPeriod.endDate).isBefore(moment());
    }

    loadIssues = async (timeLogId) => {
        const formUrl = Util.apiUrl('timelog/' + timeLogId + '/issues');
        fetch(formUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if(json.resultList.length === 0) {
                    this.setState({ backToDashboardModalOpen: true })
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    approvePayPeriod() {
        const payPeriodRuleId = this.state.selectedPayPeriodForApproval;
        if(!payPeriodRuleId)
            return;

        let url = Util.apiUrl(`payroll/${ payPeriodRuleId }/approve`);
        url += "?timezone=" + moment.tz.guess();

        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Pay period has been approved successfully.', {position: 'bottom-right'});
                this.loadData(searchBarApi.getParams());
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    deleteBreak() {
        const self = this;
        this.setState({loading: true});
        const deletedBreak = Util.formatBreakTimesForSerialization(self.state.deleteShiftBreak);
        const apiUrl = Util.apiUrl(`timelog/break/${deletedBreak.id}`);
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify({ deletedOn: deletedBreak.deletedOn }),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Break was deleted', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, confirmDeleteDialog: false, loadBreakData: !this.state.loadBreakData}));
    }

    render() {
        const companySettings = Util.getCompanySettings();
        const projectsEnabled = companySettings.enableProjects;
        const csvProperties = {
            csvHeader: [
                {label: 'Employee', key: 'employeeName'},
                {label: 'Date', key: 'date'},
                {label: 'Clock In', key: 'clockInTime'},
                {label: 'Clock Out', key: 'clockOutTime'},
                {label: 'Hours', key: 'totalTime'},
                {label: 'Break', key: 'totalBreakTime'},
                {label: 'Position', key: 'positionName'},
                {label: companySettings.projectSingleName + ' Name', key: 'projectName'},
                {label: companySettings.projectSingleName + ' Number', key: 'projectNumber'},
                {label: 'Shift Note', key: 'note'},
            ],
            data: this.state.csvData,
            csvFilename: 'TimeCards.csv',
            loading: this.state.loading
        };

        const buttons = {
            add: this.toggleNewModal,
            download: csvProperties,
            savePdf: false,
            loading: this.state.loading
        };

        let projectColumn = {};
        if (projectsEnabled) {
            projectColumn = {
                Header: companySettings.projectSingleName, accessor: 'projectNumber', headerClassName: 'table-header', Aggregated: ' ',
                Cell: row => (<span>{row.value === undefined || Util.isNull(row.original)
                    ? ''
                    : <span>{row.value} - {row.original.projectName}</span>}</span>)
            };
        }

        return (
            <div className="h-100">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 'time-cards'}
                            onClick={() => {
                                this.toggle('time-cards');
                            }}
                        >
                            Time Entries
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ position: 'relative' }}
                            active={this.state.activeTab === 'time-card-approval'}
                            onClick={() => {
                                this.toggle('time-card-approval');
                            }}>
                                Time Card Approval
                                {this.approvalRequired() ?
                                <span style={{ backgroundColor: "red", width: "12px", height: "12px", borderRadius: "50%", position: "absolute",
                                                top: "-1px", right: "-5px" }}/> : null}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} style={{height: 'calc(100% - 38px)'}}>
                    <TabPane tabId="time-cards">
                        <div className="animated fadeIn">
                            <Container fluid>
                            {
                                !this.state.newDialog ? '' :
                                <TimeLogModal
                                    loadBreakData={this.state.loadBreakData}
                                    shiftLog={this.state.selectedShiftLog}
                                    isOpen={this.state.newDialog}
                                    toggleGeoLocationModal={this.toggleGeoLocationModal}
                                    toggleModal={this.toggleNewModal}
                                    toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                    handleSubmit={this.handleSubmit}
                                    toggleBreakModal={this.toggleBreakModal}
                                    coords={this.props.coords}
                                    positions={this.state.positions}
                                    />
                            }
                        {   !this.state.breakDialog ? '' :
                        <BreakModal
                            toggleBreakModal={this.toggleBreakModal}
                            handleBreakSubmit={this.handleBreakSubmit}
                            isOpen={this.state.breakDialog}
                            shiftLog={this.state.selectedShiftLog}
                            />
                        }
                        {   !this.state.confirmDeleteDialog ? '' :
                        <ConfirmDeleteModal
                            isOpen={this.state.confirmDeleteDialog}
                            deleteDialog={this.state.deleteDialog}
                            />
                        }
                    <Modal isOpen={this.state.geoLocationDialog} size={'lg'}>
                            <ModalHeader>{this.state.locationTitle}</ModalHeader>
                            <ModalBody style={{height: '500px'}}>
                        {!this.props.isGeolocationAvailable
                            ? <div>Your browser does not support Geolocation</div>
                        : !this.props.isGeolocationEnabled
                            ? <div>Geolocation is not enabled</div>
                        : this.props.coords ?
                        <MapContainer
                            latitude={latitude}
                            longitude={longitude}
                            locationTitle={this.state.locationTitle}
                            /> : ''
                        }
                    </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={() => this.toggleGeoLocationModal(null)}>Close</Button>
                        </ModalFooter>
                        </Modal>
                        <Card className="col-lg-12 mt-3 card-accent-primary">
                            <CardBody>
                            <SearchBar
                        buttons={buttons}
                        onSearch={this.loadData}
                        includePayPeriods={true}
                        includeShowOpenButton={true}
                        showOpen={this.showOpen}
                        includeDateRange={true}
                        includeEmployee={true}
                        includeDepartment={Util.isAdmin()}
                        includePosition={true}
                        includeProject={true}
                        includeSearchLabel={true}
                        setSearchParams={this.setSearchParams}
                        allowFilterByEmployee={true}
                        getSearchBarApi={this.setSearchBarApi}
                        />
                        <TimeTable
                        data={this.state.data}
                        tableColumns={this.getTableColumns()}
                        toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                        toggleEditModal={this.toggleEditModal}
                        toggleGeoLocationModal={this.toggleGeoLocationModal}
                        tableSettings={{allowFilterByEmployee: true, filterByEmployee: this.state.searchParams.filterByEmployee, createTableColumns: this.createTableColumns(), canDelete: canDelete, canEdit: canEdit, showPositionColumn: this.state.showPositionColumn, showProjectColumn: this.state.showProjectColumn, showPostedColumn: this.state.showPostedColumn, showShiftNoteColumn: true}}
                        />
                        </CardBody>
                        </Card>
                        </Container>
                        </div>
                    </TabPane>
                    <TabPane tabId="time-card-approval">
                        <Container fluid>
                        {this.state.payrolls.map(x => (
                            <Card className="col-lg-12 mt-3 card-accent-primary" key={x.id}>
                            <CardBody>
                                <div className="searchBar no-wrap">
                                    <h4 className="d-inline align-middle">{x.name + " " + Util.basicDateFormatter(x.startDate) + " - " + Util.basicDateFormatter(x.endDate)}</h4>
                                    {this.isPayPeriodApproved(x) ?
                                    <div className="pull-right">
                                        <span style={{ color: 'green', fontStyle: 'italic' }} className="d-inline align-middle">Approved</span>
                                    </div>
                                    :
                                    <div className="pull-right">
                                        {!x.canApprove > 0 ?
                                            moment(x.endDate) > moment() ?
                                            <span style={{color: 'orange', fontStyle: 'italic', marginRight: "8px"}} className="d-inline align-middle">{"Pay Period ends " + Util.basicDateFormatter(x.endDate)}</span> :
                                                x.activePto > 0 ?
                                                    <span style = {{color: 'red', fontStyle: 'italic', marginRight: "8px"}} className="d-inline align-middle">There is unapproved PTO for this period. Click <a href ="/pto/employeePto">here</a> to resolve. </span>:
                                                    <span style = {{color: 'red', fontStyle: 'italic', marginRight: "8px"}} className="d-inline align-middle">Please resolve issues listed below </span>
                                        :
                                            <span style={{ color: 'green', fontStyle: 'italic', marginRight: "8px" }} className="d-inline align-middle">All clear. Approve this pay period </span>
                                        }
                                        <Button onClick={() => { this.setState({ selectedPayPeriodForApproval: x.payPeriodRuleId }); this.toggleConfirmApproveModal(); }} disabled={!x.canApprove} className="header-button btn btn-outline-success">Approve</Button>
                                    </div> }
                                </div>

                            {x.shiftLogs && x.shiftLogs.resultList && x.shiftLogs.resultList.length > 0 ?
                                <TimeTable
                                    data={x.data.data}
                                    tableColumns={this.getTableColumns()}
                                    toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                    toggleEditModal={this.toggleEditModal}
                                    toggleGeoLocationModal={this.toggleGeoLocationModal}
                                    tableSettings={{allowFilterByEmployee: true, filterByEmployee: this.state.searchParams.filterByEmployee, createTableColumns: this.createTableColumns(), canDelete: canDelete, canEdit: canEdit, showPositionColumn: this.state.showPositionColumn, showProjectColumn: this.state.showProjectColumn, showPostedColumn: this.state.showPostedColumn, showShiftNoteColumn: true}}
                                /> : null }
                            </CardBody>
                            </Card>
                        ))}
                        </Container>
                    </TabPane>
                </TabContent>
                <ConfirmModal
                    isOpen={this.state.confirmApproveModalOpen}
                    title="Approve pay period"
                    message="Are you sure you want to approve this pay period?"
                    confirmText="Approve"
                    confirm={this.approvePayPeriod}
                    cancel={this.toggleConfirmApproveModal}
                />
                <DuplicateTimeLogModal
                    isOpen={this.state.duplicateTimeLogModalOpen}
                    duplicateTimeLog={this.state.duplicateTimeLog}
                    cancel={() => { this.setState({ duplicateTimeLogModalOpen: false }) }}
                    onDuplicateShiftClick={(id) => { this.openDuplicateModal(id); }}
                />
                <CustomModal
                    isOpen={this.state.backToDashboardModalOpen}
                    title="Issues resolved"
                    message="Time entry has been resolved successfully."
                    buttons={[
                            {
                                id: 'cancel',
                                text: 'Cancel',
                                color: 'secondary',
                                onClick: () => this.setState({ backToDashboardModalOpen: false })
                            },
                            {
                                id: 'back_to_dashboard',
                                text: 'Back to dashboard',
                                color: 'success',
                                onClick: () => this.props.history.push("/dashboard")
                            }
                        ]}
                />
                <ToastContainer />
            </div>
        )
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    },
    suppressLocationOnMount: !trackLocation
})(ManageTimeCards);