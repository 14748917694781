import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Util from '../Framework/Util';
import {Async} from 'react-select'
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";

export default class SelectPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positionId: '',
            position: this.props.position,
            positions: []
        }
    }

    handleChange = newPosition => {
        if (newPosition) {
            this.setState({
                positionId: newPosition.id,
                position: newPosition
            });
        } else {
            this.setState({
                positionId: '',
                position: null
            });
        }

        this.props.onChange(newPosition);
    };

    loadPositions = input => {
        if (!input && this.state.positions.length === 0) {
            const apiUrl = Util.apiSearchUrl(`company/positions/search`, {data: ''});
            return fetch(apiUrl, {credentials: 'include'})
                .then(response => response.json())
                .then(json => {
                    this.setState({positions: json.resultList});
                    return {options: json.resultList}
                })
                .catch(error => ServerResponseHandler.handleError(error));
        }

        if (!input) {
            return Promise.resolve({options: this.state.positions});
        }

        const apiUrl = Util.apiSearchUrl(`company/positions/search`, {data: input});
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => response.json())
            .then(json => {
                return {options: json.resultList};
            })
            .catch(error => ServerResponseHandler.handleError(error));
    };

    render() {
        return (
            <Async className="fb-select" name="position" ref="position"
                   valueKey="id" labelKey="name" multi={false}
                   value={this.state.position}
                   onChange={this.handleChange}
                   loadOptions={this.loadPositions}
                   backspaceRemoves={true}
                   autoload={true}
                   placeholder={'Select a Position...'}
                   optionRenderer={(option) => {
                       return (
                           <span>{option.name}</span>
                       )
                   }}
                   valueRenderer={(option) => {
                       return (
                           <span>{option.name}</span>
                       )
                   }}
            />
        )
    }
}

SelectPosition.propTypes = {
    onChange: PropTypes.func.isRequired,
    position: PropTypes.object
};