import React, {useState, createRef} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody, ModalFooter, Modal, Col, Row
} from "reactstrap";

import Select from "react-select";
import * as Util from "../Framework/Util";
import FBDateTimePicker from "../DateTimePickers/FBDateTimePicker";

const companySettings = Util.getCompanySettings();

let breakTypes = [{name: 'Lunch'}, {name: 'Break'}];
let breakInChanged = false;
let breakOutChanged = false;

function BreakModal(props) {

    const [shiftBreak, setShiftBreak] = useState(companySettings.roundToNearest !== 0 ? {breakIn: props.shiftLog.roundedClockIn, breakOut: props.shiftLog.roundedClockOut} : {breakIn: props.shiftLog.clockIn, breakOut: props.shiftLog.clockOut});
    const [breakType, setBreakType] = useState(props.breakLogData ? {name: props.breakLogData.breakType} : {name: 'Break'});
    const [breakInOut, setBreakInOut] = useState(companySettings.roundToNearest !== 0
        ? {id: props.breakLogData ? props.breakLogData.id : null, breakIn: props.breakLogData ? props.breakLogData.roundedBreakIn : props.shiftLog.roundedClockIn, breakOut: props.breakLogData ? props.breakLogData.roundedBreakOut : props.shiftLog.roundedClockOut}
        : {id: props.breakLogData ? props.breakLogData.id : null, breakIn: props.breakLogData ? props.breakLogData.breakIn : props.shiftLog.clockIn, breakOut: props.breakLogData ? props.breakLogData.breakOut : props.shiftLog.clockOut});

    function handleBreakTypeChange(breakType) {
        if (!breakType) {
            setBreakType({name: ''});
        } else {
            setBreakType(breakType);
        }
    }

    function handleBreakInChange(event) {
        let _breakInOut = {...breakInOut};
        _breakInOut.breakIn = event ? new Date(event) : null;
        setBreakInOut(_breakInOut);
        breakInChanged = true;
    }

    function handleBreakOutChange(event) {
        let _breakInOut = {...breakInOut};
        _breakInOut.breakOut = event ? new Date(event) : null;
        setBreakInOut(_breakInOut);
        breakOutChanged = true;
    }

    function breakSubmit(event) {
        breakInOut.breakType = breakType.name;
        breakInOut.breakIn = breakInOut.breakIn;
        breakInOut.breakOut = breakInOut.breakOut;
        props.handleBreakSubmit(event, breakInOut);
    }

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Break</ModalHeader>
            <ModalBody id="modal-form">
                <Label>Break Type</Label>
                <div className="mb-3 fb-select">
                    <Select
                        isClearable
                        getOptionValue={(option) => option.name}
                        getOptionLabel={(option) => option.name}
                        value={breakType}
                        options={breakTypes}
                        onChange={handleBreakTypeChange}
                        placeholder={'Select a Break Type...'}
                    />
                </div>
                <Row>
                    <Col>
                        <Label>Break Start</Label>
                        <FBDateTimePicker
                            id="breakIn"
                            value={breakInOut.breakIn}
                            onChange={handleBreakInChange}
                        />
                    </Col>
                    <Col>
                        <Label>Break End</Label>
                        <FBDateTimePicker
                            id="breakOut"
                            value={breakInOut.breakOut}
                            onChange={handleBreakOutChange}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={(event) => breakSubmit(event)}>Save</Button>{' '}
                <Button color="secondary" onClick={() => props.toggleBreakModal()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

function useFormInput(initialValue) {
    const [form, setForm] = useState(initialValue);

    function handleChange(e) {
        let newFormObj = Object.assign({}, form);
        newFormObj[e.target.name] = Math.floor(e.target.value);
        setForm(newFormObj);
    }

    return [
        form,
        handleChange
    ]
}

export default BreakModal;