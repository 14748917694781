import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import {ToastContainer, toast} from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import moment from "moment";
import CompanyHolidayTable from "../../../components/DataTables/CompanyHolidayTable";
import FBDatePicker from "../../../components/DateTimePickers/FBDatePicker";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const companySettings = Util.getCompanySettings();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
const canDelete = isAdminOrManager;
let savedPinLength;

class Holidays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteHolidayName: "holiday",
            holidayName: '',
            holidayDate: '',
            selectedHoliday: {
                id: 0,
                name: '',
                startDate: moment(),
                endDate: moment(),
                active: true,
                pto: false
            },
            holidayDialogOpen: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleHolidaySubmit = this.handleHolidaySubmit.bind(this);
        this.loadCompanyHolidays = this.loadCompanyHolidays.bind(this);
        this.toggleNewModalHoliday = this.toggleNewModalHoliday.bind(this);
        this.saveHoliday = this.saveHoliday.bind(this);
        this.toggleConfirmDeleteHolidayModal = this.toggleConfirmDeleteHolidayModal.bind(this);
        this.deleteHoliday = this.deleteHoliday.bind(this);
        this.handleHolidayChange = this.handleHolidayChange.bind(this);
        this.handleHolidayDateChange = this.handleHolidayDateChange.bind(this);
        this.openEditModal = this.openEditModal.bind(this);

        this.loadCompanyHolidays(true);
    }

    componentDidUpdate = (prevProps, prevState, prevContext) => {
        const editableContent = document.getElementsByClassName('contentEditable');
        Array.prototype.filter.call(editableContent, element => {
            element.addEventListener('keypress', evt => {
                if (evt.which === 13) {
                    element.blur();
                }
            })
        });
    };

    handleHolidayChange(event) {
        let selectedHoliday = {...this.state.selectedHoliday};
        selectedHoliday[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        this.setState({selectedHoliday: selectedHoliday});
    }

    handleHolidayDateChange(field, date) {
        let selectedHoliday = {...this.state.selectedHoliday};
        selectedHoliday[field] = date;
        this.setState({selectedHoliday: selectedHoliday});
    }

    handleChange(e, saveData) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value}, () => {
                if (saveData) {
                    this.saveData();
                }
            });
            Util.showInputError(this, e.target.name);
        }
    }

    handleHolidaySubmit(event) {
        event.preventDefault();

        if (!this.state.selectedHoliday.name) {
            toast.error('Name is empty', {position: 'bottom-right'});
            return;
        }

        if (!this.state.selectedHoliday.startDate) {
            toast.error('Start date is empty', {position: 'bottom-right'});
            return;
        }

        if (!this.state.selectedHoliday.endDate) {
            toast.error('End date is empty', {position: 'bottom-right'});
            return;
        }

        const holiday = {
            name: this.state.selectedHoliday.name,
            startDate: this.state.selectedHoliday.startDate,
            endDate: this.state.selectedHoliday.endDate,
            date: this.state.selectedHoliday.startDate,
            active: this.state.selectedHoliday.active,
            pto: this.state.selectedHoliday.pto,
        };

        this.setState({holidayDialogOpen: !this.state.holidayDialogOpen});

        if (this.state.selectedHoliday.id) {
            this.saveHoliday('PATCH', holiday, this.state.selectedHoliday.id);
        } else {
            this.saveHoliday('POST', holiday);
        }
    }

    openEditModal(row) {
        let selected = this.state.companyHolidayList.find(x => x.id == row.id);
        if (!selected)
            return;

        this.setState({
            selectedHoliday: {
                id: selected.id,
                name: selected.name,
                startDate: Util.formatDateFromUtc(selected.startDate),
                endDate: Util.formatDateFromUtc(selected.endDate),
                active: selected.active,
                pto: selected.pto
            }, holidayDialogOpen: true
        });
    }

    loadCompanyHolidays(initial = false) {
        if (initial) this.state.loading = true;
        else this.setState({
            companyHolidayList: [],
            loading: true
        });

        const apiUrl = Util.apiUrl(`company/settings/holidays`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                    if (json.resultList !== null && json.resultList !== undefined) {
                        this.setState({companyHolidayList: json.resultList});
                    }
                }
            )
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                this.setState({
                    loading: false,
                    holidayName: '',
                    holidayDate: ''
                })
            });
    }

    toggleNewModalHoliday() {
        this.setState({
            holidayDialogOpen: !this.state.holidayDialogOpen,
            selectedHoliday: {
                id: 0,
                name: '',
                startDate: moment(),
                endDate: moment(),
                active: true,
                pto: false
            }
        });
    }

    saveHoliday(method, obj, objId = '') {
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/settings/holidays/${objId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(obj),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Holiday rule was saved', {position: 'bottom-right'});
                this.loadCompanyHolidays();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    toggleConfirmDeleteHolidayModal(row) {
        this.setState({
            deleteHolidayName: row !== null ? row.name : "holiday",
            row: row,
            confirmDeleteHolidayDialog: !this.state.confirmDeleteHolidayDialog,
        });
    }

    deleteHoliday() {
        this.setState({loading: true});
        const deletedRow = this.state.row;
        deletedRow.deletedOn = Util.formatDateToUtc(moment());
        const apiUrl = Util.apiUrl(`company/settings/holidays/${deletedRow.id}`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify(deletedRow),
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Holiday was deleted', {position: 'bottom-right'});
                this.loadCompanyHolidays();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false, confirmDeleteHolidayDialog: false}));
    }

    render() {
        const settings = this.state;
        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <Row id="company-form">
                        <Col lg="12" md="12"> {/*================ Hours Of Operation Section ================*/}
                            <Card
                                className="card-accent-primary"> {/*================ Holidays Section ================*/}
                                <CardHeader>
                                    <Row className="justify-content-between align-items-center no-gutters">
                                        <Col>
                                            <div>Holidays</div>
                                        </Col>
                                        {
                                            !canEdit ? "" :
                                                <Col>
                                                    <button className="header-button btn btn-outline-success pull-right"
                                                            onClick={() => this.toggleNewModalHoliday()}><i
                                                        className="material-icons">add</i></button>
                                                </Col>
                                        }
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <CompanyHolidayTable
                                        data={settings.companyHolidayList}
                                        toggleConfirmDeleteModal={this.toggleConfirmDeleteHolidayModal}
                                        tableSettings={{
                                            canDelete: canDelete, canEdit: canEdit
                                        }}
                                        openEditModal={this.openEditModal}
                                    />
                                </CardBody>
                            </Card>
                            <Modal isOpen={this.state.holidayDialogOpen}
                                   className="modal-md">{/*================ New Holiday Modal ================*/}
                                <form onSubmit={this.handleHolidaySubmit} id="modal-form" noValidate>
                                    <ModalHeader>Holidays</ModalHeader>
                                    <ModalBody>
                                        <div className="mb-3">
                                            <Label>Holiday Name<span className="error">*</span></Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="Name" name="name" ref="name"
                                                       value={this.state.selectedHoliday.name}
                                                       className="form-control" onChange={this.handleHolidayChange}
                                                       required/>
                                            </InputGroup>
                                        </div>

                                        <div className="mb-3">
                                            <Label>Start Date<span className="error">*</span></Label>
                                            <InputGroup className="mb-3">
                                                <FBDatePicker id="startDate"
                                                              value={this.state.selectedHoliday.startDate}
                                                              name="startDate" ref="startDate"
                                                              onChange={date => this.handleHolidayDateChange('startDate', date)}
                                                              required/>
                                            </InputGroup>
                                        </div>
                                        <div className="mb-3">
                                            <Label>End Date<span className="error">*</span></Label>
                                            <FBDatePicker id="endDate" value={this.state.selectedHoliday.endDate}
                                                          name="endDate" ref="endDate"
                                                          onChange={date => this.handleHolidayDateChange('endDate', date)}
                                                          required/>
                                        </div>

                                        <div className="mb-3">
                                            <Label>Active</Label>
                                            <Label className="switch switch-icon switch-pill switch-info pull-right">
                                                <Input type="checkbox" name="active" ref="pto"
                                                       className="switch-input"
                                                       checked={this.state.selectedHoliday.active} onChange={(e) => {
                                                    this.handleHolidayChange(e)
                                                }}/>
                                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className="switch-handle"/>
                                            </Label>
                                        </div>

                                        <div className="mb-3">
                                            <Label>PTO</Label>
                                            <Label className="switch switch-icon switch-pill switch-info pull-right">
                                                <Input type="checkbox" name="pto" ref="pto"
                                                       className="switch-input"
                                                       checked={this.state.selectedHoliday.pto} onChange={(e) => {
                                                    this.handleHolidayChange(e)
                                                }}/>
                                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                                <span className="switch-handle"/>
                                            </Label>
                                        </div>

                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="success">Save</Button>{' '}
                                        <Button color="secondary"
                                                onClick={() => this.toggleNewModalHoliday()}>Cancel</Button>
                                    </ModalFooter>
                                </form>
                            </Modal>
                            <Modal
                                isOpen={settings.confirmDeleteHolidayDialog}>{/*================ Delete Holiday Modal ================*/}
                                <ModalHeader>Delete Holiday</ModalHeader>
                                <ModalBody>
                                    Are you sure you want to delete {settings.deleteHolidayName}?
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="success" onClick={() => this.deleteHoliday()}>Delete</Button>{' '}
                                    <Button color="secondary"
                                            onClick={() => this.toggleConfirmDeleteHolidayModal(null)}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    showFormErrors = () => {
        let isFormValid = true;
        for (const input of document.getElementById('company-form').querySelectorAll('input')) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        }

        return isFormValid;
    };
}

export default Holidays;