import React from 'react';
import {Container} from 'reactstrap';
import CompanyName from "../SharedReportElements/CompanyName";
import Title from "../SharedReportElements/Title";
import PdfColumnHeaders from "../SharedReportElements/PdfColumnHeaders";
import PtoTableRowData from "./PayrollReportElements/PtoTableRowData";
import PayrollReportTotals from "./PayrollReportElements/PayrollReportTotals";


const PayrollReport = (props) => {
    return !props.pdfData || props.pdfData.length < 1 ?  '' : <Container fluid className={'wrapper'}>

        <div className={'mt-2'}>
            <CompanyName companyName={JSON.parse(localStorage.getItem('companyTenant')).name}/>
        </div>
        <div>
            <Title title={props.title}/>
        </div>
        <div>
            <PdfColumnHeaders pdfColumnHeaders={props.pdfColumnHeaders}/>
        </div>
        <div>
             <PtoTableRowData pdfData={props.pdfData}/>
        </div>
        <div>
            <PayrollReportTotals totals={props.totals}/>
        </div>
    </Container>
};

export default PayrollReport;