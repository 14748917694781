import React, {Component} from "react";

class ResultQuantity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: localStorage.getItem("isAdmin"),
        };
    }

    render() {
        return (
            <select className=" col-1 form-control input-group"
                    onChange={(e) => {
                        this.props.updateNumResults(e.target.value)
                    }}>
                <option key="10" value="10">10</option>
                <option key="25" value="25">25</option>
                <option key="50" value="50">50</option>
                <option key="100" value="100">100</option>
            </select>
        )
    }
}

export default ResultQuantity