import React from 'react';
import {Row, Col, Tooltip} from 'reactstrap';
import * as Util from "../../../Framework/Util";
import useTooltip from "../../../../Hooks"


function ProjectDetailsPositionSummary(props) {
    const [tooltip, setTooltip] = useTooltip({});

    return (<div className={'mt-3 p-3'}>
            <div className={'mb-2'}><h4 className={'text-center'}>Position Totals</h4></div>
            <Row className={'row-header col-header col-lg-12'}>
                {
                    props.tableHeaders.map((column, index) => {
                        return column.showColumn ? <Col key={index}>{column.header}</Col> : ''
                    })
                }
            </Row>
            {
                props.totals.map((column, index) => {
                    return (<div key={index}>
                            <Row className={'row-header col-lg-12'}>
                                <Col id={'name' + index}>{column.name}</Col>
                                <Col>{Util.formatTimeFromSeconds(column.totalDuration)}</Col>
                                {props.showColumn ? <Col>{Util.formatCurrency(column.totalCost.toFixed(2))}</Col> : '' }
                                <Tooltip target={'name' + index} isOpen={tooltip['name' + index]} toggle={() => setTooltip('name' + index)}>{column.name}</Tooltip>
                            </Row>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ProjectDetailsPositionSummary;
