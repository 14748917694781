import React from 'react';
import {Row, Col, Container, Card, CardBody} from 'reactstrap';
import CompanyName from "../SharedReportElements/CompanyName";
import Title from "../SharedReportElements/Title";
import SubTitle from "../SharedReportElements/SubTitle";
import PdfColumnHeaders from "../SharedReportElements/PdfColumnHeaders";
import EmployeeTimeCardsPdfData from "./EmployeeTimeCardsReportElements/EmployeeTimeCardsPdfData";
import NoData from "../../DataTables/NoData";
import EmployeeTimeCardsTotals from "./EmployeeTimeCardsReportElements/EmployeeTimeCardsTotals";
import EmployeePtoTotals from "./EmployeeTimeCardsReportElements/EmployeePtoTotals";

let tableHeaders = ['Regular', 'Overtime', 'PTO Hours', 'Actual Hours'];
let ptoTableHeaders = ['Sick Hours', 'Vacation Hours', 'Holiday Hours', 'Total Hours'];

function EmployeeTimeCardsReport(props) {


    return (
        !props.pdfData || props.pdfData.length < 1 ? <NoData/> :
            props.pdfData.timeCardList.map((row, index) => {
                return (<Container fluid key={index} className={'mt-2'}>
                        <Card className={'page'}>
                            <CardBody>
                                <div className={"mt-2"}>
                                    {
                                        !props.pdfData.title ? '' :
                                            <Title title={props.pdfData.title}
                                                   class={'text-center'}
                                            />
                                    }
                                </div>
                                <div>
                                    {
                                        !props.pdfData.subTitle ? '' :
                                            <SubTitle subTitle={props.pdfData.subTitle}/>
                                    }
                                </div>
                                <div>
                                    <CompanyName companyName={JSON.parse(localStorage.getItem('companyTenant')).name}/>
                                </div>
                                <div><Row className={'justify-content-between row-header'}><Col
                                    className={"col-2 text-left pl-0"}><strong>{row.firstName} {row.lastName}</strong></Col>
                                    <Col
                                        className={"col-2 text-right pr-0"}><strong>{props.pdfData.department}</strong></Col></Row>
                                </div>
                                <div>
                                    {
                                        <PdfColumnHeaders pdfColumnHeaders={props.pdfColumnHeaders.filter(x => x.title !== 'Notes')} class={'col-2'}/>
                                    }
                                </div>
                                <div>
                                    {
                                        row.timeCardData.length < 1 ? <NoData/> :
                                            <EmployeeTimeCardsPdfData showProjects={props.pdfData.showProjects}
                                                                      showNotes={props.pdfData.showNotes}
                                                                      pdfData={row.timeCardData}/>
                                    }
                                </div>
                                <div>
                                    {
                                        <EmployeePtoTotals ptoTableHeaders={ptoTableHeaders} ptoTotals={row}/>
                                    }
                                </div>
                                <div>
                                    {
                                        <EmployeeTimeCardsTotals tableHeaders={tableHeaders} totals={row}/>
                                    }
                                </div>
                            </CardBody>
                            <Row className={'signature justify-content-around no-gutters'}>
                                <Col className={'col-4'}><span>Employee Signature</span></Col>
                                <Col className={'col-4'}><span>Manager Signature</span></Col>
                            </Row>
                        </Card>
                        <div className={row[props.pdfData.timeCardList.length - 1] ? '' : 'page-break'}></div>
                    </Container>

            )

            })
    )
}

export default EmployeeTimeCardsReport;