import React from 'react';
import {
    Button,
    ModalHeader,
    ModalBody, ModalFooter, Modal
} from "reactstrap";

function ConfirmDeleteModal(props) {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>{props.deleteDialog.header}</ModalHeader>
            <ModalBody>
                {props.deleteDialog.warning ? <span className="alert-danger">{props.deleteDialog.warning}<br/></span> : ''}
                {
                    props.deleteDialog.message.map((line, index) => {
                        return <p key={index}>{line}</p>;
                    })
                }
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={props.deleteDialog.confirm}>{props.deleteDialog.confirmText ? props.deleteDialog.confirmText : 'Delete'}</Button>{' '}
                <Button color="secondary" onClick={() => props.deleteDialog.cancel(null)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmDeleteModal;