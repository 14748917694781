import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import * as Util from "../Framework/Util";
import NoData from "./NoData";


class ProjectsTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                {
                    self.props.getTableColumns().map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                !self.props.data || self.props.data.length < 1 ?
                    <NoData/> :
                self.props.data.map((row, index) => {
                    return <div className='data-row' key={index}>
                        <Row className='row-data row-header col-lg-12'>
                            <Col><span>{row.firstName}</span></Col>
                            <Col><span>{row.lastName}</span></Col>
                            <Col><span>{row.sickHours}</span></Col>
                            <Col><span>{row.vacationHours}</span></Col>
                            <Col><span>{row.holidayHours}</span></Col>
                            <Col><span>{row.regularHours}</span></Col>
                            <Col><span>{row.overtime}</span></Col>
                            <Col><span>{row.total}</span></Col>
                            <Col><span>{row.pay}</span></Col>
                        </Row>
                    </div>
                })
            }
            <div className="summary-row">
                <div className="summary-container">
                    <div className="summary-item"><span>Regular Hours:</span> {Util.formatTimeFromSeconds(self.props.getTotalValues.totalRegularHours)}</div>
                    <div className="summary-item"><span>Overtime Hours:</span> {Util.formatTimeFromSeconds(self.props.getTotalValues.totalOvertimeHours)}</div>
                    <div className="summary-item"><span>PTO Hours:</span> {Util.formatTimeFromSeconds(self.props.getTotalValues.totalPtoHours)}</div>
                    <div className="summary-item"><span>Total hours:</span> {Util.formatTimeFromSeconds(self.props.getTotalValues.totalHours)}</div>
                </div>
            </div>
        </div>
    }

    render() {
        return this.createTable();
    }
}

export default ProjectsTable;
