import React, {useState, useEffect, createRef} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Tooltip,
    Row,
} from "reactstrap";
import QRCode from 'qrcode.react';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import {ToastContainer, toast} from 'react-toastify';
import {SearchBar} from "../../../components/Framework/SearchBar";
import ReactToPrint from "react-to-print";
import PropTypes from 'prop-types';
import SelectDepartment from "../../../components/Selects/SelectDepartment";
import SelectOvertimeRule from "../../../components/Selects/SelectOvertimeRule";
import SelectPayPeriodRule from "../../../components/Selects/SelectPayPeriodRule";
import SelectPtoRule from "../../../components/Selects/SelectPtoRule";
import ImageCrop from "../../../components/Framework/ImageCrop";
import EmployeesTable from "../../../components/DataTables/EmployeesTable";
import EmployeePositionsTable from "../../../components/DataTables/EmployeePositionsTable";
import EmployeeScheduleTable from "../../../components/DataTables/EmployeeScheduleTable";
import CustomModal from "../../../components/Modal/CustomModal";

const moment = require('moment-timezone');
const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || Util.getCompanySettings().allowEditTime;
const canDelete = isAdminOrManager;
import * as _ from "lodash"
import {generateRandomPin, getCompanySettings} from "../../../components/Framework/Util";
import FBDatePicker from "../../../components/DateTimePickers/FBDatePicker";
import EmployeeBreakRulesTable from "../../../components/DataTables/EmployeeBreakRulesTable";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import TimeClockPinModal from "../../../components/Modal/TimeClockPinModal";
import {isAdmin} from "../../../components/Framework/Util";
import EmployeeProjectsTable from "../../../components/DataTables/EmployeeProjectsTable";

const companySettings = Util.getCompanySettings();

let searchBarApi;

// This is it's own functional component so we can print this and not the entire page
function FbQrCode(props) {
    return (
        <div className="qr-badge" key={props.value}>
            <QRCode value={props.value} renderAs={'svg'}/>
            <br/>
            {Util.nameFormatter(props.nameFirst, props.nameLast)}
        </div>
    )
}

FbQrCode.propTypes = {
    value: PropTypes.string,
    nameFirst: PropTypes.string,
    nameLast: PropTypes.string
};

function Employees(props) {
    //#region Consts
    const [data, setData] = useState([]);
    const [maxResults, setMaxResults] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteEmployees, setDeleteEmployees] = useState([]);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [sorted, setSorted] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [companyPositionList, setCompanyPositionList] = useState([]);
    const [companyProjectList, setCompanyProjectList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [companyBreakRuleList, setCompanyBreakRuleList] = useState([]);
    const [breakRuleList, setBreakRuleList] = useState([]);
    const [selected, setSelected] = useState({});
    const [selectedProjects, setSelectedProjects] = useState({});
    const [selectedBreakRule, setSelectedBreakRule] = useState({});
    const [qrUserData, setQrUserData] = useState({});
    const [id, setId] = useState(0);
    const [updatedFrom, setUpdatedFrom] = useState('employees');
    const [email, setEmail] = useState('');
    const [nameFirst, setNameFirst] = useState('');
    const [nameLast, setNameLast] = useState('');
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [hourlyRate, setHourlyRate] = useState('$0.00');
    const [title, setTitle] = useState('');
    const [admin, setAdmin] = useState(false);
    const [manager, setManager] = useState(false);
    const [allowMobile, setAllowMobile] = useState(false);
    const [defaultContact, setDefaultContact] = useState(false);
    const [ptoSick, setPtoSick] = useState(0.0);
    const [ptoVacation, setPtoVacation] = useState(0.0);
    const [ptoHoliday, setPtoHoliday] = useState(0.0);
    const [departmentId, setDepartmentId] = useState('');
    const [overtimeRuleId, setOvertimeRuleId] = useState('');
    const [hireDate, setHireDate] = useState(null);
    const [active, setActive] = useState(true);
    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [managerTooltipOpen, setManagerTooltipOpen] = useState(false);
    const [department, setDepartment] = useState(null);
    const [overtimeRule, setOvertimeRule] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [qrDialog, setQrDialog] = useState(false);
    const [newDialog, setNewDialog] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [confirmDefaultUserDialog, setconfirmDefaultUserDialog] = useState(false);
    const [csvImportModal, setCsvImportModal] = useState(false);
    const [pin, setPin] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [employeeScheduleList, setEmployeeScheduleList] = useState([]);
    const [reloadEmployees, setReloadEmployees] = useState(props.reloadEmployees);
    const [payPeriodRuleId, setPayPeriodRuleId] = useState('');
    const [payPeriodRule, setPayPeriodRule] = useState(null);
    const [ptoRuleId, setPtoRuleId] = useState('');
    const [ptoRule, setPtoRule] = useState(null);
    const [lastPtoAccrualDate, setLastPtoAccrualDate] = useState('');
    const [dirty, setDirty] = useState(false);
    const [cancelConfirmationModalShow, setCancelConfirmationModalShow] = useState(false);
    const [confirmChangingHireDateModalShow, setConfirmChangingHireDateModalShow] = useState(false);
    const [originalHireDate, setOriginalHireDate] = useState(null);
    const [changeHireDateMessage, setChangeHireDateMessage] = useState(null);
    const [originalPTORuleId, setOriginalPTORuleId] = useState(null);
    const [changingHireDateMessage, setChangingHireDateMessage] = useState("");
    const [showPinModal, setShowPinModal] = useState(false);
    const [newPin, setNewPin] = useState(null);
    const [needsUpgradeModalShow, setNeedsUpgradeModalShow] = useState(false);
    const [activeUsersCount, setActiveUsersCount] = useState(0);
//#endregion
    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [props.reloadEmployees]);

    let phoneInput = createRef();
    let emailInput = createRef();
    let nameLastInput = createRef();
    let nameFirstInput = createRef();
    let usernameInput = createRef();
    let ptoVacationInput = createRef();
    let ptoSickInput = createRef();
    let ptoHolidayInput = createRef();
    let titleInput = createRef();
    let hourlyRateInput = createRef();
    let activeInput = createRef();
    let allowMobileInput = createRef();
    let managerInput = createRef();
    let pinInput = createRef();
    let adminInput = createRef();
    let defaultContactInput = createRef();
    let qrRef = createRef();

    //#region functions
    function toggleManagerTooltip() {
        setManagerTooltipOpen(!managerTooltipOpen);
    }

    function getTableColumns() {
        return [
            {displayName: 'First Name', hideColumn: false},
            {displayName: 'Last Name', hideColumn: false},
            {displayName: 'Last Worked', hideColumn: false},
            {displayName: 'Title', hideColumn: false},
            {displayName: 'Position', hideColumn: false},
            {displayName: 'Department', hideColumn: false},
            {displayName: 'Active', hideColumn: false},
            {displayName: 'Password', hideColumn: false},
        ];
    }

    function getEmployeePositionsTableColumns() {
        return [
            {displayName: 'Active', hideColumn: false, columnSize: 2},
            {displayName: 'Name', hideColumn: false, columnSize: ''},
            {displayName: 'Hourly Rate', hideColumn: false, columnSize: ''},
        ];
    }

    function getEmployeeProjectsTableColumns() {
        return [
            {displayName: 'Active', hideColumn: false, columnSize: ''},
            {displayName: 'Name', hideColumn: false, columnSize: ''},
        ];
    }

    function getEmployeeBreakRulesTableColumns() {
        return [
            {displayName: 'Active', hideColumn: false, columnSize: 2},
            {displayName: 'Name', hideColumn: false, columnSize: ''}
        ];
    }

    function setSearchBarApi(api) {
        searchBarApi = api;
    }

    function showInputError(ref) {
        if (Util.isEmpty(ref)) {
            return true;
        }
        if (Util.isNull(ref)) {
            return true;
        }
        const validity = ref.validity;
        if (Util.isNull(validity)) {
            return true;
        }
        return validity.valid;
    }

    function handleChange(e, setInput, ref) {
        if (e.target) {
            e.target.classList.add('active');
            if (e.target.name === 'Phone') {
                setInput(e.target.value.replace(/^(\d{3})(\d{3})(\d)+$/, "($1)$2-$3"));
            } else {
                setInput(e.target.value);
            }

            setDirty(true);

            if (ref) {
                showInputError(ref.current);
            }
        }
    }

    function handleDateChange(date) {
        setDirty(true);
        setHireDate(date);
    }

    function handleCheckBoxChange(e, setInput) {
        setDirty(true);
        setInput(e.target.checked);
    }

    function makeDefaultUser() {
        setDefaultContact(true);
        toggleConfirmDefaultModal();
    }

    function handleDepartmentChange(department) {
        setDirty(true);
        if (!department) {
            setDepartment(null);
            setDepartmentId('');
        } else {
            setDepartment(department);
            setDepartmentId(department.id);
        }
    }

    function handleOvertimeRuleChange(overtimeRule) {
        setDirty(true);
        if (!overtimeRule) {
            setOvertimeRule(null);
            setOvertimeRuleId('');
        } else {
            setOvertimeRule(overtimeRule);
            setOvertimeRuleId(overtimeRule.id);
        }
    }

    function handlePtoRuleChange(ptoRule) {
        setDirty(true);
        if (!ptoRule) {
            setPtoRule(null);
            setPtoRuleId('');
        } else {
            setPtoRule(ptoRule);
            setPtoRuleId(ptoRule.id);
        }
    }

    function resetPassword(user) {
        const formUrl = Util.apiUrl(`register/reset_password`);
        let payload = {
            username: user.username,
        };

        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    toast.success('Reset password email sent for ' + user.username + '.', {position: 'bottom-right'});
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function handlePayPeriodRuleChange(payPeriodRule) {
        setDirty(true);
        if (!payPeriodRule) {
            setPayPeriodRule(null);
            setPayPeriodRuleId('');
        } else {
            setPayPeriodRule(payPeriodRule);
            setPayPeriodRuleId(payPeriodRule.id);
        }
    }

    function toggleQrModal(employee) {
        setQrDialog(!qrDialog);
        if (!employee) {
            setQrUserData({id: '', nameFirst: '', nameLast: ''});
        } else {
            setQrUserData(employee);
        }
    }

    function isUserLimitReached() {
        const limit = parseInt(localStorage.getItem("maxUsers"));
        const userCount = activeUsersCount;

        return !(userCount < limit);
    }

    function toggleNewModal(skipNotSaveConfirmation) {
        const needsUpgrade = isUserLimitReached();
        if(!id && needsUpgrade) {
            setNeedsUpgradeModalShow(true);
            return;
        }

        if (id && dirty && !skipNotSaveConfirmation) {
            setCancelConfirmationModalShow(true);
            return;
        }

        loadPositionList(0);
        loadProjectList(0);
        loadBreakRuleList(0);
        loadEmployeeSchedule();
        getDefaultPayPeriodRule();
        getDefaultPtoRule();
        getDefaultOvertimeRule();
        setNewDialog(!newDialog);
        setId(null);
        setEmail('');
        setUsername('');
        setNameFirst('');
        setNameLast('');
        setPhone('');
        setHourlyRate('$0.00');
        setTitle('');
        setAdmin(false);
        setManager(false);
        setAllowMobile(false);
        setDefaultContact(false);
        setPtoSick(0.0);
        setPtoVacation(0.0);
        setPtoHoliday(0.0);
        setHireDate(moment());
        setActive(true);
        setDepartmentId('');
        setDepartment(null);
        setImageUrl('');
        setImage('');
        setSelected({});
        setSelectedBreakRule({});
    }

    function getDefaultPayPeriodRule() {
        const apiUrl = Util.apiUrl(`pay_period_rule`);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const defaultPayPeriodRule = _.filter(json.resultList, function (o) {
                    return (o.default)
                });
                if (defaultPayPeriodRule && defaultPayPeriodRule.length > 0) {
                    setPayPeriodRule(defaultPayPeriodRule[0]);
                    setPayPeriodRuleId(defaultPayPeriodRule[0].id);
                } else {
                    setPayPeriodRule(null);
                    setPayPeriodRuleId('');
                }
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    function getDefaultPtoRule() {
        const apiUrl = Util.apiUrl(`pto_rule`);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const defaultPtoRule = _.filter(json.resultList, function (o) {
                    return (o.default)
                });
                if (defaultPtoRule && defaultPtoRule.length > 0) {
                    setPtoRule(defaultPtoRule[0]);
                    setPtoRuleId(defaultPtoRule[0].id);
                } else {
                    setPtoRule(null);
                    setPtoRuleId('');
                }
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    function getDefaultOvertimeRule() {
        const apiUrl = Util.apiUrl(`company/settings/overtimeRules`);
        return fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const defaultOvertimeRule = _.filter(json.resultList, function (o) {
                    return (o.default)
                });
                if (defaultOvertimeRule && defaultOvertimeRule.length > 0) {
                    setOvertimeRule(defaultOvertimeRule[0]);
                    setOvertimeRuleId(defaultOvertimeRule[0].id);
                } else {
                    setOvertimeRule(null);
                    setOvertimeRuleId('');
                }
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    function toggleEditModal(event, employee) {
        if (event.target.classList.contains('delete')) {
            return false;
        }
        loadPositionList(employee.id);
        let selected = {};
        if (employee.userTenantPositionList) {
            for (const pos of employee.userTenantPositionList) {
                selected[pos.positionId] = {
                    positionId: pos.position.id,
                    name: pos.position.name,
                    hourlyRate: pos.hourlyRate
                };
            }
        }
        loadEmployeeSchedule(employee.id);

        loadProjectList(employee.id);
        let selectedProjects = {};
        if (employee.userTenantProjectList) {
            for (const pos of employee.userTenantProjectList) {
                selectedProjects[pos.projectId] = {
                    projectId: pos.project.id,
                    name: pos.project.name
                };
            }
        }

        loadBreakRuleList(employee.id);
        let selectedBreakRuleTmp = {};
        if (employee.userTenantBreakRuleList) {
            for (const pos of employee.userTenantBreakRuleList) {
                selectedBreakRuleTmp[pos.breakRuleId] = {
                    breakRuleId: pos.breakRule.id,
                    name: pos.breakRule.name,
                };
            }
        }

        setNewDialog(!newDialog);
        setSelected(selected);
        setSelectedProjects(selectedProjects);
        setSelectedBreakRule(selectedBreakRuleTmp);
        setId(employee.id);
        setEmail(employee.email);
        setNameFirst(employee.nameFirst);
        setNameLast(employee.nameLast);
        setUsername(employee.username);
        setPhone(employee.phone);
        setHourlyRate(employee.hourlyRate);
        setTitle(employee.title);
        setAdmin(employee.admin);
        setManager(employee.manager);
        setAllowMobile(employee.allowMobile);
        setDefaultContact(employee.defaultContact);
        setPtoSick(employee.ptoSick);
        setPtoVacation(employee.ptoVacation);
        setPtoHoliday(employee.ptoHoliday);
        setDepartmentId(employee.departmentId);
        setDepartment(employee.department);
        setOvertimeRuleId(employee.overtimeRuleId);
        setOvertimeRule(employee.overtimeRule);
        setPayPeriodRuleId(employee.payPeriodRuleId);
        setPayPeriodRule(employee.payPeriodRule);
        setPtoRuleId(employee.ptoRuleId);
        setOriginalPTORuleId(employee.ptoRuleId);
        setPtoRule(employee.ptoRule);
        setImageUrl(employee.imageUrl);
        setImage(employee.image);
        setHireDate(Util.formatDateFromUtc(employee.hireDate));
        setOriginalHireDate(Util.formatDateFromUtc(employee.hireDate));
        setActive(employee.active);
        setLastPtoAccrualDate(Util.formatDateFromUtc(employee.lastPtoAccrualDate));
    }

    function toggleConfirmDeleteModal(row) {
        setDeleteEmployees([row.id]);
        setConfirmDeleteDialog(!confirmDeleteDialog);
    }

    function toggleConfirmDefaultModal() {
        setconfirmDefaultUserDialog(!confirmDefaultUserDialog);
    }

    function toggleCheckedRow(event, index, row) {
        const newSelected = Object.assign({}, selected);
        const isChecked = event.target.checked;
        if (isChecked) {
            newSelected[row.positionId] = row;
        } else {
            delete newSelected[row.positionId];
        }
        setSelected(newSelected);
        setDirty(true);
    }

    function toggleCheckedProjectRow(event, index, row) {
        const newSelected = Object.assign({}, selectedProjects);
        const isChecked = event.target.checked;
        if (isChecked) {
            newSelected[row.projectId] = row;
        } else {
            delete newSelected[row.projectId];
        }
        setSelectedProjects(newSelected);

        setDirty(true);
    }

    function toggleCheckedBreakRuleRow(event, index, row) {
        const newSelected = Object.assign({}, selectedBreakRule);
        const isChecked = event.target.checked;
        if (isChecked) {
            newSelected[row.breakRuleId] = row;
        } else {
            delete newSelected[row.breakRuleId];
        }
        setSelectedBreakRule(newSelected);
        setDirty(true);
    }

    function positionRate(event, index, row) {
        const newSelected = Object.assign({}, selected);
        newSelected[row.positionId] = row;
        const data = [...positionList];
        data[index].hourlyRate = event.target.value;
        setPositionList(data);
        setSelected(newSelected);
        setDirty(true);
    }

    function toggleImageModal() {
        setShowImageModal(!showImageModal);
    }

    function saveImage(base64) {
        setDirty(true);
        setImage(base64);
        setShowImageModal(!showImageModal);
    }

    function checkPTOAccrualCriticalFields() {
        let mess = '';
        let showDisclaimer = false;

        if (moment(hireDate).format('L') !== moment(originalHireDate).format('L')) {
            mess = "Are you sure you want to change the hire date from " + moment(originalHireDate).format('L') +
                " to " + moment(hireDate).format('L');

            if (originalPTORuleId && ptoRuleId !== originalPTORuleId) {
                mess += " and PTO rule?";
            } else {
                mess += "?"
            }

            showDisclaimer = true;
        } else {
            if (originalPTORuleId && ptoRuleId !== originalPTORuleId) {
                mess = "Are you sure you want to change the PTO rule?";
                showDisclaimer = true;
            }
        }

        mess += " This may cause incorrect PTO accrual.";

        if (showDisclaimer) {
            setChangingHireDateMessage(mess);
            return true;
        }

        return false;
    }

    function checkScheduleFormat(value) {
        return /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(value);
    }

    function handleSubmit(event, ignoreHireDate) {
        if (event) {
            event.preventDefault();
        }

        if (hireDate == null) {
            toast.error("Hire Date is required.", {position: 'bottom-right'});
            return;
        }
        
        if (username == null || username === "") {
            toast.error("Username is required.", {position: 'bottom-right'});
            return false;
        }
       if (username.length < 6) {
          toast.error("Username must have six characters.", {position: "bottom-right",
       });
       return false;
        }
        let wrongFormat = false;
        employeeScheduleList.forEach((item) => {
            if (item.active && (!checkScheduleFormat(item.startTime) || !checkScheduleFormat(item.endTime))) {
                wrongFormat = true;
            }
        });

        if (wrongFormat) {
            toast.error("Wrong format for schedule time.", {position: 'bottom-right'});
            return;
        }

        if (id && !ignoreHireDate) {
            const showDisclaimer = checkPTOAccrualCriticalFields();

            if (showDisclaimer) {
                setConfirmChangingHireDateModalShow(true);
                return;
            }
        }

        let ptoAccrualDate = lastPtoAccrualDate;
        if (ptoRule && !ptoAccrualDate) {
            ptoAccrualDate = moment().startOf('day');
            setLastPtoAccrualDate(ptoAccrualDate);
        }

        setLoading(true);

        const selectedPositions = [];
        for (const key in selected) {
            selectedPositions.push(selected[key]);
        }

        const selectedProjectsTemp = [];
        for (const key in selectedProjects) {
            selectedProjectsTemp.push(selectedProjects[key]);
        }

        const selectedBreakRules = [];
        for (const key in selectedBreakRule) {
            selectedBreakRules.push(selectedBreakRule[key]);
        }

        let payload = {
            updatedFrom: updatedFrom,
            username: username,
            email: email,
            nameFirst: nameFirst,
            nameLast: nameLast,
            phone: phone,
            hourlyRate: hourlyRate,
            title: title,
            admin: admin,
            manager: manager,
            allowMobile: allowMobile,
            defaultContact: defaultContact,
            ptoSick: ptoSick,
            ptoVacation: ptoVacation,
            ptoHoliday: ptoHoliday,
            hireDate: Util.formatDateToUtc(hireDate),
            active: active,
            imageUrl: imageUrl,
            image: image,
            departmentId: departmentId,
            overtimeRuleId: overtimeRuleId,
            payPeriodRuleId: payPeriodRuleId,
            ptoRuleId: ptoRuleId,
            employeePositions: selectedPositions,
            employeeProjects: selectedProjectsTemp,
            employeeBreakRules: selectedBreakRules,
            employeeScheduleList: employeeScheduleList,
            lastPtoAccrualDate: Util.formatDateToUtc(ptoAccrualDate),
        };

        const handleResponse = success => {
            setNewDialog(!success);
            if (success) {
                loadData();
            }
        };

        if (!id) {
            saveData('POST', payload)
                .then(data => handleResponse(data));
        } else {
            saveData('PATCH', payload, id)
                .then(data => handleResponse(data))
                .then(data => {
                    if (id == Util.getUserTenant().id) {
                        Util.updateUserLocalStorage(id);
                    }
                });
        }
    }

    function saveData(method, employee, employeeId = '') {
        setLoading(true);

        const apiUrl = Util.apiUrl(`users/${employeeId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(employee),
        };

        return fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('User was saved', {position: 'bottom-right'});
                if (employeeId === Util.getUserTenant().id) {
                    localStorage.setItem('userTenant', JSON.stringify(data));
                }

                loadData();
                return true;
            })
            .catch(error => {
                ServerResponseHandler.handleError(error);
                return false;
            })
            .finally(() => {
                setLoading(false);
                setPin('');
            });
    }

    function loadActiveUsersCount() {
        const apiUrl = Util.apiUrl(`users/active_count`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                setActiveUsersCount(json);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadData() {
        getDefaultPayPeriodRule();
        getDefaultPtoRule();
        getDefaultOvertimeRule();
        setId(null);
        setEmail('');
        setNameFirst('');
        setNameLast('');
        setUsername('');
        setPhone('');
        setHourlyRate('$0.00');
        setTitle('');
        setAdmin(false);
        setManager(false);
        setAllowMobile(false);
        setDefaultContact(false);
        setPtoSick(0.0);
        setPtoVacation(0.0);
        setPtoHoliday(0.0);
        setDepartmentId('');
        setDepartment(null);
        setImageUrl('');
        setImage('');
        setHireDate(null);
        setActive(true);
        setLastPtoAccrualDate(null);

        const zone = moment.tz.guess();

        const apiParams = searchBarApi.getParams();
        delete apiParams.employee;

        const apiUrl = Util.apiSearchUrl(`users`, apiParams);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.maxResults !== 0) {
                    json.resultList = parseTableData(_.sortBy(json.resultList, (getCompanySettings().nameFormat === 'Firstname Lastname' ? ['nameFirst', 'nameLast'] : ['nameLast', 'nameFirst'])));
                }
                const data = Util.sanitizeData(json.resultList);
                setCsvData(parseCsvData(json.resultList));
                setMaxResults(json.maxResults);

                if (data && data.length) {
                    data.forEach(d => {
                        if (d.lastTimeWorked)
                            d.lastTimeWorked = Util.basicDateFormatter(d.lastTimeWorked);
                    });
                }

                setData(data);
                loadActiveUsersCount();
                loadPositionList();
                loadBreakRuleList();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function parseCsvData(dataList) {
        dataList.map(employee => {
            employee.isAdmin = employee.admin ? 'TRUE' : 'FALSE';
            employee.isActive = employee.active ? 'TRUE' : 'FALSE';
            employee.csvSickHours = employee.ptoSick;
            employee.csvPtoHours = employee.ptoVacation;
            employee.hireDate = moment(employee.hireDate).format('YYYY-MM-DD');
        })
    }

    function parseTableData(dataList) {
        for (const tableRow of dataList) {
            if (tableRow.nameFirst) {
                tableRow.employeeName = Util.nameFormatter(tableRow.nameFirst, tableRow.nameLast)
            }
            if (tableRow.userTenantPositionList) {
                let filteredPositionList = tableRow.userTenantPositionList.filter((value, index, array) => {
                    return value.position.deletedOn == null
                })
                tableRow.position = filteredPositionList
                    .map(pos => pos.position.name)
                    .join(", ")
            }
        }
        return dataList;
    }

    function deleteEmployee() {
        setLoading(true);
        const apiUrl = Util.apiUrl(`users/${deleteEmployees[0]}`);
        deleteEmployees[0].deletedOn = Util.formatDateToUtc(moment());
        fetch(apiUrl, {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(deleteEmployees[0]),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Employee deleted', {position: 'bottom-right'});
                loadData();
                setConfirmDeleteDialog(false);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function confirmHireDateChange() {
        handleSubmit(null, true);
    }

    function loadPositionList(employeeCode = 0) {
        setLoading(true);
        const apiUrl = Util.apiUrl(`company/positions/${employeeCode}`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                setPositionList(json);
                loadCompanyPositions();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadProjectList(employeeCode = 0) {
        setLoading(true);

        let search = {};
        search.projectStatus = 'Open';

        const apiUrl = Util.apiSearchUrl(`projects`, search);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                setProjectList(json.resultList.map(x => { return {...x, projectId: x.id} }));
                //loadCompanyProjects();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadCompanyPositions() {
        setLoading(true)

        const apiUrl = Util.apiUrl(`company/positions`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => setCompanyPositionList(json))
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadCompanyProjects() {
        setLoading(true)

        let search = {};
        search.projectStatus = 'Open';

        const apiUrl = Util.apiSearchUrl(`projects`, search);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => setCompanyProjectList(json))
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadBreakRuleList(employeeCode = 0) {
        setLoading(true);
        const apiUrl = Util.apiUrl(`company/breakrules/${employeeCode}`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                setBreakRuleList(json);
                loadCompanyBreakRules();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadCompanyBreakRules() {
        setLoading(true)

        const apiUrl = Util.apiUrl(`break_rule`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => setCompanyBreakRuleList(json))
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function importCsv() {
        setCsvImportModal(!csvImportModal)
    }

    function importUsers(event) {
        const data = new FormData();
        const apiUrl = Util.apiUrl(`users/import`);
        let file = event.target.files[0];
        data.append('file', file);
        const params = {
            method: 'POST',
            credentials: 'include',
            body: data,
        };
        return fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Users imported', {position: 'bottom-right'});
                loadData();
                return true;
            })
            .catch(error => {
                ServerResponseHandler.handleError(error);
                return false;
            })
            .finally(() => setCsvImportModal(!csvImportModal));
    }

    async function changePin() {
        let pin = generateRandomPin(Util.getCompanySettings().pinLength);
        setPin(pin);
        setNewPin(pin);

        await saveNewPin(pin, id);
        setShowPinModal(true);
    }

    async function saveNewPin(pin, employeeId = '') {
        setLoading(true);

        let payload = {
            pin: pin.toString(),
        };

        const apiUrl = Util.apiUrl(`users/${employeeId}`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload),
        };

        await fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(data => {
                if (!data.errorMessage) {
                    toast.success('Pin was updated', {position: 'bottom-right'});
                } else {
                    toast.error('Failed to update pin. Please try again', {position: 'bottom-right'});
                }
            })
            .catch(error => {
                ServerResponseHandler.handleError(error);
            })
            .finally(() => {
                    setLoading(false);
                }
            );
    }


    /*================ Employee Schedule Functions ================*/
    function loadEmployeeSchedule(employeeId) {
        setEmployeeScheduleList([]);

        const apiUrl = Util.apiUrl(`company/schedule/` + employeeId);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => {
                return ServerResponseHandler.getResponse(response);
            })
            .then(json => {
                let recievedDays = json;
                let expectedDays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                ];
                let schedules = expectedDays.map((day, i) => {
                    let match = recievedDays ? recievedDays.filter(d => d.dayOfWeek === day) : null;
                    if (!match || match.length <= 0) {
                        return {
                            dayOfWeek: day,
                            active: false,
                            startTime: "",
                            endTime: "",
                            changed: false,
                        };
                    }
                    match.changed = false;
                    return match[0];
                });
                setEmployeeScheduleList(schedules);
            })
            .catch(error => {
                ServerResponseHandler.handleError(error);
            });
    }

    function saveEmployeeSchedule(dayToSave, method) {
        if (!dayToSave) {
            toast.error("Unable to save hours of operation update", {position: 'bottom-right'});
        }
    }

    function getNeedsUpdateModalButtons() {
        const buttons = [];

        buttons.push({
            id: 'cancel',
            text: 'Cancel',
            color: 'outline-secondary',
            onClick: () => setNeedsUpgradeModalShow(false)
        });

        if(Util.isAdmin()) {
            buttons.push({
                id: 'update_subscription',
                    text: 'Update Subscription',
                    color: 'success',
                    onClick: () => {
                    props.history.push("/company/billing");
                }
            });
        }

        return buttons;
    }

//#endregion
    const csvProperties = {
        csvHeader: [
            {label: 'First Name', key: 'nameFirst'},
            {label: 'Last Name', key: 'nameLast'},
            {label: 'Email', key: 'email'},
            {label: 'Phone', key: 'phone'},
            {label: 'Title', key: 'title'},
            {label: 'Hire Date', key: 'hireDate'},
            {label: 'Active', key: 'isActive'},
            {label: 'Admin', key: 'isAdmin'},
            {label: 'Hourly Rate', key: 'hourlyRate'},
            {label: 'Positions', key: 'position'},
            {label: 'Department', key: 'department.name'},
            {label: 'Overtime Rule', key: 'overtimeRule.name'},
            {label: 'Pay Period Rule', key: 'payPeriodRule.name'},
            {label: 'PTO Rule', key: 'ptoRule.name'},
            {label: 'Sick Hours', key: 'csvSickHours'},
            {label: 'PTO Hours', key: 'csvPtoHours'},
            {label: 'Holiday Hours', key: 'ptoHoliday'},
            {label: 'Id', key: 'id'}
        ],
        data: data,
        csvFilename: 'employees.csv',
        loading: loading
    };

    const buttons = {
        add: toggleNewModal,
        download: csvProperties,
        importCsv: importCsv,
        savePdf: false,
        loading: loading
    };

    const hidePositionsOnForm = !positionList || positionList.length === 0;
    const positionsExist = companyPositionList && companyPositionList.maxResults !== 0;

    let positionColumn = {};

    if (positionsExist) {
        positionColumn = {Header: 'Position', accessor: 'position', headerClassName: 'table-header',}
    }

    const tableColumns = [
        {Header: 'Employee', accessor: 'employeeName', headerClassName: 'table-header',},
        {Header: 'Last Worked', accessor: 'clockIn', headerClassName: 'table-header',},
        {Header: 'Title', accessor: 'title', headerClassName: 'table-header',},
        positionColumn,
        {Header: 'Department', accessor: 'department.name', headerClassName: 'table-header',},
        {
            Header: 'Active', accessor: 'active', headerClassName: 'table-header', width: 100,
            Cell: row => (row.value === true ? <span>Yes</span> : <span>No</span>)
        },
        {
            Header: 'Actions', accessor: 'actions', headerClassName: 'table-header', width: 100,
            Cell: row => (
                <center>
                    <i className="fas fa-edit" onClick={() => toggleEditModal(row.original)}
                       style={{paddingRight: '10px'}}></i>
                    <i className="fas fa-qrcode" onClick={() => toggleQrModal(row.original)}></i>
                </center>
            )
        }
    ];

    if (!positionsExist) {
        tableColumns.splice(3, 1);
    }

    let displayImage = <img className="default-image-icon md-card-image image-circle" id="userImage"
                            src="/img/image_placeholder.png"
                            width={200} height={200}/>;
    if (image || imageUrl) {
        displayImage = <img className="default-image-icon md-card-image img-thumbnail image-circle"
                            id="userImage" src={image || imageUrl} width={200} height={200}/>;
    }

    return (
        <div>
            <Container fluid>
                <Modal isOpen={qrDialog} toggle={() => toggleQrModal(null)}
                       className={'modal-primary ' + props.qrDialog}>
                    <ModalHeader toggle={() => toggleQrModal(null)}>Employee QR Badge</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <FbQrCode value={qrUserData.id}
                                      nameFirst={qrUserData.nameFirst}
                                      nameLast={qrUserData.nameLast}
                                      ref={qrRef}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ReactToPrint
                            trigger={() => <Button color="success" disabled={loading}>Print</Button>}
                            content={() => qrRef}/>
                        <Button color="secondary" onClick={() => toggleQrModal(null)}
                                disabled={loading}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ConfirmModal
                    isOpen={confirmChangingHireDateModalShow}
                    title="Change hire date"
                    message={changingHireDateMessage}
                    confirmText="Yes"
                    confirm={confirmHireDateChange}
                    cancel={() => {
                        setConfirmChangingHireDateModalShow(false);
                    }}
                />
                <Modal isOpen={csvImportModal} toggle={() => setCsvImportModal(!csvImportModal)}>
                    <ModalHeader toggle={(e) => importCsv(e)}>Import CSV</ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <span
                                className="font-italic">* Required fields: First Name, Last Name, Email, Hire Date.</span>
                        </div>
                        <div className="mb-3">
                            <Button color='primary' onClick={() => document.getElementById('csv-import').click()}
                                    style={{marginTop: '10px'}}>CSV Upload</Button>
                            <div id={'imageForm'}>
                                <input id={'csv-import'} type={'file'} onChange={(e) => importUsers(e)} hidden/>
                            </div>
                        </div>
                        <div>
                            <a href='https://s3.amazonaws.com/fishbowllabor/StaticContent/employeesExampleNew.csv'
                               download>Download Sample Import File</a>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => importCsv()}
                                disabled={loading}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={newDialog} className={'modal-lg'}>
                    <form onSubmit={handleSubmit} id="employee-form" noValidate>
                        <ModalHeader toggle={() => toggleNewModal()}>
                            {
                                !id ? 'New Employee' : 'Employee: ' + Util.nameFormatter(nameFirst, nameLast)
                            }
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <Card className="card-accent-primary">
                                        <CardHeader>User Image</CardHeader>
                                        <CardBody>
                                            {displayImage}
                                            <br/>
                                            <ImageCrop saveImage={saveImage}
                                                       showModal={showImageModal}
                                                       toggleModal={toggleImageModal}
                                                       imageId={'employeeImage'}/>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-md-6" hidden={hidePositionsOnForm}>
                                    <Card className="card-accent-primary mb-4">
                                        <CardHeader>
                                            <div>Positions {companySettings.requirePositionClockIn && <span className="error">*</span>}</div>
                                        </CardHeader>
                                        <CardBody>
                                            <EmployeePositionsTable
                                                data={positionList}
                                                tableColumns={getEmployeePositionsTableColumns()}
                                                positionRate={positionRate}
                                                toggleCheckedRow={toggleCheckedRow}
                                                positionList={positionList}
                                                selected={selected}
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Card className="card-accent-primary">
                                        <CardHeader>User Details</CardHeader>
                                        <CardBody>
                                            <Label>First Name<span className="error">*</span></Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="First Name" name="NameFirst"
                                                       ref={nameFirstInput}
                                                       value={nameFirst} autoComplete="off"
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setNameFirst))
                                                }}
                                                       required/>
                                            </InputGroup>

                                            <Label>Last Name<span className="error">*</span></Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="Last Name" name="NameLast"
                                                       ref={nameLastInput}
                                                       value={nameLast} autoComplete="off"
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setNameLast))
                                                }}
                                                       required/>
                                            </InputGroup>

                                            <Label>Username<span className="error">*</span></Label>
                                            <InputGroup className="mb-3">
                                                <input
                                                    type="text"
                                                    pattern=".{6,}"
                                                    placeholder="Username"
                                                    name="Username"
                                                    ref={usernameInput}
                                                    value={username} autoComplete="off"
                                                    className="form-control"
                                                    onChange={event => {
                                                    (handleChange(event, setUsername))
                                                }}
                                                    required/>
                                            </InputGroup>

                                            <Label>Email<span className="error">*</span></Label>
                                            <InputGroup className="mb-3">
                                                <input type="email" placeholder="Email" name="Email"
                                                       ref={emailInput}
                                                       value={email} autoComplete="off"
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setEmail))
                                                }}
                                                       required/>
                                            </InputGroup>

                                            <Label>Department</Label>
                                            <div className="mb-3">
                                                <SelectDepartment department={department}
                                                                  onChange={handleDepartmentChange}/>
                                            </div>

                                            <Label>Phone</Label>
                                            <InputGroup className="mb-3">
                                                <input type="tel" placeholder="(123)456-7890" name="Phone"
                                                       ref={phoneInput}
                                                       value={phone} autoComplete="off"
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setPhone, phoneInput))
                                                }}/>
                                            </InputGroup>

                                            <Label>Hire Date</Label>
                                            <FBDatePicker
                                                value={hireDate}
                                                onChange={date => handleDateChange(date)}
                                                placeholder="Hire Date"
                                            />

                                            <div style={{paddingTop: '25px'}}>
                                                <Label>Active</Label>
                                                <Label
                                                    className="switch switch-icon switch-pill switch-info pull-right">
                                                    <Input type="checkbox" name="Active"
                                                           ref={activeInput}
                                                           className="switch-input"
                                                           checked={active}
                                                           onChange={event => {
                                                               (handleCheckBoxChange(event, setActive))
                                                           }}/>
                                                    <span className="switch-label" data-on={'\uf00c'}
                                                          data-off={'\uf00d'}/>
                                                    <span className="switch-handle"/>
                                                </Label>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-md-6">
                                    <Card className="card-accent-primary">
                                        <CardHeader>Payroll</CardHeader>
                                        <CardBody>
                                            <Label>Hourly Rate</Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="$0.00" name="HourlyRate"
                                                       ref={hourlyRateInput}
                                                       value={hourlyRate}
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setHourlyRate))
                                                }}/>
                                            </InputGroup>

                                            <Label>Title</Label>
                                            <InputGroup className="mb-3">
                                                <input type="text" placeholder="Title" name="Title"
                                                       ref={titleInput}
                                                       value={title}
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setTitle))
                                                }}/>
                                            </InputGroup>

                                            <Label>Pay Period Rule</Label>
                                            <div className="mb-3">
                                                <SelectPayPeriodRule payPeriodRule={payPeriodRule}
                                                                     isClearable={false}
                                                                     onChange={handlePayPeriodRuleChange}/>
                                            </div>

                                            <Label>Overtime Rule</Label>
                                            <div className="mb-3">
                                                <SelectOvertimeRule overtimeRule={overtimeRule}
                                                                    onChange={handleOvertimeRuleChange}/>
                                            </div>

                                            <Label>PTO Rule</Label>
                                            <div className="mb-3">
                                                <SelectPtoRule ptoRule={ptoRule}
                                                               onChange={handlePtoRuleChange}/>
                                            </div>

                                            <Label>Time Off - Sick</Label>
                                            <InputGroup className="mb-3">
                                                <input type="number" placeholder="0.00" step="any" name="ptoSick"
                                                       ref={ptoSickInput}
                                                       value={ptoSick}
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setPtoSick))
                                                }}/>
                                            </InputGroup>

                                            <Label>Time Off - Vacation</Label>
                                            <InputGroup className="mb-3">
                                                <input type="number" placeholder="0.00" step="any"
                                                       name="ptoVacation"
                                                       ref={ptoVacationInput}
                                                       value={ptoVacation}
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setPtoVacation))
                                                }}/>
                                            </InputGroup>

                                            <Label>Time Off - Holiday</Label>
                                            <InputGroup className="mb-3">
                                                <input type="number" placeholder="0.00" step="any" name="ptoHoliday"
                                                       ref={ptoHolidayInput}
                                                       value={ptoHoliday}
                                                       className="form-control" onChange={event => {
                                                    (handleChange(event, setPtoHoliday))
                                                }}/>
                                            </InputGroup>

                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-md-6">
                                    <Card className="card-accent-primary">
                                        <CardHeader>Permissions</CardHeader>
                                        <CardBody>
                                            <div className="clearfix">
                                                <Label>Default Contact</Label>
                                                <Label
                                                    className="switch switch-icon switch-pill switch-info pull-right"
                                                    title={defaultContact ? "Default User cannot be toggled off. To choose a new Default Contact, toggle it on for another user" : "Employee is the default contact. This should be an Admin user."}
                                                    style={defaultContact ? {cursor: 'default', opacity: '0.5'} : {}}>
                                                    <Input type="checkbox" name="defaultContact"
                                                           ref={defaultContactInput}
                                                           className="switch-input"
                                                           disabled={defaultContact}
                                                           checked={defaultContact}
                                                           onChange={toggleConfirmDefaultModal}/>
                                                    <span className="switch-label" data-on={'\uf00c'}
                                                          data-off={'\uf00d'}/>
                                                    <span className="switch-handle"/>
                                                </Label>
                                            </div>

                                            <div className="clearfix">
                                                <Label>Admin</Label>
                                                <Label
                                                    className="switch switch-icon switch-pill switch-info pull-right"
                                                    title="Give this employee administrator rights">
                                                    <Input type="checkbox" name="admin" ref={adminInput}
                                                           className="switch-input"
                                                           checked={admin}
                                                           onChange={event => {
                                                               (handleCheckBoxChange(event, setAdmin))
                                                           }}/>
                                                    <span className="switch-label" data-on={'\uf00c'}
                                                          data-off={'\uf00d'}/>
                                                    <span className="switch-handle"/>
                                                </Label>
                                            </div>

                                            <div className="clearfix">
                                                <Label>Manager</Label>
                                                <Label
                                                    className="switch switch-icon switch-pill switch-info pull-right"
                                                    id="managerTooltip" style={{cursor: 'default'}}>
                                                    <Input type="checkbox" name="manager"
                                                           ref={managerInput}
                                                           className="switch-input"
                                                           checked={manager}
                                                           onChange={event => {
                                                               (handleCheckBoxChange(event, setManager))
                                                           }} disabled/>
                                                    <span className="switch-label" data-on={'\uf00c'}
                                                          data-off={'\uf00d'}/>
                                                    <span className="switch-handle"/>
                                                </Label>
                                                <Tooltip placement="right" isOpen={managerTooltipOpen}
                                                         target="managerTooltip" toggle={toggleManagerTooltip}>
                                                    This is enabled through Departments
                                                </Tooltip>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-md-6">
                                    <Card className="card-accent-primary">
                                        <CardHeader>Time Clock</CardHeader>
                                        <CardBody>
                                            <InputGroup className="mb-3">
                                                <div className={'text-right'}>
                                                    <Button color="primary" id="change-pin" className="btn"
                                                            disabled={loading} onClick={changePin}>Change Pin</Button>
                                                </div>
                                            </InputGroup>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-md-6">
                                    <Card className="card-accent-primary">
                                        <CardHeader>
                                            <div>Schedule</div>
                                        </CardHeader>
                                        <CardBody>
                                            <EmployeeScheduleTable
                                                setDirty={() => setDirty(true)}
                                                data={employeeScheduleList}
                                                saveDataParent={saveEmployeeSchedule}
                                                tableSettings={{
                                                    canEdit: canEdit
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-md-6">
                                    <Card className="card-accent-primary mb-4">
                                        <CardHeader>
                                            <div>Break Rules</div>
                                        </CardHeader>
                                        <CardBody>
                                            <EmployeeBreakRulesTable
                                                data={breakRuleList}
                                                tableColumns={getEmployeeBreakRulesTableColumns()}
                                                positionRate={positionRate}
                                                toggleCheckedRow={toggleCheckedBreakRuleRow}
                                                positionList={breakRuleList}
                                                selected={selectedBreakRule}
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <Card className="card-accent-primary mb-4">
                                        <CardHeader>
                                            <div>Projects</div>
                                        </CardHeader>
                                        <CardBody>
                                            <EmployeeProjectsTable
                                                data={projectList}
                                                tableColumns={getEmployeeProjectsTableColumns()}
                                                toggleCheckedRow={toggleCheckedProjectRow}
                                                projectList={projectList}
                                                selected={selectedProjects}
                                                />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" disabled={loading}>Save</Button>{' '}
                            <Button color="secondary" onClick={() => toggleNewModal()}
                                    disabled={loading}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={confirmDeleteDialog}>
                    <ModalHeader>Delete Log</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this employee?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => deleteEmployee()}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => toggleConfirmDeleteModal(null)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={confirmDefaultUserDialog}>
                    <ModalHeader>Change Default User</ModalHeader>
                    <ModalBody>
                        Choosing a new Default User will remove Default User from the previous holder, are you sure you
                        want this employee to be the new Default?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => makeDefaultUser()}>Confirm</Button>{' '}
                        <Button color="secondary"
                                onClick={() => toggleConfirmDefaultModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <CustomModal
                    isOpen={cancelConfirmationModalShow}
                    title="Keep your changes?"
                    message="There are unsaved changes for the employee. Are you sure you want to close the modal?"
                    buttons={[
                        {
                            id: 'cancel',
                            text: 'Cancel',
                            color: 'outline-secondary',
                            onClick: () => setCancelConfirmationModalShow(false)
                        },
                        {
                            id: 'close',
                            text: 'Close',
                            onClick: () => {
                                toggleNewModal(true);
                                setDirty(false);
                                setCancelConfirmationModalShow(false);
                            }
                        },
                        {
                            id: 'save_and_close',
                            text: 'Save and Close',
                            color: 'success',
                            onClick: () => {
                                handleSubmit();
                                setDirty(false);
                                setCancelConfirmationModalShow(false);
                            }
                        }
                    ]}
                />
                <CustomModal
                    isOpen={needsUpgradeModalShow}
                    title="User limit has been reached!"
                    message={Util.isAdmin() ? "You have reached the maximum number of users. Please update your subscription." :
                            "You have reached the maximum number of users. Please contact an account admin to have the subscription updated."}
                    buttons={getNeedsUpdateModalButtons()}
                />
                <TimeClockPinModal
                    showModal={showPinModal}
                    toggleModal={setShowPinModal}
                    pin={newPin}
                />
                <Row className="col-12">
                    <Card className="col-lg-12 card-accent-primary">
                        <CardHeader>
                            <SearchBar
                                buttons={buttons}
                                onSearch={loadData}
                                includeEmployee={true}
                                includePosition={true}
                                includeDepartment={true}
                                includeActiveFlag={true}
                                getSearchBarApi={setSearchBarApi}
                            />
                        </CardHeader>
                        <CardBody>
                            <EmployeesTable
                                data={data}
                                tableColumns={getTableColumns()}
                                toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                                toggleEditModal={toggleEditModal}
                                tableSettings={{
                                    canDelete: canDelete,
                                    canEdit: canEdit
                                }}
                                resetPassword={resetPassword}
                            />
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <ToastContainer/>
        </div>
    )
}

export default Employees;