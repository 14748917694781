import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        userLoggedIn()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />
    )}/>
);

export const AdminRoute = ({component: Component, ...rest}) => (
   <Route {...rest} render={(props) => {
       if (userLoggedIn()) {
           if (isAdmin()) {
               return (<Component {...props} />);
           } else {
               return (<Redirect to='/timeclock/my'/>);
           }
       } else {
           return (<Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />);
       }
   }}/>
);

export const ManagerRoute = ({component: Component, sessionDetails, ...rest}) => (
    <Route {...rest} render={(props) => {
        if (userLoggedIn()) {
            if (isManager() || isAdmin()) {
                return (<Component {...props} sessionDetails={sessionDetails} />);
            } else {
                return (<Redirect to='/timeclock/my'/>);
            }
        } else {
            return (<Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />);
        }
    }}/>
);

function userLoggedIn() {
    const loggedIn = localStorage.getItem("loggedIn");
    return JSON.parse(loggedIn) === true;
}

function isManager() {
    const manager = localStorage.getItem("isManager");
    return JSON.parse(manager) === true;
}

function isAdmin() {
    const admin = localStorage.getItem("isAdmin");
    return JSON.parse(admin) === true;
}
