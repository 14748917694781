import React, {useState, useEffect} from 'react';
import {
    Container,
    Card,
    CardBody,
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import {SearchBar} from "../../../components/Framework/SearchBar";
import { ToastContainer, toast } from 'react-toastify';
import GlobalCompaniesTable from "../../../components/DataTables/GlobalCompaniesTable"
import CompanyModal from "../../../components/Modal/CompanyModal";
import AddCompanyModal from "../../../components/Modal/AddCompanyModal";
import ConfirmDeleteDialog from "../../../components/Modal/ConfirmDeleteModal";

let searchBarApi;
let deleteCompany = {name: ''};

function Executor(props) {
    const [loading, setLoading] = useState(false);
    const [companyDialog, setCompanyDialog] = useState(false);
    const [addCompanyDialog, setAddCompanyDialog] = useState(false);
    const [company, setCompany] = useState({});
    const [managedCompanies, setManagedCompanies] = useState([]);
    const [data, setData] = useState([]);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

    let initialTableColumns = [
        {displayName: 'Company', columnId: 'company', hideColumn: false},
        {displayName: 'Email', columnId: 'email', hideColumn: false},
        {displayName: 'Max Users', columnId: 'maxUsers', hideColumn: false},
        {displayName: 'Stripe Customer ID', columnId: 'stripeCustomerId', hideColumn: true},
        {displayName: 'Stripe Subscription ID', columnId: 'stripeSubscriptionId', hideColumn: true},
        {displayName: 'FB Integrated', columnId: 'fbIntegrated', hideColumn: false},
        {displayName: 'Status', columnId: 'status', hideColumn: false},
        {displayName: 'Expire on', columnId: 'expireOn', hideColumn: false},
        {displayName: 'Last Login', columnId: 'lastLogin', hideColumn: false},
    ];
    const [tableColumns, setTableColumns] = useState(initialTableColumns);

    useEffect(loadData, []);

    const csvProperties = {
        csvHeader: [
            {label: 'Company', key: 'name'},
            {label: 'Email', key: 'defaultContact[email]'},
            {label: 'Max Users', key: 'maxUsers'},
            {label: 'FB Integrated', key: 'fishbowlIntegrated'},
            {label: 'Status', key: 'status'},
            {label: 'Expire on', key: 'expirationDate'},
            {label: 'Last Login', key: 'lastLogin'},
        ],
        data: data,
        csvFilename: 'employees.csv',
        loading: loading
    };

    const buttons = {
        download: csvProperties,
        savePdf: false,
        loading: loading
    };

    const confirmDelete = {
        header: 'Remove Company',
        message: ['Are you sure you want to remove ' + deleteCompany.name + ' from this Administrator?'],
        confirm: removeMangedCompany,
        cancel: toggleConfirmDeleteModal
    };


    function setSearchBarApi(api) {
        searchBarApi = api;
    }

    function toggleConfirmDeleteModal(e, company = {name: ''}) {
        deleteCompany = company;
        setConfirmDeleteDialog(!confirmDeleteDialog);
    }

    function removeMangedCompany() {
        deleteCompany.administratorCompanyId = null;
        saveCompany(deleteCompany);
        setConfirmDeleteDialog(false);
    }

    function toggleCompanyModal(event, company) {
        let companyId = company ? company.id : null;
        setCompany(company ? company : {});
        setCompanyDialog(!companyDialog);
        getManagedCompanies(companyId);
    }

    function toggleAddCompanyModal() {
        setAddCompanyDialog(!addCompanyDialog);
    }

    function setCompanyStatus(companies) {
        setData(companies);
    }

    function loadData() {
        setLoading(true);
        const userGlobal = Util.getUserGlobal();
        const formUrl = userGlobal.executor ? Util.apiSearchUrl(`company_global/search_list`, searchBarApi.getParams()) : Util.apiUrl(`company_global/managed_companies/${userGlobal.companyId}`);
        fetch(formUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                setCompanyStatus(userGlobal.executor ? json.resultList : json);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function closeCompanyDialog() {
        loadData();
        setCompanyDialog(!companyDialog);
    }

    function saveCompany(_company, skipSetCompanyDialog) {
        setLoading(true);
        const formUrl = Util.apiUrl(`company_global/saveGlobalOnly`);
        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(_company)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success("Company was saved", {position: 'bottom-right'});
                loadData();
                
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));

        if (!skipSetCompanyDialog)
            setCompanyDialog(!companyDialog);
    }

    function getManagedCompanies(companyId = null) {
        if (companyId) {
            setLoading(true);
            const formUrl = Util.apiUrl(`company_global/managed_companies/${companyId}`);
            fetch(formUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {'Content-Type': 'application/json;charset=UTF-8'}
            })
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    if (json && json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    setManagedCompanies(json);
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => setLoading(false));
        }
    }

    function addManagedCompany(managedCompany) {
        setLoading(true);
        const apiUrl = Util.apiUrl(`company_global/add_managed_company/${company.id}`);

        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(managedCompany.id)
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                getManagedCompanies(company.id);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
        setAddCompanyDialog(!addCompanyDialog);
    }

    return (
        <div className="animated fadeIn">
            <ToastContainer/>
            <Container fluid>
                <CompanyModal closeCompanyDialog={closeCompanyDialog} save={saveCompany} managedCompanies={managedCompanies}
                              toggleAddCompanyModal={toggleAddCompanyModal} toggleEditModal={toggleCompanyModal}
                              toggleConfirmDeleteModal={toggleConfirmDeleteModal}  isOpen={companyDialog}
                              company={company}/>
                <AddCompanyModal save={addManagedCompany} toggleModal={toggleAddCompanyModal}
                                 toggleConfirmDeleteModal={toggleConfirmDeleteModal} isOpen={addCompanyDialog}
                                 company={company}/>
                <ConfirmDeleteDialog isOpen={confirmDeleteDialog} deleteDialog={confirmDelete}/>
                <Card className="col-lg-12 mt-3 card-accent-primary">
                    <CardBody>
                        <SearchBar
                            buttons={buttons}
                            onSearch={loadData}
                            includeGeneral={true}
                            getSearchBarApi={setSearchBarApi}
                            allowToggleTableColumns={true}
                            tableColumns={tableColumns}
                            updateColumnArray={setTableColumns}
                        />
                        <GlobalCompaniesTable
                            data={data}
                            tableColumns={tableColumns}
                            toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                            toggleEditModal={toggleCompanyModal}
                            tableSettings={{
                                canEdit: true
                            }}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default Executor;