import * as Util from "../Framework/Util";

const isAdmin = Util.isAdmin();
const isManager = Util.isManager();
const companySettings = Util.getCompanySettings();
const userGlobal = Util.getUserGlobal();
const isExecutor = userGlobal.executor;
const loggedWithExecutor = Util.getLoggedWithExecutor();
const isAdministrator = userGlobal.company.canAdministrate;

let terminals = null;
let projects = null;
let dashboard = null;
let manage = null;
let employees = null;
let company = null;
let qbo = null;
let reports = null;
let executor = null;
let schedule = null;
let support = null;
let backToExecutor = null;

if (isAdmin) {
    terminals = {
        name: 'Terminals',
        url: '/timeclock/terminals',
        icon: '',
        margin: 15,
    };

    employees = {
        name: 'Employees',
        url: '/employees',
        icon: 'people',
        margin: 15
    };

    support = {
        name: 'Support',
        action: 'support',
        icon: 'support_agent',
        margin: 0,
    };

    company = {
        name: 'Company Settings',
        url: '/company',
        icon: 'account_balance',
        margin: 0,
        // children: [
        //     {
        //         name: 'Company Details',
        //         url: '/company/details',
        //         icon: '',
        //         margin: 15,
        //     },
        //     {
        //         name: 'Company Settings',
        //         url: '/company/settings',
        //         icon: '',
        //         margin: 15,
        //     },
        //     {
        //         name: 'Departments',
        //         url: '/company/departments',
        //         icon: '',
        //         margin: 15,
        //     },
        //     {
        //         name: 'Employees',
        //         url: '/company/employees',
        //         icon: '',
        //         margin: 15,
        //     },
        // ]
    };

    qbo = {
        name: 'Integrations',
        url: '/integrations',
        icon: 'widgets',
        margin: 0,
        children: [
            {
                name: 'QuickBooks Desktop',
                url: '/integrations/qbd',
                icon: '',
                margin: 15
            },
            {
                name: 'QuickBooks Online',
                url: '/integrations/qbo',
                icon: '',
                margin: 15
            }
        ]
    };
}

if(isAdministrator) {
    executor = {
        name: 'Executor',
        url: '/executor',
        icon: 'input',
        margin: 0
    };

    support = {
        name: 'Support',
        action: 'support',
        icon: 'support_agent',
        margin: 0,
    };
} else if(isExecutor) {
    executor = {
        name: 'Executor',
        url: '/executor',
        icon: 'input',
        margin: 0
    }

    support = {
        name: 'Support',
        action: 'support',
        icon: 'support_agent',
        margin: 0,
    };
}

if (isManager || isAdmin) {
    dashboard = {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'dashboard',
        margin: 0,
    };

    schedule = {
        name: 'Schedule',
        url: '/schedule',
        icon: 'calendar_today',
        margin: 0,
    };

    manage = {
        name: 'Time Entries',
        url: '/manage/time-cards',
        icon: 'event_note',
        margin: 0,
        warning: true,
        // children: [
        //     {
        //         name: 'Time Cards',
        //         url: '/manage/time-cards',
        //         icon: '',
        //         margin: 15,
        //     },
        //     {
        //         name: 'Time Off Requests',
        //         url: '/manage/pto',
        //         icon: '',
        //         margin: 15,
        //     },
        // ]
    };

    reports = {
        name: 'Reports',
        url: '/reports',
        icon: 'assessment',
        margin: 0,
        children: [
            {
                name: 'Run Payroll',
                url: '/reports/payroll',
                icon: '',
                margin: 15,
            },
            {
                name: 'Employee Time Cards',
                url: '/reports/time-cards',
                icon: '',
                margin: 15,
            },
            {
                name: companySettings.projectSingleName + ' Details',
                url: '/reports/project-details',
                icon: '',
                margin: 15,
            },
            // {
            //     name: 'Full-time Analysis',
            //     url: '/reports/full-time-analysis',
            //     icon: '',
            //     margin: 15,
            // },
            {
                name: 'Employee QR Code',
                url: '/reports/employee-qr-code',
                icon: '',
                margin: 15,
            },
            {
                name: 'Audit',
                url: '/reports/audit',
                icon: '',
                margin: 15,
            },
        ]
    };
}

if (companySettings.enableProjects && (isAdmin || isManager)) {
    projects = {
        name: companySettings.projectPluralName,
        url: '/projects',
        icon: 'build',
        margin: 0,
    };
}

if (loggedWithExecutor) {
    backToExecutor = {
        name: 'Back to executor',
        url: '/toexecutor',
        action: 'backtoexecutor',
        icon: 'launch',
        margin: 0,
    };
}

export default {
    items: [
        dashboard,
        {
            name: 'Time Clock',
            url: '/timeclock/my',
            icon: 'schedule',
            margin: 0
        },
        manage,
        {
            name: 'PTO',
            url: '/pto',
            icon: 'flight_takeoff',
            margin: 15,
        },
        projects,
        reports,
        schedule,
        employees,
        company,
        qbo,
        executor,
        support,
        {
            divider: true
        },
        backToExecutor,
        {
            name: 'Logout',
            url: '/logout',
            icon: 'launch',
            margin: 0,
        },
    ]
};
