import React, {Component} from 'react';
import {
    Button,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Card,
    CardBody,
} from "reactstrap";
import ReactTable from "react-table";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import { ToastContainer, toast } from 'react-toastify';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import '../../../../scss/vendors/react-select/react-select.scss';
import {SearchBar} from "../../../components/Framework/SearchBar";
import SelectProjectStatus from "../../../components/Selects/SelectProjectStatus";
import Paging from "../../../components/Paging/Paging";
import SelectPickStatus from "../../../components/Selects/SelectPickStatus";
import ProjectsTable from "../../../components/DataTables/ProjectsTable";
import ProjectsTimeLogTable from "../../../components/DataTables/ProjectsTimeLogTable";
import * as _ from "lodash"
import FBDateTimePicker from "../../../components/DateTimePickers/FBDateTimePicker";

const moment = require('moment-timezone');
const isAdminOrManager = Util.isAdmin() || Util.isManager();
let companySettings = Util.getCompanySettings();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
let lastSearchedParameters = {
    projectStatus: 'Open'
};

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            projects: [],
            csvProjects: [],
            projectsPagingOptions: this.getDefaultPaging(),
            projectsTotal: 0,
            maxResults: 0,
            page: 1,
            sizePerPage: 10,
            modalDialog: false,
            logList: [],
            id: '',
            number: '',
            name: '',
            description: '',
            bomCategory: '',
            budgetedHours: 0,
            startDate: 0,
            endDate: 0,
            receiveDate: 0,
            projectStatus: 'Open',
            pickStatus: 'Entered',
            laborItemList: [],
            laborItemModalDialog: false,
            laborId: null,
            laborName: '',
            laborActive: true,
            selectedProject: {},
            total: {},
            csvImportModal: false,
            showSensitiveData: false
        };

        companySettings = Util.getCompanySettings();

        this.getTableColumns = this.getTableColumns.bind(this);
        this.showFormErrors = this.showFormErrors.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleReceiveDateChange = this.handleReceiveDateChange.bind(this);
        this.handleProjectStatusChange = this.handleProjectStatusChange.bind(this);
        this.handlePickStatusChange = this.handlePickStatusChange.bind(this);
        this.renderActive = this.renderActive.bind(this);
        this.toggleLogsModal = this.toggleLogsModal.bind(this);
        this.toggleLaborModal = this.toggleLaborModal.bind(this);
        this.importCsv = this.importCsv.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.toggleLaborItemModal = this.toggleLaborItemModal.bind(this);
        this.toggleLaborItemEditModal = this.toggleLaborItemEditModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.renderEditableCell = this.renderEditableCell.bind(this);
        this.renderEditableCheckBoxCell = this.renderEditableCheckBoxCell.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.parseTableData = this.parseTableData.bind(this);
        this.saveLaborItem = this.saveLaborItem.bind(this);
        this.loadLaborItemData = this.loadLaborItemData.bind(this);
        this.handleLaborItemSubmit = this.handleLaborItemSubmit.bind(this);
        this.deleteLaborItem = this.deleteLaborItem.bind(this);
        this.importProjectDetails = this.importProjectDetails.bind(this);
        this.getTimeTableColumns = this.getTimeTableColumns.bind(this);
        this.setPage = this.setPage.bind(this);
        this.resetPaging = this.resetPaging.bind(this);
        this.getDefaultPaging = this.getDefaultPaging.bind(this);
        this.fetchCsvData = this.fetchCsvData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    getTableColumns() {
        const columns = [];

        if(this.state.showSensitiveData) {
            columns.push({displayName: 'UUID', hideColumn: false});
        }

        columns.push(
            {displayName: 'Number', hideColumn: false},
            {displayName: 'Name', hideColumn: false},
            {displayName: 'Description', hideColumn: false},
            {displayName: 'Last Activity', hideColumn: false},
            {displayName: 'Budgeted Hours', hideColumn: false},
            {displayName: 'Actual Hours', hideColumn: false},
            {displayName: companySettings.projectSingleName + ' Status', hideColumn: false},
            {displayName: companySettings.projectSingleName + ' Pick Status', hideColumn: false},
            {displayName: companySettings.bomCategorySingleName, hideColumn: false}
        );

        if(this.state.showSensitiveData) {
            columns.push({displayName: 'Date Last Modified', hideColumn: false});
        }

        return columns;
    }

    getTimeTableColumns() {
        return [
            {displayName: 'Employee', hideColumn: false},
            {displayName: 'Clock In', hideColumn: false},
            {displayName: 'Clock Out', hideColumn: false},
            {displayName: 'Hours', hideColumn: false},
            {displayName: 'Overtime Hours', hideColumn: companySettings.includeOvertimeInProjectCost ? false : true},
            {displayName: 'Break', hideColumn: false},
            {displayName: 'Cost', hideColumn: false},
        ];
    }

    importCsv() {
        this.setState({csvImportModal: !this.state.csvImportModal})
    }

    showFormErrors(formId) {
        const inputs = document.getElementById(formId).querySelectorAll('input');
        let isFormValid = true;
        for (const input of inputs) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        }
        return isFormValid;
    }

    getDefaultPaging() {
        return {
            page: 1,
            size: 400
        };
    }

    resetPaging() {
        let updatedPaging = {...this.state.projectsPagingOptions};
        updatedPaging.page = 1;
        this.setState({ projectsPagingOptions: updatedPaging });
        return updatedPaging;
    }

    importProjectDetails(event) {
        const data = new FormData();
        const apiUrl = Util.apiUrl(`projects/import`);
        let file = event.target.files[0];
        data.append('file', file);
        const params = {
            method: 'POST',
            credentials: 'include',
            body: data,
        };
        return fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Projects imported', {position: 'bottom-right'});
                loadData();
                return true;
            })
            .catch(error => {
                ServerResponseHandler.handleError(error);
                return false;
            })
            .finally(() => {
                this.loadData(),
                this.setState({csvImportModal: !this.state.csvImportModal})
            });
    }

    handleCheckBoxChange(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value});
            Util.showInputError(this, e.target.name);
        }
    }

    handleStartDateChange(startDate) {
        this.setState({startDate: startDate});
    }

    handleEndDateChange(endDate) {
        this.setState({endDate: endDate});
    }

    handleReceiveDateChange(receiveDate) {
        this.setState({receiveDate: receiveDate});
    }

    setPage(page) {
        let updatedOptions = {...this.state.projectsPagingOptions};
        updatedOptions.page = page;
        this.setState({ projectsPagingOptions: updatedOptions });
        this.loadData(lastSearchedParameters, updatedOptions);
    }

    handleProjectStatusChange(projectStatus) {
        this.setState({projectStatus: projectStatus});
    }

    handlePickStatusChange(pickStatus) {
        this.setState({pickStatus: pickStatus});
    }

    renderActive(cellInfo) {
        let active = <span className="badge badge-secondary">Inactive</span>;
        if (cellInfo.original.active) {
            active = <span className="badge badge-success">Active</span>
        }
        return (<div className="text-center">
                {active}
            </div>
        );
    }

    toggleLogsModal(event, project = null) {
        if (project) {
            const apiUrl = Util.apiUrl(`projects/${project.id}/timelogs`);
            this.setState({loading: true});
            fetch(apiUrl, {credentials: 'include'})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(data => {
                    let json = {resultList: data};
                    let dataList = Util.formatEmployeeTimeDataByDate(json, true);
                    const zone = moment.tz.guess();
                    let totalCost = 0;
                    let Data = JSON.parse(JSON.stringify(dataList));
                    Data.data.map(timeLogs => {
                        timeLogs.timeEvents.map(obj => {
                            if (obj.employeeNameFirst) {
                                obj.employeeName = Util.nameFormatter(obj.employeeNameFirst, obj.employeeNameLast)
                            }
                            if (obj.roundedClockIn) {
                                const value = obj.roundedClockIn;
                                obj.clockInTime = moment.tz(value, zone).format('h:mm A');
                                obj.date = moment.tz(value, zone).format('dddd, MMM DD');
                            }
                            if (obj.roundedClockOut) {
                                obj.clockOutTime = moment.tz(obj.roundedClockOut, zone).format('h:mm A');
                                obj.csvClockOutTime = moment.tz(obj.roundedClockOut, zone).format('h:mm A');
                            } else {
                                obj.clockOutTime = <span className="row-badge badge-success">Working</span>;
                                obj.csvClockOutTime = 'Working';
                            }
                            if (obj.durationForProjects.totalDuration) {
                                obj.totalTime = Util.formatTimeFromSeconds(obj.durationForProjects.totalDuration);
                            }
                            if (obj.breakRoundedTime) {
                                obj.totalBreakTime = Util.formatTimeFromSeconds(obj.breakRoundedTime);
                            }
                            obj.roundedTotalCost = 0;
                            if (obj.durationForProjects.totalDuration) {
                                const regularCost = Number((Util.hoursFromSeconds(obj.durationForProjects.regularDuration) * obj.hourlyRate).toFixed(2));
                                const dailyOvertimeCost = Number((Util.hoursFromSeconds(obj.durationForProjects.dailyOvertimeDuration) * obj.hourlyRate * 1.5).toFixed(2));
                                const dailyDoubleOvertimeCost = Number((Util.hoursFromSeconds(obj.durationForProjects.dailyDoubleOvertimeDuration) * obj.hourlyRate * 2).toFixed(2));
                                obj.totalCost = regularCost + dailyOvertimeCost + dailyDoubleOvertimeCost;
                            }
                            totalCost += Number(obj.totalCost ? obj.totalCost : 0);
                        });
                    });
                    this.setState({logsDialog: !this.state.logsDialog, logList: Data.data, selectedProject: project, total: {cost: Util.formatCurrency(totalCost, true), time: Util.formatTimeFromSeconds(project.actualHours)}});
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => this.setState({loading: false}));
        } else {
            this.setState({total: {}, logList: [], selectedProject: project ? project : {}, logsDialog: !this.state.logsDialog});
        }
    }

    toggleLaborModal() {
        this.setState({laborDialog: !this.state.laborDialog});
    }

    toggleNewModal(event) {
        if (event.target && (event.target.classList.contains('toggle-log'))) {
            return false;
        }
        this.setState({
            modalDialog: !this.state.modalDialog,
            id: null,
            number: '',
            name: '',
            description: '',
            startDate: Date.now(),
            endDate: Date.now(),
            receiveDate: '',
            budgetedHours: 0,
            projectStatus: 'Open',
            pickStatus: 'Entered',
            bomCategory: ''
        });
    }

    toggleLaborItemModal() {
        this.setState({
            laborItemModalDialog: !this.state.laborItemModalDialog,
            laborId: null,
            laborName: '',
            laborActive: true,
        });
    }

    toggleLaborItemEditModal(laborItem) {
        this.setState({
            laborItemModalDialog: !this.state.laborItemModalDialog,
            laborId: laborItem.id,
            laborName: laborItem.name,
            laborActive: laborItem.active,
        });
    }

    toggleEditModal(event, project) {
        if (event.target.classList.contains('log')) {
            return false;
        }
        this.setState({
            id: project.id,
            number: project.number,
            name: project.name,
            description: project.description,
            budgetedHours: project.budgetedHours,
            startDate: project.startDate,
            endDate: project.endDate,
            receiveDate: project.receiveDate,
            projectStatus: project.projectStatus,
            pickStatus: project.pickStatus,
            modalDialog: !this.state.modalDialog,
            bomCategory: project.bomCategory
        });
    }

    renderEditableCell(cellInfo) {
        return (
            <div
                className="contentEditable"
                style={{backgroundColor: "#fafafa"}}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.laborItemList];
                    this.saveLaborItem('PATCH', e.target.innerText, data[cellInfo.index]['id']);
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.laborItemList[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    renderEditableCheckBoxCell(cellInfo) {
        const data = [...this.state.laborItemList];
        return (
            <div
                suppressContentEditableWarning
                onBlur={e => {
                    this.saveLaborItem('PATCH', e.target.innerText, data[cellInfo.index]['id']);
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.laborItemList[cellInfo.index][cellInfo.column.id]
                }}
            >
                <Label className="switch switch-icon switch-pill switch-info pull-right"
                       title="Active">
                    <Input type="checkbox" name="allowAddTime" ref="allowAddTime" className="switch-input"
                           checked={data[cellInfo.index]['active']}
                           onChange={e => {
                               this.saveLaborItem('PATCH', {active: e.target.checked}, data[cellInfo.index]['id']);
                           }}
                    />
                    <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                    <span className="switch-handle"/>
                </Label>
            </div>
        );
    }

    fetchCsvData(callback) {
        this.setState({loading: true});
        let apiUrl = Util.apiSearchUrl(`projects`, lastSearchedParameters) + "&includeShiftLogs=true";

        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const prepareData = this.parseTableData(_.sortBy(json.resultList, 'number'));
                this.setState({csvProjects: prepareData }, () => { callback(); });
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    handleSubmit() {
        if (!this.showFormErrors('modal-form')) {
            console.error('form is invalid: do not submit');
            return;
        }

        const start = moment(this.state.startDate);
        const end = moment(this.state.endDate);

        if ( !start.isSameOrBefore(end) ) {
            toast.error('End date must be after the start date.', { position: 'bottom-right' });
            return;
        }

        this.setState({
            modalDialog: !this.state.modalDialog,
        });

        let payload = {
            number: this.state.number,
            name: this.state.name,
            description: this.state.description,
            budgetedHours: this.state.budgetedHours,
            startDate: Util.formatDateToUtc(this.state.startDate),
            endDate: Util.formatDateToUtc(this.state.endDate),
            receiveDate: Util.formatDateToUtc(this.state.receiveDate),
            projectStatus: this.state.projectStatus,
            pickStatus: this.state.pickStatus,
            bomCategory: this.state.bomCategory
        };

        if (!this.state.id) {
            this.saveData('POST', payload);
        } else {
            this.saveData('PATCH', payload, this.state.id);
        }
    }

    saveData(method, project, projectId = '') {
        this.setState({loading: true});

        const apiUrl = Util.apiUrl(`projects/${projectId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(project),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success(companySettings.projectSingleName + ' was saved', {position: 'bottom-right'});
                this.loadData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    loadData(search = lastSearchedParameters, pagingOptions) {
        this.setState({project: null, loading: true});
        lastSearchedParameters = search;
        let apiUrl = Util.apiSearchUrl(`projects`, lastSearchedParameters) + "&includeShiftLogs=true";

        if(!pagingOptions)
            pagingOptions = this.resetPaging();

        apiUrl += ("&pagingOptions=" + encodeURIComponent(JSON.stringify(pagingOptions)));
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let pagingO = { page: json.page, size: json.size }
                const prepareData = this.parseTableData(_.sortBy(json.resultList, 'number'));
                this.setState({projects: prepareData, csvProjects: json.maxResults < json.size ? prepareData : [], projectsTotal: json.maxResults, projectsPagingOptions: pagingO })
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    parseTableData(dataList) {
        for (let obj of dataList) {
            if (obj.lastActivity) {
                obj.lastActivity = Util.basicDateFormatter(obj.lastActivity);
            }
            if (obj.dateLastModified) {
                obj.dateLastModified = Util.basicDateFormatter(obj.dateLastModified, true);
            }
            obj.csvHours = Util.formatTimeFromSeconds(obj.actualHours);
        }

        return dataList;
    }

    saveLaborItem(method, laborItem, laborItemId = '') {
        this.setState({loading: true});

        const apiUrl = Util.apiUrl(`projects/laborItems/${laborItemId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(laborItem),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Labor item was saved', {position: 'bottom-right'});
                this.loadLaborItemData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    loadLaborItemData() {
        this.setState({laborItemList: [], loading: true});

        const apiUrl = Util.apiUrl(`projects/laborItems`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => this.setState({laborItemList: json.resultList}))
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    handleLaborItemSubmit(event) {
        event.preventDefault();
        if (!this.showFormErrors('modal-laborItem-form')) {
            console.error('form is invalid: do not submit');
            return;
        }

        this.setState({laborItemModalDialog: !this.state.laborItemModalDialog});

        let payload = {
            name: this.state.laborName,
            active: this.state.laborActive,
        };

        if (!this.state.laborId) {
            this.saveLaborItem('POST', payload);
        } else {
            this.saveLaborItem('PATCH', payload, this.state.laborId);
        }
    }

    deleteLaborItem(laborItemId) {
        if (!confirm("Are you sure you want to remove this labor Item?")) {
            return;
        }

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`projects/laborItems/${laborItemId}`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(deleteShiftBreak),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Labor item was deleted', {position: 'bottom-right'});
                this.loadLaborItemData();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    render() {
        const csvProperties = {
            csvHeader: [
                {label: 'Number', key: 'number'},
                {label: 'Name', key: 'name'},
                {label: 'Description', key: 'description'},
                {label: 'Last Activity', key: 'lastActivity'},
                {label: 'Budgeted Hours', key: 'budgetedHours'},
                {label: 'Actual Hours', key: 'csvHours'},
                {label: 'Status', key: 'projectStatus'},
                {label: 'Pick Status', key: 'pickStatus'},
                {label: companySettings.bomCategorySingleName, key: 'bomCategory'}
            ],
            data: this.state.csvProjects,
            csvFilename: 'projects.csv',
            fetchData: this.fetchCsvData,
            useFetch: this.state.projectsTotal > this.state.projectsPagingOptions.size
        };

        const buttons = {
            add: this.toggleNewModal,
            download: csvProperties,
            importCsv: this.importCsv,
            savePdf: false,
            loading: this.state.loading
        };

        const project = this.state;

        return (
            <div className="animated fadeIn">
                <ToastContainer />
                <Row className="mt-3 mb-3 ml-5">
                    <InputGroup className="mb-1">
                        <label>Show more Project data</label>
                        <Label className="switch switch-icon switch-pill switch-info ml-4">
                            <Input type="checkbox" name="tos"
                                   className="switch-input"
                                   id="tos"
                                   checked={ this.state.showSensitiveData }
                                   onChange={() => { this.setState({showSensitiveData: !this.state.showSensitiveData}) }} />
                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                            <span className="switch-handle"/>
                        </Label>
                    </InputGroup>
                </Row>
                <Container fluid>
                    <Modal isOpen={this.state.logsDialog} className="modal-lg">
                        <ModalHeader toggle={() => this.toggleLogsModal()}>
                            {Util.trimWithEllipses(`Time Logs - ${this.state.selectedProject.name ? this.state.selectedProject.name : ''} ${this.state.selectedProject.description ? `- ${this.state.selectedProject.description}` : ''}`, 100)}</ModalHeader>
                        <ModalBody id="modal-form">
                            <Card className="col-lg-12 mt-3 card-accent-primary">
                                <CardBody>
                                    <ProjectsTimeLogTable
                                        data={this.state.logList}
                                        tableColumns={this.getTimeTableColumns()}
                                        toggleEditModal={this.toggleEditModal}
                                        tableSettings={{columnOffset: 2, canDelete: false, canEdit: false}}
                                    />
                                    {!this.state.logList || this.state.logList.length === 0 ? ''
                                        :
                                        companySettings.includeOvertimeInProjectCost ?
                                            <Row className="row-date" style={{padding: '0 1.25rem'}}>
                                                <Col className="col-5"><span>Totals:</span></Col>
                                                <Col className="col-2.5 p-0" style={{width: '100%', borderTop: '1px gray solid', borderBottom: '4px gray double', textAlign: 'center'}}><span>{this.state.total.time}</span></Col>
                                                <Col className="col-2.5 p-0" style={{width: '100%', borderTop: '1px gray solid', borderBottom: '4px gray double', textAlign: 'center'}}></Col>
                                                <Col className="col-2 p-0" style={{width: '100%', borderTop: '1px gray solid', borderBottom: '4px gray double', textAlign: 'center'}}><span>{this.state.total.cost}</span></Col>
                                            </Row>
                                            :
                                            <Row className="row-date" style={{padding: '0 1.25rem'}}>
                                                <Col className="col-7"><span>Totals:</span></Col>
                                                <Col className="col-1 p-0" style={{width: '100%', borderTop: '1px gray solid', borderBottom: '4px gray double', textAlign: 'center'}}><span>{this.state.total.time}</span></Col>
                                                <Col className="col-1.5 p-0" style={{width: '100%', borderTop: '1px gray solid', borderBottom: '4px gray double', textAlign: 'center'}}></Col>
                                                <Col className="col-2 p-0" style={{width: '100%', borderTop: '1px gray solid', borderBottom: '4px gray double', textAlign: 'center'}}><span>{this.state.total.cost}</span></Col>
                                            </Row>
                                    }
                                </CardBody>
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.toggleLogsModal()}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.laborDialog}>
                        <ModalHeader className="d-inline">
                            Labor Items
                            <div className="mw-100 pull-right">
                                <Button color="success" className="header-button" onClick={() => {
                                    this.toggleLaborItemModal(this);
                                }}>
                                    <i className="material-icons">add</i>
                                </Button>
                            </div>
                        </ModalHeader>
                        <ModalBody id="modal-form">
                            <ReactTable
                                data={this.state.laborItemList}
                                onFetchData={(state, instance) => {
                                    this.loadLaborItemData();
                                }}
                                minRows={5}
                                defaultPageSize={500}
                                showPagination={false}
                                sortable
                                noDataText="No labor items"
                                className="-striped -highlight react-bs-table react-bs-table-bordered"
                                columns={[
                                    {Header: 'Name', accessor: 'name', headerClassName: 'table-header'},
                                    {
                                        Header: 'Active',
                                        accessor: 'active',
                                        headerClassName: 'table-header',
                                        width: 70,
                                        dataAlign: 'center',

                                        Cell: this.renderActive

                                    },
                                    {
                                        Header: 'Actions',
                                        accessor: 'actions',
                                        headerClassName: 'table-header',
                                        width: 80,
                                        Cell: row => (
                                            <center>
                                                <span onClick={() => this.toggleLaborItemEditModal(row.original)}
                                                      className="row-badge badge-info material-icons mr-1">edit
                                                </span>
                                                <span onClick={() => this.deleteLaborItem(row.original.id)}
                                                      className="row-badge badge-danger material-icons">delete
                                                </span>
                                            </center>
                                        )
                                    }
                                ]}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.toggleLaborModal()}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalDialog}>
                        <ModalHeader toggle={this.toggleNewModal} style={{overflow: 'hidden'}}>
                            <React.Fragment>
                            {
                                project.id === null || project.id === '' ? 'New ' + companySettings.projectSingleName : companySettings.projectSingleName + ': ' + Util.trimWithEllipses(project.name, 30)
                            }
                            {project.id === null || project.id === '' ? null
                               : <p onClick={(event) => this.toggleLogsModal(event, this.state.projects.find(x => x.id == project.id))} style={{ margin: '0', color: '#2271b6', fontSize: '14px', cursor: 'pointer'}}>Time Entries</p>
                            }
                            </React.Fragment>
                        </ModalHeader>
                        <ModalBody id="modal-form">
                            <Label>Number</Label>
                            <InputGroup className="mb-3">
                                <input type="text" placeholder={companySettings.projectSingleName + " Number"} maxLength={30} name="number" ref="number"
                                       value={project.number}
                                       className="form-control" onChange={this.handleChange} required/>
                            </InputGroup>

                            <Label>Name</Label>
                            <InputGroup className="mb-3">
                                <input type="text" placeholder={companySettings.projectSingleName + " Name"} name="name" ref="name"
                                       value={project.name} maxLength={70}
                                       className="form-control" onChange={this.handleChange} required/>
                            </InputGroup>

                            <Label>Description</Label>
                            <InputGroup className="mb-3">
                                <textarea type="text" placeholder={companySettings.projectSingleName + " Description"} name="description"
                                          ref="description" maxLength={256} value={project.description}
                                          className="form-control" onChange={this.handleChange}/>
                            </InputGroup>

                            <Row style={{marginBottom: '15px'}}>
                                <Col>
                                    <Label>Start Date</Label>
                                    <FBDateTimePicker
                                        id="startDate"
                                        value={project.startDate}
                                        onChange={this.handleStartDateChange}
                                    />
                                </Col>
                                <Col>
                                    <Label>End Date</Label>
                                    <FBDateTimePicker
                                        id="endDate"
                                        value={project.endDate}
                                        onChange={this.handleEndDateChange}
                                    />
                                </Col>
                            </Row>

                            <Label>Budgeted Hours</Label>
                            <InputGroup className="mb-3">
                                <input type="number" step="any" name="budgetedHours" ref="budgetedHours"
                                       value={project.budgetedHours}
                                       className="form-control" onChange={this.handleChange} min="0"
                                       max="999999999999999999" required/>
                            </InputGroup>

                            <Label>Status</Label>
                            <div className="mb-3">
                                <SelectProjectStatus projectStatus={project.projectStatus}
                                                     onChange={this.handleProjectStatusChange}/>
                            </div>

                            <Label>Pick Status</Label>
                            <div className="mb-3">
                                <SelectPickStatus pickStatus={project.pickStatus}
                            onChange={this.handlePickStatusChange}/>
                            </div>

                            <Label>{companySettings.bomCategorySingleName}</Label>
                            <InputGroup className="mb-3">
                                <input type="text" placeholder={companySettings.bomCategorySingleName} name="bomCategory"
                            ref="bomCategory" maxLength={100} value={project.bomCategory}
                            className="form-control" onChange={this.handleChange}/>
                            </InputGroup>

                            <Label>Receive Date</Label>
                            <FBDateTimePicker
                                id="receiveDate"
                                placeholder="Receive Date"
                                value={project.receiveDate ? project.receiveDate : null}
                                onChange={this.handleReceiveDateChange}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => this.handleSubmit()}>Save</Button>{' '}
                            <Button color="secondary" onClick={this.toggleNewModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.laborItemModalDialog}>
                        <form onSubmit={this.handleLaborItemSubmit} id="modal-laborItem-form" noValidate>
                            <ModalHeader toggle={() => this.toggleLaborItemModal(this)}>
                                {
                                    project.laborId === null || project.laborId === '' ? 'New Labor Item' : 'Labor Item: ' + project.laborName
                                }
                            </ModalHeader>
                            <ModalBody>
                                <Label>Name<span className="error">*</span></Label>
                                <InputGroup className="mb-3">
                                    <input type="text" placeholder="Labor Item" name="laborName" ref="laborName"
                                           value={project.laborName}
                                           className="form-control" onChange={this.handleChange} required
                                           pattern=".*\S+.*"/>
                                </InputGroup>
                                <Label>Active</Label>
                                <InputGroup className="mb-3">
                                    <Label className="switch switch-icon switch-pill switch-info pull-right">
                                        <Input type="checkbox" name="laborActive" ref="laborActive"
                                               className="switch-input"
                                               checked={project.laborActive}
                                               onChange={this.handleCheckBoxChange}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                </InputGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success">Save</Button>{' '}
                                <Button color="secondary"
                                        onClick={() => this.toggleLaborItemModal()}>Cancel</Button>
                            </ModalFooter>
                        </form>
                    </Modal>
                    <Modal isOpen={this.state.csvImportModal} toggle={() => this.setState({csvImportModal: !this.csvImportModal})}>
                        <ModalHeader toggle={(e) => this.importCsv(e)}>Import CSV</ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <span className="font-italic">* Required fields: Number, Name, Status, Pick Status.</span>
                            </div>
                            <div className="mb-3">
                                <Button color='primary' onClick={() => document.getElementById('csv-import').click()} style={{marginTop: '10px'}}>CSV Upload</Button>
                                <div id={'imageForm'}>
                                    <input id={'csv-import'} type={'file'} onChange={(e) => this.importProjectDetails(e)} hidden/>
                                </div>
                            </div>
                            <div>
                                <a href='https://s3.amazonaws.com/fishbowllabor/StaticContent/sampleProjectUpload.csv' download>Download Sample Import File</a>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.importCsv()}
                                    disabled={this.loading}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Card className="col-lg-12 mt-3 card-accent-primary">
                        <CardBody>
                            <SearchBar
                                buttons={buttons}
                                onSearch={this.loadData}
                                includeGeneral={true}
                                includeProjectStatus={true}
                                projectStatus={ lastSearchedParameters.projectStatus }
                            />
                            <ProjectsTable
                                data={this.state.projects}
                                tableColumns={this.getTableColumns()}
                                toggleEditModal={this.toggleEditModal}
                                toggleLogModal={this.toggleLogsModal}
                                tableSettings={{columnOffset: 2, canEdit: canEdit}}
                            />
                            {this.state.projects && this.state.projects.length > 0 && this.state.projectsTotal > this.state.projects.length ?
                            <div style={{marginTop: '26px'}}>
                                <Paging page={this.state.projectsPagingOptions.page}
                                        size={this.state.projectsPagingOptions.size}
                                        total={this.state.projectsTotal}
                                        setPage={this.setPage}
                                        disabled={this.state.loading} />
                            </div>
                                : null }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Projects;