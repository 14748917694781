import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import moment from "moment";

class CompanyHolidayTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
        this.overflowVisible = {
            overflow: "visible"
        };
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                <Col key="0" className="col-lg-3 text-center">Break Rule</Col>
                <Col key="1" className='col-lg-3 text-center'>Length</Col>
                <Col key="2" className='col-lg-3 text-center'>Place Break At</Col>
                {
                    !self.props.tableSettings.canDelete
                        ?
                        ''
                        :
                        <Col key="3" className="col-lg-3" style={this.overflowVisible}>Delete</Col>
                }
                
            </Row>
            {
                self.props.data.length < 1 ?
                    <NoData/> :
                self.props.data.map((row, index) => {
                    return <div className='data-row margin-top' style={{ color: row.active ? 'inherit' : 'darkgrey' }} key={index}>
                        <Row style={{ cursor: 'pointer' }} onClick={() => self.props.openEditModal(row)} className='row-data row-header col-lg-12'>
                            <Col className='col-lg-3'><span>{row.name}</span></Col>
                            <Col className='col-lg-3'>
                                <span>{row.lengthValue} {row.lengthUnit}</span>
                            </Col>
                            <Col className='col-lg-3'><span>{row.placeBreakAt}</span></Col>
                            {
                                !self.props.tableSettings.canDelete
                                    ?
                                    ''
                                    :
                                    <Col className="cursor-text col-lg-3">
                                                     <span title="Delete"
                                                           onClick={(event) => { event.stopPropagation(); self.props.toggleConfirmDeleteModal(row)}}
                                                           className="delete action-icon material-icons">clear</span>
                                    </Col>
                            }
                        </Row>
                    </div>
                })
            }
        </div>
    }


    render() {
        return this.createTable();
    }
}

export default CompanyHolidayTable;
