import React from 'react';
import * as Util from "../Framework/Util";
import {NavLink} from "react-router-dom";

function SidebarHeader() {
    let userTenant = Util.getUserTenant();

    return (
        <div className="sidebar-header">
            <NavLink to={"/user/settings"}
                     className="text-white d-flex align-items-center justify-content-around"
                     style={{textDecoration: "none"}}>
                <div>
                    {
                        userTenant === null || !userTenant.imageUrl || userTenant.imageUrl === '' ?
                            <img className="img-avatar" src={'https://s3.amazonaws.com/fishbowllabor/StaticContent/default5.jpg'}/>
                            :
                            <img className="img-avatar" src={userTenant.imageUrl}/>
                    }
                </div>
                <div className="user-info">
                    <strong>{userTenant.nameFirst} {userTenant.nameLast}</strong>
                    <div className="text-muted">
                        {
                            !Util.isEmpty(userTenant.title) ?
                                (<small>{userTenant.title}</small>)
                                : (<small>New User</small>)
                        }
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default SidebarHeader;
