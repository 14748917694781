import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";

class NotificationRuleTable extends Component {
    constructor(props) {
        super(props);
        this.createTable = this.createTable.bind(this);
        this.state = {};
    }

    createTable() {
        const self = this;
        return <div>
            <Row className="row-header col-header col-lg-12">
                <Col key="0" className="text-center">Receive notifications when</Col>
                <Col key="1" className='text-center'>Active</Col>
                <Col key="2" className='text-center'>Emails</Col>
                <Col key="3" className='text-center'>Phones</Col>
            </Row>
            {
                (!self.props.data || self.props.data.length < 1) ?
                    <NoData/> :
                    self.props.data.map((row, index) => {
                        return <div className='data-row-edit margin-top' key={index}>
                            <Row style={{ cursor: 'pointer '}} onClick={() => {self.props.openEditModal(row)}} className='row-data row-header col-lg-12'>
                                <Col><span>{row.name}</span></Col>
                                <Col>
                                    {row.active ?
                                        <span title="Active"
                                              className="material-icons">check</span>  : '' }
                                </Col>
                                <Col><span>{row.emails}</span></Col>
                                <Col><span>{row.phones}</span></Col>
                            </Row>
                        </div>
                    })
            }
        </div>
    }


    render() {
        return this.createTable();
    }
}

export default NotificationRuleTable;
