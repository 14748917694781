import React from 'react';
import {Col, Row, Container, Tooltip} from 'reactstrap';
import * as Util from "../Framework/Util";
import NoData from "./NoData";
import useTooltip from "../../Hooks";

function TimeTable(props) {
    const flagShiftsExceeding = Util.getCompanySettings().flagShiftsExceeding;
    const [tooltip, setTooltip] = useTooltip({});

    let rowHeaderColumns = props.tableSettings.createTableColumns;

    const getRowClassName = (row) => {
        let className = props.tableSettings.canEdit ? "data-row-edit" : "data-row";
        if(row.issue) {
            className += " data-row-issue";
        }

        return className;
    }

    return (
        <div>
            <Row className="row-header row-date col-header col-lg-12">
                {
                    props.tableColumns.map((column, z) => {
                        return column.hideColumn ? '' :
                                <Col id={'header-' + z} key={z}>{column.displayName}
                                    <Tooltip target={'header-' + z} isOpen={tooltip['header-' + z]} toggle={() => setTooltip('header-' + z)}>{column.displayName}</Tooltip>
                                </Col>
                    })
                }
            </Row>

            {
                props.data.length < 1 ?
                    <NoData/> :
                    props.data.map((date, i) => {
                        rowHeaderColumns.splice(0, 1, <div>{date.name}</div>);
                        rowHeaderColumns.splice(2, 1, <div>{Util.convertTimeToHours(date.totalTime)}</div>);
                        return <div key={i}>
                            {
                                i === 0 ? <div className="margin-top"></div> :
                                    <div className="dropdown-divider"></div>
                            }
                            {
                                <Container fluid>
                                    <Row className='row-date col-header col-lg-12'>
                                        {
                                            rowHeaderColumns ? rowHeaderColumns.map((column, y) => {
                                                return <Col key={y}>{column}</Col>
                                            }) : <Col></Col>
                                        }
                                    </Row>
                                </Container>
                            }
                            <div className="data">
                                {
                                    date.timeEvents.map((row, x) => {
                                        let index = i + '-' + x;
                                        return <div
                                            className={getRowClassName(row)}
                                            key={x}>
                                            {
                                                <Row className={"row-header col-lg-12"}
                                                     onClick={(event) => props.toggleEditModal(event, row)}>
                                                    {
                                                        !props.tableSettings.showProjectColumn ? '' :
                                                        <Tooltip target={'projectName' + index} isOpen={tooltip['projectName' + index]} toggle={() => setTooltip('projectName' + index)}>{row.projectName}</Tooltip>
                                                    }
                                                    {
                                                        !props.tableSettings.showPositionColumn ? '' :
                                                            <Tooltip target={'positionName' + index} isOpen={tooltip['positionName' + index]} toggle={() => setTooltip('positionName' + index)}>{row.positionName}</Tooltip>
                                                    }
                                                    {
                                                        !props.tableSettings.allowFilterByEmployee ? '' :
                                                            props.tableSettings.filterByEmployee ?
                                                                <Col><span>{row.date}</span></Col>
                                                                :
                                                                <Col><span>{row.employeeNameFirst + ' ' + row.employeeNameLast}</span></Col>
                                                    }
                                                    <Col className={"align-items-center"}>
                                                        <div
                                                            className={"geoLocationContainer"}>{row.clockInGeoLocation ?
                                                            <span
                                                                className="material-icons geoLocation geoLocation-clock-in"
                                                                onClick={() => props.toggleGeoLocationModal(row.clockInGeoLocation, 'Clock In Location')}>location_on</span> :
                                                            <span
                                                                className="material-icons geoLocation-hidden">location_on</span>}
                                                            <span>{row.clockInTime}</span>
                                                        </div>
                                                    </Col>
                                                    {
                                                        row.csvClockOutTime !== "Working" ?
                                                            <Col>
                                                                <div
                                                                    className={"geoLocationContainer"}>{row.clockOutGeoLocation ?
                                                                    <span
                                                                        className="material-icons geoLocation geoLocation-clock-out"
                                                                        onClick={() => props.toggleGeoLocationModal(row.clockOutGeoLocation, 'Clock Out Location')}>location_on</span> :
                                                                    row.csvClockOutTime === "On Break" ? '' :
                                                                    <span
                                                                        className="material-icons geoLocation-hidden">location_on</span>}
                                                                    <span>{row.clockOutTime ? row.clockOutTime : ''}</span>
                                                                </div>
                                                            </Col>
                                                            : <Col><h5 className="mb-1"><span className="badge badge-pill badge-success">Working</span></h5>
                                                            </Col>
                                                    }
                                                    {
                                                        row.csvClockOutTime !== "Working" ? <Col><span className={flagShiftsExceeding && flagShiftsExceeding < row.durationForPayroll ? 'alert' : ''}
                                                            style={{ color: flagShiftsExceeding && flagShiftsExceeding < row.durationForPayroll && row.issue ? 'black' : '' }}
                                                            >{Util.convertTimeToHours(row.durationForPayroll)}</span></Col>
                                                            :
                                                            <Col><span
                                                            >In Progress</span></Col>
                                                    }
                                                    {
                                                        !row.breakRoundedTime && !row.lunchBreakRoundedTime ?
                                                            <Col><span>-</span></Col> :
                                                            <Col><span
                                                            >{Util.convertTimeToHours(row.breakRoundedTime + row.lunchBreakRoundedTime)}</span></Col>

                                                    }
                                                    {
                                                        props.tableSettings.showPositionColumn ?
                                                            <Col className="ellipsis" id={'positionName' + index}><span
                                                            >{row.positionName ? row.positionName : '-'}</span></Col> : ''
                                                    }
                                                    {
                                                        props.tableSettings.showProjectColumn ?
                                                            <Col className="ellipsis" id={'projectName' + index}><span
                                                            >{row.projectNumber ? row.projectNumber : '-'}</span></Col> : ''
                                                    }
                                                    {
                                                        props.tableSettings.showShiftNoteColumn ?
                                                            <Col className="ellipsis" id={"shiftNote" + index}>
                                                                <Tooltip target={'shiftNote' + index}
                                                                         isOpen={tooltip['shiftNote' + index]}
                                                                         toggle={() => setTooltip('shiftNote' + index)}>{row.shiftNote}</Tooltip>
                                                                <span>{row.shiftNote ? row.shiftNote : '-'}</span></Col> : ''
                                                    }
                                                    {
                                                        !props.tableSettings.canDelete ? '' :
                                                            row.clockOut
                                                                ?
                                                                <Col className="cursor-text">
                                                     <span title="Delete"
                                                           onClick={() => props.toggleConfirmDeleteModal(row, 'deleteShift')}
                                                           className="delete action-icon material-icons  ">clear</span>
                                                                </Col>
                                                                :
                                                                <Col className="cursor-text">
                                                                    <span
                                                                        className="badge badge-success mr-1">Active</span>
                                                                </Col>

                                                    }
                                                    {
                                                        props.tableSettings.showPostedColumn ?
                                                            row.datePosted ?
                                                                <Col className="cursor-text">
                                                                    <span
                                                                        className="badge badge-success mr-1">Posted</span>
                                                                </Col> :
                                                                <Col className="cursor-text">
                                                                    <span></span>
                                                                </Col>
                                                            : ''
                                                    }
                                                </Row>
                                            }
                                        </div>

                                    })

                                }
                            </div>
                        </div>
                    })
            }
        </div>
    )
}

export default TimeTable;
