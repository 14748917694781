import React from 'react';
import {Row, Col, Tooltip} from 'reactstrap';
import * as Util from '../../../../components/Framework/Util';
import useTooltip from "../../../../Hooks"


function ProjectDetailsEmployeeSummary(props) {
    const [tooltip, setTooltip] = useTooltip({});
    return (<div className={'mt-3 p-3'}>
            <div className={'mb-2'}><h4 className={'text-center'}>Employee Totals</h4></div>
            <Row className={'row-header col-header col-lg-12'}>
                {
                    props.tableHeaders.map((column, index) => {
                        return column.showColumn ? <Col key={index} className={column.header === 'Employee Name' ? props.class : ''}>{column.header}</Col> : ''
                    })
                }
            </Row>
            {
                props.totals.map((column, index) => {
                    return (<div key={index}>
                            <Row className={'row-header col-lg-12'}>
                                <Col id={'employee' + index} className={"props.class"}>{column.firstName} {column.lastName}</Col>
                                <Col>{Util.formatTimeFromSeconds(column.totalDuration)}</Col>
                                {props.showColumn ? <Col>{Util.formatCurrency(column.totalCost.toFixed(2))}</Col> : ''}
                                <Tooltip target={'employee' + index} isOpen={tooltip['employee' + index]} toggle={() => setTooltip('employee' + index)}>{column.firstName} {column.lastName}</Tooltip>
                            </Row>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ProjectDetailsEmployeeSummary;
