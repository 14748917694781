import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from "../../../components/Framework/PrivateRoute";
import * as Util from "../../../components/Framework/Util";
import { ToastContainer, toast } from 'react-toastify';

import UserSettings from "./UserSettings";

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: Util.isAdmin(),
            isManager: Util.isManager(),
        };
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Switch>
                    <PrivateRoute exact path="/user/settings" name="Settings" component={UserSettings}/>
                    {/*<PrivateRoute exact path="/user/profile" name="Profile" component={UserProfile}/>*/}
                    <PrivateRoute path="/user" name="Settings" component={UserSettings}/>
                </Switch>
            </div>
        )
    }
}

export default User;