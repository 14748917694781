import { ToastContainer, toast } from 'react-toastify';
import config from "react-global-configuration";

export function getResponse(response) {
    if (response.ok) {
        if (response.status === 204) {
            return null;
        }

        if (response.url !== undefined && response.url !== null && response.url.indexOf(config.get('apiBaseUrl') + 'login') !== -1) {
            localStorage.clear();
            window.location.href = '/login';
            return;
        }
        return response.json();
    } else if (response.status === 400 || response.status === 403 || response.status === 500) {
        return response.json();
    } else if (response.status > 400 && response.status < 600) {
        return response.text().then(data => {
            throw new Error(data)
        });
    }
    return null;
}

export function handleError(error) {
    if (error == null || error === '') {
        toast.error("There's a problem connecting to the server.", {position: 'bottom-right'});
    } else {
        console.log("There was an unhandled error that may indicate session has lapsed and user needs to log back in, the error says: " + error.message);
    }
}