import {Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {Component} from "react";
import AuditComparisonModal from "../Audit/AuditComparisonModal";

class LoadingModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render(){
        return(<Modal isOpen={this.props.loading}>
            <ModalHeader>Loading</ModalHeader>
            <ModalBody>
                <div>
                    <span><i className="fa fa-spinner fa-spin mr-2"/> Loading data...</span>
                </div>
            </ModalBody>
        </Modal>)
    }

}

export default LoadingModal;