import React, {Component} from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import * as Util from "../../../components/Framework/Util";
import {SearchBar} from "../../../components/Framework/SearchBar";
import 'react-dates/initialize';
import TimeOffRequestModal from "../../../components/Modal/TimeOffRequestModal"
import MyTimeOffTable from "../../../components/DataTables/MyTimeOffTable";

import moment from "moment";

import 'react-dates/lib/css/_datepicker.css';

require("react-datetime/css/react-datetime.css");

moment.suppressDeprecationWarnings = true;

const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || Util.getCompanySettings().allowEditTime;
const endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

class MyTimeOff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 20,
            sorted: null,
            filtered: null,
            searchParams: {
                endDate: endDate,
                startDate: startDate
            },
            newDialog: false,
            ptoRequest: null,
        };

        this.getTableColumns = this.getTableColumns.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.toggleNewModal = this.toggleNewModal.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
    }


    getTableColumns() {
        return [
            {displayName: 'Date', hideColumn: false},
            {displayName: 'Type', hideColumn: false},
            {displayName: 'Hours', hideColumn: false},
            {displayName: 'Notes', hideColumn: false},
            {displayName: 'Status', hideColumn: false},
            {displayName: 'Delete', hideColumn: false}
        ];
    }


    setSearchParams(search) {
        this.setState({searchParams: search});
    }

    toggleNewModal() {
        this.setState({
            newDialog: !this.state.newDialog,
            ptoRequest: null,
        });
    }

    toggleConfirmDeleteModal(row, shouldDelete) {
        this.setState({
            row: row,
            confirmDeleteDialog: !this.state.confirmDeleteDialog,
        });
        if(shouldDelete) {
            this.props.deletePtoRequest(row.ptoRequestId, row.employeeCode, row)
        }
    }

    render() {
        const csvProperties = {
            csvHeader: [
                {label: 'Type', key: 'type'},
                {label: 'Date', key: 'datesRequested'},
                {label: 'Hours', key: 'hours'},
                {label: 'Notes', key: 'note'},
                {label: 'Status', key: 'status'},
            ],
            data: this.props.myPtoData,
            csvFilename: 'myPtoRequests.csv'
        };

        const buttons = {
            add: this.toggleNewModal,
            download: csvProperties,
            savePdf: false,
            loading: this.state.loading
        };

        return (
            <div className="animated fadeIn">
                <Container fluid>
                    {
                        !this.state.newDialog ? '' :
                        <TimeOffRequestModal
                            addPtoRequest={this.props.addPtoRequest}
                            newDialog={this.state.newDialog}
                            ptoRequest={this.state.ptoRequest}
                            toggleModal={this.toggleNewModal}
                            showEmployeeList={false}
                        />
                    }
                    <Modal isOpen={this.state.confirmDeleteDialog} className="modal-primary">
                        <ModalHeader>Delete PTO Request</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete this PTO request?
                            <br/>
                            <strong>{this.state.laborName}</strong>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => this.toggleConfirmDeleteModal(this.state.row, true)}>Delete</Button>{' '}
                            <Button color="secondary"
                                    onClick={() => this.toggleConfirmDeleteModal(null)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Card className="col-lg-12 mt-3 card-accent-primary">
                        <CardHeader><h5>My PTO Requests</h5></CardHeader>

                        <CardBody>
                            <SearchBar
                                buttons={buttons}
                                onSearch={this.props.getMyPtoRequests}
                                includeSearchLabel={true}
                                includeDateRange={true}
                                includePtoStatus={true}
                                setSearchParams={this.setSearchParams}
                                startDate={moment(this.state.searchParams.startDate)}
                                endDate={moment(this.state.searchParams.endDate)}
                                isPtoFilter={true}
                                getSearchBarApi={this.props.setSearchBarApi}
                                dateRanges={Util.ptoDateRanges}
                            />
                            <MyTimeOffTable
                                data={this.props.myPto}
                                tableColumns={this.getTableColumns()}
                                toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
                                toggleEditModal={this.toggleEditModal}
                                tableSettings={{
                                    canDelete: true,
                                    canEdit: canEdit,
                                    isAdminOrManager: isAdminOrManager
                                }}
                            />
                        </CardBody>
                    </Card>
                    <div className='row no-gutters justify-content-end pl-0 pr-0'>
                        <Card className="col-lg-3 col-md-5 col-sm-12 mt-3 card-accent-primary ">
                            <CardBody>
                                <h4 className="mb-3">My Current Balance</h4>
                                <h6>Vacation Total: {this.props.userTenant ? this.props.userTenant.ptoVacation : 0} hrs</h6>
                                <h6>Sick Total: {this.props.userTenant ? this.props.userTenant.ptoSick : 0} hrs</h6>
                                <h6>Holiday Total: {this.props.userTenant ? this.props.userTenant.ptoHoliday : 0} hrs</h6>
                            </CardBody>
                        </Card>
                    </div>
            </Container>
            </div>
        )
    }
}

export default MyTimeOff;