import React, {useState, useEffect} from 'react';
import {
    Button,
    Card,
    CardBody,
    InputGroup,
    Label,
    Row,
    Col,
    ModalHeader,
    ModalBody, CardHeader, ModalFooter, Modal
} from "reactstrap";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import * as Util from "../Framework/Util";
import ShiftBreaksTable from "../DataTables/ShiftBreaksTable"
import SelectProject from "../Selects/SelectProject";
import SelectEmployee from "../Selects/SelectEmployee";
import BreakModal from "./BreakModal";
import _ from "lodash";
import FBDateTimePicker from "../DateTimePickers/FBDateTimePicker";
import TimeLogIssue from "../TimeLogIssue/TimeLogIssue";

const moment = require('moment-timezone');
const companySettings = Util.getCompanySettings();
const isAdminOrManager = Util.isAdmin() || Util.isManager();
const canEdit = isAdminOrManager || companySettings.allowEditTime;
const canDelete = isAdminOrManager;
let clockInChanged = false;
let clockOutChanged = false;
const newShiftBreak = {
    breakTimeHours: 0,
    breakTimeMinutes: 0,
    breakType: {name: 'Break'}
};
const breakColumns = [
    {displayName: 'Start', hideColumn: false},
    {displayName: 'End', hideColumn: false},
    {displayName: 'Type', hideColumn: false},
    {displayName: 'Input', hideColumn: false},
    {displayName: 'Delete', hideColumn: !canDelete}
];

function TimeLogModal(props) {
    const loggedInUser = Util.getUserTenant();
    const companySettings =  Util.getCompanySettings();
    const [loading, setLoading] = useState(false);
    const [shiftLogBreaks, setShiftLogBreaks] = useState([]);
    const [newShiftLogBreaks, setNewShiftLogBreaks] = useState([]);
    const [employee, setEmployee] = useState(props.myTimeClock ? {employeeCode: loggedInUser.id} : props.shiftLog && props.shiftLog.employeeCode ? {employeeCode: props.shiftLog.employeeCode} : null);
    const [position, setPosition] = useState(props.shiftLog.positionId ? {id: props.shiftLog.positionId, name: props.shiftLog.positionName} : null);
    const [positions, setPositions] = useState([]);
    const [project, setProject] = useState(props.shiftLog.project ? {projectId: props.shiftLog.projectId, project: props.shiftLog.project} : {projectId: '', project: null});
    const [breakModal, setBreakModal] = useState(false);
    const [confirmCreateShiftModalActive, setConfirmCreateShiftModalActive] = useState(false);
    const [breakLogData, setBreakLogData] = useState(null);
    const [submitLock, setSubmitLock] = useState(false);
    const [clockInOut, setClockInOut] = useState(companySettings.roundToNearest !== 0 ? {clockIn: props.shiftLog.roundedClockIn, clockOut: props.shiftLog.roundedClockOut} : {clockIn: props.shiftLog.clockIn, clockOut: props.shiftLog.clockOut});
    const [shiftLog, setShiftLog] = useFormInput(props.shiftLog);
    const [issues, setIssues] = useState([]);

    useEffect(() =>{
        loadData();
        loadIssues();
        if(!props.myTimeClock && props.shiftLog.employeeCode) {
            handleEmployeeChange(props.shiftLog.employeeCode === loggedInUser.id ? loggedInUser : props.shiftLog.employee ? props.shiftLog.employee : {id: props.shiftLog.employeeCode});
        } else {
            parseUserPositions();
        }
    }, [props.loadBreakData]);

    const projectsEnabled = companySettings.enableProjects;
    const positionsEnabled = companySettings.enablePositions;

    function loadData() {
        const search = {timeLogId: props.shiftLog.id};
        const formUrl = Util.apiSearchUrl(`timelog/get-breaks`, search);
        fetch(formUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                let shiftBreaks = _.filter(json.resultList, function(o) { return (o.timeLogId === props.shiftLog.id && o.deletedOn === null)});
                if(companySettings.roundToNearest !== 0){
                    for(let i = 0; i < shiftBreaks.length; i++){
                        shiftBreaks[i].breakIn = Util.formatDateFromUtc(shiftBreaks[i].roundedBreakIn);
                        shiftBreaks[i].breakOut = Util.formatDateFromUtc(shiftBreaks[i].roundedBreakOut);
                        shiftBreaks[i].duration = Util.formatDateFromUtc(shiftBreaks[i].roundedDuration);
                    }
                }
                else{
                    for(let i = 0; i < shiftBreaks.length; i++) {
                        shiftBreaks[i].breakIn = Util.formatDateFromUtc(shiftBreaks[i].breakIn);
                        shiftBreaks[i].breakOut = Util.formatDateFromUtc(shiftBreaks[i].breakOut);
                        shiftBreaks[i].duration = Util.formatDateFromUtc(shiftBreaks[i].duration);
                    }
                }
                shiftBreaks = shiftBreaks.sort((a, b) => moment(a.breakIn) - moment(b.breakIn));
                setShiftLogBreaks(shiftBreaks);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function loadIssues() {
        if(!props.shiftLog || !props.shiftLog.id)
            return;

        const formUrl = Util.apiUrl('timelog/' + props.shiftLog.id + '/issues');
        fetch(formUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if(json.resultList) {
                    setIssues(json.resultList);
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function parseUserPositions(user = false) {
        const userDetails = user ? user : loggedInUser;
        if (userDetails.userTenantPositionList) {
            let positions = [];
            userDetails.userTenantPositionList.filter(x => !x.position.deletedOn).map(pos => {
                const position = {id: pos.positionId, name: pos.position.name};
                positions.push(position);
            });
            setPositions(_.sortBy(positions, [position => position.name.toLowerCase()]));
        }
    }

    function handlePositionChange(position) {
        if (!position) {
            setPosition(null);
        } else {
            setPosition(position);
        }
    }

    function handleEmployeeChange(employee) {
        if (!employee) {
            setEmployee(null);
        } else {
            if(!props.shiftLog.positionId) {
                handlePositionChange( null);
            }
            setEmployee({employeeCode: employee.id, employee: employee, positionsId: '', position: null});
            if(employee && employee.id === loggedInUser.id && employee.userTenantPositionList) {
                parseUserPositions(employee);
            } else {
                const apiUrl = Util.apiUrl(`users/${employee.id}`);
                fetch(apiUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                })
                    .then(response => ServerResponseHandler.getResponse(response))
                    .then(json => {
                        if (json.errorMessage) {
                            toast.error(json.errorMessage, {position: 'bottom-right'});
                            return;
                        }
                        parseUserPositions(json);
                    })
                    .catch(error => ServerResponseHandler.handleError(error))
                    .finally();
            }
        }
    }

    function handleProjectChange(project) {
        if (!project) {
            setProject({projectId: '', project: null});
        } else {
            setProject({projectId: project.id, project: project});
        }
    }

    function toggleEditBreakModal(event, breakLog) {
        setBreakLogData(breakLog);
        setBreakModal(!breakModal);
    }

    function toggleBreakModal() {
        setBreakModal(!breakModal);
    }

    function handleBreakEdit(breakLog) {
        const formUrl = Util.apiUrl(`timelog/break/${ breakLog.id }`);
        breakLog = Util.formatBreakTimesForSerialization(breakLog, false);
        fetch(formUrl, {
            method: 'PUT',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(breakLog),
        })
        .then(response => ServerResponseHandler.getResponse(response))
        .then(json => {
            if (json.errorMessage) {
                toast.error(json.errorMessage, {position: 'bottom-right'});
                return;
            }
            toast.success('Break was updated', {position: 'bottom-right'});
            loadData();
        })
        .catch(error => ServerResponseHandler.handleError(error))
        .finally(() => toggleBreakModal());
    }

    function handleBreakSubmit(event, breakLog) {
        if(breakLog.id) {
            handleBreakEdit(breakLog);
            return;
        }

        breakLog.origin = 'MANUAL';
        breakLog.duration = Util.getDurationInSeconds(breakLog.breakIn, breakLog.breakOut);
        newShiftLogBreaks.push(breakLog);
        setNewShiftLogBreaks(newShiftLogBreaks);
        toggleBreakModal();
    }

    function handleClockInChange(event) {
        let _clockInOut = {...clockInOut};
        _clockInOut.clockIn = event ? new Date(event) : null;
        setClockInOut(_clockInOut);
        clockInChanged = true;
    }

    function handleClockOutChange(event) {
        let _clockInOut = {...clockInOut};
        _clockInOut.clockOut = event ? new Date(event) : null;
        setClockInOut(_clockInOut);
        clockOutChanged = true;
    }

    function deleteBreak(breakLog) {
        let breakList = newShiftLogBreaks.slice();
        breakList = _.pull(breakList, breakLog);
        setNewShiftLogBreaks(breakList);
    }

    function confirmSubmit() {
        let today = moment.utc();
        let requested = moment.utc(clockInOut.clockIn);
        let difference = today.diff(requested, 'days');
        if(Math.abs(difference) > 30 && !shiftLog.id){
            setConfirmCreateShiftModalActive(true);
        }
        else{
            submit();
        }
    }

    function toggleConfirmCreateShiftModal(shiftLogId) {
        setConfirmCreateShiftModalActive(!confirmCreateShiftModalActive);
    }

    function submit() {
        let companySettings = Util.getCompanySettings();

        if(!props.myTimeClock && (!employee || !employee.employeeCode)){
            toast.error('You must select an employee to ' + (shiftLog.id ? 'update' : 'create') + ' a time card.', {position: 'bottom-right'});
            return;
        }

        const isFutureTimeLog = moment(clockInOut.clockIn).isAfter(moment());
        if (!Util.isManager() && !Util.getCompanySettings().allowFutureTime && isFutureTimeLog) {
            toast.error('You do not have permission to submit a future shift', {position: 'bottom-right'});
            return;
        }

        if (companySettings.requireProjectClockIn && (!project.projectId || project.projectId === '')) {
            toast.error('You must select a ' + companySettings.projectSingleName + ' to ' + (shiftLog.id ? 'update' : 'create') + ' a time card.', {position: 'bottom-right'});
            return;
        }

        if (companySettings.requirePositionClockIn && (!position || !position.id)) {
            toast.error('You must select a position to ' + (shiftLog.id ? 'update' : 'create') + ' a time card. If you are unable to select a position please contact your manager.', {position: 'bottom-right'});
            return;
        }

        if(Util.getDurationInSeconds(clockInOut.clockIn, clockInOut.clockOut) > 0 && Util.getDurationInSeconds(clockInOut.clockIn, clockInOut.clockOut) < Util.combineBreakDurations(newShiftLogBreaks)) {
            toast.error('Break duration cannot exceed shift duration.', {position: 'bottom-right'});
            return;
        }

        setSubmitLock(true);

        let geoLocation = null;
        if (props.coords) {
            geoLocation = {latitude: props.coords.latitude, longitude: props.coords.longitude};
        }

        const payload = {
            id: shiftLog.id,
            employeeCode: employee.employeeCode,
            projectId: project.projectId,
            positionId: position ? position.id : '',
            clockIn: Util.formatDateToUtc(clockInChanged ? clockInOut.clockIn : shiftLog.clockIn),
            clockOut: Util.formatDateToUtc(clockOutChanged ? clockInOut.clockOut : shiftLog.clockOut),
            overrideAutoBreak: shiftLog.overrideAutoBreak,
            breakType: shiftLog.breakType ? shiftLog.breakType.name : null,
            note: shiftLog.shiftNote,
            clockInGeoLocation: geoLocation,
            clockOutGeoLocation: geoLocation
        };
        props.handleSubmit(payload, shiftLog.id ? 'PATCH' : 'POST', newShiftLogBreaks, () => setSubmitLock(false));
    }

    return (
        <div>
            {
                !breakModal ? '' :
                    <BreakModal
                        toggleBreakModal={toggleBreakModal}
                        handleBreakSubmit={handleBreakSubmit}
                        isOpen={breakModal}
                        shiftLog={shiftLog}
                        breakLogData={breakLogData}
                    />
            }
            {
                <Modal isOpen={confirmCreateShiftModalActive}>
                    <ModalHeader>Confirm Create Shift</ModalHeader>
                    <ModalBody>
                        {'Are you sure you want to create a shift more than 30 days from now? (Date selected is ' + moment(clockInOut.clockIn).format('dddd, MMM DD YYYY')  + ')'}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => submit()}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => toggleConfirmCreateShiftModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            <Modal id={"timelog-modal-" + shiftLog.id} isOpen={props.isOpen}>
                <ModalHeader toggle={() => props.toggleModal()}>Time Log</ModalHeader>
                <ModalBody id="modal-form">
                    {
                        shiftLog.myShift ? '' :
                        !Util.isEmpty(shiftLog.employeeName)
                            ? <div className="clearfix" style={{marginBottom: 10 + 'px'}}>
                                <Label>Shift for:
                                    <span style={{
                                        fontWeight: 'bold',
                                        fontSize: 14 + 'px'
                                    }}>{'  ' + shiftLog.employeeName}</span>
                                </Label>
                            </div>
                            : <div>
                                <Label>Employee</Label>
                                <div className="mb-3">
                                    <SelectEmployee employee={employee} onChange={handleEmployeeChange}/>
                                </div>
                            </div>
                    }

                    {issues.length > 0 ?
                        <React.Fragment>
                            <h6 style={{ color: 'red' }}>Issues:</h6>
                            {issues.map((issue, index) => {
                                    return (
                                        <div style={{ color: 'red' }} key={index}>
                                            <span>{index+1}. </span><TimeLogIssue issue={issue} />
                                        </div>
                                    )
                                })}
                                <br />
                        </React.Fragment>
                    : null}

                    <Row style={{marginBottom: '15px'}}>
                        <Col>
                            <Label>Clock In</Label>
                            <FBDateTimePicker
                                id="clockIn"
                                value={clockInOut.clockIn}
                                onChange={handleClockInChange}
                            />
                        </Col>
                        <Col>
                            <Label>Clock Out</Label>
                            <FBDateTimePicker
                                id="clockOut"
                                value={clockInOut.clockOut}
                                onChange={handleClockOutChange}
                            />
                        </Col>
                    </Row>
                    {
                        !positionsEnabled
                            ? ''
                            : <div>
                                <Label>Position</Label>
                                <div className="mb-3 fb-select">
                                    <Select
                                        isClearable
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        value={position} options={positions}
                                        onChange={handlePositionChange}
                                        placeholder={'Select a Position...'}
                                    />
                                </div>
                            </div>
                    }

                    {
                        !projectsEnabled
                            ? ''
                            : <div>
                                <Label>{companySettings.projectSingleName}</Label>
                                <div className="mb-3">
                                    <SelectProject
                                        employeeNeeded={true}
                                        employee={employee}
                                        project={project.project} onChange={handleProjectChange}/>
                                </div>
                            </div>
                    }

                    <Label>Shift Note</Label>
                    <InputGroup className="mb-3">
                        <input type="text" placeholder="Shift notes" name="shiftNote"
                               value={shiftLog.shiftNote} style={{borderRadius: '4px', border: '1px solid #CCCCCC'}}
                               className="form-control" onChange={setShiftLog}/>
                    </InputGroup>
                    <Card className="card-accent-primary">
                        <CardHeader>
                            <Row className="justify-content-between align-items-center no-gutters">
                                <Col><div>Breaks</div></Col>
                                <Col>
                                    <button className="header-button btn btn-outline-success pull-right"
                                            onClick={shiftLog.id ? props.toggleBreakModal : toggleBreakModal}><i
                                        className="material-icons">add</i></button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="pl-0 pr-0">
                            <ShiftBreaksTable
                                data={!shiftLog.id ? newShiftLogBreaks : shiftLogBreaks}
                                toggleGeoLocationModal={props.toggleGeoLocationModal}
                                tableColumns={breakColumns}
                                toggleConfirmDeleteModal={!shiftLog.id ? deleteBreak : props.toggleConfirmDeleteModal}
                                toggleEditBreakModal={toggleEditBreakModal}
                                tableSettings={{
                                    canDelete: canDelete,
                                    canEdit: canEdit
                                }}
                            />
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" disabled={submitLock} onClick={() => confirmSubmit()}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => props.toggleModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

function useFormInput(initialValue) {
    const [form, setForm] = useState(initialValue);

    function handleChange(e) {
        let newFormObj = Object.assign({}, form);
        newFormObj[e.target.name] = e.target.value;
        setForm(newFormObj);
    }

    return [
        form,
        handleChange
    ]
}

export default TimeLogModal;