import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';

class RegisterSuccess extends Component {
    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="mx-4 text-center">
                                <CardBody className="p-4">
                                    <img style={{width: '100%'}} src="https://s3.amazonaws.com/fishbowllabor/StaticContent/fbtimelogo.png" /><br/><br/>
                                    <p className="text-muted"><b>Registration Successful</b><br/>Check your email inbox for further instructions.</p>
                                    <Button color="primary" block onClick={this.backButton}>Back</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    backButton(e) {
        window.location.href='/login';
    }
}

export default RegisterSuccess;
