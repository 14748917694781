import React from 'react';
import {Row, Col} from 'reactstrap';
import NoData from "./NoData";
import * as Util from "../Framework/Util";

function ManagedCompaniesTable(props) {
    return (
        <div>
            <Row className="row-header col-header col-lg-12">
                {
                    props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                props.data.length < 1 ?
                    <NoData/> :
                    props.data.map((row, index) => {
                        return <div
                            className="data-row margin-top"
                            key={index}>
                            <Row className='row-data row-header col-lg-12'>
                                <Col><span>{row.name}</span></Col>
                                <Col><span>{row.defaultContact ? row.defaultContact.email : ''}</span></Col>
                                <Col><span>{getStatus(row)}</span></Col>
                                <Col><span>{row.active ? 'Yes' : 'No'}</span></Col>
                                <Col className="cursor-text">
                                     <span title="Delete" onClick={(e) => props.toggleConfirmDeleteModal(e, row)}
                                           className="delete action-icon material-icons  ">clear</span>
                                </Col>
                            </Row>
                        </div>
                    })
            }
        </div>
    )
}

function getStatus(company) {
    if(company.active) {
        if(company.dateSubscribed) {
            return 'Active';
        } else {
           return 'On Trial';
        }
    } else {
        if(!company.dateSubscribed && Date.now() < Date.parse(company.expirationDate)) {
            return 'Trial Expired';
        }
        if(Date.now() < Date.parse(company.expirationDate)) {
            return 'Expired';
        } else {
            return 'Inactive';
        }
    }
}

export default ManagedCompaniesTable;
