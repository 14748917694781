import React, { useState, useEffect } from 'react';
import Timeline, { TimelineHeaders, SidebarHeader, CustomHeader, TimelineMarkers, TodayMarker, CustomMarker } from 'react-calendar-timeline';
import moment from 'moment';
import * as Util from '../../../components/Framework/Util';
import * as ServerResponseHandler from '../../../components/Framework/ServerResponseHandler';
import 'react-calendar-timeline/lib/Timeline.css';
import { Row, Col, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { array } from 'prop-types';

const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};

const eventStatusColors = {
    attention: '#4FA84D',
    unassigned: '#4D73A8',
    ready: '#4FA84D',
    complete: '#888A8C',
};

const ProjectScheduleTimeline = ({openEventModalById, events, users: propsUsers}) => {
    
    const [ groups, setGroups ] = useState([]);
    const [ items, setItems ] = useState([]);    
    const [ users, setUsers ] = useState([]);
    const [ workingHours, setWorkingHours ] = useState([]);
    const [ workingDays, setWorkingDays ] = useState([]);
    const [ employeeWorkingDays, setEmployeeWorkingDays ] = useState({
        "monday": 0,
        "tuesday": 0,
        "wednesday": 0,
        "thursday": 0,
        "friday": 0,
        "saturday": 0,
        "sunday": 0
    });
    const [ holidays, setHolidays ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ defaultTimeStart, setDefaultTimeStart ] = useState(moment().startOf('week'));
    const [ defaultTimeEnd, setDefaultTimeEnd ] = useState(moment().endOf('week'));
    const [ defaultTimeRange, setDefaultTimeRange ] = useState(defaultTimeEnd - defaultTimeStart);    
    const [ activeLayout, setActiveLayout ] = useState('week');

    useEffect(() => {
        const loadData = async () => {
            const fetchedUsers = [];
            const fetchedUsersWorkingHours = {...employeeWorkingDays};
            for ( const propsUser of propsUsers ) {
                const workingDays = {};
                for ( const workingDay of propsUser.workingSchedule ) {
                    workingDays[workingDay.day] = workingDay.workingHours;
                    fetchedUsersWorkingHours[workingDay.day.toLowerCase()] += workingDay.workingHours;
                }

                fetchedUsers.push({
                    id: propsUser.id,
                    nameFirst: propsUser.nameFirst,
                    imageUrl: propsUser.imageUrl,
                    name: propsUser.nameFirst + ' ' + propsUser.nameLast,
                    workingDays    
                });
            }
            
            setUsers(fetchedUsers);            
            setEmployeeWorkingDays(fetchedUsersWorkingHours);                        

            const fetchedGroups = [];
            const fetchedItems = [];
            const fetchedHolidays = [];
            for ( const event of events ) {
                if ( event.extendedProps.type === 'holiday' ) {                    
                    fetchedHolidays.push(moment(event.start).format('DD-MM-YYYY'));
                    continue;
                }
                
                if ( !fetchedGroups.find(g => g.id === event.extendedProps.projectId) ) {
                    fetchedGroups.push({
                        id: event.extendedProps.projectId,
                        title: event.title,
                        duration: event.extendedProps.duration
                    });
                }                

                fetchedItems.push({
                    id: event.id,
                    group: event.extendedProps.projectId,
                    title: '',
                    start: moment(event.start),
                    end: moment(event.end),
                    eventTimes: getEventTimes(moment(event.start), moment(event.end)),
                    users: event.extendedProps.attendees.map(u => u.userId),                    
                    bgColor : event.color,
                });
            }

            fetchedGroups.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

            setHolidays(fetchedHolidays);
            setGroups(fetchedGroups);
            setItems(fetchedItems);

            const workingHoursResponse = await fetch(Util.apiUrl('company/settings/hoursOfOperation'), { credentials: "include" });
            const workingHoursResults = await ServerResponseHandler.getResponse(workingHoursResponse);
            
            setWorkingHours([...workingHoursResults.resultList]);
            setWorkingDays(workingHoursResults.resultList.filter(x => x.active).map(w => w.dayOfWeek));

            setLoading(false);
        };

        loadData();
    }, [events]);

    const handleChangeLayout = (layout) => {
        const start = moment().startOf(layout);
        const end = moment().endOf(layout);

        setActiveLayout(layout);
        setDefaultTimeStart(start);
        setDefaultTimeEnd(end);
        setDefaultTimeRange(end - start);
    }

    const handleMoveLeft = () => {
        const start = defaultTimeStart.clone().subtract(1, activeLayout);
        const end = start.clone().endOf(activeLayout);
        
        setDefaultTimeStart(start);
        setDefaultTimeEnd(end);        
    }

    const handleMoveRight = () => {
        const start = defaultTimeStart.clone().add(1, activeLayout);
        const end = start.clone().endOf(activeLayout);

        setDefaultTimeStart(start);
        setDefaultTimeEnd(end);  
    }    

    const handleMoveToday = () => {
        setActiveLayout('week');
        setDefaultTimeStart(moment().startOf('week'));
        setDefaultTimeEnd(moment().endOf('week'));
    }

    const groupRenderer = ({group, isRightSidebar}) => {
        if ( isRightSidebar ) {
            return (  
                <div className="timeline-custom-group-right">
                    {Math.ceil(group.duration / 60 / 60)}
                </div>
            )
        }
        return (
            <div className="timeline-custom-group" style={{paddingLeft: '10px'}}>                
                {group.title}
            </div>
        )
    }    

    const itemRenderer = ({item, itemContext, getItemProps, getResizeProps}) => {        
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        
        const itemStyle = {
            border: 'none',
            backgroundColor: item.bgColor
        };
        
        return (
            <div {...getItemProps({style: itemStyle})}>
              {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
        
              <div
                className="rct-item-content"
                style={{ maxHeight: `${itemContext.dimensions.height}`, width: '100%' }}
                id={"Tooltip" + item.id}
              >                  
                {itemContext.title}
              </div>
              
              <UncontrolledTooltip target={"Tooltip" + item.id}>
                  <div style={{textAlign: 'left'}}>
                    <b>Start</b> {item.start.format('MM/DD/YYYY h:mm A')} <br/>
                    <b>End</b> {item.end.format('MM/DD/YYYY h:mm A')} 
                    {item.users.length > 0 &&
                        <React.Fragment>
                            <br/>
                            <b>Users</b> <br/>
                            <ul>
                                {users.filter(u => item.users.includes(u.id)).map(u => {
                                    return (
                                        <li key={u.id}>{u.name}</li>
                                    )
                                })}
                            </ul>
                        </React.Fragment>
                    }
                </div>
              </UncontrolledTooltip>
        
              {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
            </div>
        )
    }

    const getEventTimes = (start, end) => {
        const diffInDays = end.diff(start, 'days');

        if ( diffInDays === 0 ) {
            return [{
                date: start.format('YYYY-MM-DD'),
                durationHours: Math.ceil(end.diff(start, 'hours'))
            }];
        }

        const times = [];
        for ( let i = 0; i <= diffInDays; i++ ) {
            let date = start.clone().add(i, 'days').format('YYYY-MM-DD');

            let durationHours = 24;
            if ( i === 0 ) {
                durationHours = 24 - start.hours();
            } else if ( i === diffInDays ) {
                durationHours = start.clone().add(i, 'days').hours();
            }

            times.push({date, durationHours});
        }

        return times;
    }

    const getDayEventsDuration = (day) => {
        const formatedDay = day.format('YYYY-MM-DD');
        let eventsHours = 0;
        let usersHours = 0;

        for ( const item of items ) {
            for ( const eventTime of item.eventTimes ) {                
                if ( eventTime.date === formatedDay ) {
                    eventsHours += eventTime.durationHours;                    
                    if ( item.users.length ) {
                        usersHours += users
                            .filter(u => item.users.includes(u.id) && u.workingDays.hasOwnProperty(day.format('dddd')))
                            .map(u => u.workingDays[day.format('dddd')])
                            .reduce((a,b) => a + b, 0);                        
                    }
                }
            }
        }

        return { eventsHours, usersHours };
    }

    const handleItemClick = (itemId) => {
        openEventModalById(itemId);
    }

    if (loading) return null;
    
    return (
        <div>
            <Row>
                <Col style={{textAlign: 'center', marginBottom: '20px'}}>
                    <ButtonGroup>
                        <button
                            className={activeLayout === 'day' ? 'btn btn-outline-active' : 'btn btn-outline-inactive'}
                            onClick={() => {handleChangeLayout('day')}}
                        >
                            Day
                        </button>                        
                        <button 
                            className={activeLayout === 'week' ? 'btn btn-outline-active' : 'btn btn-outline-inactive'}
                            onClick={() => {handleChangeLayout('week')}}
                        >
                            Week
                        </button>                        
                        <button 
                            className={activeLayout === 'month' ? 'btn btn-outline-active' : 'btn btn-outline-inactive'}
                            onClick={() => {handleChangeLayout('month')}}
                        >
                            Month
                        </button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'center', marginBottom: '20px'}}>
                    <ButtonGroup>
                        <button
                            className='btn btn-outline-inactive'
                            onClick={handleMoveLeft}
                        >                            
                            &lt;
                        </button>  
                        <button
                            className='btn btn-outline-inactive'
                            onClick={handleMoveToday}
                        >
                            Today
                        </button>                        
                        <button 
                            className='btn btn-outline-inactive'
                            onClick={handleMoveRight}
                        >
                            &gt;
                        </button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col className="col-md-1" style={{ position: 'relative' }}>                    
                    <div className="timeline-legend holiday" style={{width: '30px', height: '30px', display: 'inline-block', float: 'left'}}></div>
                    <div style={{height: '30px', lineHeight: '30px', float: 'left'}}>&nbsp;Holiday</div>
                </Col>
                <Col className="col-md-2" style={{ position: 'relative' }}>
                    <div className="timeline-legend non-working-day" style={{width: '30px', height: '30px', display: 'inline-block', float: 'left'}}></div>
                    <div style={{height: '30px', lineHeight: '30px', float: 'left'}}>&nbsp;Non working day</div>
                </Col>
            </Row>
            <Timeline
                groups={groups}
                items={items}
                keys={keys}
                sidebarContent={<div>Above The Left</div>}
                itemsSorted
                itemTouchSendsClick={false}
                stackItems
                itemHeightRatio={1}
                showCursorLine
                canMove={false}
                canResize={false}
                visibleTimeStart={defaultTimeStart.valueOf()}
                visibleTimeEnd={defaultTimeEnd.valueOf()}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                lineHeight={50}
                onItemSelect={handleItemClick}
                onItemClick={handleItemClick}
                verticalLineClassNamesForTime={(start, end) => {
                    const startMoment = moment(start);     
                    const classes = [''];

                    if ( holidays.includes(startMoment.format('DD-MM-YYYY')) ) {
                        classes.push('holiday');
                    } else if ( !workingDays.includes(startMoment.format('dddd')) ) {
                        classes.push('non-working-day');
                    }

                    if ( activeLayout === 'week' ) {
                        classes.push('week-layout');
                    }
                    
                    return classes;
                }}
            >   
                <TimelineHeaders className="sticky">
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            const { style } = getRootProps();
                            return (
                                <div                            
                                    style={{
                                        ...style, 
                                        backgroundColor: '#979797',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '50px',
                                        fontWeight: 700
                                    }}
                                >
                                    Projects
                                </div>
                            )
                        }}
                    </SidebarHeader>            
                    <CustomHeader height={50} headerData={{someData: 'data'}} unit="day">
                        {({
                            headerContext: { intervals },
                            getRootProps,
                            getIntervalProps,
                            showPeriod,
                            data,
                        }) => {
                            
                            return (
                            <div {...getRootProps()}>
                                {intervals.map(interval => {
                                    let bgColor = '#979797';
                                    let fontWeight = 400;
                                    if ( moment().format('DD-MM-YYYY') === interval.startTime.format('DD-MM-YYYY') ) {
                                        bgColor = '#F5A624';
                                        fontWeight = 700;
                                    }
                                    const intervalStyle = {
                                        lineHeight: '25px',
                                        textAlign: 'center',
                                        borderLeft: '1px solid #ddd',
                                        cursor: 'pointer',
                                        backgroundColor: bgColor,
                                        color: 'white',
                                        fontWeight
                                    }
                                    const { eventsHours, usersHours } = getDayEventsDuration(interval.startTime);                                
                                    const employeeHours = employeeWorkingDays[interval.startTime.format('dddd').toLowerCase()];

                                    return (
                                        <div key={"key"}
                                            onClick={() => {
                                                return;
                                                showPeriod(interval.startTime, interval.endTime)
                                            }}
                                            {...getIntervalProps({
                                                interval,
                                                style: intervalStyle
                                            })}
                                        >
                                            <p style={{marginBottom: 0}}>{interval.startTime.format('MM/DD/YYYY')}</p>
                                            <p style={{marginBottom: 0}}>{interval.startTime.format('ddd') + ' ' + eventsHours + ' / ' + employeeHours + ' hrs'}</p>                                    
                                        </div>
                                    )
                                })}
                            </div>
                            )
                        }}
                    </CustomHeader>
                </TimelineHeaders>
            </Timeline>
        </div>
    );

}

export default ProjectScheduleTimeline;