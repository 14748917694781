import React from 'react';
import {Row, Col, Tooltip} from 'reactstrap';
import NoData from "./NoData";
import * as Util from "../Framework/Util";
import useTooltip from "../../Hooks";

function ShiftBreaksTable(props) {
    const [tooltip, setTooltip] = useTooltip({});
    return (
        <div>
            <Row className="row-header col-header col-lg-12">
                {
                    props.tableColumns.map((column, index) => {
                        return column.hideColumn ? '' :
                            <Col key={index}>{column.displayName}</Col>
                    })
                }
            </Row>
            {
                props.data.length < 1 ?
                    <NoData/> :
                    props.data.map((row, index) => {
                        return <div
                            className={props.tableSettings.canEdit ? 'data-row-edit margin-top' : 'data-row margin-top'}
                            key={index}>
                            <Row onClick={(event) => { if(props.tableSettings.canEdit) { props.toggleEditBreakModal(event, row); } }} className='row-data row-header col-lg-12'>
                                <Tooltip target={'breakIn' + index} isOpen={tooltip['breakIn' + index]} toggle={() => setTooltip('breakIn' + index, true)}>{Util.basicDateFormatter(row.breakIn, true)}</Tooltip>
                                <Col id={'breakIn' + index}>
                                    <div
                                        className={"geoLocationContainer"}>{row.breakInGeoLocation ?
                                        <span
                                            className="material-icons geoLocation geoLocation-clock-in"
                                            onClick={() => props.toggleGeoLocationModal(row.breakInGeoLocation, 'Break In Location')}>location_on</span> :
                                        <span
                                            className="material-icons geoLocation-hidden">location_on</span>}
                                        <span>{Util.ParseTimeFromDateTime(row.breakIn)}</span>
                                    </div>
                                </Col>
                                <Tooltip target={'breakOut' + index} isOpen={tooltip['breakOut' + index]} toggle={() => setTooltip('breakOut' + index, true)}>{Util.basicDateFormatter(row.breakOut, true)}</Tooltip>
                                <Col id={'breakOut' + index}>
                                    <div
                                        className={"geoLocationContainer"}>{row.breakOutGeoLocation ?
                                        <span
                                            className="material-icons geoLocation geoLocation-clock-in"
                                            onClick={() => props.toggleGeoLocationModal(row.breakOutGeoLocation, 'Break In Location')}>location_on</span> :
                                        <span
                                            className="material-icons geoLocation-hidden">location_on</span>}
                                        <span>{Util.ParseTimeFromDateTime(row.breakOut)}</span>
                                    </div>
                                </Col>
                                <Col><span>{row.breakType}</span></Col>
                                <Col><span>{row.origin === 'MANUAL' ? 'Manual' : 'Time Clock'}</span></Col>
                                {
                                    !props.tableSettings.canDelete
                                        ?
                                        ''
                                        :
                                        <Col className="cursor-text">
                                                    {row.breakOut ?
                                                     <span title="Delete"
                                                           onClick={(event) => { event.stopPropagation(); props.toggleConfirmDeleteModal(row, 'deleteShiftBreak') }}
                                                           className="delete action-icon material-icons">clear</span>
                                                               : <span title="Still active"
                                                                    onClick={(event) => { event.stopPropagation(); }}
                                                                    style={{ color: "lightgrey" }}
                                                                    className="delete action-icon material-icons">clear</span> }
                                        </Col>
                                }
                            </Row>
                        </div>
                    })
            }
        </div>
    )
}

export default ShiftBreaksTable;
