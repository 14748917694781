import React, {Component} from 'react';
import * as Util from "../../../components/Framework/Util";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import AuditTypeDropDown from "../../../components/Audit/AuditTypeDropDown";
import ResultQuantity from "../../../components/Audit/ResultQuantity";
import Pagination from "../../../components/Audit/Pagination";
import AuditComparisonModal from "../../../components/Audit/AuditComparisonModal";
import FilterModal from "../../../components/Audit/FilterModal";
import LoadingModal from "../../../components/Modal/LoadingModal";

class AuditReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowList: [],
            availablePages: 1,
            currentPage: 1,
            resultsPerPage: 10,
            startDate: '',
            endDate: '',
            lastEditedBy: '',
            createdBy: '',
            userId: '',
            currentAuditType: '',
            auditDisplayModal: false,
            filterModal: false,
        }
        this.applyFilter = this.applyFilter.bind(this);
    }

    //Table
    getTableColumns() {
        return [
            {displayName: 'Audit Id', keyValue: 'auditId'},
            {displayName: 'Entity', keyValue: 'entity'},
            {displayName: 'Change Type', keyValue: 'changeType'},
            {displayName: 'Date', keyValue: 'date'},
            {displayName: 'Changed By', keyValue: 'changedBy'}
        ];
    }

    getRows(createdBy, lastEditedBy, startDate, endDate) {
        if (this.state.currentAuditType === "") return;
        let endpoint =
            'audit/getData?type=' + this.state.currentAuditType
            + '&numResults=' + this.state.resultsPerPage + '&page=' + this.state.currentPage;

        let genericApiParams = '';
        if (lastEditedBy !== undefined && lastEditedBy !== null) genericApiParams += '&lastEditedBy=' + lastEditedBy
        if (createdBy !== undefined && createdBy !== null) genericApiParams += '&createdBy=' + createdBy
        if (startDate !== undefined && startDate !== "") genericApiParams += '&startDate=' + startDate
        if (endDate !== undefined && endDate !== "") genericApiParams += '&endDate=' + endDate

        let url = Util.apiUrl(endpoint + genericApiParams);

        fetch(url, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => this.generateRowsForTable(json))
            .then(() => this.getPaginationData(genericApiParams))
            .then(() => this.setState({loading: false}))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    generateRowsForTable(json) {
        let rows = [];
        if (json == null) return;

        for (const row of json) {
            let date = new Date(row["timeStamp"]);
            rows.push({
                auditId: row["audit_idId"],
                entity: row["table"],
                changeType: row["eventType"],
                date: date.toDateString() + " " + date.toLocaleTimeString(),
                changedBy: (!row["lastEditedBy"]) ? "" : row["lastEditedByUser"]["nameFirst"] + " " + row["lastEditedByUser"]["nameLast"],
                userId: row["lastEditedBy"],
                rowId: row["row_id"]
            });
        }
        this.setState({rowList: rows})
    }

    //Audit Type from DD
    updateAuditType(value) {
        this.setState({
            currentAuditType: value,
            createdBy: null,
            lastEditedBy: null,
            startDate: '',
            endDate: '',
            loading: true
        }, () => {
            this.getRows();
        });
    }

    //Pagination
    updateNumResults(value) {
        this.setState({resultsPerPage: value, currentPage: 1}, () => {
            this.getRows();
        });
    }

    updatePage(pageNum) {
        this.setState({currentPage: pageNum, loading: true}, () => {
            this.getRows();
        })
    }

    getPaginationData(urlAppendage) {
        let url = Util.apiUrl('audit/resultCount?type=' + this.state.currentAuditType + urlAppendage);
        fetch(url, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then((json) => this.updateResultCount(json))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    updateResultCount(resultCount) {
        let pageCount = Math.ceil(resultCount / this.state.resultsPerPage);
        if (pageCount === 0) pageCount = 1;
        this.setState({availablePages: pageCount});
    }

    //Audit Display Modal
    toggleAuditDisplayModal() {
        this.setState({auditDisplayModal: !this.state.auditDisplayModal});
    }

    setRowIdForDisplayModal(rowId) {
        this.setState({rowIdForAuditDisplayModal: rowId}, () => {
            this.toggleAuditDisplayModal();
        })
    }

    //Filter Modal
    toggleFilterModal() {
        this.setState({
            filterModal: !this.state.filterModal
        });
    }

    applyFilter(lastEditedBy, createdBy, startDate, endDate) {
        this.setState({currentPage: 1}, () => {
            this.getRows(createdBy, lastEditedBy, startDate, endDate)
        });
    }

    resetFilter() {
        this.setState({startDate: '', endDate: '', lastEditedBy: '', createdBy: ''}, () => this.getRows())
    }

    render() {
        let headers = this.getTableColumns().map(
            header => <th scope="col" key={header.keyValue}>{header.displayName}</th>);

        let rows = this.state.rowList.map(
            row => <tr key={row.auditId} onClick={() => {
                this.setRowIdForDisplayModal(row.auditId)
            }}>
                <th scope="row">{row.auditId}</th>
                <td>{row.entity}</td>
                <td>{row.changeType}</td>
                <td>{row.date}</td>
                <td>{row.changedBy}</td>
            </tr>);

        return (
            <div className="container-fluid">
                <LoadingModal loading={this.state.loading} />
                <div className="text-center "><h1>History</h1></div>
                <div className=" card-accent-primary card mt-3">
                    <div className="mb-3 ml-3 mt-3">
                        <AuditTypeDropDown auditTypeChanged={(newType) => this.updateAuditType(newType)}/>
                        <button className="btn pull-right" onClick={() => this.toggleFilterModal()}><i
                            className="material-icons">plagiarism</i></button>
                        <button className="btn pull-right" onClick={() => this.resetFilter()}>Reset</button>
                    </div>
                    <div className="">
                        <table className="table">
                            <thead>
                            <tr>
                                {headers}
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>

                        {/*pagination*/}
                        <div className="container">
                            <div className="row justify-content-center">
                                <ResultQuantity updateNumResults={(value) => this.updateNumResults(value)}/>
                                <Pagination availablePages={this.state.availablePages}
                                            currentPage={this.state.currentPage}
                                            numResults={this.state.resultsPerPage}
                                            updatePage={(pageNum) => this.updatePage(pageNum)}/>
                            </div>
                        </div>

                        <AuditComparisonModal
                            toggleModal={() => {
                                this.toggleAuditDisplayModal()
                            }}
                            modal={this.state.auditDisplayModal}
                            // auditData={this.state.currentAuditData}
                            rowId={this.state.rowIdForAuditDisplayModal}
                            auditType={this.state.currentAuditType}
                            // uuidMap={this.state.uuidMap}
                        />

                        <FilterModal
                            toggleModal={() => {
                                this.toggleFilterModal()
                            }}
                            modal={this.state.filterModal}
                            applyFilter={this.applyFilter}
                            createdBy={this.state.createdBy}
                            lastEditedBy={this.state.lastEditedBy}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            updateEditedBy={(value) => {
                                this.setState({lastEditedBy: value})
                            }}
                            updateCreatedBy={(value) => {
                                this.setState({createdBy: value})
                            }}
                            updateStartDate={(value) => {
                                this.setState({startDate: value})
                            }}
                            updateEndDate={(value) => {
                                this.setState({endDate: value})
                            }}
                            currentAuditType={this.state.currentAuditType}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AuditReport;