import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import {ToastContainer, toast} from 'react-toastify';
import * as Util from "../../../components/Framework/Util";
import moment from "moment";
import BreakRuleTable from "../../../components/DataTables/BreakRuleTable";
import FBTimePicker from "../../../components/DateTimePickers/FBTimePicker";

const companySettings = Util.getCompanySettings();
const isAdminOrManager = Util.isAdmin() || Util.isManager();
const userGlobal = Util.getUserGlobal();
const canEdit = isAdminOrManager;
const canDelete = isAdminOrManager;

const canCreateBreakRule = canEdit;
const canDeleteBreakRule = canDelete;

const dayOfWeekMapping = {
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat'
};

const dayOfWeekOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class Break extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            confirmDeleteBreakRuleDialog: false,
            selectedBreakRuleForDelete: null,
            newDialogBreakRule: false,
            breakRules: [],
            breakRule: this.getDefaultBreakRuleModal()
        };

        this.loadBreakRules = this.loadBreakRules.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleCheckBoxChangeBreakRule = this.handleCheckBoxChangeBreakRule.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBreakRuleChange = this.handleBreakRuleChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.toggleNewModalBreakRule = this.toggleNewModalBreakRule.bind(this);
        this.handleBreakRuleSubmit = this.handleBreakRuleSubmit.bind(this);
        this.dayOfWeekClicked = this.dayOfWeekClicked.bind(this);
        this.toggleConfirmDeleteBreakRuleModal = this.toggleConfirmDeleteBreakRuleModal.bind(this);
        this.deleteBreak = this.deleteBreak.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.getDefaultBreakRuleModal = this.getDefaultBreakRuleModal.bind(this);

        this.loadBreakRules(true);
    }

    loadBreakRules(initial = false) {
        const apiUrl = Util.apiUrl(`break_rule`);
        fetch(apiUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                let breakRules = json.resultList;
                breakRules = breakRules.map(x => {
                    return {
                        ...x,
                        breakRuleWeekDaySet: x.breakRuleWeekDaySet.sort(this.sortDayOfWeek),
                        placeBreakAtSpecificTime: x.placeBreakAtSpecificTime ? moment(x.placeBreakAtSpecificTime) : moment().hour(12).minute(0)
                    };
                });
                this.setState({breakRules: breakRules});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
            });
    }

    getDefaultBreakRuleModal() {
        return {
            name: '',
            isDefault: false,
            lengthValue: 15,
            repeatEvery: false,
            lengthUnit: 'Minutes',
            whenShiftHasReachedValue: 15,
            whenShiftHasReachedUnit: 'Minutes',
            placeBreakAt: 'Start Of Shift',
            placeBreakAtSpecificTime: moment(new Date(new Date().setHours(8,0,0,0))),
            breakRuleWeekDaySet: [
                {
                    active: false,
                    dayOfWeek: 'Sunday'
                },
                {
                    active: false,
                    dayOfWeek: 'Monday'
                },
                {
                    active: false,
                    dayOfWeek: 'Tuesday'
                },
                {
                    active: false,
                    dayOfWeek: 'Wednesday'
                },
                {
                    active: false,
                    dayOfWeek: 'Thursday'
                },
                {
                    active: false,
                    dayOfWeek: 'Friday'
                },
                {
                    active: false,
                    dayOfWeek: 'Saturday'
                }
            ]
        };
    }

    saveData() {
        if (!this.showFormErrors()) {
            console.error('form is invalid: do not submit');
            return;
        }

        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`company/settings`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(companySettings),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                localStorage.setItem("companySettings", JSON.stringify(json));
                toast.success('Break settings were saved', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    dayOfWeekClicked(dayOfWeek) {
        const updatedBreakRule = {...this.state.breakRule};
        const updatedBreakRuleWeekDaySet = [...updatedBreakRule.breakRuleWeekDaySet];

        const index = updatedBreakRuleWeekDaySet.findIndex(item => item.dayOfWeek === dayOfWeek);
        if (index === -1)
            return;

        const updatedDayOfWeek = {...updatedBreakRuleWeekDaySet[index]};
        updatedDayOfWeek.active = !updatedDayOfWeek.active;

        updatedBreakRuleWeekDaySet[index] = updatedDayOfWeek;
        updatedBreakRule.breakRuleWeekDaySet = updatedBreakRuleWeekDaySet;

        this.setState({breakRule: updatedBreakRule});
    }

    handleBreakRuleSubmit(event) {
        event.preventDefault();

        if (!this.state.breakRule.name) {
            toast.error('Name is empty', {position: 'bottom-right'});
            return;
        }

        const breakRule = {
            ...this.state.breakRule
        };

        if (breakRule.placeBreakAt === 'Specific Time') {
            if ((this.state.breakRule.placeBreakAtSpecificTime.isValid === undefined && !moment(this.state.breakRule.placeBreakAtSpecificTime, "YYYY-MM-DD", true).isValid())
                || !this.state.breakRule.placeBreakAtSpecificTime.isValid()) {
                toast.error('Time is required', {position: 'bottom-right'});
                return;
            } else {
                breakRule.placeBreakAtSpecificTime = Util.formatDateToUtc(breakRule.placeBreakAtSpecificTime);
            }
        }

        this.setState({newDialogBreakRule: !this.state.newDialogBreakRule});
        if (breakRule.id) {
            this.saveBreakRule('PATCH', breakRule, breakRule.id);
        } else {
            this.saveBreakRule('POST', breakRule);
        }
    }

    toggleConfirmDeleteBreakRuleModal(breakRule) {
        this.setState({
            selectedBreakRuleForDelete: breakRule,
            confirmDeleteBreakRuleDialog: !this.state.confirmDeleteBreakRuleDialog
        });
    }

    openEditModal(row) {
        this.toggleNewModalBreakRule(row);
    }

    deleteBreak(selectedBreakRuleForDelete) {
        const apiUrl = Util.apiUrl(`break_rule/${selectedBreakRuleForDelete.id}`);
        const params = {
            method: 'PATCH',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify({deletedOn: moment()}),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Break rule was deleted', {position: 'bottom-right'});
                this.loadBreakRules();
            })
            .catch(error => ServerResponseHandler.handleError(error));

        this.toggleConfirmDeleteBreakRuleModal(null);
    }

    saveBreakRule(method, obj, objId = '') {
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`break_rule/${objId}`);
        const params = {
            method: method,
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            body: JSON.stringify(obj),
        };
        fetch(apiUrl, params)
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('Break rule was saved', {position: 'bottom-right'});
                this.loadBreakRules();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({loading: false}));
    }

    toggleNewModalBreakRule(breakRule) {
        this.setState({
            breakRule: breakRule,
            newDialogBreakRule: !this.state.newDialogBreakRule
        });
    }

    handleChange(e, saveData) {
        if (e.target) {
            e.target.classList.add('active');
            this.setState({[e.target.name]: e.target.value}, () => {
                if (saveData) {
                    this.saveData();
                }
            });
            Util.showInputError(this, e.target.name);
        }
    }

    handleBreakRuleChange(e) {
        if (e.target) {
            e.target.classList.add('active');
            const updatedBreakRule = {...this.state.breakRule};
            updatedBreakRule[e.target.name] = e.target.value;

            if (e.target.name === 'placeBreakAt' && e.target.value === 'Specific Time') {
                updatedBreakRule['repeatEvery'] = false;
            }

            this.setState({breakRule: updatedBreakRule});
            Util.showInputError(this, e.target.name);
        }
    }

    handleTimeChange(time) {
        const updatedBreakRule = {...this.state.breakRule};
        updatedBreakRule['placeBreakAtSpecificTime'] = moment(time).startOf('minute');
        this.setState({breakRule: updatedBreakRule});
    }

    sortDayOfWeek(a, b) {
        return dayOfWeekOrder.indexOf(a.dayOfWeek) - dayOfWeekOrder.indexOf(b.dayOfWeek);
    }

    handleCheckBoxChangeBreakRule(e) {
        const updatedBreakRule = {...this.state.breakRule};

        updatedBreakRule[e.target.name] = e.target.checked;
        this.setState({breakRule: updatedBreakRule});
    }

    handleCheckBoxChange(e) {
        switch (e.target.name) {
            case 'allowBreaks':
                if (!e.target.checked) {
                    companySettings.paidBreaks = false;
                    companySettings.breaksInProjects = false;
                }
                break;
            case 'paidBreaks':
                if (!e.target.checked) {
                    companySettings.breaksInProjects = false;
                }
                break;
            case 'paidLunchBreaks':
                if (!e.target.checked) {
                    companySettings.lunchBreaksInProjects = false;
                }
                break;
            case 'allowLunchBreaks':
                if (!e.target.checked) {
                    companySettings.paidLunchBreaks = false;
                    companySettings.lunchBreaksInProjects = false;
                }
                break;
            case 'useAutoBreaks':
                if (!e.target.checked) {
                    companySettings.paidAutoBreaks = false;
                    companySettings.paidAutoLunchBreaks = false;
                }
                break;
        }

        companySettings[e.target.name] = e.target.checked;
        this.saveData()
    }

    showFormErrors = () => {
        let isFormValid = true;
        for (const input of document.getElementById('company-form').querySelectorAll('input')) {
            input.classList.add('active');
            const isInputValid = Util.showInputError(this, input.name);
            if (!isInputValid) {
                isFormValid = false;
            }
        }

        return isFormValid;
    };

    render() {
        const settings = companySettings;

        return (
            <div className="animated fadeIn">
                <Container fluid>
                    <Row id="company-form">
                        <Col md="12" lg="6">
                            <Card className="card-accent-primary">
                                <CardHeader>
                                    Break Settings
                                </CardHeader>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label>Use Manual Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow breaks">
                                            <Input type="checkbox" name="allowBreaks" ref="allowBreaks"
                                                   className="switch-input"
                                                   checked={settings.allowBreaks} onChange={(e) => {
                                                this.handleCheckBoxChange(e)
                                            }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="ml-3">Paid Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               style={!settings.allowBreaks ? {cursor: 'default', opacity: '0.5'} : {}}
                                               title="Employee breaks to be included in payroll">
                                            <Input type="checkbox" name="paidBreaks" ref="paidBreaks"
                                                   className="switch-input"
                                                   checked={settings.paidBreaks}
                                                   onChange={(e) => {
                                                       this.handleCheckBoxChange(e)
                                                   }}
                                                   disabled={!settings.allowBreaks}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label
                                            className="ml-3">{'Include Paid Breaks on ' + companySettings.projectPluralName}</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               style={(!settings.paidBreaks || !settings.allowBreaks) ? {
                                                   cursor: 'default',
                                                   opacity: '0.5'
                                               } : {}}
                                               title="Employee breaks to be included in payroll">
                                            <Input type="checkbox" name="breaksInProjects" ref="breaksInProjects"
                                                   className="switch-input"
                                                   checked={settings.breaksInProjects}
                                                   onChange={(e) => {
                                                       this.handleCheckBoxChange(e)
                                                   }}
                                                   disabled={!settings.paidBreaks || !settings.allowBreaks}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Use Manual Lunch Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow lunch breaks">
                                            <Input type="checkbox" name="allowLunchBreaks" ref="allowLunchBreaks"
                                                   className="switch-input"
                                                   checked={settings.allowLunchBreaks} onChange={(e) => {
                                                this.handleCheckBoxChange(e)
                                            }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="ml-3">Paid Lunch Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               style={!settings.allowLunchBreaks ? {
                                                   cursor: 'default',
                                                   opacity: '0.5'
                                               } : {}}
                                               title="Employee breaks to be included in payroll">
                                            <Input type="checkbox" name="paidLunchBreaks" ref="paidLunchBreaks"
                                                   className="switch-input"
                                                   checked={settings.paidLunchBreaks}
                                                   onChange={(e) => {
                                                       this.handleCheckBoxChange(e)
                                                   }}
                                                   disabled={!settings.allowLunchBreaks}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label
                                            className="ml-3">{'Include Paid Lunch Breaks on ' + companySettings.projectPluralName}</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               style={(!settings.paidLunchBreaks || !settings.allowBreaks) ? {
                                                   cursor: 'default',
                                                   opacity: '0.5'
                                               } : {}}
                                               title="Employee breaks to be included in payroll">
                                            <Input type="checkbox" name="lunchBreaksInProjects"
                                                   ref="lunchBreaksInProjects"
                                                   className="switch-input"
                                                   checked={settings.lunchBreaksInProjects}
                                                   onChange={(e) => {
                                                       this.handleCheckBoxChange(e)
                                                   }}
                                                   disabled={!settings.paidLunchBreaks || !settings.allowBreaks}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label>Use Auto Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow breaks">
                                            <Input type="checkbox" name="useAutoBreaks" ref="useAutoBreaks"
                                                   className="switch-input"
                                                   checked={settings.useAutoBreaks} onChange={(e) => {
                                                this.handleCheckBoxChange(e)
                                            }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="ml-3">Paid Auto Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               style={!settings.useAutoBreaks ? {
                                                   cursor: 'default',
                                                   opacity: '0.5'
                                               } : {}}
                                               title="Employee auto breaks to be included in payroll">
                                            <Input type="checkbox" name="paidAutoBreaks" ref="paidAutoBreaks"
                                                   className="switch-input"
                                                   checked={settings.paidAutoBreaks}
                                                   onChange={(e) => {
                                                       this.handleCheckBoxChange(e)
                                                   }}
                                                   disabled={!settings.useAutoBreaks}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="ml-3">Paid Auto Lunch Breaks</Label>
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               style={!settings.useAutoBreaks ? {
                                                   cursor: 'default',
                                                   opacity: '0.5'
                                               } : {}}
                                               title="Employee auto lunch breaks to be included in payroll">
                                            <Input type="checkbox" name="paidAutoLunchBreaks" ref="paidAutoLunchBreaks"
                                                   className="switch-input"
                                                   checked={settings.paidAutoLunchBreaks}
                                                   onChange={(e) => {
                                                       this.handleCheckBoxChange(e)
                                                   }}
                                                   disabled={!settings.useAutoBreaks}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {settings.useAutoBreaks ?
                            <Col md="12" lg="6">
                                <Card className="card-accent-primary">
                                    <CardHeader>
                                        <Row className="justify-content-between align-items-center no-gutters">
                                            <Col>
                                                <div>Auto Break Rules</div>
                                            </Col>
                                            {
                                                !canCreateBreakRule ? "" :
                                                    <Col>
                                                        <button
                                                            className="header-button btn btn-outline-success pull-right"
                                                            onClick={() => this.toggleNewModalBreakRule(this.getDefaultBreakRuleModal())}>
                                                            <i
                                                                className="material-icons">add</i></button>
                                                    </Col>
                                            }
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <BreakRuleTable
                                            data={this.state.breakRules}
                                            toggleConfirmDeleteModal={this.toggleConfirmDeleteBreakRuleModal}
                                            tableSettings={{
                                                canDelete: canDeleteBreakRule
                                            }}
                                            openEditModal={this.openEditModal}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            : null}
                    </Row>
                </Container>
                <Modal isOpen={this.state.newDialogBreakRule} className="modal-md">
                    <form onSubmit={this.handleBreakRuleSubmit} id="modal-form" noValidate>
                        <ModalHeader>Break Rule</ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Label>Name</Label>
                                <InputGroup className="mb-3">
                                    <input type="text" placeholder="Name" name="name" ref="breakName"
                                           value={this.state.breakRule.name}
                                           className="form-control" onChange={this.handleBreakRuleChange} required/>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Label>Type</Label>
                                <select name="breakType" ref="typeOfBreakRule"
                                        onChange={(e) => {
                                            this.handleBreakRuleChange(e)
                                        }}
                                        value={this.state.breakRule.breakType}
                                        className="form-control pull-right mb-3"
                                        style={{marginTop: '8px', marginRight: '0px'}}>
                                    <option value="Break" key="Break">Break</option>
                                    <option value="Lunch" key="Lunch">Lunch</option>
                                </select>
                            </div>
                            <Label>Place break at</Label>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <select name="placeBreakAt" ref="placeBreakAt"
                                                onChange={(e) => {
                                                    this.handleBreakRuleChange(e)
                                                }}
                                                value={this.state.breakRule.placeBreakAt}
                                                className="form-control pull-right"
                                                style={{marginTop: '8px', marginRight: '0px'}}>
                                            <option value="Start Of Shift" key="start">Start of shift</option>
                                            <option value="Middle Of Shift" key="middle">Middle of shift</option>
                                            <option value="End Of Shift" key="end">End of shift</option>
                                            <option value="Specific Time" key="specific">Specific time</option>
                                        </select>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    {this.state.breakRule.placeBreakAt === "Specific Time" ?
                                        <FBTimePicker
                                            id="placeBreakAtSpecificTime"
                                            value={this.state.breakRule.placeBreakAtSpecificTime}
                                            onChange={time => this.handleTimeChange(time)}
                                        />
                                        : null}
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label>Days of the week</Label>
                                <InputGroup className="mb-3">
                                    <div style={{display: 'flex'}}>
                                        {this.state.breakRule.breakRuleWeekDaySet.map((breakRuleWeekDay) => {
                                            return (
                                                <div key={breakRuleWeekDay.dayOfWeek}
                                                     onClick={(e) => this.dayOfWeekClicked(breakRuleWeekDay.dayOfWeek)}
                                                     className={breakRuleWeekDay.active ? "break-day-of-week-container active" : "break-day-of-week-container"}>{dayOfWeekMapping[breakRuleWeekDay.dayOfWeek]}</div>
                                            );
                                        })}
                                    </div>
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <Label>Length of break</Label>
                                <InputGroup className="mb-3">
                                    <input type="number" min="1" placeholder="Length" name="lengthValue"
                                           ref="lengthValue"
                                           value={this.state.breakRule.lengthValue}
                                           className="form-control" onChange={this.handleBreakRuleChange} required/>
                                    <select name="lengthUnit" ref="lengthUnit"
                                            onChange={(e) => {
                                                this.handleBreakRuleChange(e)
                                            }}
                                            value={this.state.breakRule.lengthUnit}
                                            className="form-control pull-right"
                                            style={{width: 60 + 'px'}}>
                                        <option value="Minutes">minutes</option>
                                        <option value="Hours">hours</option>
                                    </select>
                                </InputGroup>
                            </div>
                            {this.state.breakRule.placeBreakAt !== "Specific Time" ?
                                <div className="mb-3">
                                    <Label>Create break when shift has reached</Label>
                                    <InputGroup className="mb-3">
                                        <input type="number" min="1" placeholder="Shift has reached"
                                               name="whenShiftHasReachedValue" ref="whenShiftHasReachedValue"
                                               value={this.state.breakRule.whenShiftHasReachedValue}
                                               className="form-control" onChange={this.handleBreakRuleChange} required/>
                                        <select name="whenShiftHasReachedUnit" ref="whenShiftHasReachedUnit"
                                                onChange={(e) => {
                                                    this.handleBreakRuleChange(e)
                                                }}
                                                value={this.state.breakRule.whenShiftHasReachedUnit}
                                                className="form-control pull-right"
                                                style={{width: 60 + 'px'}}>
                                            <option value="Minutes">minutes</option>
                                            <option value="Hours">hours</option>
                                        </select>
                                    </InputGroup>
                                    <span style={{fontStyle: 'italic'}}>Note: A 10 min break after an hour would result in 50 min of work and a 10 min break.</span>
                                </div> : null
                            }
                            {this.state.breakRule.placeBreakAt !== "Specific Time" ?
                                <div className="mb-3">
                                    <InputGroup className="mb-3">
                                        <Label className="switch switch-icon switch-pill switch-info pull-right"
                                               title="Allow breaks">
                                            <Input type="checkbox" name="repeatEvery" ref="repeatEvery"
                                                   className="switch-input"
                                                   checked={this.state.breakRule.repeatEvery} onChange={(e) => {
                                                this.handleCheckBoxChangeBreakRule(e)
                                            }}/>
                                            <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                            <span className="switch-handle"/>
                                        </Label>
                                        <Label style={{marginLeft: "8px"}}>Repeat
                                            every {this.state.breakRule.whenShiftHasReachedValue} {this.state.breakRule.whenShiftHasReachedUnit}</Label>
                                    </InputGroup>
                                </div> : null
                            }
                            <div className="mb-3">
                                <InputGroup className="mb-3">
                                    <Label className="switch switch-icon switch-pill switch-info pull-right"
                                           title="Active">
                                        <Input type="checkbox" name="active" ref="active"
                                               className="switch-input"
                                               checked={this.state.breakRule.active} onChange={(e) => {
                                            this.handleCheckBoxChangeBreakRule(e)
                                        }}/>
                                        <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                        <span className="switch-handle"/>
                                    </Label>
                                    <Label
                                        style={{marginLeft: "8px"}}>{this.state.breakRule.active ? "Active" : "Inactive"}</Label>
                                </InputGroup>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success">Save</Button>{' '}
                            <Button color="secondary"
                                    onClick={() => this.toggleNewModalBreakRule(this.getDefaultBreakRuleModal())}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={this.state.confirmDeleteBreakRuleDialog}>
                    <ModalHeader>Delete Break Rule</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete break rule?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success"
                                onClick={() => this.deleteBreak(this.state.selectedBreakRuleForDelete)}>Delete</Button>{' '}
                        <Button color="secondary"
                                onClick={() => {
                                    this.toggleConfirmDeleteBreakRuleModal(null)
                                }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default Break;