import React, {Component} from 'react';
import {Container, Row, Col, CardHeader, CardBody, Card, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import moment from "moment";
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from "../../../components/Framework/Util";
import "react-table/react-table.css";
import ManageTimeOffRequests from "../Manage/ManageTimeOffRequests";
import MyTimeOff from "./MyTimeOff";
import { ToastContainer, toast } from 'react-toastify';
import * as _ from "lodash"
import PtoHolidayTable from "../../../components/DataTables/PtoHolidayTable";

const isAdminOrManager = Util.isAdmin() || Util.isManager();
let myTOSearchBarApi = {};
let manageTOSearchBarApi = {};

class Pto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTenant: Util.getUserTenant(),
            employeePtoGroup: [],
            employeePtoObject: {},
            employeeCsvData: [],
            myPtoData: [],
            myPto: [],
            page: 1,
            sizePerPage: 20,
            employee: null,
            newDialog: false,
            ptoRequest: null,
            activeTab: '1',
            ptoHolidayList: [],
            loading: true
        };

        this.getEmployeePtoRequests = this.getEmployeePtoRequests.bind(this);
        this.addPtoRequest = this.addPtoRequest.bind(this);
        this.parseTableData = this.parseTableData.bind(this);
        this.updateEmployeePtoStatus = this.updateEmployeePtoStatus.bind(this);
        this.deletePtoRequest = this.deletePtoRequest.bind(this);
        this.getMyPtoRequests = this.getMyPtoRequests.bind(this);
        this.setMyTOSearchBarApi = this.setMyTOSearchBarApi.bind(this);
        this.setManageTOSearchBarApi = this.setManageTOSearchBarApi.bind(this);
        this.getUserTenant = this.getUserTenant.bind(this);
        this.loadCompanyHolidays = this.loadCompanyHolidays.bind(this);
        this.cleanSearchObject = this.cleanSearchObject.bind(this);

        this.loadCompanyHolidays();
    }

    componentDidMount() {
        this.getUserTenant();
        if (isAdminOrManager) {
            this.getEmployeePtoRequests();
        }
        this.getMyPtoRequests();
        let tab = this.props.location.pathname === '/pto' ? 'myPto' : this.props.location.pathname.replace('/pto/', '');
        this.setState({activeTab: tab});
    }

    setMyTOSearchBarApi(api) {
        myTOSearchBarApi = api;
    }

    setManageTOSearchBarApi(api) {
        manageTOSearchBarApi = api;
    }

    getUserTenant() {
        this.setState({loading: true});
        const apiUrl = Util.apiUrl(`users/get_logged_in_user`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json) {
                if (json.errorMessage) {
                    return;
                }
                this.setState({userTenant: json.UserTenant});
                Util.storeSession(json);
            }
        }).catch(error => ServerResponseHandler.handleError(error.message)
        ).finally();
    }

    loadCompanyHolidays() {
        let activeList = [];

        const apiUrl = Util.apiUrl(`company/settings/holidays`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        }).then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                    if (json.resultList !== null && json.resultList !== undefined) {
                        json.resultList.forEach((holiday) => {
                            if (holiday.active) {
                                activeList.push(holiday);
                            }
                        });
                        this.state.ptoHolidayList = activeList;
                    }
                }
            )
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => this.setState({
                loading: false,
            }));
    }

    getMyPtoRequests() {
        const self = this;
        const search = myTOSearchBarApi.getParams();
        self.setState({loading: true});
        Util.getPayPeriods(search).then(search => {
            const apiUrl = Util.apiSearchUrl('pto/my', search);
            fetch(apiUrl, {credentials: 'include',})
                .then(response => ServerResponseHandler.getResponse(response))
                .then(json => {
                    const data = JSON.parse(JSON.stringify(json));
                    if (data.maxResults !== 0) {
                        data.resultList = self.parseTableData(data.resultList);
                    }
                    const formattedRequests = Util.groupByEmployee(data.resultList, Util.mapArray);
                    self.setState({
                        myPtoData: data.resultList,
                        myMaxResults: data.maxResults,
                        myPto: formattedRequests.groupByEmployee,
                        myPtoObject: formattedRequests.ptoObject,
                    });
                })
                .catch(error => ServerResponseHandler.handleError(error))
                .finally(() => self.setState({loading: false}));
        })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    cleanSearchObject(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || propName === 'employee') {
                delete obj[propName];
            }
        }
        return obj;
    }

    getEmployeePtoRequests() {
        const self = this;
        self.setState({loading: true});
        let search = manageTOSearchBarApi.getParams();
        search=this.cleanSearchObject(search);
        // If Sick or Vacation time has changed, update it in local storage for current user.
        const loadDataUrl = Util.apiSearchUrl(`pto`, search);
        fetch(loadDataUrl, {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                const formattedRequests = Util.groupByEmployee(_.sortBy(json.resultList, 'startDate'), Util.mapArray)
                let csvData = [];
                if (json.maxResults !== 0) {
                    csvData = self.parseTableData(json.resultList);
                }
                self.setState({
                    employeePtoGroup: formattedRequests.groupByEmployee,
                    employeePtoObject: formattedRequests.ptoObject,
                    employeeCsvData: csvData
                });
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false}))
            .catch(error => ServerResponseHandler.handleError(error));
    }

    addPtoRequest(method, payload, requestId = '') {
        const self = this;
        self.setState({loading: true});
        const formUrl = Util.apiUrl(`pto/${requestId}`);
        fetch(formUrl, {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(payload),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Time off request was saved', {position: 'bottom-right'});
                if (isAdminOrManager) {
                    self.getEmployeePtoRequests(self.state.searchParams);
                }
                self.getMyPtoRequests();
                self.getUserTenant();

            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false}));
    }

    updateEmployeePtoStatus(row, requestStatus) {
        this.updateEmployeePtoData('PATCH', row.ptoRequestId, requestStatus, row);
    };

    updateEmployeePtoData(method, ptoRequestId = '', requestStatus, row) {
        const self = this;
        self.setState({loading: true});
        row.status = requestStatus;
        const formUrl = Util.apiUrl(`pto/${ptoRequestId}`);
        fetch(formUrl, {
            method: method,
            credentials: 'include',
            body: JSON.stringify(row),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Time off request was updated', {position: 'bottom-right'});
                self.getEmployeePtoRequests(self.state.searchParams);
                self.getMyPtoRequests();
                this.getUserTenant();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false}));
    }

    deletePtoRequest(ptoRequestId, employeeId, row) {
        const self = this;
        self.setState({loading: true});
        row.deletedOn = Util.formatDateToUtc(moment());
        const formUrl = Util.apiUrl(`pto/${ptoRequestId}`);
        fetch(formUrl, {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify(row),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                toast.success('Time off was removed', {position: 'bottom-right'});
                if (isAdminOrManager) {
                    self.getEmployeePtoRequests(self.state.searchParams);
                }
                self.getMyPtoRequests()
                this.getUserTenant();
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => self.setState({loading: false, confirmDeleteDialog: false}));
    }

    parseTableData(dataList) {
        let employeeCsvData = [];
        const moment = require('moment-timezone');
        dataList.forEach(obj => {
            let startDate = moment(obj.startDate);
            obj.datesRequested = Util.basicDateFormatter(startDate.utc());
            obj.name = obj.employeeNameFirst + ' ' + obj.employeeNameLast;
            employeeCsvData.push(obj);
        });
        return _.sortBy(employeeCsvData, 'startDate');
    }

    toggle(tab) {
        let path = '/pto/' + tab;
        this.props.history.push(path);
        this.setState({
            activeTab: tab,
        });
    }

    render() {
        return (
            <div>
                <ToastContainer />
                {!isAdminOrManager && this.state.ptoHolidayList && this.state.ptoHolidayList.length === 0
                    ?
                    <div className="animated fadeIn">
                        <Container fluid>
                            <MyTimeOff
                                loading={this.state.loading}
                                getMyPtoRequests={this.getMyPtoRequests}
                                addPtoRequest={this.addPtoRequest}
                                deletePtoRequest={this.deletePtoRequest}
                                myPtoData={this.state.myPtoData}
                                myPto={this.state.myPto}
                                myPtoObject={this.state.myPtoObject}
                                userTenant={this.state.userTenant}
                                setSearchBarApi={this.setMyTOSearchBarApi}
                            />
                        </Container>
                    </div>
                    :
                    <div className="h-100">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    active={this.state.activeTab === 'myPto'}
                                    onClick={() => {
                                        this.toggle('myPto');
                                    }}
                                >
                                    My PTO
                                </NavLink>
                            </NavItem>
                            {
                                isAdminOrManager ?
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === 'employeePto'}
                                            onClick={() => {
                                                this.toggle('employeePto');
                                            }}
                                        >
                                            Employee PTO
                                        </NavLink>
                                    </NavItem> : null
                            }
                            {
                                this.state.ptoHolidayList && this.state.ptoHolidayList.length > 0 ?
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === 'holidays'}
                                            onClick={() => {
                                                this.toggle('holidays');
                                            }}
                                        >
                                            Holidays
                                        </NavLink>
                                    </NavItem> : null
                            }
                        </Nav>
                        <TabContent activeTab={this.state.activeTab} style={{height: 'calc(100% - 38px)'}}>
                            <TabPane tabId="myPto">
                                <MyTimeOff
                                    loading={this.state.loading}
                                    getMyPtoRequests={this.getMyPtoRequests}
                                    addPtoRequest={this.addPtoRequest}
                                    deletePtoRequest={this.deletePtoRequest}
                                    myPtoData={this.state.myPtoData}
                                    myPto={this.state.myPto}
                                    myPtoObject={this.state.myPtoObject}
                                    userTenant={this.state.userTenant}
                                    setSearchBarApi={this.setMyTOSearchBarApi}
                                />
                            </TabPane>
                            <TabPane tabId="employeePto">
                                <ManageTimeOffRequests
                                    getEmployeePtoRequests={this.getEmployeePtoRequests}
                                    addPtoRequest={this.addPtoRequest}
                                    updateEmployeePtoStatus={this.updateEmployeePtoStatus}
                                    deletePtoRequest={this.deletePtoRequest}
                                    employeePtoGroup={this.state.employeePtoGroup}
                                    employeePtoObject={this.state.employeePtoObject}
                                    employeeCsvData={this.state.employeeCsvData}
                                    setSearchBarApi={this.setManageTOSearchBarApi}
                                />
                            </TabPane>
                            <TabPane tabId="holidays">
                                <Container fluid>
                                    <Card className="col-lg-12 mt-3 card-accent-primary">
                                        <CardHeader>
                                            <Row className="justify-content-between align-items-center no-gutters">
                                                <Col>
                                                    <div>Holidays</div>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <PtoHolidayTable
                                                data={this.state.ptoHolidayList}
                                            />
                                        </CardBody>
                                    </Card>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>
                }
            </div>
        )
    }
}

export default Pto;