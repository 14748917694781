import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from "../../../components/Framework/PrivateRoute";

import EmployeeQrReport from "./EmployeeQrReport";
import RunPayroll from "./RunPayroll";
import EmployeeTimeCards from "./EmployeeTimeCards";
import ProjectDetailReport from "./ProjectDetailReport";
import { ToastContainer, toast } from 'react-toastify';
import AuditReport from "./AuditReport";

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            isAdmin: localStorage.getItem("isAdmin"),
            isManager: localStorage.getItem("isManager"),
        };
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Switch>
                    <PrivateRoute path="/reports/payroll" name="Reports" component={RunPayroll}/>
                    <PrivateRoute path="/reports/time-cards" name="Reports" component={EmployeeTimeCards}/>
                    <PrivateRoute path="/reports/project-details" name="Reports" component={ProjectDetailReport}/>
                    <PrivateRoute path="/reports/full-time-analysis" name="Reports" component={EmployeeQrReport}/>
                    <PrivateRoute path="/reports/employee-qr-code" name="Reports" component={EmployeeQrReport}/>
                    <PrivateRoute path="/reports/audit" name="Reports" component={AuditReport}/>
                </Switch>
            </div>
        )
    }
}

export default Reports;