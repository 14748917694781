import React from 'react';
import {Row, Col, Container, Card, CardBody} from 'reactstrap';
import CompanyName from "../SharedReportElements/CompanyName";
import Title from "../SharedReportElements/Title";
import SubTitle from "../SharedReportElements/SubTitle";
import PdfColumnHeaders from "../SharedReportElements/PdfColumnHeaders";
import NoData from "../../DataTables/NoData";
import ProjectDetailsTotalSummary from "./ProjectDetailsReportElements/ProjectDetailsTotalSummary";
import ProjectDetailsPositionSummary from "./ProjectDetailsReportElements/ProjectDetailsPositionSummary";
import ProjectDetailsEmployeeSummary from "./ProjectDetailsReportElements/ProjectDetailsEmployeeSummary";
import ProjectDetailsPdfData from "./ProjectDetailsReportElements/ProjectDetailsPdfData";
import ProjectDetailsGrandTotals from "./ProjectDetailsReportElements/ProjectDetailsGrandTotals";



function ProjectDetailsReport(props) {

    return (<Container fluid className={'page'}>
            {
                props.pdfData.projectList.length ?
                    props.pdfData.projectList.map((row, index) => {
                        let _index = index;
                        return (<div key={index} className={'mt-2'}>
                                <Card>
                                    <CardBody className={'pt-1'}>
                                        <div>
                                            {
                                                !props.pdfData.title ? '' :
                                                    <Title title={props.pdfData.title}
                                                           class={'text-center'}
                                                    />
                                            }
                                        </div>
                                        <div>
                                            {
                                                !props.pdfData.subTitle ? '' :
                                                    <SubTitle subTitle={props.pdfData.subTitle}/>
                                            }
                                        </div>
                                        <div className={'mb-2'}>
                                            <CompanyName
                                                companyName={JSON.parse(localStorage.getItem('companyTenant')).name}/>
                                        </div>
                                        <div><Row className={'justify-content-between row-header'}><Col
                                            className={"text-left pl-0"}><strong>{row.name}</strong></Col>
                                            <Col
                                                className={"col-2 text-right pr-0"}><strong>{row.projectStatus}</strong></Col></Row>
                                        </div>
                                        <div>
                                            {
                                                <PdfColumnHeaders pdfColumnHeaders={props.pdfColumnHeaders}
                                                                  class={props.pdfData.showCost ? 'col-2' : ''}/>
                                            }
                                        </div>
                                        <div>
                                            {
                                                row.timeLogList.length < 1 ? <NoData/> :
                                                    <ProjectDetailsPdfData parentIndex={_index} showColumn={props.pdfData.showCost} class={props.pdfData.showCost ? 'col-2' : ''} pdfData={row.timeLogList}/>
                                            }
                                        </div>
                                        <div>
                                            {
                                                <ProjectDetailsTotalSummary  showColumn={props.pdfData.showCost} tableHeaders={props.totalSummaryHeaders}
                                                                            totals={row}/>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    }) : <NoData/>
            }
            <Card>
                <CardBody>
                    <div>
                        {
                            <ProjectDetailsGrandTotals  showColumn={props.pdfData.showCost} tableHeaders={props.grandTotalSummaryHeaders}
                                                       totals={props.pdfData.grandTotalsSummary}/>
                        }
                    </div>
                </CardBody>
            </Card>

        { props.pdfData.showEmployeeSummary || props.pdfData.showPositionSummary ? <Card>
                <CardBody>
                    { props.pdfData.showEmployeeSummary ? <div>
                        {
                            <ProjectDetailsEmployeeSummary  showColumn={props.pdfData.showCost} tableHeaders={props.employeeSummaryHeaders}
                                                           totals={props.pdfData.employeeSummaryMap}/>
                        }
                    </div> : '' }
                    { props.pdfData.showPositionSummary ? <div>
                        {
                            <ProjectDetailsPositionSummary  showColumn={props.pdfData.showCost} tableHeaders={props.positionSummaryHeaders}
                                                           totals={props.pdfData.positionSummaryMap}/>
                        }
                    </div>: '' }
                </CardBody>
            </Card> : '' }
        </Container>
    )
}

export default ProjectDetailsReport;