import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ReactDOM from 'react-dom';
import * as Util from '../../../components/Framework/Util';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

class ProjectSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      date: new Date(),
      columnHeight: null,
      showLegend: true,
      items: props.events.map(event => { return {
          id: event.id,
          group: event.extendedProps.projectId,
          title: '',
          start: moment(event.start),
          end: moment(event.end),
          eventTimes: this.getEventTimes(moment(event.start), moment(event.end)),
          users: event.extendedProps.attendees ? event.extendedProps.attendees.map(u => u.userId) : [],
          bgColor : event.color,
          extendedProps: event.extendedProps
      }})
    };

    this.getDayEventsDuration = this.getDayEventsDuration.bind(this);
  }

  calendarRef = React.createRef();

  allowDrop = (ev) => {
    ev.preventDefault();
  };

  drop = (ev, eventId) => {
    ev.preventDefault();
    const userId = ev.dataTransfer.getData('userId');

    this.props.addUserToEvent(userId, eventId);
  }

  async componentDidMount() {    
    window.drop = this.allowDrop;

    this.setState({loading: false});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.columnHeight == null && prevState.columnHeight == this.state.columnHeight) {
           this.calculateColumnsHeight();
      }
  }

  calculateColumnsHeight() {
      const calendarColumn = ReactDOM.findDOMNode(this.calendarRef.current);
      if (calendarColumn){
          const calculatedHeight = calendarColumn.clientHeight;
          this.setState({columnHeight: calculatedHeight});
      }
  }

  updateTitleDate = inputDate => {
    let date = null;
    if (typeof inputDate === 'string') {
      date = moment(inputDate, 'YYYY-MM-DD', true);
    } else if (typeof inputDate === 'object') {
      date = moment(inputDate._date.toDateString());
    }
    if (date !== null) {
      const calendarInstance = this.calendarRef.current.getInstance();
      calendarInstance.setDate(date.toDate());
      this.setState({ date: date.toDate() });
    }
  };

    getEventTimes = (start, end) => {
        const diffInDays = end.diff(start, 'days');

        if ( diffInDays === 0 ) {
            return [{
                date: start.format('YYYY-MM-DD'),
                durationHours: Math.ceil(end.diff(start, 'hours'))
            }];
        }

        const times = [];
        for ( let i = 0; i <= diffInDays; i++ ) {
            let date = start.clone().add(i, 'days').format('YYYY-MM-DD');

            let durationHours = 24;
            if ( i === 0 ) {
                durationHours = 24 - start.hours();
            } else if ( i === diffInDays ) {
                durationHours = start.clone().add(i, 'days').hours();
            }

            times.push({date, durationHours});
        }

        return times;
    }

  renderEvent = ({ event, el }) => {
    if ( event.rendering === 'background' ) {
      let content = (
        <div style={{padding: '4px', color: '#000', paddingTop: '16px' }}>
          <p><b>{event.title}</b></p>
        </div>
      );
      ReactDOM.render(content, el);
      return el;
    }

    let content = (
      <div 
        style={{color: '#fff', padding: '4px', width: '100%', height: '100%', cursor: 'pointer'}} 
        onDrop={(ev) => {this.drop(ev, event.id)}} 
        onDragOver={(event) => {this.allowDrop(event)}}
        key={event.id}
      >
        
        <div style={{
          whiteSpace: 'nowrap',
          height: '23.8px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          <p style={{display: 'inline', marginRight: '4px'}}>{event.title}</p>
          {event.extendedProps.attendees.map(attendee => {          
            return (              
              <img key={attendee.userId} className="img-avatar" title={attendee.name} src={attendee.image} style={{ height: '2em', marginRight: '2px' }}/>              
            )
          })}
        </div>
      </div>
    );
    ReactDOM.render(content, el);
    return el;
  };

  getDayEventsDuration = (day) => {
        const formatedDay = day.format('YYYY-MM-DD');
        let eventsHours = 0;
        let usersHours = 0;

        const eventsWithoutHolidays = this.state.items.filter(x => x.extendedProps.type != 'holiday');
        for ( const item of eventsWithoutHolidays) {
            for ( const eventTime of item.eventTimes ) {
                if ( eventTime.date === formatedDay) {
                    eventsHours += eventTime.durationHours;
                }
            }
        }

        return { eventsHours, usersHours };
    }

  getNumberOfHoursForDate(momentDate, e) {
      if(moment(e.start).isSame(e.end, 'day'))
          return e.extendedProps.durationHours;

      if(momentDate.isSameOrAfter(e.start, 'day') && momentDate.isSameOrBefore(e.end, 'day')) {
          if(momentDate.isSame(e.start, 'day')) {
              const duration = moment.duration(momentDate.add(1, "days").diff(moment(e.start)));
              return parseFloat(duration.asHours().toFixed(2));
          }

          if(momentDate.isSame(e.end, 'day')) {
              const duration = moment.duration(moment(e.end).diff(momentDate));
            return parseFloat(duration.asHours().toFixed(2));
          }

          return 24;
      }

      return 0;
  }

  renderDay = ({date, el, view}) => {
    if ( view.type === 'dayGridMonth' ) {
        const momentDate = moment(date);
        const availableHours = this.props.availableEmployeeHours[momentDate.format('dddd')];
        const { eventsHours, usersHours } = this.getDayEventsDuration(momentDate);
        const isOffTime = eventsHours > availableHours;

        const contentClassName = isOffTime ? "schedule-day-cell-head day-problem-header" : "schedule-day-cell-head";

      const content = (
        <p className={contentClassName}><b>1 / {availableHours}</b></p>
      );

      ReactDOM.render(content, el);
      return el;
    }
  }

  renderHeaderDate = (date) => {
    const momentDate = moment(date);
    const availableHours = this.props.availableEmployeeHours[momentDate.format('dddd')];
    const { eventsHours, usersHours } = this.getDayEventsDuration(momentDate);
    const isToday = momentDate.startOf('day').diff(moment().startOf('day'), 'days') === 0;
    const isOffTime = eventsHours > availableHours;

    return `
      <div class="schedule-header-date ${isToday ? 'today' : ''}">
        <p>${Util.basicDateFormatter(moment(date))}</p>
        <p class="${isOffTime ? 'day-problem-header' : ''}">${moment(date).format('ddd')} ${eventsHours} / ${availableHours} hrs</p>
      </div>
    `;
  }

  handleEventClick = ({event}) => {
    const selectedEvent = this.props.events.find(e => e.id === event.id);    
    this.props.openEventModal(selectedEvent);  
    this.renderCalendar();      
  };

  renderCalendar = () => {        
    this.calendarRef.current.getApi().render();    
  };

  render() {    
    if ( this.state.loading ) return null;

    return (
      <div className="animated fadeIn">
          <div className="calendar-body calendar-light-background row">
            <div style={{ height: this.state.columnHeight ? this.state.columnHeight + "px" : "inherit" }} className="employee-section col-md-2 drop-shadow">
              <div className="employee-section-header">
                {Util.basicDateFormatter(this.state.date)}
              </div>
              <ListGroup style={{ height: "92%", overflow: "auto" }}>
                {this.props.users.map((user, key) => {
                  return (                          
                    <ListGroupItem 
                      key={key}                                   
                      draggable="true" 
                      onDragStart={e => e.dataTransfer.setData('userId', user.id)}
                      style={{cursor: 'pointer'}}
                    >
                      <img
                          className="img-avatar"
                          src={user.imageUrl}
                          style={{ height: '2em' }}
                      />                        
                      {' ' + user.nameFirst + ' ' + user.nameLast}                    
                    </ListGroupItem>                             
                  );
                })}   
              </ListGroup>      
            </div>
            <div className="col-md-10 project-schedule-calendar" style={{ position: "relative" }}>
              <FullCalendar  
                ref={this.calendarRef}             
                plugins={[timeGridPlugin, dayGridPlugin]}
                header={{
                  left: 'prev,next today',                
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                allDaySlot={false}
                events={this.props.events}
                eventRender={this.renderEvent}
                eventClick={this.handleEventClick}
                businessHours={this.props.hoursOfOperation}
                columnHeaderHtml={(date) => this.renderHeaderDate(date)}
                dayRender={this.renderDay}
                eventLimit={true}
                eventOverlap={(stillEvent, movingEvent) => {
                    return true;
                }}
                height="auto"
              />
              {this.state.showLegend ?
              <div style={{ position: "absolute", right: "28px", bottom: "34px", backgroundColor: "white", zIndex: "1", boxShadow: "2px 2px 2px lightgrey"}}>
                  <div style={{ width: "100%", height: "100%", padding: "22px 28px", display: "flex", position: "relative" }}>
                      <div style={{ marginRight: "36px" }}>
                          <div>
                              <div style={{ height: "10px", width: "10px", backgroundColor: "#2271b6", borderRadius: "50%", display: "inline-block", marginRight: "4px"}}></div>
                              <span>Unassigned</span>
                          </div>
                          <div>
                              <div style={{ height: "10px", width: "10px", backgroundColor: "#AD5050", borderRadius: "50%", display: "inline-block", marginRight: "4px"}}></div>
                              <span>Attention</span>
                          </div>
                          <div>
                              <div style={{ height: "10px", width: "10px", backgroundColor: "#4FA84D", borderRadius: "50%", display: "inline-block", marginRight: "4px"}}></div>
                              <span>Ready</span>
                          </div>
                      </div>
                      <div>
                          <div>
                              <div style={{ height: "10px", width: "10px", backgroundColor: "#888A8C", borderRadius: "50%", display: "inline-block", marginRight: "4px"}}></div>
                              <span>Completed</span>
                          </div>
                          <div>
                              <div style={{ height: "10px", width: "10px", backgroundColor: "#D0D0D0", borderRadius: "50%", display: "inline-block", marginRight: "4px"}}></div>
                              <span>Closed</span>
                          </div>
                          <div>
                              <div style={{ height: "10px", width: "10px", backgroundColor: "#F5A624", borderRadius: "50%", display: "inline-block", marginRight: "4px"}}></div>
                              <span>On Hold</span>
                          </div>
                      </div>
                    </div>
                    <i onClick={() => { this.setState({ showLegend: false }); }} style={{ position: "absolute", top: "6px", right: "6px", color: "grey", fontSize: "16px", cursor: "pointer" }} className="fa fa-times" aria-hidden="true" />
                  </div>
                : null }
                <div className="pull-right">
                    {this.state.showLegend ? <div style={{height: "19px"}}></div> :
                    <span onClick={() => { this.setState({ showLegend: true }); }} style={{ fontStyle: "italic", color: "grey", cursor: "pointer"}}>Show legend</span> }
                </div>
              </div>
          </div>
      </div>
    );
  }
}

export default ProjectSchedule;
