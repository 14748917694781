import React, { useState, useEffect } from 'react';
import { 
    Button, 
    Modal, ModalHeader, ModalBody, ModalFooter, 
    ListGroup, ListGroupItem,
    Container, Row, Col,
    Label, Input, FormGroup, Form
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import * as Util from "../../../components/Framework/Util";
import FBDateTimePicker from "../../../components/DateTimePickers/FBDateTimePicker";

let companySettings = Util.getCompanySettings();

const ProjectEventModal = (props) => {

    const { isOpen, toggleEventModal, event:originalEvent, updateEvent, detachUser, createEvent, deleteEvent, updateEventDates, events } = props;

    const [ event, setEvent ] = useState(originalEvent);
    const [ startDate1, setStartDate1 ] = useState('');
    const [ duration1, setDuration1 ] = useState('');
    const [ startDate2, setStartDate2 ] = useState('');
    const [ duration2, setDuration2 ] = useState('');
    const [ eventSplitting, setEventSplitting ] = useState(false);

    useEffect(() => {
        setEvent(originalEvent);
        setDefaults();
    }, [originalEvent])

    const setDefaults = () => {
        setEventSplitting(false);
        setStartDate1(null);
        setDuration1('');
        setStartDate2(null);
        setDuration2('');
    }

    const onSave = async () => { 

        if ( startDate1 === '' || duration1 === '' || startDate2 === '' || duration2 === '' ) {
            toast.error('All fields are required', { position: 'bottom-right' });
            return;
        }

        const durationSeconds1 = duration1 * 60 * 60;
        const durationSeconds2 = duration2 * 60 * 60;

        await createEvent(startDate1, durationSeconds1, event.extendedProps.projectId);
        await createEvent(startDate2, durationSeconds2, event.extendedProps.projectId);

        await deleteEvent(event.id);

        toggleEventModal();
    }   

    const handleStartDateChange = (date) => {
        const changedEvent = { ...event };
        changedEvent.start = date;
        setEvent(changedEvent);
    }

    const handleEndDateChange = (date) => {
        const changedEvent = { ...event };
        changedEvent.end = date;
        setEvent(changedEvent);
    }

    const handleStart1DateChange = (date) => {
        setStartDate1(date);
    }

    const handleStart2DateChange = (date) => {
        setStartDate2(date);
    }

    const onDeleteEvent = async (eventId) => {
        await deleteEvent(eventId);
        toggleEventModal();

        toast.success('Event is deleted', { position: 'bottom-right' });
    }

    const onSaveDates = async () => {
        const start = moment(event.start);
        const end = moment(event.end);

        if ( !start.isSameOrBefore(end) ) {
            toast.error('End date must be after the start date.', { position: 'bottom-right' });
            return;
        }

        const changedEvent = { ...event };
        const durationHours = end.diff(start, 'hours');

        changedEvent.extendedProps.duration = durationHours * 60 * 60;
        changedEvent.extendedProps.durationHours = durationHours;
        
        await updateEventDates(changedEvent);

        toggleEventModal();

        toast.success('Event dates modified', { position: 'bottom-right' });
    }

    const coveredHoursScheduleCalculate = (userSchedule, projectTimeIntervals, day) => {
        const userStartTime = moment(day.format("YYYY-MM-DD") + " " + userSchedule.startTime);
        const userEndTime = moment(day.format("YYYY-MM-DD") + " " + userSchedule.endTime);
        let tmpProjectTimeIntervals = projectTimeIntervals;

        projectTimeIntervals.forEach((projectInterval => {
            if(!(userStartTime.isSameOrBefore(projectInterval.start) && userEndTime.isSameOrBefore(projectInterval.start))
                || !(userStartTime.isSameOrAfter(projectInterval.end) && userEndTime.isSameOrAfter(projectInterval.end))) {

                let tmpInterval = tmpProjectTimeIntervals.find(x => x.start.isSame(projectInterval.start) && x.end.isSame(projectInterval.end));

                if(userStartTime.isSameOrBefore(projectInterval.start) && userEndTime.isSameOrAfter(projectInterval.end)) {
                    tmpInterval.allCovered = true;
                }

                if(userStartTime.isSameOrBefore(projectInterval.start) && userEndTime.isBefore(projectInterval.end)) {
                    tmpInterval.start = userEndTime;
                }

                if(userStartTime.isAfter(projectInterval.start) && userEndTime.isSameOrAfter(projectInterval.end)) {
                    tmpInterval.end = userStartTime;
                }

                if(userStartTime.isAfter(projectInterval.start) && userEndTime.isBefore(projectInterval.end)) {
                    const firstInterval = { allCovered: false, start: tmpInterval.start, end: userStartTime };
                    const secondInterval = { allCovered: false, start: userEndTime, end: tmpInterval.end };

                    const index = tmpProjectTimeIntervals.indexOf(tmpInterval);
                    tmpProjectTimeIntervals.splice(index, 1);
                    tmpProjectTimeIntervals.push(firstInterval);
                    tmpProjectTimeIntervals.push(secondInterval);
                }
            }
        }));

        return tmpProjectTimeIntervals;
    }

    const getUnCoveredHoursForDay = (day) => {
        const atendeesIds = event.extendedProps.attendees.map(x => x.userId);
        const startTime = day.isSame(event.start, 'day') ? moment(moment(event.start).format("YYYY-MM-DD h:mm a")) : day.startOf('day');
        const endTime = day.isSame(event.end, 'day') ? moment(moment(event.end).format("YYYY-MM-DD h:mm a")) : moment(day.format("YYYY-MM-DD") + " 24");

        let projectTimeIntervals = [{ allCovered: false, start: startTime, end: endTime }];

        if ( props.users && props.users.length > 0 ) {
            const userSchedules = props.users
                .filter(u => atendeesIds.includes(u.id) && u.workingSchedule.some(x => x.day == day.format('dddd')))
                .map(u => u.workingSchedule.find(x => x.day == day.format('dddd')));

            userSchedules.forEach((userSchedule) => {
                projectTimeIntervals = coveredHoursScheduleCalculate(userSchedule, projectTimeIntervals, day);
            });
        }

        const unCoveredHours = projectTimeIntervals.filter(x => !x.allCovered)
            .map(x => moment.duration(x.end.diff(x.start)).asHours())
            .reduce((a,b) => a + b, 0);

        return  unCoveredHours;
    }

    const getCoveredHours = () => {
        let uncoveredEventHours = 0;

        for (let m = moment(event.start); m.isSameOrBefore(moment(event.end)); m.add(1, 'days')) {
            uncoveredEventHours += getUnCoveredHoursForDay(m);
        }

        const coveredHours = moment.duration(moment(event.end).diff(moment(event.start))).asHours() - uncoveredEventHours;
        return coveredHours < 0 ? 0 : coveredHours;
    }

    const removeUser = (key) => {        
        const changedEvent = {...event};        

        detachUser(changedEvent.extendedProps.attendees[key].userTenantId);
        
        const attendee = changedEvent.extendedProps.attendees[key];
        changedEvent.extendedProps.attendees.splice(key, 1);
        changedEvent.extendedProps.attendeesBudget -= attendee.budgetTime;
        
        updateEvent(changedEvent);
    }

    if ( !event ) {
        return null;
    }
    
    return (
        <div>
            <Modal isOpen={isOpen}>
                <ModalBody>
                    { event.extendedProps.attendees.length > 0 &&
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <h4>Users</h4>
                                    <ListGroup>
                                        {
                                            event.extendedProps.attendees.map((attendee, key) => {
                                                return (
                                                    <ListGroupItem key={key}>
                                                        <img className="img-avatar" src={attendee.image} style={{ height: '2em' }} />
                                                        {' ' + attendee.name}
                                                        <Button className="float-right" color="danger" size="sm" onClick={() => removeUser(key)}><i className="fa fa-trash"></i></Button>                                                    
                                                    </ListGroupItem>
                                                );
                                            })
                                        }
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <h5 style={{ margin: "12px auto 0" }}>{getCoveredHours() + " / " + moment.duration(moment(event.end).diff(moment(event.start))).asHours() + " hrs"}</h5>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{width: '100%', height: 0, margin: '16px 0', borderBottom: '1px solid #dfdfdf'}}></div>
                                </Col>
                            </Row>
                        </React.Fragment>                  
                    }            
                    <h4>Schedule - Project {event.title} - {event.name}</h4>
                    <Row>
                        <Col>
                            <Row style={{marginTop: '15px', marginBottom: '15px'}}>
                                <Col>
                                    <Label for="eventStartDate">Start Date</Label>
                                    <FBDateTimePicker
                                        id="eventStartDate"
                                        value={event.start}
                                        onChange={handleStartDateChange}
                                    />
                                </Col>
                                <Col>
                                    <Label for="eventEndDate">End Date</Label>
                                    <FBDateTimePicker
                                        id="eventEndDate"
                                        value={event.end}
                                        onChange={handleEndDateChange}
                                    />
                                </Col>
                            </Row>
                            <p style={{ textAlign: 'right', marginTop: '10px', marginBottom: '5px' }}>
                                Scheduled Duration: {moment(event.end).diff(moment(event.start), "hours")} hrs
                            </p>

                            { event.extendedProps.bomCategory ?
                            <p style={{ textAlign: 'right', marginBottom: '5px' }}>
                                {companySettings.bomCategorySingleName + ": " + event.extendedProps.bomCategory }
                            </p>
                            : null
                            }

                            <p style={{ textAlign: 'right', marginBottom: '5px' }}>
                                Pick Status: {event.extendedProps.pickStatus}
                            </p>

                            { event.extendedProps.receiveDate ?
                            <p style={{ textAlign: 'right', marginBottom: '5px' }}>
                                Receive Date: { moment(event.extendedProps.receiveDate).format("MM/DD/YYYY") }
                            </p>
                            : null
                            }

                            <div style={{ marginTop: "18px" }} className="pull-right">
                                {props.events.filter(x => x.extendedProps.projectId == event.extendedProps.projectId).length > 1
                                        ? <React.Fragment><Button color="danger" onClick={() => { onDeleteEvent(event.id) }}>Delete</Button>{' '}</React.Fragment>
                                        : null
                                }

                                <Button color="primary" onClick={onSaveDates}>Save</Button>{' '}
                                <Button color="default" onClick={toggleEventModal}>Cancel</Button>
                            </div>                            
                        </Col>                    
                    </Row>    
                    <Row>
                        <Col>
                            <div style={{width: '100%', height: 0, margin: '20px 0', borderBottom: '1px solid #dfdfdf'}}></div>
                        </Col>
                    </Row>               
                    <Row>
                        <Col>
                            <h4 style={{display:'inline'}}>Split event</h4>
                            <Label
                                className="switch switch-icon switch-pill switch-info pull-right"
                                title="Enable event splitting"
                            >
                                <Input type="checkbox"                                        
                                    className="switch-input"
                                    checked={eventSplitting}
                                    onChange={() => { setEventSplitting(!eventSplitting) }}
                                />
                                <span className="switch-label" data-on={'\uf00c'} data-off={'\uf00d'}/>
                                <span className="switch-handle"/>
                            </Label>
                        </Col>        
                    </Row>                    
                    <Row style={{marginBottom: '15px'}}>
                        <Col>                            
                            <Label>Start Date</Label>
                            <FBDateTimePicker
                                id="splitStartDate1"
                                placeholder="Start Date"
                                value={startDate1}
                                disabled={!eventSplitting}
                                style={!eventSplitting ? {backgroundColor: '#f1f2f2'} : {}}
                                onChange={handleStart1DateChange}
                            />
                        </Col>
                        <Col>
                            <Label>Start Date</Label>
                            <FBDateTimePicker
                                id="splitStartDate2"
                                placeholder="Start Date"
                                value={startDate2}
                                disabled={!eventSplitting}
                                style={!eventSplitting ? {backgroundColor: '#f1f2f2'} : {}}
                                onChange={handleStart2DateChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="splitDuration1">Duration (hrs)</Label>
                            <Input type="text"
                                   id="duration1"
                                   value={duration1}
                                   placeholder="Duration"
                                   onChange={e => setDuration1(e.target.value)}
                                   disabled={!eventSplitting}
                                   style={!eventSplitting ? {cursor: 'default', opacity: '0.5'} : {}}
                            />
                        </Col>
                        <Col>
                            <Label for="splitDuration2">Duration (hrs)</Label>
                            <Input type="text"
                                   id="duration2"
                                   value={duration2}
                                   placeholder="Duration"
                                   onChange={e => setDuration2(e.target.value)}
                                   disabled={!eventSplitting}
                                   style={!eventSplitting ? {cursor: 'default', opacity: '0.5'} : {}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="pull-right" style={{marginTop: '20px'}}>
                                <Button color="primary" onClick={onSave} disabled={!eventSplitting}>Save</Button>{' '}
                                <Button color="default" onClick={toggleEventModal}>Cancel</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ProjectEventModal;